import React from "react";
import { CheckCircleTwoTone, RadioButtonUnchecked } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";

export const SortingOptions = ({ options, filters, handleFilters }) => {
    const optionsMap = {
        "Group creation Date": {
            value: undefined,
            checked: (option) => option === undefined || option === "",
        },
        "Match score": {
            value: "match",
            checked: (option) => option === "match",
        },
    };

    const optionsClassMap = {
        "Group creation Date": "group-creation-date",
        "Match score": "match-score",
    };

    const isChecked = (option, value) => {
        return optionsMap[option].checked(value);
    };

    return (
        <div className="sorting-row">
            <span>Sort by:</span>
            {options.filter(option => option !== 'Match score')?.map((option) => (
                <FormControlLabel
                    key={optionsClassMap[option]}
                    className={optionsClassMap[option]}
                    control={
                        <Checkbox
                            data-testid="filter-sorting"
                            checked={isChecked(option, filters.sort)}
                            onChange={() => handleFilters("sort", optionsMap[option].value)}
                            checkedIcon={<CheckCircleTwoTone />}
                            icon={<RadioButtonUnchecked />}
                        />
                    }
                    label={option}></FormControlLabel>
            ))}
        </div>
    );
};
