import { useEffect, useState, RefObject } from "react";

/**
 * Custom hook to detect when a component has scrolled to the bottom.
 *
 * @param {RefObject<HTMLElement>} targetRef - Reference to the target component
 * @param {() => void} callback - Optional callback function to execute when user reaches the bottom
 * @return {boolean} Returns a boolean indicating if the component has scrolled to the bottom
 */
export default function useScrollToBottom(targetRef: RefObject<HTMLElement>, callback?: () => void) {
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        function handleScroll() {
            if (!targetRef.current) return;

            const { scrollTop, clientHeight, scrollHeight } = targetRef.current;

            // Check if component has scrolled to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
        }

        if (!targetRef.current) return;

        targetRef.current.addEventListener("scroll", handleScroll);
        return () => {
            if (!targetRef.current) return;
            targetRef.current.removeEventListener("scroll", handleScroll);
        };
    }, [targetRef]); // Dependency on targetRef

    useEffect(() => {
        if (isBottom) callback?.();
    }, [isBottom]);

    return isBottom;
}
