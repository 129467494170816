import { View } from "@aws-amplify/ui-react";
import React, { useEffect, useState, useContext } from "react";
import { renderQuestions } from "./renderQuestions";

import "../Profile/Profile.css";

// COMPONENTS
import ProfileHead from "./components/ProfileHead/ProfileHead";
import { useFavorites } from "hooks/useFavorites";
import ProfileBody from "./components/ProfileBody/ProfileBody";
import useProfile from "hooks/useProfile";
import { useProfileEdit } from "hooks/useProfileEdit";
import { useLocation } from "react-router-dom";
import { completeUserData } from "utils/functions";
import useDeleteAccount from "@hooks/useDeleteAccount";

// CONTEXT
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useGlobalContext } from "context/AppContext";
import { LoginContext } from "context/LoginContext";
import { useBreakpoint } from "hooks/useBreakpoint";

// the Profile component is used to display the personal or someone else's profile, the difference being the presence of an id
// edit is used further down the page to enable/disable edit buttons
const Profile = ({ id, state, allowSendMessage, isPublicProfile, getPersonalProfile }) => {
    const {
        isEditing,
        setIsEditing,
        onSave,
        onCancel,
        editState,
        setEditState,
        changeEditState,
        isLoadingSave,
        errorPopupMessage,
        showErrorPopup,
        setShowErrorPopup,
    } = useProfileEdit({
        getPersonalProfile,
    });

    const { favoritesToDelete, setFavoritesToDelete } = usePaginatedContext();
    const { redirectToChat, redirectToSurvey, fillUpSurveyPopup, setFillUpSurveyPopup } = useProfile();
    const { filterFields, loading } = useContext(AsyncFilterFieldsContext);
    const [favorited, setFavorited] = useState(state.favorited);

    const { updateFavorites, wait } = useFavorites({ allProfiles: id, favoritesToDelete, setFavoritesToDelete });
    const favoritesTooltipTitle = favorited ? "Unfavorite User" : "Favorite User";
    const fullUserData = completeUserData(state.profile);
    const [openSaveMessageWarning, setOpenSaveMessageWarning] = useState(false);

    const [openDeleteAccount, setOpenDeleteAccount] = useState(false);
    const { closeAccountInput, setCloseAccountInput, handleCloseDeleteAccountPopup, handleDeleteAccount } =
        useDeleteAccount({
            setOpenDeleteAccount,
        });
    const deleteAccountState = {
        closeAccountInput,
        setCloseAccountInput,
        handleCloseDeleteAccountPopup,
        handleDeleteAccount,
        openDeleteAccount,
        setOpenDeleteAccount,
    };

    const { isLoggedIn, personalProfile } = useGlobalContext();
    const { isTablet, isMobile } = useBreakpoint();

    const { loginPopup, setLoginPopup } = useContext(LoginContext);

    const changeFavorited = () => setFavorited(!favorited);

    const location = useLocation();
    useEffect(() => {
        if (location.pathname === "/edit-profile") {
            setIsEditing(true);
        } else {
            setIsEditing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <View className="desktop-width fullscreen-mobile profile-root ">
            <ProfileHead
                id={id}
                state={state}
                allowSendMessage={allowSendMessage}
                isPublicProfile={isPublicProfile}
                favoritesTooltipTitle={favoritesTooltipTitle}
                updateFavorites={updateFavorites}
                favorited={favorited}
                changeFavorited={changeFavorited}
                redirectToChat={redirectToChat}
                redirectToSurvey={redirectToSurvey}
                fillUpSurveyPopup={fillUpSurveyPopup}
                setFillUpSurveyPopup={setFillUpSurveyPopup}
                locationString={fullUserData.formattedLocation}
                isGM={fullUserData.isGM}
                isPlayer={fullUserData.isPlayer}
                isProfessionalGM={fullUserData.isProfessionalGM}
                wait={wait}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                onSave={onSave}
                onCancel={onCancel}
                isLoadingSave={isLoadingSave}
                editState={editState}
                filterFields={filterFields}
                loading={loading}
                setOpenSaveMessageWarning={setOpenSaveMessageWarning}
                loginPopup={loginPopup}
                setLoginPopup={setLoginPopup}
                isLoggedIn={isLoggedIn}
                personalId={personalProfile?.id}
                isSmallScreen={isMobile || isTablet}
                twitterName={fullUserData?.twitter_name}
                changeEditState={changeEditState}
            />
            <ProfileBody
                filterFields={filterFields}
                loading={loading}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                state={state}
                renderQuestions={renderQuestions}
                changeEditState={changeEditState}
                editState={editState}
                setEditState={setEditState}
                setOpenSaveMessagingWarning={setOpenSaveMessageWarning}
                openSaveMessagingWarning={openSaveMessageWarning}
                onSave={onSave}
                deleteAccountState={deleteAccountState}
                isTablet={isTablet}
                errorPopupMessage={errorPopupMessage}
                showErrorPopup={showErrorPopup}
                setShowErrorPopup={setShowErrorPopup}
                onCancelEditing={onCancel}
                isPublicProfile={isPublicProfile}
            />
        </View>
    );
};

export default Profile;
