import { useEffect, useRef, useState } from "react";
import { getPhotoID } from "utils/functions";

const useImageSrc = (profile) => {
    const [imgSrc, setImageSource] = useState("");
    const [extensions, setExtensions] = useState(["png", "jpeg", "svg"]);
    const imgRef = useRef();
    const onError = () => {
        try {
            if (imgRef.current && extensions.length > 0) {
                const _extensions = [...extensions];
                const firstToTry = _extensions.shift();
                const profileImage = getPhotoID(profile);
                const fixedImage = profileImage.replace("jpg", firstToTry);
                setExtensions(_extensions);
                setImageSource(fixedImage);
            } else {
                setImageSource("/avatars/profile_3.png");
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        setImageSource(getPhotoID(profile));
    }, [profile]);

    return {
        onError,
        imgRef,
        imgSrc,
    };
};

export default useImageSrc;
