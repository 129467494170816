import React from "react";
import "../../pages/Home/UserList.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const FilterChosenIcon = () => {
    return (
        <>
            <CheckCircleIcon className="check" />
            <ExpandMoreIcon className="expand-icon" />
        </>
    );
};

export const FilterAccordion = ({ children, label, id, filterChosen }) => {
    return (
        <Accordion className="filter-item">
            <AccordionSummary
                className="filter-item-header"
                expandIcon={
                    <div className="expand-icon-container">
                        {filterChosen ? <FilterChosenIcon /> : <ExpandMoreIcon />}
                    </div>
                }
                id={id}>
                <Typography className={`filter-item-title ${filterChosen ? "filter-chosen" : ""}`}>{label}</Typography>
            </AccordionSummary>
            <AccordionDetails className="filter-options">{children}</AccordionDetails>
        </Accordion>
    );
};
