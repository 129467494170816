import React from "react";

import { TextField, Typography, FormGroup, FormControlLabel, Box, Checkbox } from "@mui/material";
import { Question as QuestionType } from "@pages/EmailNotificationSettings/EmailNotificationSettings.questions";
import { useAtomValue } from "jotai";

import formAtom from "@atoms/formsAtoms";

type QuestionsComponentProps<T = any> = {
    state: Partial<T>;
    setState: React.Dispatch<React.SetStateAction<Partial<T>>>;
    questions: Array<QuestionType>;
    formId: string;
};

export default function Questions<T>({ state, setState, questions, formId }: QuestionsComponentProps<T>) {
    const formOptions = useAtomValue(formAtom)[formId];

    return (
        <React.Fragment>
            {questions.map((question) =>
                question.Hidden ? null : (
                    <Question
                        key={question.Title + "_question_container"}
                        state={state}
                        setState={setState}
                        question={question}
                        disabled={Boolean(formOptions?.disabled)}
                        identifier={question.Title + "_question_container"}
                    />
                ),
            )}
        </React.Fragment>
    );
}

type QuestionComponentProps<T = any> = {
    state: Partial<T>;
    question: QuestionType;
    disabled: boolean;
    setState: React.Dispatch<React.SetStateAction<Partial<T>>>;
    identifier: string;
};

function Question<T>({
    question,
    identifier,
    state,
    setState,
    disabled,
}: QuestionComponentProps<T>): React.ReactElement {
    const questionField = question.APIField as keyof T;

    function Fields() {
        switch (question.Type) {
            case "string": {
                return (
                    <TextField
                        type="text"
                        label={question.Title}
                        value={(state[questionField] as string) || ""}
                        onChange={(e) => setState((prev) => ({ ...prev, [questionField]: e.target.value }))}
                        disabled={disabled}
                    />
                );
            }

            case "number": {
                return (
                    <TextField
                        type="number"
                        label={question.Title}
                        value={(state[questionField] as number) || ""}
                        onChange={(e) => setState((prev) => ({ ...prev, [questionField]: Number(e.target.value) }))}
                        disabled={disabled}
                    />
                );
            }

            case "boolean": {
                return (
                    <FormGroup>
                        <FormControlLabel
                            label={question.Title}
                            disabled={disabled}
                            control={
                                <Checkbox
                                    checked={Boolean(state[questionField])}
                                    onChange={() =>
                                        setState((prev) => ({
                                            ...prev,
                                            [questionField]: !state[questionField],
                                        }))
                                    }
                                />
                            }
                        />
                    </FormGroup>
                );
            }

            case "array": {
                const stateValue = state[questionField] as Record<string, boolean> | undefined;

                return (
                    <FormGroup>
                        {question.Choices?.map((choice, index) => {
                            const optionField = choice.APIField;
                            const checked: boolean = Boolean(stateValue?.[optionField]);

                            if (choice.Hidden) return null;

                            return (
                                <FormControlLabel
                                    key={index + question.Title + "_question_option_"}
                                    label={choice.Title}
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            defaultChecked={checked}
                                            disabled={disabled}
                                            onChange={() => {
                                                setState((prev) => {
                                                    const memoryValue =
                                                        (prev[questionField] as Record<string, boolean>) || {};

                                                    memoryValue[optionField] = !memoryValue[optionField];

                                                    return { ...prev, [questionField]: memoryValue };
                                                });
                                            }}
                                        />
                                    }
                                />
                            );
                        })}
                    </FormGroup>
                );
            }

            default:
                return null;
        }
    }

    return (
        <Box key={identifier} className="profile-component question">
            <Typography className="question-title">{question.Title}</Typography>
            <Typography className="question-description">{question.Description}</Typography>
            <Fields />
        </Box>
    );
}
