import "./Group.scss";

import React, { useEffect, useMemo } from "react";

import { useBreakpoint } from "hooks/useBreakpoint";
import { useGlobalContext } from "context/AppContext";
import { useLocation, useNavigate } from "react-router-dom";

import useManageGroup from "@hooks/useManageGroup";

import { View } from "@aws-amplify/ui-react";
import { Box, CircularProgress } from "@mui/material";
import { CheckOutlined, Close } from "@mui/icons-material";

import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";
import { AlertPopUpSmall } from "@components/AlertPopUp/AlertPopUpSmall";
import { GroupsActionBar } from "@components/Groups/GroupsActionBar";
import { DeleteGroupDialog } from "@components/Groups/GroupDialogs";
import { GroupPlayersManager } from "@components/Groups/GroupPlayers";
import { GroupChatLinkManager } from "@components/Groups/GroupChatLink";
import { GroupPlatformManager } from "@components/Groups/GroupPlatform";
import { GroupScheduleManager } from "@components/Groups/GroupSchedule";
import { GroupLanguagesManager } from "@components/Groups/GroupLanguages";
import { GroupGamePlayedManager } from "@components/Groups/GroupGamePlayed";
import { GroupDescriptionManager } from "@components/Groups/GroupDescription";
import { GroupCampaignTierManager } from "@components/Groups/GroupCampaignTier";
import { GroupInvitedUsersManager } from "@components/Groups/GroupInvitedUsers";
import { BlockGroupDescriptionModal } from "@components/BlockActionModal";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";

import { GroupType } from "models/group/GroupModel";
import { ProfileModel } from "models/ProfileModels";

type SurveyStateObject = {
    name: string;
    gender: string;
};

interface Props {
    title: string;
    variant: "CREATE" | "EDIT";
    onSubmit?: (group: GroupType) => void;
    onCancel?: () => void;
    surveyState?: SurveyStateObject;
}

export default function ManageGroup({ title, variant, onSubmit, onCancel, surveyState }: Props) {
    /* States and Hooks */

    const { personalProfile }: { personalProfile: Partial<ProfileModel> } = useGlobalContext();
    const { isMobile, isTablet, isDesktop, isLaptop } = useBreakpoint();

    const { state } = useLocation();
    const navigate = useNavigate();

    const groupId = state?.groupId || "";

    const profile = useMemo(() => {
        if (surveyState) {
            return {
                ...personalProfile,
                name: personalProfile.name || surveyState?.name,
                gender: personalProfile.gender || surveyState?.gender || null,
            } as Partial<ProfileModel>;
        }
        return personalProfile;
    }, [personalProfile, surveyState]);

    const {
        loading,
        groupSize,
        showErrors,
        groupState,
        setGroupSize,
        handleSubmit,
        setGroupState,
        userOwnsGroup,
        updateProperty,
        popupController,
        handleRemovePlayer,
        requiredProperties,
        blockDescriptionModal,
        openDeleteGroupDialog,
        setBlockDescriptionModal,
        setOpenDeleteGroupDialog,
    } = useManageGroup({
        groupId,
        personalProfile: profile,
        variant,
        onSubmit,
    });

    /* Lifecycle */
    useEffect(() => {
        document.title = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Variables */

    const containerClasses: string[] = [
        "group-page",
        "create-group-page",
        "manage-group-page",
        "fullscreen-mobile-group",
        isMobile ? "mobile-group-page" : "",
        isTablet ? "tablet-group-page" : "",
        isDesktop || isLaptop ? "desktop-group-page" : "",
    ];

    const userIsHost: boolean = personalProfile?.name?.[0] === groupState.Creator;

    const disableSubmit: boolean = variant === "CREATE" && userOwnsGroup;

    if (loading)
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                className={containerClasses.join(" ")}>
                <CircularProgress />
            </Box>
        );

    return (
        <>
            <AlertPopUpSmall {...popupController} />
            <BlockGroupDescriptionModal
                open={blockDescriptionModal}
                setOpen={setBlockDescriptionModal}
                cancel={{
                    onClick: () => {
                        setGroupState({});
                        navigate("/user-list");
                    },
                }}
            />
            <DeleteGroupDialog open={openDeleteGroupDialog} onClose={setOpenDeleteGroupDialog} groupId={groupId} />
            <View className={containerClasses.join(" ")}>
                <ShouldRender returnNull condition={isMobile || isTablet}>
                    <Box className="group-hero-action-bar-container">
                        <GroupsActionBar
                            title={variant === "CREATE" ? "Create group" : "Edit group"}
                            disabled={disableSubmit}
                            buttonText={variant === "CREATE" ? "Create Group" : "Save changes"}
                            handleSubmit={handleSubmit}
                        />
                    </Box>
                </ShouldRender>
                <Box className="group-components-container">
                    <GroupCampaignTierManager
                        submitDisabled={disableSubmit}
                        tier={groupState.game_hosting_type || "Free"}
                        cost={groupState.cost || 0}
                        setCost={(value) => updateProperty("cost", value)}
                        setTier={(value) => updateProperty("game_hosting_type", value)}
                        variant={variant}
                        showErrors={showErrors}
                        handleSubmit={handleSubmit}
                        startPlayingProfileLink={groupState.startplaying_link || ""}
                        setStartPlayingProfileLink={(value) => updateProperty("startplaying_link", value)}
                    />
                    <GroupGamePlayedManager
                        game={groupState.game || 0}
                        variant={variant}
                        setGame={(value) => updateProperty("game", value)}
                        showErrors={showErrors}
                    />
                    <GroupScheduleManager
                        variant={variant}
                        showErrors={showErrors}
                        personalProfile={personalProfile}
                        groupScheduleEnd={groupState.schedule_end || ""}
                        groupScheduleStart={groupState.schedule_start || ""}
                        setGroupScheduleEnd={(value) => updateProperty("schedule_end", value)}
                        setGroupScheduleStart={(value) => updateProperty("schedule_start", value)}
                    />
                    <GroupPlatformManager
                        setGroup={setGroupState}
                        platform={groupState.game_platform || 0}
                        groupCity={groupState.city}
                        groupState={groupState.state}
                        showErrors={showErrors}
                        setPlatform={(value) => updateProperty("game_platform", value)}
                        groupCountry={groupState.country || ""}
                    />
                    <GroupPlayersManager
                        groupId={groupId}
                        variant={variant}
                        members={groupState.members || []}
                        hostView={userIsHost}
                        playerAmount={groupSize}
                        handleJoinGroup={() => {}}
                        setPlayerAmount={setGroupSize}
                        handleRemovePlayer={handleRemovePlayer}
                    />
                    <GroupInvitedUsersManager {...{ showErrors, groupSize, group: groupState }} />
                    <GroupChatLinkManager
                        link={groupState.url_link || ""}
                        setLink={(value) => updateProperty("url_link", value)}
                        showErrors={showErrors}
                    />
                    <GroupDescriptionManager
                        description={groupState.groups_description || ""}
                        setDescription={(value) => updateProperty("groups_description", value)}
                    />
                    <GroupLanguagesManager
                        language={groupState.languages || 0}
                        setLanguages={(value) => updateProperty("languages", value)}
                    />
                    <ShouldRender
                        returnNull
                        condition={isMobile}
                        alternativeComponent={
                            <Box className="group-bottom-buttons">
                                <ShouldRender
                                    alternativeComponent={
                                        <SecondaryApplyButton
                                            icon={<Close fontSize="small" />}
                                            onClick={() => setOpenDeleteGroupDialog(true)}>
                                            Delete Group
                                        </SecondaryApplyButton>
                                    }
                                    returnNull
                                    condition={variant === "CREATE"}>
                                    <SecondaryApplyButton
                                        onClick={() => {
                                            if (onCancel) onCancel();
                                            else {
                                                setGroupState({});
                                                navigate(-1);
                                            }
                                        }}
                                        icon={<Close fontSize="small" />}>
                                        Cancel
                                    </SecondaryApplyButton>
                                </ShouldRender>
                                <ApplyButton
                                    onClick={handleSubmit}
                                    disabled={disableSubmit}
                                    icon={<CheckOutlined fontSize="small" />}>
                                    {variant === "CREATE" ? "Create group" : "Save Changes"}
                                </ApplyButton>
                            </Box>
                        }>
                        <ApplyButton
                            icon={<CheckOutlined fontSize="small" />}
                            disabled={disableSubmit}
                            onClick={handleSubmit}>
                            {variant === "CREATE" ? "Create group" : "Save Changes"}
                        </ApplyButton>
                    </ShouldRender>
                </Box>
            </View>
        </>
    );
}
