import React, { useMemo } from "react";
import { Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import "./index.css";

function MenuLogIn({ isLoggedIn, btnFunc = null, userHasProfile, activeBtn }) {
    const logInRedirect = "/";

    const loggedInLink = useMemo(() => {
        return userHasProfile ? "/profile" : "/createprofile";
    }, [userHasProfile]);

    function handleClick(event) {
        if (!btnFunc) return;

        event.preventDefault();
        btnFunc();
    }

    return (
        <>
            <Link
                onClick={handleClick}
                to={isLoggedIn ? loggedInLink : logInRedirect}
                className={`menu-item-link ${activeBtn === "/profile" ? "active" : ""}`}>
                <AccountCircleTwoToneIcon sx={{ width: "24px" }} />
                <Text className="hamburger-menu-item">My Profile</Text>
            </Link>
        </>
    );
}

export default MenuLogIn;
