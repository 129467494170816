import "./GroupDialogs.scss";

import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import { ArrowForwardIos, Check, Close } from "@mui/icons-material";

import { leaveGroup } from "@services/groupsProvider";
import { AlertPopUpSmall } from "@components/AlertPopUp/AlertPopUpSmall";
import { usePopupController } from "@components/AlertPopUp";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { conditionalClassName } from "utils/functions";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { useMessages } from "@pages/Inbox/hooks/useMessages";

type JoinGroupDialogProps = {
    groupId: string;
    open: boolean;
    onUndo: () => void;
    onConfirm: () => void;
    creatorId?: string;
    onClose: () => void;
};

export default function JoinGroupDialog({
    open,
    onUndo,
    groupId,
    onClose,
    onConfirm,
    creatorId,
}: JoinGroupDialogProps) {
    /* States and Hooks */
    const navigate = useNavigate();
    const { handleMessageClick } = useMessages();

    const [messageState, setMessageState] = useState("Meet Your Fellowship");
    const groupPathRe = new RegExp(
        "/group/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}",
    );

    useEffect(() => {
        try {
            if (groupPathRe.test(window.location.pathname)) {
                setMessageState("Message Host");
            } else {
                setMessageState("Meet Your Fellowship");
            }
        } catch (error) {
            console.error("there was an error proceeding with this request", error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { isMobile } = useBreakpoint();
    const { popup, showPopup } = usePopupController();

    /* Functions */
    async function leave() {
        try {
            await leaveGroup(groupId);

            showPopup({
                title: "Success",
                severity: "success",
                description: "We understand accidents can happen, and you have been removed from the group.",
            });

            onUndo?.();
        } catch (error) {
            showPopup({
                title: "Error",
                severity: "error",
                description:
                    "We understand accidents can happen, but we weren’t able to remove you from the group at this time. Please try again.",
            });
        }
    }

    function goToGroup() {
        if (groupPathRe.test(window.location.pathname)) {
            handleMessageClick({ publicID: creatorId as string });
            onConfirm?.();
        } else {
            navigate(`/group/${groupId}`);
            onConfirm?.();
        }
    }

    /* Variables */

    const className = conditionalClassName(
        ["group-dialog-container"],
        ["join-group"],
        [isMobile, "group-dialog-mobile"],
    );

    return (
        <>
            <AlertPopUpSmall {...popup} />
            <Dialog open={open}>
                <DialogContent className={className}>
                    <ShouldRender returnNull condition={!isMobile}>
                        <Check
                            sx={{
                                background: "#4caf50",
                                color: "#171717",
                            }}
                            className="group-icon"
                        />
                    </ShouldRender>
                    <Box className="group-content">
                        <Typography variant="h3" className="group-alert-title">
                            You are in!
                            <Close className="close-icon" onClick={onClose} />
                        </Typography>
                        <Typography className="group-alert-description">
                            Join us by the fire, dear friend, and share in the tales of distant lands and realms untold.
                        </Typography>
                        <Box className="group-dialog-buttons">
                            <SecondaryApplyButton
                                onClick={leave}
                                icon={<Box component={"img"} src="/redo-arrow.svg" />}>
                                undo
                            </SecondaryApplyButton>
                            <ApplyButton onClick={goToGroup}>
                                {messageState} <ArrowForwardIos fontSize="small" />
                            </ApplyButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
