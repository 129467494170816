import { useEffect, useState } from "react";

import { useAtom } from "jotai";
import { groupSizeAtom, invitedUsersAtom, InvitedUserType } from "@atoms/groupsAtoms";

import { getGroups } from "@services/groupsProvider";
import { GroupType, GroupTypeInList } from "@models/group/GroupModel";

import { emailRegexp } from "utils/constants";
import { preventDoubleCall } from "@utils/promiseUtils";
import { GroupInvitedUsersManagerProps } from "components/Groups/GroupInvitedUsers/GroupInvitedUsersManager";
import { useBreakpoint } from "./useBreakpoint";
import { ProfileModel } from "models/ProfileModels";

type useGroupInviteProps = {
    user?: ProfileModel;
    group?: Partial<GroupType>;
    variant?: GroupInvitedUsersManagerProps["variant"];
};
export default function useGroupInvite({ variant = "embedded", group, user }: useGroupInviteProps = {}) {
    /* States and Hooks */
    const [users, setUsers] = useAtom(invitedUsersAtom);
    const [loading, setLoading] = useState(true);
    const [groupSize, setGroupSize] = useAtom(groupSizeAtom);
    const [viewerGroup, setViewerGroup] = useState<GroupTypeInList | null>(null);
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [openInvitedUsers, setOpenInvitedUsers] = useState(false);

    const { isMobile } = useBreakpoint();

    /* Functions */

    /**
     * Validate and check if all users has the correct email address or if they are fulfilled
     * @param users List of users
     * @returns {Array<InvitedUserType>} List of validated users to be updated in the state
     */
    function validateUsers(users: Array<InvitedUserType>): Array<InvitedUserType> {
        const filtered = structuredClone(users).map((user, index, list) => {
            if (!Boolean(user.name)) return user;
            // In case the user is not an email then we don't need to do anything
            if (!user.name?.includes("@")) return user;
            // Otherwise we need to check if it is a valid email
            if (!emailRegexp.test(user.name)) {
                // Set the error message in the state to be shown
                list[index].error = "Please type a valid email address";
            }
            return user;
        });

        return filtered;
    }

    /**
     * Extract the username or id from each user to be used in the API, centralizing the logic
     * @param {Array<InvitedUserType>} users List of users
     * @returns {Array<string>} List of usernames or ids
     */
    function extractUserInformation(users: Array<InvitedUserType>): Array<string> {
        return users.map((user) => (Boolean(user.id) ? user.id : user.name)).filter(Boolean);
    }

    function resetState(clear: boolean = false) {
        if (!groupSize) return;
        const seats: Array<InvitedUserType> = new Array(
            isMobile && variant === "popup"
                ? 1 // Mobile variant for single invite
                : (groupSize as number) -
                  (group?.members?.filter((member) => member.member_type !== "OPEN SEAT").length || 0),
        )
            .fill({
                id: "",
                image: "",
                name: "",
            })
            .map((seat, index) => {
                if (Boolean(users[index]) && !clear) return users[index];
                else return seat;
            });

        setUsers(seats);
    }

    function openInvitedUsersDialog() {
        setOpenInvitedUsers(true);
    }

    function closeInvitedUsersDialog() {
        setOpenInvitedUsers(false);
        resetState();
    }

    /* Lifecycle */
    useEffect(() => {
        if (variant === "embedded")
            // Only check if there is an owned group if the variant is "embedded"
            // otherwise it should be checked manually to prevent side effects
            preventDoubleCall<Array<GroupTypeInList>>(() => getGroups({ QueryType: "OWNED" })).then((res) => {
                if (res.length > 0) setViewerGroup(res[0]);
                setLoading(false);
            });
    }, []);

    useEffect(resetState, [group, groupSize, isMobile, variant]);

    useEffect(() => {
        if (group?.group_size) setGroupSize(group.group_size);
    }, [group?.group_size]);

    /* Variables */
    const userIsMember: boolean = !!viewerGroup?.members?.find((member) => member.id === user?.id);

    return {
        loading,
        resetState,
        viewerGroup,
        userIsMember,
        openInviteDialog,
        setOpenInviteDialog,
        validateUsers,
        invitedUsers: users,
        setInvitedUsers: setUsers,
        extractUserInformation,
        openInvitedUsers,
        setOpenInvitedUsers: (value: boolean) => {
            if (value) openInvitedUsersDialog();
            else closeInvitedUsersDialog();
        },
    };
}
