import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ProfileChangeEmailDialog from "components/ProfileChangeEmail/Dialog/ProfileChangeEmailDialog";
import ProfileChangeEmailDialogScreens, { DEFAULT_SCREENS } from "components/ProfileChangeEmail/Screens";
import { ChangeEmailContext, ChangeEmailProvider } from "context/ChangeEmailContext";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import "./style.scss";
import useCurrentUserInfo from "hooks/useCurrentUserInfo";
import useChangeEmail from "hooks/useChangeEmail";
import { Auth } from "aws-amplify";
import { useSignOut } from "hooks/useSignOut";
import { useSnackbarContext } from "context/SnackbarContext";

const DEFAULT_OBJ = {
    onCancelText: null,
    onAcceptText: null,
    hideCloseBtn: true,
    onClose: () => null,
    onCancel: () => null,
    onAccept: () => null,
};

const ProfileChangeEmail = () => {
    const [showDialog, setShowDialog] = useState(false);
    const {
        activeScreen,
        setActiveScreen,
        isBusy,
        setIsBusy,
        isLocked,
        setInitPasswordValidation,
        passwordIsValid,
        setPasswordIsValid,
        newValidEmail,
        code,
        setCodeIsInvalid,
    } = useContext(ChangeEmailContext);
    const { email } = useCurrentUserInfo();
    const { change, update } = useChangeEmail();
    const { signOutAndRedirect } = useSignOut();
    const { showSnackbar } = useSnackbarContext();

    function closeDialog() {
        setShowDialog(false);
        setActiveScreen(DEFAULT_SCREENS.FORM);
    }

    useEffect(() => {
        if (passwordIsValid && DEFAULT_SCREENS.FORM) {
            setIsBusy(false);
            setActiveScreen(DEFAULT_SCREENS.VALIDATE);
        }
    }, [passwordIsValid]);

    const selectActiveScreen = useCallback(() => {
        if (!activeScreen) return;
        switch (activeScreen) {
            case "form":
                return {
                    ...DEFAULT_OBJ,
                    title: "Change Email",
                    onCancelText: "Cancel",
                    onAcceptText: "Change Email",
                    onCancel: () => {
                        closeDialog();
                        setPasswordIsValid(false)
                    },
                    onAccept: async () => {
                        if (isBusy || isLocked) return;
                        if (passwordIsValid){
                            setActiveScreen(DEFAULT_SCREENS.VALIDATE);
                        } else {
                            setIsBusy(true);
                            setInitPasswordValidation(true);
                        }
                    },
                    onClose: () => {
                        console.log("CLOSE")
                        if (isBusy) return;
                        closeDialog();
                    },
                };

            case "validate":
                return {
                    ...DEFAULT_OBJ,
                    title: "Validate Email",
                    onCancelText: "Back",
                    onAcceptText: "Proceed",
                    onAccept: () => {
                        setIsBusy(true);
                        // Change email attribute (Cognito)
                        change(newValidEmail)
                            .then((r) => {
                                setActiveScreen(DEFAULT_SCREENS.CONFIRMATION);
                            })
                            .catch((e) => {
                                showSnackbar({
                                    title: "Something went wrong",
                                    anchor: { vertical: "top", horizontal: "right" },
                                    description: e?.response?.data?.Error || "Please, try again later.",
                                });
                                console.error(e);
                            })
                            .finally(() => {
                                setIsBusy(false);
                            });
                    },
                    onCancel: () => {
                        setActiveScreen(DEFAULT_SCREENS.FORM);
                    },
                    onClose: () => {
                        closeDialog();
                    },
                };

            case "confirmation":
                return {
                    ...DEFAULT_OBJ,
                    title: "Confirm new Email",
                    onAcceptText: "Confirm",
                    onCancelText: null,
                    onAccept: async () => {
                        setIsBusy(true);
                        // Verify attribute change confirmation code:
                        Auth.verifyCurrentUserAttributeSubmit("email", code)
                            .then(() => {
                                // Confirmation code is valid!
                                // Run Neptune update:
                                update()
                                    .then((r) => {
                                        setActiveScreen(DEFAULT_SCREENS.FINISH);
                                        // Neptune successfully updated!
                                        showSnackbar({
                                            title: "Success",
                                            severity: "success",
                                            anchor: { vertical: "top", horizontal: "right" },
                                            description:
                                                "Your email address has been successfully updated. Please log in again to confirm the change.",
                                        });
                                    })
                                    .catch((e) => {
                                        // Error when trying to update Neptune.
                                        showSnackbar({
                                            title: "Something went wrong",
                                            anchor: { vertical: "top", horizontal: "right" },
                                            description:
                                                "If you’re having troubles, please contact support (hello@rpgmatch.org).",
                                        });
                                        console.error(e);
                                    })
                                    .finally(() => {
                                        // Force user logout in any case
                                        setTimeout(() => {
                                            signOutAndRedirect();
                                        }, 1500);
                                    });
                            })
                            .catch((e) => {
                                if (!String(e).includes("Invalid verification code provided")) {
                                    showSnackbar({
                                        title: "Something went wrong",
                                        anchor: { vertical: "top", horizontal: "right" },
                                        description: "Please try again later (" + e + ").",
                                    });
                                }
                                setIsBusy(false);
                                setCodeIsInvalid(true);
                                console.error(e);
                            });
                    },
                };
            case "finish":
                return {
                    ...DEFAULT_OBJ,
                    onAccept: () => {
                        // logout user
                        closeDialog();
                    },
                    onCancel: () => {
                        setActiveScreen(DEFAULT_SCREENS.CONFIRMATION);
                    },
                    onClose: () => {
                        closeDialog();
                    },
                };
            default:
                return DEFAULT_OBJ;
        }
    }, [showDialog, activeScreen, isLocked, code]);

    const renderDialog = useMemo(() => {
        if (showDialog && activeScreen) {
            return (
                <ProfileChangeEmailDialog data={selectActiveScreen()} show={showDialog}>
                    <ProfileChangeEmailDialogScreens />
                </ProfileChangeEmailDialog>
            );
        }
    }, [showDialog, activeScreen, isLocked, code]);

    return (
        <>
            <View className={"profile-change-email"}>
                <div className={"profile-change-email_current"}>
                    <Text>
                        <small>Email</small>
                        <br />
                        {email}
                    </Text>
                    <button
                        className="profile-change-email_current-button"
                        onClick={() => {
                            setShowDialog(true);
                        }}>
                        Change Email
                    </button>
                </div>
                {renderDialog || null}
            </View>
            <Backdrop sx={{ color: "#fff", zIndex: "1399" }} open={isBusy} onClick={() => null}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};

export default ProfileChangeEmail;

export const ProfileChangeEmailWrapper = () => {
    return (
        <ChangeEmailProvider>
            <ProfileChangeEmail />
        </ChangeEmailProvider>
    );
};
