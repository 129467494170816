export function matchColor(matchScore) {
    const matchScoreColor = {
        bgColor: "",
        borderColor: "",
        textColor: "",
    };
    switch (true) {
        case matchScore <= 30:
            matchScoreColor.bgColor = "#999999";
            matchScoreColor.borderColor = "#B8B8B8";
            matchScoreColor.textColor = "#171717";
            break;
        case matchScore <= 35:
            matchScoreColor.bgColor = "#B7B4AB";
            matchScoreColor.borderColor = "#D6D6D6";
            matchScoreColor.textColor = "#171717";
            break;
        case matchScore <= 40:
            matchScoreColor.bgColor = "#B5AA87";
            matchScoreColor.borderColor = "#CAC2A8";
            matchScoreColor.textColor = "#2D2D2E";
            break;
        case matchScore <= 45:
            matchScoreColor.bgColor = "#C7B166";
            matchScoreColor.borderColor = "#D6BB5D";
            matchScoreColor.textColor = "#2D2D2E";
            break;
        case matchScore <= 50:
            matchScoreColor.bgColor = "#DEB41D";
            matchScoreColor.borderColor = "#EBDB68";
            matchScoreColor.textColor = "#121212";
            break;
        case matchScore <= 55:
            matchScoreColor.bgColor = "#DCCC41";
            matchScoreColor.borderColor = "#E3DF78";
            matchScoreColor.textColor = "#121212";
            break;
        case matchScore <= 60:
            matchScoreColor.bgColor = "#C6C952";
            matchScoreColor.borderColor = "#D5D85C";
            matchScoreColor.textColor = "#121212";
            break;
        case matchScore <= 65:
            matchScoreColor.bgColor = "#AEBE4D";
            matchScoreColor.borderColor = "#C8DC4C";
            matchScoreColor.textColor = "#121212";
            break;
        case matchScore <= 70:
            matchScoreColor.bgColor = "#A1AF4C";
            matchScoreColor.borderColor = "#C2D161";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 75:
            matchScoreColor.bgColor = "#89AF4C";
            matchScoreColor.borderColor = "#9DC857";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 80:
            matchScoreColor.bgColor = "#5AAF4C";
            matchScoreColor.borderColor = "#66BB6A";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 85:
            matchScoreColor.bgColor = "#43A047";
            matchScoreColor.borderColor = "#4CAF50";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 90:
            matchScoreColor.bgColor = "#388E3C";
            matchScoreColor.borderColor = "#43A047";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 95:
            matchScoreColor.bgColor = "#2E7D32";
            matchScoreColor.borderColor = "#388E3C";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        case matchScore <= 100:
            matchScoreColor.bgColor = "#1B5E20";
            matchScoreColor.borderColor = "#2E7D32";
            matchScoreColor.textColor = "#FFFFFF";
            break;
        default:
            matchScoreColor.bgColor = "#999999";
            matchScoreColor.borderColor = "#B8B8B8";
            matchScoreColor.textColor = "#171717";
            break;
    }
    return matchScoreColor;
}
