import React from "react";
import { View, Text, Loader } from "@aws-amplify/ui-react";

export function LoaderComponent({ content = "Fetching users..." }) {
    return (
        <View data-testid="loader-component" textAlign={"center"} height={"auto"} margin={"1rem"}>
            <Text margin={"1rem"}>{content}</Text>
            <Loader width={"6rem"} height={"6rem"} filledColor={"white"} emptyColor={"red"} />
        </View>
    );
}
