import { useState, useEffect } from "react";
import { useBrowserAgent } from "./useBrowserAgent";

export const useCompleteProfileStyles = () => {
    const [classes, setClasses] = useState({
        container: "interview-container app-inner-gutters",
        backButton: "bottom-text",
    });
    const { safariAgent } = useBrowserAgent();

    useEffect(() => {
        if (safariAgent) {
            setClasses({
                container: "interview-container-safari app-inner-gutters",
                backButton: classes.backButton + " bottom-text-safari",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [safariAgent]);

    return { ...classes, safariAgent };
};
