import { useState } from "react";

import { useAtom } from "jotai";
import formAtom from "@atoms/formsAtoms";

import emailNotificationSettingsProvider, {
    EmailNotificationSettingsSchema,
} from "@services/emailNotificationSettingsProvider";

import { useQuery } from "react-query";
import { useGlobalContext } from "context/AppContext";
import { GlobalContextType } from "./useGroupsList";

export const emailNotificationSettingsFormID = "EmailNotificationSettingsForm";

export default function useEmailNotificationSettings() {
    /* States and Hooks */
    const [state, setState] = useState<Partial<EmailNotificationSettingsSchema>>({});
    const [isLoading, setIsLoading] = useState(true);
    const [formOptions, setFormOptions] = useAtom(formAtom);
    const [originalState, setOriginalState] = useState<Partial<EmailNotificationSettingsSchema>>({});
    const [stateHasChanged, setStateHasChanged] = useState(false);
    const [blockedAllAlerts, setBlockedAllAlerts] = useState(false);
    const { personalProfile }: GlobalContextType = useGlobalContext();

    /* Functions */

    async function savePreferences(): Promise<EmailNotificationSettingsSchema> {
        if (blockedAllAlerts)
            return emailNotificationSettingsProvider.updateNotificationSettings(
                emailNotificationSettingsProvider.generateEmailNotificationSettings(false),
            );

        return emailNotificationSettingsProvider.updateNotificationSettings(state).then((res) => {
            setState(res);
            return res;
        });
    }

    const rollback = () => {
        setState(originalState);
        setStateHasChanged(false);
        setBlockedAllAlerts(false);
        setFormOptions((old) => ({ ...old, [emailNotificationSettingsFormID]: { disabled: false } }));
    };

    const disableAllAlerts = () => {
        // Storing copy of state for further rollbacks
        setOriginalState(state);

        // Storing information about changing state
        setStateHasChanged(true);
        setBlockedAllAlerts(true);

        // Create a new template with all fields set to true (UI reasons, flip on upload)
        const newState = emailNotificationSettingsProvider.generateEmailNotificationSettings(true);

        // Update state
        setState(newState);

        // Update global form options to reflect all fields being disabled
        setFormOptions((old) => ({ ...old, [emailNotificationSettingsFormID]: { disabled: true } }));
    };

    /* Lifecycle */
    const { isError } = useQuery({
        queryFn: emailNotificationSettingsProvider.getNotificationSettings,
        enabled: Boolean(personalProfile?.id),
        queryKey: ["EmailNotificationSettings", personalProfile?.id],
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            setState(data);
            setOriginalState(data);

            // Check if the state has all options closed and then update the UI
            const allDisabled = emailNotificationSettingsProvider.generateEmailNotificationSettings(false);

            if ("Email" in data) delete data.Email;
            if ("UserID" in data) delete data.UserID;

            if (JSON.stringify(data) === JSON.stringify(allDisabled)) disableAllAlerts();

            console.debug(data);
            console.debug(allDisabled);
        },
        onSettled: () => setIsLoading(false),
    });

    /* Variables */
    const isBlocked = Boolean(formOptions[emailNotificationSettingsFormID]?.disabled);

    /* Return */
    return {
        state,
        isError,
        rollback,
        setState,
        isLoading,
        isBlocked,
        stateHasChanged,
        savePreferences,
        disableAllAlerts,
    };
}
