import React from "react";

import { Typography } from "@mui/material";
import BlockActionModal, { BlockActionModalProps } from "./BlockActionModal";

/* Templates implemented for Modals based on BlockActionModal */
export function BlockGroupDescriptionModal({
    open,
    setOpen,
    content,
    cancel,
    confirm,
}: Partial<BlockActionModalProps>) {
    return (
        <BlockActionModal
            open={open || false}
            setOpen={setOpen ? setOpen : () => {}}
            title="Unable To Save Changes"
            content={
                content || (
                    <>
                        <Typography className="block-action-modal-text">
                            We noticed that the contents of your groups description violate our community standards.
                            <br />
                            <br />
                            Please take the necessary steps to revise it before attempting to save again. Your
                            cooperation in keeping our platform safe is greatly appreciated.
                        </Typography>
                    </>
                )
            }
            confirm={{
                text: "BACK TO EDITION",
                onClick: () => {
                    setOpen?.(false);
                },
                ...confirm,
            }}
            cancel={{
                text: "EXIT WITHOUT SAVING",
                onClick: () => {
                    setOpen?.(false);
                },
                ...cancel,
            }}
        />
    );
}

export { BlockActionModal };
