import { useSnackbarContext } from "context/SnackbarContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { profileProvider } from "services/profileProvider";
import { AVATAR_REMOVED_PLACEHOLDER, PROFILE_IMAGE_MESSAGES } from "utils/constants";
import { removeProfileImages } from "utils/functions";
import { api } from "services/api";
import { endpoints } from "services/endpoints";
import { awsFileURL } from "utils/functions";
import { useGlobalContext } from "context/AppContext";
import { useSetAtom } from "jotai";
import { showInvalidImageMessageAtom } from "../atoms/profileImage";

export const useProfileEdit = ({ getPersonalProfile, isTest = false }) => {
    /* States and Hooks */
    const navigate = useNavigate();
    const [isEditing, setIsEditing] = useState(false);
    const [editState, setEditState] = useState({});
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);
    const [errorPopupMessage, setErrorPopupMessage] = useState("");
    const { personalProfile } = useGlobalContext();

    const { showSnackbar } = useSnackbarContext();
    const showInvalidImageMessage = useSetAtom(showInvalidImageMessageAtom);

    /* Functions */

    const changeEditState = (state) => {
        setEditState((oldState) => {
            return {
                ...oldState,
                ...state,
            };
        });
    };

    // Upload image to S3
    async function uploadBase64Image(data, { onSuccess, onError, onFinally } = {}) {
        return api
            .put(endpoints.profileImageUpload, data)
            .then((response) => onSuccess?.(response))
            .catch((err) => onError?.(err))
            .finally(() => onFinally?.());
    }

    const onSave = async ({ openMatchscore = true, currentEditState = editState, goToUserList = true }) => {
        try {
            // Starting the loader
            setIsLoadingSave(true);

            // Check if there is any change in the profile, in order to save it
            if (Object.keys(currentEditState).length === 0) {
                setIsEditing(false);
                return;
            }
            // Checks if there is a profile ID so we can edit it
            const profileId = personalProfile?.id;
            if (!profileId) throw new Error("Something went wrong, please try again later");

            // Checks if the startplaying link is filled if the user is a professional GM
            if (
                currentEditState?.questionnaireTransitionDM?.includes("professionalGM") &&
                !currentEditState?.startplaying
            ) {
                showSnackbar({ title: "Professional GM", description: "You must enter your StartPlaying account" });
                return;
            }

            // Clone the state to be edited
            const state = currentEditState;

            // In chase the user added a new Platform (VTT) using the "Other" option
            if (state.hasOwnProperty("otherVTT")) {
                // Add the "Other" option to the platform array
                if (state?.vtt?.includes("Other") && !!state.otherVTT) state.vtt.push(state.otherVTT);

                // Remove the otherVTT property because it should not be in the database
                delete state.otherVTT;
            }

            // In case the user added a new Twitter name
            if (state.hasOwnProperty("twitter_name")) {
                // Extract the twitter name from the state
                const twitter_name = state.twitter_name;
                // Delete it from the state, because it should not be sent to the database as it is
                delete state.twitter_name;
                // Update the Twitter name property in the database
                await profileProvider.putValuesTwitter({ twitter_name: twitter_name });
            }

            // In case the user emptyed the number of games run input
            if (state.hasOwnProperty("numberOfGamesRun") && state["numberOfGamesRun"] === "") {
                state["numberOfGamesRun"] = 0;
            }

            // When doing unit testing we do not need to call the API
            if (isTest) {
                setIsLoadingSave(false);
                return;
            }

            // Image Upload to S3

            if (state.profileImage) {
                // Upload the image to S3 and start image moderation
                await uploadBase64Image(state.profileImage, {
                    onSuccess: (response) => {
                        // When the API is called, check if the image violates moderation policies
                        if (response?.data?.Message === PROFILE_IMAGE_MESSAGES.IMAGE_MODERATION_WARNING) {
                            // If it does, show an error message, propagating the error to the parent
                            throw new Error(response?.data?.Message);
                        }

                        // If not then continue with the edit
                        state.profileURL = `${awsFileURL()}/images/${profileId}/profile_image.png?${Date.now()}`;

                        // Delete the image data from the state
                        delete state.profileImage;
                    },
                    onError: (err) => {
                        // Propagate error
                        throw new Error(err.message ?? PROFILE_IMAGE_MESSAGES.UPLOAD_ERROR);
                    },
                });
            }

            // Edit the profile on database

            await profileProvider.editProfile(state, {
                callback: async (response) => {
                    const errorMessage = response?.data?.error;
                    if (errorMessage) {
                        setErrorPopupMessage(errorMessage);
                        setShowErrorPopup(true);
                        return;
                    }

                    if (state?.profileURL === AVATAR_REMOVED_PLACEHOLDER) {
                        // As the profileURL don't accept an empty string (default value),
                        // was defined a placeholder value to be considered as a flag to deletion.
                        await removeProfileImages();
                    }

                    // Get the updated profile
                    await getPersonalProfile();

                    // Reset the state
                    setEditState({});
                    setIsEditing(false);

                    // Generate the matchscore if needed
                    if (goToUserList) {
                        navigate(openMatchscore ? "/matchscore" : "/");
                    }
                },
                errorCallback: (error) => {
                    // Propagate error with the API response message
                    throw new Error(error.response?.data);
                },
            });
        } catch (error) {
            if (error?.message === PROFILE_IMAGE_MESSAGES.IMAGE_MODERATION_WARNING) {
                // In case the image violates the moderation policies, show an dialog instead of the snackbar
                showInvalidImageMessage(true); // This is a atom, that interacts with useDropZoneEditor to show an error dialog
                return;
            }
            // In case of any error, show an error snackbar
            showSnackbar({
                title: PROFILE_IMAGE_MESSAGES.ERROR_OCCURRED,
                description: error?.message ?? PROFILE_IMAGE_MESSAGES.UPLOAD_ERROR,
                severity: "error",
                anchor: { vertical: "top", horizontal: "right" },
            });
        } finally {
            // Reset loading state
            setIsLoadingSave(false);
        }
    };
    const onCancel = () => navigate("/profile");

    const enableMessaging = async (setPopup) => {
        const editState = { messageDisabled: false };

        try {
            changeEditState(editState);
            await onSave({ openMatchscore: false, currentEditState: editState, goToUserList: false });
            showSnackbar({
                title: "Success",
                description: "You enabled messaging.",
                severity: "success",
                anchor: { vertical: "top", horizontal: "left" },
            });
        } finally {
            setPopup?.(false);
        }
    };

    return {
        isEditing,
        setIsEditing,
        editState,
        setEditState,
        changeEditState,
        onSave,
        onCancel,
        isLoadingSave,
        enableMessaging,
        showErrorPopup,
        setShowErrorPopup,
        errorPopupMessage,
        setErrorPopupMessage,
    };
};
