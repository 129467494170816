import React, { useState } from "react";
import "./InboxItem.scss";
import BlockTwoToneIcon from "@mui/icons-material/BlockTwoTone";
import PlayerVerifiedIcon from "components/Profile/components/PlayerVerifiedIcon";
import { Avatar, Box, Typography } from "@mui/material";
import { getDateDescription } from "utils/dateTimeFormatter";
import { useNavigate } from "react-router-dom";
import { useMessagesContext } from "@context/MessagesContext";
import PlayerProIcon from "components/Profile/components/PlayerProIcon";
import { Col, Row } from "react-bootstrap";
import { getPhotoID } from "utils/functions";
import { InboxMessageDataModel } from "models/inbox/InboxMessageDataModel";
import { MessageModel } from "models/inbox/MessageModel";
import { useUser } from "@pages/Inbox/hooks/useUser";
import { useSnackbarContext } from "context/SnackbarContext";
import LoadingBtn from "@components/LoadingBtn/LoadingBtn";
import { useMessages } from "@pages/Inbox/hooks/useMessages";

type InboxItemProps = {
    profileData: InboxMessageDataModel;
    messageData?: MessageModel;
};

export const InboxItem = ({ profileData, messageData }: InboxItemProps) => {
    const navigate = useNavigate();
    const { isBlockedUserList } = useMessagesContext();
    const { handleMessageClick } = useMessages();
    const { unblockUser } = useUser();
    const { showSnackbar } = useSnackbarContext();

    const unreadMessagesCount = Number(messageData?.unreadMessageCount);
    const [loadingUnblock, setLoadingUnblock] = useState(false);

    const navigateToProfile = (e: any) => {
        e.stopPropagation();
        navigate(`/profile/${profileData.vanityUrl}`);
    };

    const getDate = () => {
        const dateObj = new Date(Number(messageData?.date));
        const hoursDiff = Math.abs(new Date().valueOf() - dateObj.valueOf()) / 36e5;
        return getDateDescription(hoursDiff, dateObj, undefined);
    };

    const onClickUnblock = async (e: any) => {
        e.stopPropagation();

        const anchor = { vertical: "top", horizontal: "left" };
        setLoadingUnblock(true);

        try {
            await unblockUser({ givenId: profileData?.id as string });
            showSnackbar({
                title: "Success",
                description: "You unblocked this user.",
                severity: "success",
                anchor,
            });
        } catch {
            showSnackbar({ anchor });
        } finally {
            setLoadingUnblock(false);
        }
    };

    /**
     * Reduce the size of the string to fit mobile screens
     * @param body
     * @returns
     */
    const prepareMessageBody = (body = "") => {
        if (body.length > 20) {
            return body.substring(0, 20) + "...";
        }
        return body;
    };

    return (
        <div
            className={`inbox-item-div ${unreadMessagesCount > 0 ? "unread" : ""}`}
            onClick={() => handleMessageClick({ threadID: messageData?.threadID as string })}>
            <Row className="inbox-item-row align-items-center m-0">
                <Col className="p-0">
                    <Row className="gx-3 m-0">
                        <Col className="col-auto position-relative d-flex ps-0">
                            <Avatar
                                src={getPhotoID(profileData) || "/avatars/profile_3.png"}
                                alt="Avatar"
                                sx={{ width: 55, height: 55 }}
                                onClick={(e) => navigateToProfile(e)}>
                                <img width={55} height={55} src="/avatars/profile_3.png" alt="avatar_default" />
                            </Avatar>
                            {isBlockedUserList && <BlockTwoToneIcon className="blocked-avatar position-absolute" />}
                        </Col>
                        <Col>
                            <Row className="flex-row align-items-center gx-2 flex-nowrap">
                                <Col className="profile-name-col">
                                    <Typography className="profile-name">{profileData?.name ?? "Not found"}</Typography>
                                </Col>
                                <Col className="col-auto">{profileData?.isProfessionalGM && <PlayerProIcon />}</Col>
                                <Col className="col-auto">{profileData?.verified && <PlayerVerifiedIcon />}</Col>
                            </Row>
                            <Typography className="profile-last-message">
                                {prepareMessageBody(messageData?.body)}
                            </Typography>
                        </Col>
                        <Col className="col-auto p-0">
                            {isBlockedUserList ? (
                                <LoadingBtn
                                    className="unblock-button"
                                    variant="outlined"
                                    label="UNBLOCK"
                                    loading={loadingUnblock}
                                    handleOnClick={onClickUnblock}
                                />
                            ) : (
                                <>
                                    <Row className="m-0 pb-2">
                                        <Typography className="profile-last-message-date p-0">{getDate()}</Typography>
                                    </Row>
                                    {unreadMessagesCount > 0 && (
                                        <Row className="m-0 justify-content-end">
                                            <Box className="profile-new-messages-count d-flex p-0 justify-content-center align-items-center">
                                                {unreadMessagesCount}
                                            </Box>
                                        </Row>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
