import React from "react";

import { SvgIcon, SvgIconOwnProps } from "@mui/material";

export default function WarningShield(props: SvgIconOwnProps) {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M19.9942 6C19.9942 6 20.4937 17.5 12.0862 22C3.50569 17.5 4.00519 6 4.00519 6C8.00269 6 11.9997 2 11.9997 2C11.9997 2 15.4972 6 19.9942 6Z"
                    fill="#FFC107"
                />
                <path
                    d="M11.0002 16.071C11.0002 15.9365 11.0237 15.8135 11.0717 15.698C11.1187 15.584 11.1862 15.485 11.2732 15.402C11.3587 15.318 11.4642 15.2525 11.5852 15.2055C11.7072 15.1595 11.8442 15.135 11.9972 15.135C12.1502 15.135 12.2882 15.1595 12.4112 15.2055C12.5362 15.2525 12.6417 15.318 12.7272 15.402C12.8147 15.485 12.8822 15.584 12.9287 15.698C12.9767 15.8135 13.0002 15.9365 13.0002 16.071C13.0002 16.206 12.9767 16.329 12.9287 16.441C12.8817 16.5535 12.8142 16.6505 12.7272 16.735C12.6417 16.818 12.5362 16.883 12.4112 16.931C12.2882 16.977 12.1502 17 11.9972 17C11.8437 17 11.7072 16.977 11.5852 16.9305C11.4642 16.8825 11.3587 16.8175 11.2732 16.7345C11.1857 16.65 11.1182 16.553 11.0717 16.4405C11.0237 16.3285 11.0002 16.2055 11.0002 16.071ZM12.7402 14H11.2537L11.0432 8H12.9502L12.7402 14Z"
                    fill="#5D4037"
                />
            </svg>
        </SvgIcon>
    );
}
