import React from "react";

import { Box, Typography } from "@mui/material";

type GroupDescriptionViewProps = {
    description: string;
};

export default function GroupDescriptionView({ description }: GroupDescriptionViewProps) {
    return (
        <Box className="profile-component">
            <Typography variant="h3">Group description</Typography>
            <Box>
                {description.split("\n").map((paragraph) => (
                    <React.Fragment>
                        <br />
                        <Typography>{paragraph}</Typography>
                    </React.Fragment>
                ))}
            </Box>
        </Box>
    );
}
