import React, { PropsWithChildren, useEffect, useState } from "react";

import { ExpandLess } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

type Section = PropsWithChildren & {
    title: string;
    expanded?: boolean;
    className?: string;
    collapse?: boolean;
};

export function GroupsListSection({ children, title, expanded = false, className = "", collapse = true }: Section) {
    const { isMobile } = useBreakpoint();

    const [_expanded, _setExpanded] = useState(expanded);

    useEffect(() => {
        _setExpanded(isMobile || expanded);
        return () => _setExpanded(false);
    }, [isMobile]);

    return (
        <ShouldRender condition={isMobile !== undefined}>
            <Accordion
                sx={{
                    ...(!collapse && { "*": { cursor: "default !important" } }),
                }}
                expanded={_expanded}
                onClick={() => {
                    if (isMobile || !collapse) return;
                    _setExpanded((_expanded) => !_expanded);
                }}
                className={"groups-accordion" + " " + className}>
                <AccordionSummary expandIcon={<ExpandLess />}>
                    <Typography variant="h2">{title}</Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </ShouldRender>
    );
}
