import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { uniqBy } from "lodash";
import { FilterContext, useFilterContext } from "./FilterContext";
import { useQueries } from "react-query";
import { clearFalsyFromObject2, completeUserData, randomIntFromInterval } from "utils/functions";
import { endpoints } from "services/endpoints";
import { api } from "services/api";
import { useLocation } from "react-router-dom";
import { AsyncFilterFieldsContext } from "./AsyncFilterFieldsContext";
import { SortContext } from "./SortContext";

export const defaultFilters = {};
export const PaginatedContext = React.createContext(defaultFilters);

export const usePaginatedContext = () => {
    const { setObjectToFilter } = useFilterContext();
    const { listOrder } = useContext(SortContext);
    const { isSuccess } = useContext(AsyncFilterFieldsContext);
    const { page, setPage, currentFilters, filterSignIn, setFilterSignIn } = useContext(FilterContext);
    const location = useLocation();
    const pageNumberStorageItemName = `${location.pathname.replace("/", "")}_pageNumber`;
    const scrollStorageItemName = `${location.pathname.replace("/", "")}_initialScrollPosition`;
    const {
        isChangingPage,
        favoritesToDelete,
        setFavoritesToDelete,
        setUsers,
        users,
        setIsChangingPage,
        setTotalResults,
        totalResults,
        updateFavorites,
        scrollTarget,
        setUpdateFavorites,
        isFetching,
        setIsFetching,
    } = useContext(PaginatedContext);

    const dynamicParallelQueries = useQueries([
        {
            queryKey: ["userlist", page, currentFilters, listOrder],
            queryFn: () => getPaginatedUsers(currentFilters),
            enabled:
                window.location.pathname === "/user-list" &&
                isSuccess &&
                (users.length < totalResults || totalResults == 0),
            keepPreviousData: true,
            onSuccess: ({ data, totalResults }) => {
                setUsers((prev) => [...prev, ...data]);
                setIsChangingPage(false);
                setTotalResults(totalResults);
            },
        },
        {
            queryKey: ["game-list", page, currentFilters],
            queryFn: () => {
                if (currentFilters.gamesPlayed.length === 1) {
                    return getPaginatedUsers(currentFilters, "game")
                }
            },
            enabled: window.location.pathname?.includes("/game/") && isSuccess,
            keepPreviousData: true,
            onSuccess: ({ data }) => {
                setUsers((prev) => [...users, ...data]);
                setIsChangingPage(false);
            },
        },
        {
            queryKey: ["location-list", page, currentFilters],
            queryFn: () => getPaginatedUsers(currentFilters, "location"),
            enabled: window.location.pathname?.includes("/location/") && isSuccess,
            keepPreviousData: true,
            onSuccess: ({ data, totalResults }) => {
                setUsers((prev) => [...users, ...data]);
                setIsChangingPage(false);
                setTotalResults(totalResults);
            },
        },
        {
            queryKey: ["starred", page, currentFilters, updateFavorites],
            queryFn: () => getPaginatedUsers(currentFilters, "starred"),
            enabled: window.location.pathname === "/starred",
            keepPreviousData: true,
            onSuccess: ({ data }) => {
                setUsers((prev) => [...prev, ...data]);
                setIsChangingPage(false);
            },
        },
    ]);

    let scrollTimeout;

    async function getPaginatedUsers(filters, type = "home") {
        setIsFetching(true);
        const URLParams = new URLSearchParams();
        let querystring = "";
        const _filters = clearFalsyFromObject2(filters);
        if (_filters) {
            Object.keys(_filters).forEach((key) => {
                if (_filters[key] !== "") {
                    let keyValue = _filters[key];
                    URLParams.set(key, keyValue);
                }
            });
            querystring = `&${URLParams.toString()}`;
        }

        const pageNumber = sessionStorage.getItem(pageNumberStorageItemName);
        const sort = type === "starred" ? "&sort=starred" : "";
        const endpoint = type === "location" || "game" ? endpoints.publicProfilePaginate : endpoints.profilePaginate;
        const resultsPerPage = 20;
        const url = `${endpoint}?resultsPerPage=${resultsPerPage}&pageNumber=${pageNumber || 1}${sort}${querystring}`;
        const response = await api.get(url);
        const data = response.data.results.map((item) => {
            const state = {
                ...item.v,
                match_score: randomIntFromInterval(30, 40),
                favorited: item?.favorited,
                pageNumber,
            };

            if (item?.m?.percentage) {
                state.match_score = item?.m?.percentage;
            }

            if (item?.v?.answered_matching_questions && item?.v?.answered_matching_questions?.length > 0) {
                state.answered_matching_questions = item?.v?.answered_matching_questions[0];
            }
            return state;
        });
        return { data, totalResults: response.data.totalResults };
    }

    const updateSearchParameters = () => {
        if (isFetching || isChangingPage) return;
        const lastPage = sessionStorage.getItem(pageNumberStorageItemName);
        const nextPage = Number(lastPage) + 1;
        sessionStorage.setItem(pageNumberStorageItemName, String(nextPage));

        setPage((prev) => prev + 1);
    };

    const onTargetScroll = (event) => {
        if (isFetching || isChangingPage) return;

        const { scrollTop } = event.currentTarget;
        const { scrollHeight, clientHeight } = scrollTarget.current;
        const targetBottom = (scrollHeight - clientHeight) * 0.95;
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(function () {
            sessionStorage.setItem(scrollStorageItemName, String(scrollTop));
        }, 300);

        // Reached the bottom of the list: call next page.
        if (targetBottom < scrollTop && users.length <= totalResults) {
            updateSearchParameters();
            setIsChangingPage(true);
        }
    };

    function waitForElm(selector) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    const sanityObject = (object) => {
        let obj = {};
        Object.keys(object).forEach((key) => {
            obj[key] = ["gamesPlayed", "vtt", "badges", "age"].includes(key) ? Object.values(object[key]) : object[key];
        });

        return obj;
    };

    const handleCheckSessionStorage = async () => {
        //get the item from session storage
        const initialScrollPosition = sessionStorage.getItem(scrollStorageItemName);
        //check value from session storage
        if (initialScrollPosition && !isChangingPage) {
            const element = await waitForElm("#view-user-list");
            if (element) {
                setTimeout(() => {
                    scrollTarget.current?.scrollTo({
                        top: initialScrollPosition,
                        left: 0,
                    });
                }, 200);
            }
        }
    };

    const reloadFavorites = () => {
        setUpdateFavorites(!updateFavorites);
        sessionStorage.setItem("starred_pageNumber", 1);
    };

    const userList = useMemo(
        () =>
            users?.length > 0
                ? uniqBy(users, (item) => {
                      return item?.id?.[0];
                  }).map((item) => {
                      return completeUserData(item);
                  })
                : [],
        [users],
    );

    useEffect(() => {
        if (location.pathname === "/user-list" || location.pathname === "/starred") {
            if (filterSignIn) {
                setObjectToFilter(sanityObject(filterSignIn));
                setFilterSignIn(null);
            }
            //set initial values to item on session storage
            if (!sessionStorage.hasOwnProperty(pageNumberStorageItemName)) {
                sessionStorage.setItem(pageNumberStorageItemName, String(1));
            }

            handleCheckSessionStorage();

            window.addEventListener("beforeunload", () => {
                sessionStorage.removeItem(pageNumberStorageItemName);
                sessionStorage.removeItem(scrollStorageItemName);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, users]);

    useEffect(() => {
        if (dynamicParallelQueries) setIsFetching(dynamicParallelQueries.some((query) => !!query.isFetching));
    }, [dynamicParallelQueries]);

    return {
        onTargetScroll,
        updateSearchParameters,
        userList: userList,
        page,
        setPage,
        reloadFavorites,
        locationStatus: dynamicParallelQueries[2].status,
        gameStatus: dynamicParallelQueries[1].status,
        favoritesToDelete,
        setFavoritesToDelete,
        scrollTarget,
        isFetchingPage: isFetching,
        setUsers,
        isChangingPage,
        setIsChangingPage,
        totalResults,
    };
};

export const PaginatedProvider = ({ children }) => {
    const scrollTarget = useRef();
    const [users, setUsers] = useState([]);
    const [totalResults, setTotalResults] = useState(0);
    const [isChangingPage, setIsChangingPage] = useState(false);
    const [updateFavorites, setUpdateFavorites] = useState(false);
    const [favoritesToDelete, setFavoritesToDelete] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    return (
        <PaginatedContext.Provider
            value={{
                isChangingPage,
                favoritesToDelete,
                setFavoritesToDelete,
                setUsers,
                users,
                setIsChangingPage,
                setTotalResults,
                totalResults,
                updateFavorites,
                scrollTarget,
                setUpdateFavorites,
                isFetching,
                setIsFetching,
            }}>
            {children}
        </PaginatedContext.Provider>
    );
};
