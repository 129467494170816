import "../index.css";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "context/SnackbarContext";
import { profileProvider } from "services/profileProvider";
import { useGlobalContext } from "context/AppContext";
import { paramsToObject } from "utils/functions";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";

export const useBadges = () => {
    const [openInterstitial, setOpenInterstitial] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [badge, setBadge] = useState(sessionStorage.getItem("redeem-badge"));

    const { showSnackbar } = useSnackbarContext();
    const { personalProfile = {}, updatePersonalProfile, userCompletedProfile = {} } = useGlobalContext();
    const { filterFields } = useContext(AsyncFilterFieldsContext);

    const handleCloseInterstitial = () => {
        setOpenInterstitial(false);
        setBadge(null);
    };

    useEffect(() => {
        if (filterFields?.badgesOptions?.length > 0) {
            if (badge && window.location.pathname.includes("/profile") && personalProfile && userCompletedProfile) {
                setBadge(false);
                onCheckBadges();
            } else if (badge && window.location.pathname.includes("/createprofile")) {
                const actualParams = paramsToObject(searchParams.entries());
                delete actualParams.badge;

                const newParams = new URLSearchParams(actualParams);
                setSearchParams(newParams);

                setBadge(false);
                onCheckBadges(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [badge, personalProfile, window.location.pathname, filterFields.badgesOptions]);

    //3. FE sends request to the back end to see if request qualifies for new badge
    const onCheckBadges = async (newAccount = false) => {
        try {
            const generalBadges = filterFields?.badgesOptions || [];
            const currentBadge = generalBadges.find((item) => item?.name === badge);
            if (!currentBadge || !currentBadge.enabled) {
                throw new Error("Badge not available");
            }
            setOpenInterstitial(true);
            await profileProvider.postBadge({ id: currentBadge?.id });
            updatePersonalProfile({
                ...personalProfile,
                badges: [...(personalProfile?.badges || []), currentBadge?.id],
            });

            const isGeneralBadge = generalBadges.findIndex((x) => x.name === badge) !== -1;
            const description = `You've earned ${isGeneralBadge ? "a new" : "an exclusive Convention Attendee"} badge!${
                newAccount ? " Complete your profile to view badges!" : ""
            }`;

            showSnackbar({
                description,
                title: "Congratulations",
                severity: "success",
            });
        } catch (error) {
            const errorMessage = error?.response?.data.includes("Badge already exists")
                ? "You already have this badge"
                : error?.response?.data;
            showSnackbar({
                description: errorMessage || "Error while trying to get your badges",
                title: "Sorry!",
                severity: "error",
            });
        } finally {
            sessionStorage.removeItem("redeem-badge");
        }

        handleCloseInterstitial();
    };

    return {
        openInterstitial,
    };
};
