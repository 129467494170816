import "./GroupDialogs.scss";

import React from "react";
import { useNavigate } from "react-router-dom";

import { Warning, Close, Delete } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography } from "@mui/material";

import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { deleteGroup } from "@services/groupsProvider";
import { useBreakpoint } from "hooks/useBreakpoint";
import { SlideUp } from "@components/Transitions";

interface Props {
    open: boolean;
    groupId: string;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeleteGroupDialog = ({ open, onClose, groupId }: Props) => {
    const { isMobile } = useBreakpoint();
    const { popup, showPopup } = usePopupController();
    const navigate = useNavigate();

    async function handleSubmit() {
        try {
            // 1. Delete group
            await deleteGroup(groupId);
            onClose(false);

            // 2. Show success
            showPopup({ title: "Success", severity: "success", description: "Group has been successfully deleted." });

            // 3. Navigate to groups
            setTimeout(() => {
                navigate("/user-list");
            }, 3000);
        } catch (err) {
            console.error(err);
            // 4. Show error
            showPopup({
                title: "Error",
                severity: "error",
                description: "Group could not be deleted. Please try again.",
            });
        }
    }

    /* Variables */

    const classes: Array<string> = ["group-dialog", isMobile ? "group-dialog-mobile" : "group-dialog-desktop"];

    return (
        <>
            <AlertPopUpSmall {...popup} />
            <Dialog
                open={open}
                onClose={() => onClose(false)}
                TransitionComponent={SlideUp}
                keepMounted
                aria-describedby="remove-player-dialog"
                className={classes.join(" ")}>
                <DialogContent className="group-dialog-content">
                    <Warning />
                    <Box>
                        <DialogTitle className="group-title">Delete group</DialogTitle>
                        <Typography className="dialog-text">
                            Are you sure you want to delete your group? This will permanently erase the group and all
                            associated contacts. Keep in mind that this action cannot be undone.
                        </Typography>
                        <DialogActions className="dialog-actions">
                            <SecondaryApplyButton
                                icon={<Close />}
                                customClass="cancel-button"
                                onClick={() => onClose(false)}>
                                Cancel
                            </SecondaryApplyButton>
                            <ApplyButton icon={<Delete />} onClick={handleSubmit}>
                                delete group
                            </ApplyButton>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
