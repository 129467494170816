import { useEffect, useState } from "react";

type AgentsProps = {
    safariAgent?: boolean;
    chromeAgent?: boolean;
    IExplorerAgent?: boolean;
    firefoxAgent?: boolean;
    operaAgent?: boolean;
};

export const useBrowserAgent = () => {
    const [agents, setAgents] = useState<AgentsProps>({});
    useEffect(() => {
        const userAgentString = window.navigator.userAgent;
        // Detect Safari
        let safariAgent = userAgentString.indexOf("Safari") > -1;
        // Detect Chrome
        let chromeAgent = userAgentString.indexOf("Chrome") > -1;

        // Detect Internet Explorer
        let IExplorerAgent = userAgentString.indexOf("MSIE") > -1 || userAgentString.indexOf("rv:") > -1;

        // Detect Firefox
        let firefoxAgent = userAgentString.indexOf("Firefox") > -1;
        // Detect Opera
        let operaAgent = userAgentString.indexOf("OP") > -1;

        // Discard Chrome since it also matches Opera
        if (chromeAgent && operaAgent) chromeAgent = false;

        // Discard Safari since it also matches Chrome
        if (chromeAgent && safariAgent) safariAgent = false;
        setAgents({
            safariAgent,
            chromeAgent,
            IExplorerAgent,
            firefoxAgent,
            operaAgent,
        });
    }, []);

    return agents;
};
