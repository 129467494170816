import React from "react";

import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { SlideUp } from "@components/Transitions";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ArrowForwardIos, Close, Warning } from "@mui/icons-material";
import { ApplyButton } from "@components/ApplyButton";
import { conditionalClassName } from "utils/functions";
import { useNavigate } from "react-router-dom";

type GroupFullDialogType = {
    open: boolean;
    onClose: (value: boolean) => void;
};
export default function GroupFullDialog({ open, onClose }: GroupFullDialogType) {
    /* States and Hooks */
    const navigate = useNavigate();

    const { isMobile } = useBreakpoint();

    /* Variables */
    const classes = conditionalClassName(
        ["group-dialog"],
        ["group-full-dialog"],
        [isMobile ? "group-dialog-mobile" : "group-dialog-desktop"],
    );

    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            TransitionComponent={SlideUp}
            keepMounted
            aria-describedby="remove-player-dialog"
            className={classes}>
            <DialogContent className="group-dialog-content">
                <Warning />
                <Box>
                    <DialogTitle className="group-title">Group full</DialogTitle>
                    <Typography className="dialog-text">
                        Too bad! It seems like the group you were invited to is currently full.
                        <br />
                        <br />
                        Why not browse the Discover Groups section? You might find a better fit!{" "}
                    </Typography>
                    <DialogActions className="dialog-actions">
                        <ApplyButton
                            sx={{ width: "fit-content" }}
                            onClick={() => {
                                onClose(false);
                                navigate("/groups");
                            }}>
                            discover groups <ArrowForwardIos fontSize="small" />
                        </ApplyButton>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
