// 10/27/22 - we took out the country requirement because legacy USA users won't have it

export const requiredQuestions = [
    { id: "characterDeath", label: "Am I ok with character death?" },
    { id: "LGBTQ", label: "LGBTQIA Friendly games?" },
    { id: "lateness", label: "How late is late, when it comes to showing up at a #ttrpg session?" },
    { id: "safetyTools", label: "Importance of safety tools to me" },
    { id: "thingsLikedTTRPG", label: "Favorite things about TTRPGs" },
];

// there are a number of legacy questions we cycle through at the end of the profile
export const optionalQuestions = [
    {
        title: "Top things brought as player",
        key: "thingsBroughtAsPlayer",
    },
    {
        title: "Open to homebrew?",
        key: "homebrewRuleChanges",
    },
    {
        title: "Would you play a streamed game?",
        key: "streamedTTRPG",
    },
    {
        title: "My Favorite Character",
        key: "favoriteCharacterDescription",
    },
    {
        title: "What style of campaign do you prefer?",
        key: "preferredCampaignStyle",
    },
    {
        title: "How picky when looking for games",
        key: "gamePickiness",
    },
    {
        title: "Ok joining existing game",
        key: "joiningExistingGames",
    },

    {
        title: "Number of games run",
        key: "numberOfGamesRun",
    },
    {
        title: "Opportunities created",
        key: "opportunitiesCreated",
    },
    {
        title: "Campaign style",
        key: "campaignStyle",
    },
    {
        title: "Years running games",
        key: "numberOfYearsRunningGame",
    },
    {
        title: "Strengths as a Game Master",
        key: "strengths",
    },
    {
        title: "Do you homebrew as a Game Master?",
        key: "homebrewDM",
    },
    {
        title: "DO you stream games?",
        key: "streamGames",
    },
    {
        title: "Would you run a new TTRPG?",
        key: "newGameDM",
    },
    {
        title: "What tools do you use?",
        key: "toolsUsed",
    },
    {
        title: "Which VTT do you use?",
        key: "virtualTableTops",
    },
    {
        title: "Do characters die in your games?",
        key: "characterKillingDM",
    },
];

/**
 * checks if user has answered all required questions
 *
 * @param {object} profile
 *
 * @returns true if has completed profile, false otherwise
 */
export function checkCompletedProfile(profile = {}) {
    try {
        const requiredQuestionsKeys = ["characterDeath", "lateness", "LGBTQ", "safetyTools", "thingsLikedTTRPG"];

        const stateObj = Object.entries(profile).filter((entrie) => requiredQuestionsKeys.includes(entrie?.[0]) && entrie?.[1])

        const shouldRedirect = stateObj.length > 0;

        if (shouldRedirect) {
            return true;
        }

        return !!(
            profile?.answered_matching_questions?.length > 0 && profile?.answered_matching_questions[0] === "True"
        );
    } catch (error) {
        return false;
    }
}

/**
 * checks if user has answered at least one optional question
 *
 * @param {object} profile
 *
 * @returns true if has answered at least one question, false otherwise
 */
export function checkOptionalQuestions(profile) {
    const userHasAnsweredOptionalQuestion = (questionID, index) => {
        return Object.keys(profile).includes(questionID);
    };

    const optionalQuestionsIDs = optionalQuestions.map((question) => question.key);
    const userHasAnsweredOptionalQuestions = optionalQuestionsIDs.some(userHasAnsweredOptionalQuestion);

    return userHasAnsweredOptionalQuestions;
}
