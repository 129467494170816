import React from "react";

import { Text, View } from "@aws-amplify/ui-react";
import { ShouldRender } from "./components/ShouldRender/ShouldRender";

const renderAnswer = (questionAnswer) => {
    if (questionAnswer && questionAnswer.length == 1) {
        return questionAnswer;
    }
    if (questionAnswer && questionAnswer.length > 1) {
        return (
            <ul className="listAnswers">
                {questionAnswer.map((question) => (
                    <li key={question}>{question}</li>
                ))}
            </ul>
        );
    }
};

export const renderQuestions = (questionsArray, state) => {
    return (
        <View className="questions-container">
            {questionsArray?.map((question) => {
                const { title, key, icon } = question;

                const questionAnswer = state?.profile?.[key];
                return (
                    <ShouldRender returnNull key={key} condition={Boolean(questionAnswer)}>
                        <View key={key} className="game-preferences-question">
                            <ShouldRender condition={Boolean(icon)} returnNull>
                                <img className="game-preferences-question-icon" src={icon} alt={`${title} image`} />
                            </ShouldRender>
                            <Text as="h3" className="profile-questions">
                                <strong>{title}</strong>
                                {renderAnswer(questionAnswer)}
                            </Text>
                        </View>
                    </ShouldRender>
                );
            })}
        </View>
    );
};
