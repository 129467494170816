import { SupervisorAccountTwoTone, TagTwoTone } from "@mui/icons-material";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";
import { MoreHorizTwoTone } from "@mui/icons-material";
import { AccountCircleTwoTone } from "@mui/icons-material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { AppColors } from "./colors";
import PlayerProIcon from "components/Profile/components/PlayerProIcon";

import { Tooltip } from "@mui/material";

export const AppTitleIcons = {
    userList: <SupervisorAccountTwoTone data-testid="page-title-icon" htmlColor={AppColors.accent_color} />,
    messages: <EmailTwoToneIcon data-testid="page-title-icon" htmlColor={AppColors.accent_color} />,
    myProfile: (
        <AccountCircleTwoTone
            className="my-profile-icon"
            data-testid="page-title-icon"
            htmlColor={AppColors.text_color}
        />
    ),
    more: <MoreHorizTwoTone data-testid="page-title-icon" s htmlColor={AppColors.accent_color} />,
    starred: <StarBorderIcon data-testid="page-title-icon" s htmlColor={AppColors.accent_color} />,
    verified: (
        <Tooltip placement="top-start" title="Verified adventurer">
            <img src="/VerifiedTwoToneWhite.svg" data-testid="verified-badge" alt="verified user" />
        </Tooltip>
    ),
    pro: <PlayerProIcon />,
    explore: <TagTwoTone htmlColor={AppColors.accent_color} />,
};
