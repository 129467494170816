import { Image, View } from "@aws-amplify/ui-react";
import "./TwitterPost.css";

export default function TwitterMetrics({ metrics, handleModal, linkPost }) {
    return (
        <View onClick={() => handleModal(linkPost)}>
            <View className="twitter-metrics" data-testid="twitter-metrics-container">
                <div>
                    <Image src="/twitter/comments.svg" className="twitter-metrics-icons" alt="comments" />
                    <span>{metrics?.comments}</span>
                </div>
                <div>
                    <Image src="/twitter/retweets.svg" className="twitter-metrics-icons" alt="retweets" />
                    <span>{metrics?.retweets}</span>
                </div>
                <div>
                    <Image src="/twitter/likes.svg" className="twitter-metrics-icons" alt="likes" />
                    <span>{metrics?.likes}</span>
                </div>
                <div>
                    <Image src="/twitter/share.svg" className="twitter-metrics-icons" alt="views" />
                    <span>{metrics?.views}</span>
                </div>
                <div>
                    <Image src="/twitter/save.svg" className="twitter-metrics-icons" />
                </div>
            </View>
        </View>
    );
}
