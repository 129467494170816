import { Button, Text, View } from "@aws-amplify/ui-react";
import Popup from "@components/Popup";
import "@components/PopupConfirmation/styles.css";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

export const PopupConfirmation = ({
    open,
    setOpen,
    title,
    message,
    btnAccept = "Yes",
    btnReject = "No",
    onAccept,
    disableBtnAccept = false,
    disableBtnReject = false,
    setIsEditing = () => {},
    handleCloseAlternative,
}) => {
    const handleClose = () => {
        setOpen?.(false);
        setIsEditing?.(true);
    };

    const handleAcceptation = () => {
        onAccept?.();
        handleClose?.();
    };

    return (
        <Popup
            hideCloseBtn={true}
            open={open}
            setOpen={setOpen}
            classes={`popup-confirmation ${title ? "" : "no-title"}`}>
            <Text className="popup-confirmation-title">{title}</Text>
            <Text className="popup-confirmation-msg">{message}</Text>
            <View className="popup-confirmation-buttons">
                <ShouldRender returnNull condition={!disableBtnAccept}>
                    <Button onClick={handleAcceptation} className="popup-confirmation-button btn-accept">
                        <Text>{btnAccept}</Text>
                    </Button>
                </ShouldRender>
                <ShouldRender returnNull condition={!disableBtnReject}>
                    <Button
                        onClick={handleCloseAlternative || handleClose}
                        className="popup-confirmation-button btn-reject">
                        <Text>{btnReject}</Text>
                    </Button>
                </ShouldRender>
            </View>
        </Popup>
    );
};
