import React from "react";

import { Box, SvgIcon, useMediaQuery, useTheme } from "@mui/material";
import { useMessageNotification } from "hooks/useMessageNotification";
import EmailTwoToneIcon from "@mui/icons-material/EmailTwoTone";

const MessageLogo = ({ active }) => {
    const { hasUnreadMessages } = useMessageNotification();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.up("tablet"));

    if (hasUnreadMessages && active) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", pb: "0.25rem", margin: isTablet ? "" : "5px 0 0 1px" }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20 9.9C20.74 9.75 21.42 9.42 22 8.98V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H14.1C14.04 4.32 14 4.66 14 5C14 5.34 14.04 5.68 14.1 6H4L12 11L15.67 8.71C16.14 9.14 16.69 9.47 17.3 9.69L12 13L4 8V18H20V9.9ZM16 5C16 6.66 17.34 8 19 8C20.66 8 22 6.66 22 5C22 3.34 20.66 2 19 2C17.34 2 16 3.34 16 5Z"
                        fill="#A61F38"
                    />
                    <path
                        opacity="0.3"
                        d="M4 6H14.1C14.32 7.07 14.89 8 15.67 8.71L12 11L4 6ZM4 8V18H20V9.9C19.68 9.97 19.34 10 19 10C18.4 10 17.84 9.88 17.3 9.68L12 13L4 8Z"
                        fill="#A61F38"
                    />
                    <path
                        d="M16 5C16 6.66 17.34 8 19 8C20.66 8 22 6.66 22 5C22 3.34 20.66 2 19 2C17.34 2 16 3.34 16 5Z"
                        fill="#A61F38"
                    />
                </svg>
            </Box>
        );
    } else if (hasUnreadMessages && !active) {
        return (
            <Box sx={{ display: "flex", alignItems: "center", pb: "0.25rem", margin: isTablet ? "" : "5px 0 0 1px" }}>
                <SvgIcon
                    width={isTablet ? "24" : "35"}
                    height={isTablet ? "24" : "35"}
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M20 9.9C20.74 9.75 21.42 9.42 22 8.98V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4H14.1C14.04 4.32 14 4.66 14 5C14 5.34 14.04 5.68 14.1 6H4L12 11L15.67 8.71C16.14 9.14 16.69 9.47 17.3 9.69L12 13L4 8V18H20V9.9ZM16 5C16 6.66 17.34 8 19 8C20.66 8 22 6.66 22 5C22 3.34 20.66 2 19 2C17.34 2 16 3.34 16 5Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M4 6H14.1C14.32 7.07 14.89 8 15.67 8.71L12 11L4 6ZM4 8V18H20V9.9C19.68 9.97 19.34 10 19 10C18.4 10 17.84 9.88 17.3 9.68L12 13L4 8Z"
                        fill="white"
                    />
                    <path
                        d="M16 5C16 6.66 17.34 8 19 8C20.66 8 22 6.66 22 5C22 3.34 20.66 2 19 2C17.34 2 16 3.34 16 5Z"
                        fill="#A61F38"
                    />
                </SvgIcon>
            </Box>
        );
    } else {
        return <EmailTwoToneIcon style={{ marginBottom: "0.25rem" }} />;
    }
};

export default MessageLogo;
