import { useGlobalContext } from "@context/AppContext";
import { useMessagesContext } from "@context/MessagesContext";
import { messageProvider } from "@services/messageProvider";
import { profileProvider } from "@services/profileProvider";
import { useQuery, useQueryClient } from "react-query";
import { uniqBy } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { MessageModel } from "@models/inbox/MessageModel";
import { useEffect, useState } from "react";
import { ProfileModel, PublicProfileModel } from "@models/ProfileModels";

const INBOX_UPDATE_INTERVAL = 30000;
const INBOX_SCROLL_STORAGE_NAME = "inbox_initialScrollPosition";

export const useMessages = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { id: messagesIdParam } = useParams();
    const [initialCall, setInitialCall] = useState(true);

    const {
        publicID,
        setPublicID,
        setNewMessageOpen,
        setProfilesList,
        profilesList,
        setLoadingUsers,
        setMessages,
        setCurrentMessage,
        updateMissingProfiles,
        currentMessage,
        isBlockedUserList,
        notifyReadMessages,
        messages,
        pageNumber,
        setPageNumber,
        scrollTarget,
        setCurrentArrayList,
        currentArrayList,
        setReturnInbox,
        loadingUser,
        setLoadingUser,
        setFilteredProfiles,
        setProfiles,
    } = useMessagesContext();

    const { personalProfile, setBlockedUser, updatePersonalProfile } = useGlobalContext();
    const privateID: string = personalProfile?.id;

    const inboxQuery = useQuery({
        queryKey: ["inbox", `${privateID}`, pageNumber],
        queryFn: async () => updateInbox(),
        enabled: !!privateID && !isBlockedUserList && window.location.pathname.includes("message"),
        cacheTime: INBOX_UPDATE_INTERVAL,
        refetchInterval: INBOX_UPDATE_INTERVAL,
    });

    const blockedQuery = useQuery({
        queryKey: ["blockedUserList", `${privateID}`, loadingUser],
        queryFn: async () => getBlockedInbox(),
        enabled:
            ((!!privateID && isBlockedUserList) ||
                (Boolean(publicID) && loadingUser) ||
                (Boolean(privateID) && Boolean(publicID))) &&
            window.location.pathname.includes("message"),
        cacheTime: INBOX_UPDATE_INTERVAL,
        refetchInterval: INBOX_UPDATE_INTERVAL,
        onSuccess: async (data) => {
            await updateMissingProfiles(true, data);
        },
        onSettled: () => setInitialCall(false),
    });

    const messagesQuery = useQuery({
        queryKey: ["messages", `${privateID}${publicID}`],
        queryFn: async () => messageProvider.getChatMessages(privateID, publicID),
        enabled: Boolean(privateID) && Boolean(publicID),
        cacheTime: INBOX_UPDATE_INTERVAL,
        refetchInterval: INBOX_UPDATE_INTERVAL,
        onSuccess: (data) => {
            notifyReadMessages(data, privateID);
            mergeUnsentMessages(data);
            setLoadingUser(false);
        },
        onSettled: () => setInitialCall(false),
    });

    const deleteChat = async () => {
        try {
            await messageProvider.deleteChatMessages(privateID, publicID);
            await inboxQuery.refetch();
            setMessages([]);
            setPublicID("");
            setProfiles(null);
        } catch {
            throw Error();
        }
    };

    const onChange = (event: any) => {
        setCurrentMessage(event.target.value);
    };

    const clickHandler = async () => {
        setLoadingUser(true);
        setNewMessageOpen(true);
        if (profilesList.length) {
            setFilteredProfiles(profilesList);
            return;
        }

        setLoadingUsers(true);
        const profiles = await profileProvider.getProfiles();
        setProfilesList(profiles?.data.results);
        setFilteredProfiles(profiles?.data.results);
        setLoadingUsers(false);
    };

    const sortInboxMessages = (messages: Array<MessageModel>) =>
        messages?.sort((a, b) => Number(b.date) - Number(a.date));

    const getBlockedInbox = async () => {
        const response = await profileProvider.getBlockedProfiles();

        return response?.data;
    };

    const sendMessage = async () => {
        const newMessage: MessageModel = {
            from: privateID,
            to: publicID,
            body: currentMessage,
            date: Date.now().toString(),
        };

        setCurrentMessage("");

        messageProvider.sendMessage({
            messageData: newMessage,
            publicID,
            privateID,
            privateName: personalProfile.name,
            currentMessage,
        });
        setMessages((oldMessages) => [...oldMessages, newMessage]);

        if (messagesQuery.data?.length <= 0) {
            inboxQuery.refetch();
        }
    };

    const mergeUnsentMessages = (messages: Array<MessageModel>) => {
        if (!messages) return;
        setMessages(() => {
            const times = messages?.map((message) => message.date);
            const filteredMessages = messages?.filter((message) => times.includes(message.date));
            return uniqBy(
                messages.concat(filteredMessages).sort((a, b) => Number(a.date) - Number(b.date)),
                (item) => {
                    return item.body && item.date;
                },
            );
        });
    };

    const updateInbox = async () => {
        const inboxes: Array<MessageModel> = [];
        let total = 0;
        for (let i = 1; i <= pageNumber; i++) {
            const data = await messageProvider.getMessages({ userID: privateID, pageNumber: i });
            if (data?.results.length > 0) {
                inboxes.push.apply(inboxes, data.results);
                total = data.total;
            }
        }

        const sorted = sortInboxMessages(inboxes);
        await updateMissingProfiles(false, inboxes);
        setCurrentArrayList(sorted);

        return { total };
    };

    const handleMessageClick = ({ publicID, threadID }: { publicID?: string; threadID?: string }) => {
        const newURLPath = threadID ?? privateID + publicID;
        const currentPublicID = threadID ? threadID.replace(privateID, "") : publicID;
        // Conditional added to avoid running the code below on an already opened messages panel.
        if (messagesIdParam !== newURLPath) {
            setProfiles(null);
            setPublicID("");
            const isBlocked = blockedQuery?.data?.toString() === currentPublicID;
            setBlockedUser(isBlocked);
            setMessages([]);
            setLoadingUser(true);
            setReturnInbox(false);
            queryClient.invalidateQueries("unreadMessages");
            messagesQuery.remove();
            navigate(`/messages/${newURLPath}`);
        }
    };

    const isLoadingMessages = messagesQuery.isLoading || messagesQuery.isFetching || !privateID;
    const loadingMessage = isLoadingMessages ? "Loading messages" : "Awfully quiet in here...";

    const handleNewMessagePopupClick = (profile: PublicProfileModel | ProfileModel) => {
        const id = profile.id;
        setPublicID(id.toString());
        const { vanityUrl } = profile as PublicProfileModel;
        setProfiles({
            private: { ...personalProfile },
            public: {
                id: id.toString(),
                name: profile.name,
                messageDisabled: profile.messageDisabled,
                avatar_id: profile.avatar_id,
                vanityUrl,
            },
        });
        setMessages([]);
        setReturnInbox(false);
        setNewMessageOpen(false);
        navigate(`/messages/${privateID + id}`);
    };

    let scrollTimeout: NodeJS.Timeout;
    const onTargetScroll = (event: any) => {
        if (inboxQuery.isFetching) return;

        const { scrollTop } = event.currentTarget;
        const { scrollHeight, clientHeight } = scrollTarget.current as HTMLElement;
        const targetBottom = (scrollHeight - clientHeight) * 0.95;
        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(function () {
            sessionStorage.setItem(INBOX_SCROLL_STORAGE_NAME, String(scrollTop));
        }, 300);

        // Reached the bottom of the list: call next page.
        if (
            inboxQuery.data?.total &&
            targetBottom < scrollTop &&
            currentArrayList.length < (inboxQuery.data?.total ?? 0) &&
            pageNumber <= Math.ceil(inboxQuery.data?.total / 20)
        ) {
            setPageNumber((previous) => previous + 1);
        }
    };

    useEffect(() => {
        scrollToCorrectPlace();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentArrayList]);

    const scrollToCorrectPlace = async () => {
        //get the item from session storage
        const initialScrollPosition = sessionStorage.getItem(INBOX_SCROLL_STORAGE_NAME);
        //check value from session storage
        if (initialScrollPosition) {
            const element = await waitForElm("#scrollable-inbox-list");
            if (element) {
                setTimeout(() => {
                    scrollTarget.current?.scrollTo({
                        top: Number(initialScrollPosition),
                        left: 0,
                    });
                }, 200);
            }
        }

        window.addEventListener("beforeunload", () => {
            sessionStorage.removeItem(INBOX_SCROLL_STORAGE_NAME);
        });
    };

    function waitForElm(selector: string) {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver((mutations) => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    }

    async function getPersonalProfile() {
        try {
            const data = await profileProvider.getPersonalProfile();
            updatePersonalProfile({ ...personalProfile, ...data });
            return data;
        } catch (error) {
            navigate("/login");
        }
    }

    return {
        sendMessage,
        loadingInboxMessages: inboxQuery.isLoading,
        loadingMessage,
        updateInbox,
        handleMessageClick,
        clickHandler,
        onChange,
        deleteChat,
        inboxMessages: currentArrayList,
        messages,
        handleNewMessagePopupClick,
        onTargetScroll,
        loadingInboxBlockedMessages: blockedQuery.isLoading && initialCall,
        getPersonalProfile,
    };
};
