import "./NewMessagePopup.scss";
import React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import Popup from "components/Popup";
import { Avatar, Box, InputBase } from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { getPhotoID } from "utils/functions";
import { LoaderComponent } from "@components/LoaderComponent";
import { ProfileModel } from "models/ProfileModels";

type MessagePopupProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    profiles: Array<ProfileModel>;
    handleNewMessagePopupClick: (profile: ProfileModel) => void;
    handleSearch: (e: any) => void;
    loading: boolean;
};

export const NewMessagePopup = ({
    open,
    setOpen,
    profiles,
    handleNewMessagePopupClick,
    handleSearch,
    loading,
}: MessagePopupProps) => {
    return (
        <Popup open={open} setOpen={setOpen} classes="popup-new-message">
            <View className="new-message-popup-title-container">
                <Text className="new-message-popup-title">New Message</Text>
            </View>
            <View className="search-container">
                <View className="search-sub-container">
                    <SearchTwoToneIcon />
                    <InputBase
                        onChange={handleSearch}
                        className="search-placeholder"
                        placeholder="Search users..."
                        inputProps={{ "aria-label": "search users" }}
                    />
                </View>
            </View>
            <View className="new-message-userList">
                {loading ? (
                    <LoaderComponent />
                ) : (
                    profiles?.map((profile) => {
                        const isGm = false; // isGameMaster(profile)

                        return (
                            <Box
                                key={profile.id.toString()}
                                className={`new-message-user ${isGm ? "is-gm" : ""}`}
                                onClick={() => handleNewMessagePopupClick(profile)}>
                                <Avatar className="new-message-user-avatar" src={getPhotoID(profile)} />
                                <Text className="new-message-user-name">{profile.name}</Text>
                            </Box>
                        );
                    })
                )}
            </View>
        </Popup>
    );
};
