import React from "react";
import "./styles.css";

export const ExpandBtn = ({ expanded, expandedLabel, collapsedLabel, isHighlighted, onClick }) => {
    return (
        <div className={`expand-button-cover ${expanded && "no-cover"}`}>
            <div
                className={`expand-button ${isHighlighted && "highlighted"} ${expanded && "expanded"}`}
                onClick={onClick}>
                <div className="arrow"></div>
                {(expandedLabel || collapsedLabel) && <span>{expanded ? expandedLabel : collapsedLabel}</span>}
            </div>
        </div>
    );
};
