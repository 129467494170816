import "./NoMessage.scss";
import React from "react";
import { View, Text } from "@aws-amplify/ui-react";
import Button from "components/Button";

type NoMessageProps = {
    onClick: () => void;
};

export const NoMessage = ({ onClick }: NoMessageProps) => {
    return (
        <View className="no-messages-container">
            <Text className="no-messages-title">No messages here yet</Text>
            <Text className="no-messages-description">
                Your perception check resulted in nothing noteworthy. It's the perfect moment to start talking to people
                and find your best partners.
            </Text>

            <Button className="new-message-button" onClick={onClick}>
                start a conversation
            </Button>
        </View>
    );
};
