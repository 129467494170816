import React, { PropsWithChildren, createContext, useContext, useState } from "react";
import { GamePageModel } from "models/game/GamePageModel";

type GamePageContextProps = {
    currentGameInfo: GamePageModel;
    setCurrentGameInfo: React.Dispatch<React.SetStateAction<GamePageModel | null>>;
};

const GamePageContext = createContext<GamePageContextProps | null>(null);

export const GamePageProvider = ({ children }: PropsWithChildren<{}>) => {
    const [currentGameInfo, setCurrentGameInfo] = useState<GamePageModel | null>(null);

    return (
        <GamePageContext.Provider
            value={
                {
                    currentGameInfo,
                    setCurrentGameInfo,
                } as GamePageContextProps
            }>
            {children}
        </GamePageContext.Provider>
    );
};

export const useGamePageContext = () => {
    const currentContext = useContext(GamePageContext);

    if (!currentContext) {
        throw new Error("useGamePageContext has to be used within <GamePageContext.Provider>");
    }

    return {
        ...currentContext,
    };
};
