import React, { createContext, useState } from "react";

// Create a context for async filter fields
export const AsyncFilterFieldsContext = createContext();

// Provider
export const AsyncFilterFieldsProvider = ({ children }) => {
    const [loading, setLoading] = useState(undefined);
    const [isSuccess, setIsSuccess] = useState(undefined);
    const [filterFields, setFilterFields] = useState({
        badgesOptions: [],
        vtt: [],
        games: [],
        identityTags: [],
        languages: [],
    });

    return (
        <AsyncFilterFieldsContext.Provider
            value={{
                loading,
                setLoading,
                isSuccess,
                setIsSuccess,
                filterFields,
                setFilterFields,
            }}>
            {children}
        </AsyncFilterFieldsContext.Provider>
    );
};
