import React, { useEffect } from "react";
import "../Cards/Cards.css";
import { Container, Box, TextField } from "@mui/material";
import { Loader, Text } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";
import AuthGenericMessages from "components/AuthGenericMessages";
import { useForm } from "react-hook-form";
import Button from "components/Button";

const CardConfirmation = ({
    title = "We Sent A Code",
    buttonText = "Confirm",
    onClickLogin = () => {},
    onConfirm,
    email,
    resend,
    message,
    loading,
}) => {
    let navigate = useNavigate();

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (!email || email === "") {
            navigate("/signup");
            return <></>;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function transformEmail(email) {
        const [name, domain] = email.split("@");
        return ` ${name[0]}***${name.slice(-1)}@${domain[0]}***`;
    }

    return (
        <Container className="card login">
            <Text className="card_title">{title}</Text>
            <AuthGenericMessages>
                {message ? (
                    message
                ) : (
                    <>
                        Your code is on the way. To log in, enter the code we emailed to
                        {email ? transformEmail(email) : ""}. It may take a minute to arrive.
                    </>
                )}
            </AuthGenericMessages>

            <Box component="form" onSubmit={handleSubmit(onConfirm)}>
                <div className="card-signup-confirmation-form">
                    <TextField
                        variant="outlined"
                        type="text"
                        label="Code*"
                        {...register("code", {
                            required: "Required",
                        })}
                        disabled={loading}
                    />
                    {errors.code && <Text className="error-msg red-msg">{errors.code.message}</Text>}

                    <Button disabled={loading} type="submit" onClick={onClickLogin}>
                        {loading && (
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "5px 0",
                                }}>
                                <Loader width={"6rem"} height={"6rem"} filledColor={"white"} emptyColor={"red"} />
                            </div>
                        )}
                        {!loading && buttonText}
                    </Button>
                </div>
            </Box>
            <div className="auth-confirmation-code-sending">
                <button className="auth-resend-confirmation-btn" onClick={resend}>
                    Resend code
                </button>
            </div>
        </Container>
    );
};

export default CardConfirmation;
