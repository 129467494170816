import React, { useEffect, useState } from "react";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import View from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { locationProvider } from "services/locationProvider";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import { formatLocation, generateLocationOptions } from "utils/functions";

/*
This form can be used to update the profile location
*/

export const ProfileLocation = ({
    state,
    handleEditProfile,
    pushCounter,
    isEditing = false,
    changeEditState = () => {},
}) => {
    const [disabledValue, setDisabledValue] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingLocations, setIsLoadingLocations] = useState(false);
    const [selectValue, setSelectValue] = useState(formatLocation(state));
    const [search, setSearch] = useState(formatLocation(state, true));
    const [locationOptions, setLocationOptions] = useState([]);

    useEffect(() => {
        setDisabledValue(!selectValue.country);
    }, [selectValue]);

    useEffect(() => {
        if (search === "") {
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            getLocations();
        }, 450);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const handleIsOptionEqualToValue = (option, value) => {
        const formattedOption = formatLocation(option, true);
        const formattedValue = formatLocation(value, true);
        const formattedState = formatLocation(state, true);

        return formattedValue === formattedOption || formattedState === formattedOption;
    };

    const handleKeyUp = (e) => {
        setSearch(e.target.value);
    };

    const handleOnChange = (_, newValue) => {
        setSelectValue(newValue);

        if (isEditing) {
            changeEditState({
                city: newValue.city || "",
                state: newValue.state || "",
                country: newValue.country || "",
            });
        }
    };

    const handleRenderInput = (params) => {
        params.inputProps["data-testid"] = "location-picker-input";
        return (
            <TextField
                {...params}
                label="Location"
                onKeyUp={handleKeyUp}
                InputProps={{
                    ...params.InputProps,
                    startAdornment: <PublicTwoToneIcon className="location-icon" fontSize="small" sx={{ ml: 0.5 }} />,
                    endAdornment: (
                        <React.Fragment>
                            {isLoadingLocations ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                }}
            />
        );
    };

    const handleRenderOption = (props, option) => {
        const location = formatLocation(option, true);
        return (
            <Box component="li" {...props}>
                <PublicTwoToneIcon className="location-icon" fontSize="small" sx={{ mr: 1 }} />
                {location}
            </Box>
        );
    };

    const getLocations = async (searchRequest = search) => {
        try {
            setIsLoadingLocations(true);

            const response = await locationProvider.getLocations(searchRequest);
            const locations = generateLocationOptions(response.data);
            setLocationOptions(locations);
        } catch (error) {
            setLocationOptions([]);
        } finally {
            setIsLoadingLocations(false);
        }
    };

    //on press of the submit button send the new value to the api
    const saveValue = async () => {
        setIsLoading(true);
        await handleEditProfile({
            city: selectValue.city || "",
            state: selectValue.state || "",
            country: selectValue.country || "",
        });
        setIsLoading(false);
    };

    return (
        <View className="form-container">
            {!isEditing && <Text as="h2">What's your location?</Text>}
            <View className={isEditing ? "" : "input-container"}>
                {/* form control and input label are required for MUI selector labels to display properly */}
                <FormControl fullWidth sx={isEditing ? {} : { m: 1, minWidth: 120 }}>
                    <Autocomplete
                        id="location-picker"
                        value={selectValue}
                        options={locationOptions}
                        filterOptions={(x) => x}
                        sx={isEditing ? {} : { width: 300 }}
                        onChange={handleOnChange}
                        isOptionEqualToValue={handleIsOptionEqualToValue}
                        loading={isLoadingLocations}
                        variant="outlined"
                        disableClearable
                        renderInput={handleRenderInput}
                        renderOption={handleRenderOption}
                        getOptionLabel={(option) => formatLocation(option, true)}
                        freeSolo
                    />
                </FormControl>
            </View>
            {!isEditing && (
                <View className="button-container">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={saveValue}
                    />
                </View>
            )}
        </View>
    );
};
