import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";
import ProfileSafetyTools from "@components/ProfileInterview/ProfileSafetyTools";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const SafetyToolsComponent = ({
    state,
    handleEditProfile,
    pushCounter,
    goBack,
    goNextButton,
    counter,
    length,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-safety-tools">
                <View className="top-text">
                    <Text>Let's dig into your game preferences...</Text>
                </View>
                <View className="question-container">
                    <ProfileSafetyTools state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
                </View>
                <BackNextBtn
                    pushCounter={pushCounter}
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                    pageState={state.safetyTools}
                />
            </CompleteProfileContainer>
        </>
    );
};
