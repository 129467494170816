import "./ChatRightWrapper.scss";
import React from "react";
import { ChatContainer } from "@pages/Inbox/Inbox";
import { useMessagesContext } from "@context/MessagesContext";
import { ChatNewMessageButton } from "../ChatNewMessageButton/ChatNewMessageButton";

export const ChatRightWrapper = () => {
    const { publicID, returnInbox } = useMessagesContext();

    const placeholder = Array(10).fill("placeholder").join(" ");

    return (
        <div className="chat-right-wrapper">
            <div className="chat-wrapper">
                <div className="overflow-hidden" style={{ height: "0px" }}>
                    {placeholder}
                </div>
                {publicID === "" || returnInbox ? <ChatNewMessageButton /> : <ChatContainer />}
            </div>
        </div>
    );
};
