import React, { createContext, useState } from "react";

export const LoadingContext = createContext();

export const LoadingProvider = ({ children }) => {
    const [isFetchingLoading, setIsFetchingLoading] = useState(false);
    return (
        <LoadingContext.Provider
            value={{
                isFetchingLoading,
                setIsFetchingLoading,
            }}>
            {children}
        </LoadingContext.Provider>
    );
};
