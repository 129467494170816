import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateText } from "@utils/functions";

import { setProfile } from "@utils/setProfile";

import Profile from "components/Profile/Profile";

import { CircularProgress } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { useGlobalContext } from "context/AppContext";
import { profileProvider } from "services/profileProvider";

const PersonalProfile = (props) => {
    const [state, setState] = useState(null);
    let navigate = useNavigate();
    const { personalProfile, updatePersonalProfile } = useGlobalContext();

    async function getPersonalProfile() {
        try {
            const data = await profileProvider.getPersonalProfile();
            updatePersonalProfile({
                ...personalProfile,
                ...data,
            });
            setProfile(data, setState, navigate, validateText, false);
            return data;
        } catch (error) {
            navigate("/login");
        }
    }

    useEffect(() => {
        (async () => {
            if (personalProfile) {
                setProfile(personalProfile, setState, navigate, validateText, false);
            } else {
                await getPersonalProfile();
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalProfile]);

    if (state === null) {
        return (
            <>
                <View className="loading-container-center">
                    <CircularProgress />
                    <Text>Loading user information...</Text>
                </View>
            </>
        );
    } else {
        return (
            <>
                <View style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Profile state={state} getPersonalProfile={getPersonalProfile} />
                </View>
            </>
        );
    }
};

export default PersonalProfile;
