import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";

// Each interview question is loaded as a seperate component so it can be reused
import ProfileAge from "@components/ProfileInterview/ProfileAge";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const AgeComponent = ({ state, handleEditProfile, pushCounter, goNextButton, goBack, counter, length }) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-age ">
                <BackNextBtn
                    pushCounter={pushCounter}
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                    pageState={state?.age}
                />
                <View className="top-text">
                    <Text>Let's start with some personal details...</Text>
                </View>
                <View className="question-container">
                    <ProfileAge state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
                </View>
            </CompleteProfileContainer>
        </>
    );
};
