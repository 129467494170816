import { View } from "@aws-amplify/ui-react";
import parse from "html-react-parser";
import "./TwitterPost.css";
import { Box } from "@mui/material";
import { renderToString } from "react-dom/server";

export default function TwitterContent({ text, redirectToPost, redirectToProfile, handleModal, attachmentsURL }) {
    const editBeforeRender = () => {
        let textContent = text.slice();

        const links = textContent.match(/https?:\/\/[^\s]+/g);
        const hasMedia = attachmentsURL.filter((item) => item?.M?.url_type?.S !== "link").length > 1;
        links?.forEach((link, index) => {
            const attachment = attachmentsURL.find((item) => item?.M?.url?.S === link);
            if (attachment?.M?.url_type?.S === "link") {
                const displayURL = attachment?.M?.display_url?.S ?? link;
                const hasLinkImage = Object.keys(attachment?.M?.link_image?.M ?? {}).length > 0;

                if (hasMedia || index + 1 < links.length || !hasLinkImage) {
                    textContent = textContent.replace(
                        link,
                        `<a href="${link}" target="target_blank">${displayURL}</a>`,
                    );
                } else {
                    const imgURL = displayURL.split("/")[0];
                    const component = (
                        <a href={link} target="target_blank" className="post-link">
                            <Box
                                sx={{ height: attachment?.M?.link_image?.M?.height?.N || "auto" }}
                                component="img"
                                src={attachment?.M?.link_image?.M?.url?.S}
                            />
                            <div className="post-link-helper-text">{imgURL}</div>
                        </a>
                    );
                    const isLastWord = text.lastIndexOf(link) + link.length === text.length;
                    if (isLastWord) {
                        textContent = textContent.replace(link, renderToString(component));
                    } else {
                        textContent = textContent.replace(
                            link,
                            `<a href="${link}" target="target_blank">${displayURL}</a>`,
                        );
                        textContent += renderToString(component);
                    }
                }
            } else {
                textContent = textContent.replace(link, "");
            }
        });

        const hashtags = textContent.match(/#\w+/g);
        hashtags?.forEach((hashtag) => {
            const url = `https://twitter.com/hashtag/${hashtag.replace("#", "")}`;
            textContent = textContent.replace(hashtag, `<a href="${url}" target="target_blank">${hashtag}</a>`);
        });

        const mentions = textContent.match(/@\w+/g);
        mentions?.forEach((mention) => {
            const url = `https://twitter.com/${mention.replace("@", "").replace(":", "")}`;
            textContent = textContent.replace(mention, `<a href="${url}" target="target_blank">${mention}</a>`);
        });

        return textContent.replaceAll("\n", "<br>");
    };

    const handleLinkClick = (event) => {
        const target = event.target;
        if (["A", "IMG"].includes(target.tagName)) {
            event.preventDefault();
            let href = "";

            if (target.tagName === "A") {
                href = target.getAttribute("href");
            }

            if (target.tagName === "IMG") {
                const parent = event.currentTarget;
                const links = parent.getElementsByTagName("a");
                href = links[links.length - 1].href;
            }

            handleModal(href);
            return;
        }
        redirectToPost();
    };

    return (
        <View onClick={handleLinkClick} data-testid="twitter-content-container" className="twitter-content">
            {parse(editBeforeRender())}
        </View>
    );
}
