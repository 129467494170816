import "./GroupPlayers.scss";

import React, { useEffect } from "react";

import { Box, Typography } from "@mui/material";

import PlayerChip from "./PlayerChip";
import RenderOpenSeats from "./RenderOpenSeats";
import PlayerAmountSlider from "./PlayerAmountSlider";

import { GroupMemberType } from "models/group/GroupModel";
import { PlayerProps } from "models/group/PlayerProps";
import { useSetAtom } from "jotai";
import { groupSizeAtom } from "@atoms/groupsAtoms";

interface Props {
    variant: "CREATE" | "EDIT";
    groupId: string;
    members: Array<GroupMemberType>;
    hostView: boolean;
    playerAmount: number;
    setPlayerAmount: React.Dispatch<React.SetStateAction<number>>;
    handleRemovePlayer: (player: PlayerProps) => Promise<void>;
    handleJoinGroup: () => Promise<void> | void;
}

export default function GroupPlayersManager({
    groupId,
    members,
    hostView,
    playerAmount,
    setPlayerAmount,
    handleJoinGroup,
    handleRemovePlayer,
}: Props) {
    const setGroupSize = useSetAtom(groupSizeAtom);

    return (
        <Box className="profile-component">
            <Box className="players-title-container">
                <Typography variant="h3">Player count</Typography>
            </Box>
            <PlayerAmountSlider
                playerAmount={playerAmount}
                setPlayerAmount={(amount) => {
                    setPlayerAmount(amount);
                    setGroupSize(amount as number);
                }}
                playersList={members.map((member) => ({
                    player: member,
                    groupId,
                    hostView,
                    handleJoinGroup,
                    handleRemovePlayer,
                    handleJoinGroupDialog: () => {},
                    memberType: member.member_type,
                }))}
            />
            <Box className="players-container">
                {members.map((player) => {
                    return (
                        <PlayerChip
                            isEditing
                            key={player?.id}
                            player={player}
                            groupId={groupId}
                            hostView={hostView}
                            memberType={player?.member_type}
                            matchScore={player?.matchScore}
                            handleJoinGroupDialog={() => {}}
                            handleRemovePlayer={handleRemovePlayer}
                        />
                    );
                })}
                <RenderOpenSeats
                    isEditing
                    groupId={groupId}
                    hostView={hostView}
                    groupSize={playerAmount}
                    filledSeats={members.length}
                    handleJoinGroupDialog={() => {}}
                />
            </Box>
        </Box>
    );
}
