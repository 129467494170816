import { useGlobalContext } from "context/AppContext";
import { useMessagesContext } from "@context/MessagesContext";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessages } from "@pages/Inbox/hooks/useMessages";

const useProfile = () => {
    const { personalProfile, publicProfile } = useGlobalContext();
    const { setProfiles } = useMessagesContext();
    const { handleMessageClick } = useMessages();

    const navigate = useNavigate();
    const [fillUpSurveyPopup, setFillUpSurveyPopup] = useState(false);
    const redirectToChat = async () => {
        const { age, country, timezone } = personalProfile;
        const needsToFillOutSurvey = !age || !country || !timezone;

        setProfiles((oldProfile) => {
            return {
                ...oldProfile,
                public: publicProfile.profile,
            };
        });

        if (needsToFillOutSurvey) {
            setFillUpSurveyPopup(true);
            return;
        }

        handleMessageClick({ publicID: publicProfile.profile.id });
    };

    const redirectToSurvey = async () => {
        setFillUpSurveyPopup(false);
        navigate("/createprofile/0");
    };

    return { redirectToChat, redirectToSurvey, fillUpSurveyPopup, setFillUpSurveyPopup };
};

export default useProfile;
