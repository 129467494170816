import { View, Text } from "@aws-amplify/ui-react";
import React from "react";
import MessageOrEditBtn from "components/Profile/components/ProfileHead/MessageOrEditBtn/MessageOrEditBtn";
import ProfileAvatarBox from "components/Profile/components/ProfileHead/AvatarBox/ProfileAvatarBox";
import BackBtnLine from "./BackBtnLine/BackBtnLine";
import { PopupConfirmation } from "components/PopupConfirmation";

import { matchColor } from "utils/matchColor";
import { AppTitleIcons } from "utils/icons";
import { ShouldRender } from "../ShouldRender/ShouldRender";

const ProfileHead = ({
    id,
    state,
    allowSendMessage,
    isPublicProfile,
    favoritesTooltipTitle,
    updateFavorites,
    favorited,
    changeFavorited,
    redirectToChat,
    redirectToSurvey,
    fillUpSurveyPopup,
    setFillUpSurveyPopup,
    isGM,
    isPlayer,
    isProfessionalGM,
    wait,
    isEditing,
    setIsEditing,
    onSave,
    onCancel,
    isLoadingSave,
    editState,
    setOpenSaveMessageWarning,
    loginPopup,
    setLoginPopup,
    isLoggedIn,
    personalId,
    isSmallScreen,
    twitterName,
    changeEditState,
}) => {
    const PlayerDescription = () => {
        let description = "";
        const bio = state?.profile?.bio?.[0];

        if (!bio) {
            const retrievedData = [];

            const playerTypes = [
                ["Player", isPlayer],
                ["GM", isGM],
                ["Professional GM", isProfessionalGM],
            ];

            playerTypes.forEach((item) => {
                const [playerType, status] = item;

                if (status) {
                    retrievedData.push(playerType);
                }
            });

            switch (retrievedData.length) {
                case 1:
                    description = `I'm a ${retrievedData[0]}`;
                    break;
                case 2:
                    description = `I'm a ${retrievedData[0]} and a ${retrievedData[1]}`;
                    break;
                case 3:
                    description = `I'm a ${retrievedData[0]}, a ${retrievedData[1]} and a ${retrievedData[2]}`;
                    break;
                default:
                    break;
            }
        }

        return (
            <Text className="profile-row player-description" style={{ textAlign: bio ? "left" : "center" }}>
                {bio || description}
            </Text>
        );
    };

    const ActionButtons = () => {
        if (!isLoggedIn) {
            /**
             * action-buttons-public has 50% of width as 100% with the new bio makes it bigger than it should
             */
            return (
                <View className={`action-buttons action-buttons-public`}>
                    <MessageOrEditBtn
                        isLoggedIn={isLoggedIn}
                        profile={state?.profile}
                        isPublicProfile={false}
                        allowSendMessage={allowSendMessage}
                        redirectToChat={redirectToChat}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        disabled={state?.profile.messageDisabled?.[0]}
                        loginPopup={loginPopup}
                        setLoginPopup={setLoginPopup}
                        isSmallScreen={isSmallScreen}
                        messageDisabled={state?.messageDisabled}
                    />
                </View>
            );
        }

        const matchScoreNumber = state?.profile?.id === personalId ? 100 : Math.round(state?.profile?.matchScore || 0);
        const matchScoreColor = matchColor(matchScoreNumber);

        return (
            <MessageOrEditBtn
                isLoggedIn={isLoggedIn}
                isPublicProfile={isPublicProfile}
                allowSendMessage={allowSendMessage}
                redirectToChat={redirectToChat}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                disabled={state?.profile.messageDisabled?.[0]}
                isSmallScreen={isSmallScreen}
                profile={state?.profile}
            />
        );
    };

    return (
        <View data-testid="profile-head" className={`profile-head ${isEditing ? "edit-mode" : ""}`}>
            <BackBtnLine
                isLoggedIn={isLoggedIn}
                state={state}
                isEditing={isEditing}
                onSave={() => {
                    if (editState?.messageDisabled) setOpenSaveMessageWarning(true);
                    else onSave?.({});
                }}
                onCancel={onCancel}
                isLoadingSave={isLoadingSave}
                editState={editState}
                isProfessionalGM={isProfessionalGM}
                setLoginPopup={setLoginPopup}
            />

            <img className="profile-head-background" src="/profile_default_background.jpeg" alt="dices" />
            <View className="profile-row profile-head-row profile-head-ios-issue">
                <View className="profile-column">
                    <ProfileAvatarBox
                        allowSendMessage={allowSendMessage}
                        favoritesTooltipTitle={favoritesTooltipTitle}
                        updateFavorites={updateFavorites}
                        favorited={favorited}
                        changeFavorited={changeFavorited}
                        state={state}
                        isPublicProfile={isPublicProfile}
                        id={id}
                        wait={wait}
                        isEditing={isEditing}
                        twitterName={twitterName}
                        changeEditState={changeEditState}
                        matchScore={state.profile?.matchScore}
                        isLoggedIn={isLoggedIn}
                    />
                    <ShouldRender condition={!isEditing} returnNull>
                        <View className="user-data">
                            <Text data-testid="profile-name" className="profile-name">
                                {state?.profile?.name}
                                {state?.profile?.verified && AppTitleIcons.verified}
                                {isProfessionalGM && AppTitleIcons.pro}
                            </Text>

                            <PlayerDescription />
                            <ActionButtons />
                        </View>
                    </ShouldRender>
                </View>
            </View>

            <PopupConfirmation
                open={fillUpSurveyPopup}
                setOpen={setFillUpSurveyPopup}
                title={"Wait a minute, adventurer"}
                message="You can't message another user without first filling our Profile Survey. Let's do it?"
                btnAccept="GO TO PROFILE SURVEY"
                btnReject="STAY ON THIS PAGE"
                onAccept={redirectToSurvey}
            />
        </View>
    );
};

export default ProfileHead;
