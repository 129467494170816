import "./CreateGroupSuggestion.scss";

import React from "react";

import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import { Box, Typography } from "@mui/material";
import { ApplyButton } from "@components/ApplyButton";
import { useAtom, useAtomValue } from "jotai";
import { surveyAtom } from "@atoms/survey";
import { storage } from "@services/sessionStorage";
import { useBreakpoint } from "hooks/useBreakpoint";
import { conditionalClassName } from "utils/functions";
type GroupCreatedType = {
    state: object;
    length: number;
    goBack: () => void;
    counter: number;
    skipButton: () => void;
    pushCounter: () => void;
    handleEditProfile: (request: object) => Promise<void>;
    handleSaveSurvey: () => Promise<void>;
};
export default function GroupCreated(props: GroupCreatedType) {
    /* States and Hooks */
    const [{ groupId }, setSurvey] = useAtom(surveyAtom);

    const { isMobile, isTablet, isDesktop, isLaptop } = useBreakpoint();

    /* Variables */
    const className = conditionalClassName(
        ["form-container"],
        [isMobile, "mobile-form-container"],
        [isTablet, "tablet-form-container"],
        [isDesktop || isLaptop, "desktop-form-container"],
    );

    return (
        <CompleteProfileContainer
            rootContainerClass="create-group-suggestion group-created manage-group-page"
            style={{ backgroundImage: "url('/darkHill.png')", backgroundSize: "cover" }}>
            <Box className={className}>
                <Typography variant="h2">
                    Yay, you’re all done! All your details have been saved.
                    {isMobile ? (
                        <>
                            <br />
                            <br />
                        </>
                    ) : (
                        <br />
                    )}
                    What would you like to do now?
                </Typography>
                <Box className="actions">
                    <ApplyButton
                        onClick={() => {
                            // Setting the redirect to be used in matchscore page
                            storage.sessionStorage.surveyRedirect.update("/group/" + groupId);

                            // Executing the save survey function
                            props.handleSaveSurvey();
                        }}>
                        go to my group
                    </ApplyButton>
                    <ApplyButton onClick={props.handleSaveSurvey}>go to user list</ApplyButton>
                </Box>
            </Box>
        </CompleteProfileContainer>
    );
}
