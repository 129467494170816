import { useGlobalContext } from "context/AppContext";
import { useMessagesContext } from "@context/MessagesContext";
import { useEffect, useMemo } from "react";
import { profileProvider } from "services/profileProvider";
import { useQueryClient } from "react-query";
import { debounce } from "lodash";

export const useUser = () => {
    const queryClient = useQueryClient();
    const { profiles, setProfiles, searchInput, setSearchInput, setPublicID, setFilteredProfiles, profilesList } =
        useMessagesContext();
    const { blockedUser, setBlockedUser } = useGlobalContext();

    const { personalProfile } = useGlobalContext();
    const privateID: string = personalProfile?.id;

    useEffect(() => {
        debounceSearch(searchInput);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput, profilesList]);

    const blockUser = async () => {
        try {
            await profileProvider.blockUser({ blocked: profiles?.public?.id });
            await queryClient.refetchQueries({ queryKey: ["inbox", `${privateID}`], active: true });
            setPublicID("");
            setProfiles(null);
        } catch {
            throw Error();
        }
    };

    const unblockUser = async ({ givenId }: { givenId?: string }) => {
        const id = givenId ?? profiles?.public?.id;

        try {
            await profileProvider.blockUser({ blocked: id });
            await queryClient.refetchQueries({ queryKey: ["blockedUserList", `${privateID}`], active: true });
        } catch {
            throw Error();
        }
    };

    const handleSearch = (e: any) => {
        setSearchInput(e.target.value);
    };

    const debounceSearch = useMemo(
        () =>
            debounce(async (query) => {
                if (query !== "") {
                    const response = await profileProvider.getProfiles(`&name=${query}`);
                    const searchResult = response?.data?.results;
                    setFilteredProfiles(searchResult);
                } else {
                    setFilteredProfiles(profilesList);
                }
            }, 1500),
        [profilesList, setFilteredProfiles],
    );

    return {
        handleSearch,
        unblockUser,
        blockUser,
        blockedUser,
        setBlockedUser,
    };
};
