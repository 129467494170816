import { Tooltip } from "@mui/material";

export default function PlayerProIcon() {
    return (
        <Tooltip
            placement="top-start"
            title="This user proudly carries the title of Professional Game Master, endorsed by the esteemed Startplaying community.">
            <img
                alt="Player PRO Icon"
                data-testid="player-pro-icon"
                className="player-pro-icon"
                src="https://production-rpgmatch-profile-images.s3.us-west-2.amazonaws.com/badges/pro.svg"
            />
        </Tooltip>
    );
}
