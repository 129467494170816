import Button from "components/Button";
import React, { lazy, Suspense } from "react";
const LazyGenericDialogRoot = lazy(() => import("components/GenericDialog/GenericDialog.Root"));
const LazyGenericDialogTop = lazy(() => import("components/GenericDialog/GenericDialog.Top"));
const LazyGenericDialogContent = lazy(() => import("components/GenericDialog/GenericDialog.Content"));
const LazyGenericDialogBottom = lazy(() => import("components/GenericDialog/GenericDialog.Bottom"));

const DropZoneEditorDialog = ({
    show = false,
    onCancel,
    onClose,
    onAccept,
    children,
    title,
    onCancelText,
    onAcceptText,
    reverseButtons = false
}) => {
    return show ? (
        <Suspense fallback={"..."}>
            <LazyGenericDialogRoot name={"dialog_drop-zone-editor"} open={show}>
                <LazyGenericDialogTop>
                    {title}
                    <button className={"drop-zone-editor_close-btn"} onClick={onClose}>
                        <img src="/closeIcon.png" alt="Close dialog" />
                    </button>
                </LazyGenericDialogTop>
                <LazyGenericDialogContent>{children}</LazyGenericDialogContent>
                <LazyGenericDialogBottom>
                    <div className={`dialog_drop-zone-editor_btns ${reverseButtons ? 'dialog_drop-zone-editor_btns---reverse' : ''}`}>
                        {onCancelText && (
                            <Button backgroundColor="#171717" labelColor={"#DE4A65"} onClick={onCancel}>
                                {onCancelText}
                            </Button>
                        )}
                        {onAcceptText && <Button onClick={onAccept}>{onAcceptText}</Button>}
                    </div>
                </LazyGenericDialogBottom>
            </LazyGenericDialogRoot>
        </Suspense>
    ) : null;
};

export default DropZoneEditorDialog;
