import React, { useState } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import RequiredQuestion from "components/RequiredQuestion";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { handleSaveRequiredValues } from "@utils/functions";

export const ProfileLateness = ({ state, handleEditProfile, pushCounter }) => {
    //on press of the submit button send the new value to the api
    const hasState = () => {
        if (!state?.lateness) {
            return "";
        }

        return String(state?.lateness);
    };

    const [textValue, setTextValue] = useState(hasState);
    const [isLoading, setIsLoading] = useState(false);
    const latenessOptions = ["5+ minutes", "15+ minutes", "30+ minutes", "1 hour+"];

    return (
        <View className="form-container">
            <Text as="h2">
                How late is late when it comes to showing up at a #ttrpg session? Pick the lowest amount of time that is
                late.<small>*</small>
            </Text>
            {
                // loop through the array and build button options
                Object.values(latenessOptions).map((item, index) => (
                    <View className="button-container" key={index}>
                        <LoadingBtn
                            label={item}
                            variant="outlined"
                            loading={textValue === item && isLoading}
                            handleOnClick={() =>
                                handleSaveRequiredValues({
                                    key: "lateness",
                                    item,
                                    setTextValue,
                                    setIsLoading,
                                    handleEditProfile,
                                })
                            }
                            className={textValue === item ? "selected-but" : "safety-but"}
                        />
                    </View>
                ))
            }
        </View>
    );
};
export default ProfileLateness;
