import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileAvailabilityTable from "@components/ProfileInterview/ProfileAvailabilityTable";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const AvailabilityTableComponent = ({
    state,
    handleEditProfile,
    pushCounter,
    goNextButton,
    goBack,
    counter,
    length,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="availability-table">
                <View className="top-text"></View>
                <ProfileAvailabilityTable
                    state={state}
                    pushCounter={pushCounter}
                    handleEditProfile={handleEditProfile}
                />
                <BackNextBtn
                    pushCounter={pushCounter}
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                />
            </CompleteProfileContainer>
        </>
    );
};
