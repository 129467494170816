import React, { useState } from "react";
import { DEFAULT_SCREENS } from "components/ProfileChangeEmail/Screens";
export const ChangeEmailContext = React.createContext();

export const ChangeEmailProvider = ({ children }) => {
    const [activeScreen, setActiveScreen] = useState(DEFAULT_SCREENS.FORM);
    const [isBusy, setIsBusy] = useState(false);
    const [isLocked, setIsLocked] = useState(true);
    const [newValidEmail, setNewValidEmail] = useState(null)
    const [passwordIsValid, setPasswordIsValid] = useState(false)
    const [initPasswordValidation, setInitPasswordValidation] = useState(false)
    const [code, setCode] = useState(null)
    const [codeIsInvalid, setCodeIsInvalid] = useState(false)
    const [reSent, setReSent] = useState(false)

    return (
        <ChangeEmailContext.Provider
            value={{
                activeScreen,
                setActiveScreen,
                isBusy,
                setIsBusy,
                isLocked,
                setIsLocked,
                setNewValidEmail,
                newValidEmail,
                passwordIsValid,
                setPasswordIsValid,
                initPasswordValidation,
                setInitPasswordValidation,
                code,
                setCode,
                codeIsInvalid,
                setCodeIsInvalid,
                reSent,
                setReSent
            }}>
            {children}
        </ChangeEmailContext.Provider>
    );
};
