import { useQuery } from "react-query";
import { useGlobalContext } from "context/AppContext";
import { messageProvider } from "@services/messageProvider";

const NOTIFICATION_INTERVAL = 30000;
const UNWATCHED_URLS = ["/messages", "/messages"];

export const useMessageNotification = () => {
    const { personalProfile } = useGlobalContext();
    const enabled = !!personalProfile?.id && !UNWATCHED_URLS.includes(navigator.pathname);

    const getUnread = async () => {
        const response = await messageProvider.getUnreadMessages({ userID: personalProfile?.id});
        const unreadMessages = Number(response.messagesUnreadCounter);
        return unreadMessages;
    };

    const query = useQuery({
        queryKey: "unreadMessages",
        queryFn: getUnread,
        cacheTime: NOTIFICATION_INTERVAL,
        refetchInterval: NOTIFICATION_INTERVAL,
        enabled: enabled,
    });

    return {
        hasUnreadMessages: query.data,
    };
};
