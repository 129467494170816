import React, { useEffect, useState } from "react";
import "../FilterPopup.css";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import { FilterAccordion } from "../FilterAccordion";
import { locationProvider } from "services/locationProvider";
import { formatLocation, generateLocationOptions } from "utils/functions";

const LocationFilter = ({ filters, handleFilters }) => {
    const [isLoadingLocations, setIsLoadingLocations] = useState(false);
    const [search, setSearch] = useState("");
    const [locationOptions, setLocationOptions] = useState([]);
    const [locationValue, setLocationValue] = useState(
        filters?.location ? { country: filters.location } : formatLocation(filters),
    );

    const renderLocationsInput = (params) => {
        return (
            <TextField
                {...params}
                data-testid="location-textfield"
                label={null}
                variant="standard"
                className="filter-input"
                // onKeyUp={(e) => setSearch(e.target.value)}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
                InputProps={{
                    ...params.InputProps,
                    startAdornment:
                        filters?.country || filters?.location ? (
                            <PublicTwoToneIcon className="location-icon" fontSize="small" sx={{ ml: 0.5, mr: 1 }} />
                        ) : null,
                    endAdornment: (
                        <React.Fragment>
                            {isLoadingLocations ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                        </React.Fragment>
                    ),
                    placeholder: "Search",
                }}
            />
        );
    };

    const renderLocationOptions = (props, option) => {
        const location = formatLocation(option, true);
        return (
            <Box component="li" {...props}>
                <PublicTwoToneIcon className="location-icon" fontSize="small" sx={{ mr: 1 }} />
                {option?.location && <span className="search-for-location">search for:&nbsp;</span>}
                {location}
            </Box>
        );
    };

    const handleIsLocationOptionEqualToValue = (option, value) => {
        return formatLocation(value, true) === formatLocation(option, true);
    };

    useEffect(() => {
        if (search === "") {
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            getLocations();
        }, 450);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (locationValue === null) {
            handleFilters("location", null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationValue]);

    useEffect(() => {
        if (filters?.country === "" && filters?.location === "") {
            setLocationValue({ country: filters.country, state: filters.state, city: filters.city });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters?.country]);

    useEffect(() => {
        if (filters?.location === "") {
            setLocationValue({ country: filters.country, state: filters.state, city: filters.city });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters?.location]);

    const handleChangeLocation = (value) => {
        setLocationValue(value);
        handleFilters("location", value?.location ? { location: value.country } : value);
    };

    const getLocations = async (searchRequest = search) => {
        try {
            setIsLoadingLocations(true);

            const response = await locationProvider.getLocations(searchRequest);
            const locations = generateLocationOptions(response.data);
            setLocationOptions(locations);
        } catch (error) {
            setLocationOptions([]);
        } finally {
            setIsLoadingLocations(false);
        }
    };

    return (
        <FilterAccordion
            label="Location"
            filterChosen={Boolean(filters?.country?.length) || Boolean(filters?.location?.length)}>
            <Autocomplete
                data-testid="location-autocomplete"
                disablePortal
                onKeyDownCapture={(event) => event.stopPropagation()}
                value={locationValue}
                onChange={(_, val) => handleChangeLocation(val)}
                filterOptions={(x) => x}
                className="filter-select"
                ListboxProps={{ style: { maxHeight: "200px" } }}
                options={locationOptions}
                isOptionEqualToValue={handleIsLocationOptionEqualToValue}
                renderInput={renderLocationsInput}
                renderOption={renderLocationOptions}
                loading={isLoadingLocations}
                getOptionLabel={(option) => formatLocation(option, true)}
                freeSolo
            />
        </FilterAccordion>
    );
};

export default LocationFilter;
