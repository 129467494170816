import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbarContext } from "context/SnackbarContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import DropZoneEditorDialog from "components/DropZoneEditor/DropZoneEditorDialog";
import { debounce } from "lodash";
import { AVATAR_REMOVED_PLACEHOLDER, PROFILE_IMAGE_MESSAGES } from "utils/constants";
import { useAtom } from "jotai";
import { showInvalidImageMessageAtom } from "../atoms/profileImage";

const DEFAULT = {
    INVALID_SERVER_RESPONSE: "Image violates moderation policies",
    SCALE: 1,
    SCALE_MAX: 10,
    SCALE_MIN: 1,
    SCALE_STEP: 0.2,
};

const useDropZoneEditor = (defaultImg, fileRejections, changeEditState, profileId, removeTemporarilyAvatar) => {
    /*
    ---- State
    */
    const [showDialog, setShowDialog] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [scale, setScale] = useState(DEFAULT.SCALE);
    const [isEditing, setIsEditing] = useState(false);
    const [showRemoveDialog, setShowRemoveDialog] = useState(false);
    const [showUploadErrorDialog, setShowUploadErrorDialog] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showInvalidImageMessage, setShowInvalidImageMessage] = useAtom(showInvalidImageMessageAtom);
    const [showSuccessRemove, setShowSuccessRemove] = useState(false);
    const [hasExistingAvatar, setHasExistingAvatar] = useState(!!defaultImg);
    const [tempAvatar, setTempAvatar] = useState(null);
    const [isWaiting, setIsWaiting] = useState(false);

    /*
    ---- Hooks
    */
    const { showSnackbar } = useSnackbarContext();
    const { isDesktop } = useBreakpoint();

    /*
    ---- Functions
    */

    // Close main dialog
    const close = useCallback(() => {
        // Update states
        setIsEditing(hasExistingAvatar);
        setSelectedImage(null);
        setShowDialog(false);
        setScale(1);
    }, []);

    // Save action
    const save = useCallback(
        async (ref) => {
            // Update state and show backdrop
            setIsWaiting(true);

            if (ref) {
                // Get base64 value from canvas element
                const canvasScaled = ref.current.getImageScaledToCanvas();
                const canvasScaledDataURL = canvasScaled.toDataURL();

                // Set the image data in state
                changeEditState({ profileImage: canvasScaledDataURL });

                // Update state and hide backdrop
                setIsWaiting(false);
                // Close main dialog
                close();

                // Update states (API call moved to useProfileEdit.onSave())
                setTempAvatar(canvasScaledDataURL);
                setHasExistingAvatar(true);
                removeTemporarilyAvatar(false);
            }
        },
        [isDesktop],
    );

    // Remove action
    const remove = () => {
        // Update state to show dialog
        setShowRemoveDialog(true);
    };

    // Use debounce to provide better ux during image scaling (mobile only)
    const debouncedZoom = debounce((zoom) => {
        setScale((prev) => {
            if (zoom === "in" && prev < DEFAULT.SCALE_MAX) {
                return prev + DEFAULT.SCALE_STEP;
            } else if (zoom === "out" && prev > DEFAULT.SCALE_MIN) {
                return prev - DEFAULT.SCALE_STEP;
            } else {
                return zoom === "in" ? DEFAULT.SCALE_MAX : DEFAULT.SCALE_MIN;
            }
        });
    }, 5);

    /*
    ---- UseEffect
    */

    useEffect(() => {
        // It's considered "editing" when there's a selected image valid
        setIsEditing(!!selectedImage);
    }, [selectedImage]);

    useEffect(() => {
        // Shows an upload error dialog if user selects an invalid image
        if (fileRejections[0]?.errors) {
            setShowUploadErrorDialog(true);
        }
    }, [fileRejections]);

    /*
    ---- Memoized Dialogs
    */
    const successSaveDialog = useMemo(() => {
        if (showSuccessMessage && !isDesktop) {
            return (
                <DropZoneEditorDialog
                    title={PROFILE_IMAGE_MESSAGES.SUCCESS}
                    onAcceptText={PROFILE_IMAGE_MESSAGES.CONTINUE}
                    show={showSuccessMessage}
                    onClose={() => {
                        setShowSuccessMessage(false);
                    }}
                    onAccept={() => {
                        setShowSuccessMessage(false);
                    }}>
                    <p>{PROFILE_IMAGE_MESSAGES.AVATAR_SUCCESS}</p>
                </DropZoneEditorDialog>
            );
        }
    }, [showSuccessMessage]);

    const errorSaveDialog = useMemo(() => {
        if (showErrorMessage && !isDesktop) {
            return (
                <DropZoneEditorDialog
                    title={PROFILE_IMAGE_MESSAGES.ERROR}
                    onAcceptText={PROFILE_IMAGE_MESSAGES.CONTINUE}
                    show={showSuccessMessage}
                    onClose={() => {
                        setShowSuccessMessage(false);
                    }}
                    onAccept={() => {
                        setShowSuccessMessage(false);
                    }}>
                    <p>
                        {PROFILE_IMAGE_MESSAGES.UNABLE_UPLOAD} <br />
                        <br />
                        {PROFILE_IMAGE_MESSAGES.TRY_AGAIN}
                    </p>
                </DropZoneEditorDialog>
            );
        }
    }, [showErrorMessage]);

    const invalidImageDialog = useMemo(() => {
        if (showInvalidImageMessage) {
            return (
                <DropZoneEditorDialog
                    title={PROFILE_IMAGE_MESSAGES.INVALID_IMAGE}
                    onAcceptText={PROFILE_IMAGE_MESSAGES.CHOOSE_DIFFERENT_IMG}
                    onCancelText={PROFILE_IMAGE_MESSAGES.CANCEL}
                    show={showInvalidImageMessage}
                    onClose={() => {
                        setShowInvalidImageMessage(false);
                    }}
                    onAccept={() => {
                        setShowInvalidImageMessage(false);
                    }}>
                    <p>{PROFILE_IMAGE_MESSAGES.INVALID_IMAGE_MSG}</p>
                </DropZoneEditorDialog>
            );
        }
    }, [showInvalidImageMessage]);

    const successRemoveDialog = useMemo(() => {
        if (showSuccessRemove && !isDesktop) {
            return (
                <DropZoneEditorDialog
                    title={PROFILE_IMAGE_MESSAGES.SUCCESS}
                    onAcceptText={PROFILE_IMAGE_MESSAGES.CONTINUE}
                    show={showSuccessRemove}
                    onClose={() => {
                        setShowSuccessRemove(false);
                    }}
                    onAccept={() => {
                        setShowSuccessRemove(false);
                    }}>
                    <p>{PROFILE_IMAGE_MESSAGES.AVATAR_REMOVED}</p>
                </DropZoneEditorDialog>
            );
        }
    }, [showSuccessRemove]);

    const removeAvatarDialog = useMemo(() => {
        if (showRemoveDialog) {
            return (
                <DropZoneEditorDialog
                    title={PROFILE_IMAGE_MESSAGES.REMOVE_AVATAR}
                    onCancelText={PROFILE_IMAGE_MESSAGES.CANCEL}
                    onAcceptText={PROFILE_IMAGE_MESSAGES.REMOVE}
                    show={showRemoveDialog}
                    reverseButtons={true}
                    onClose={() => {
                        setShowRemoveDialog(false);
                    }}
                    onCancel={() => {
                        setShowRemoveDialog(false);
                    }}
                    onAccept={() => {
                        setShowRemoveDialog(false);
                        setTempAvatar(null);
                        setHasExistingAvatar(false);
                        changeEditState({ profileURL: AVATAR_REMOVED_PLACEHOLDER });
                        removeTemporarilyAvatar(true);
                        close();

                        if (!isDesktop) {
                            setShowSuccessRemove(true);
                        } else {
                            showSnackbar({
                                title: PROFILE_IMAGE_MESSAGES.SUCCESS,
                                description: PROFILE_IMAGE_MESSAGES.AVATAR_REMOVED,
                                severity: "success",
                                anchor: { vertical: "top", horizontal: "right" },
                            });
                        }
                    }}>
                    <p>
                        {PROFILE_IMAGE_MESSAGES.CONFIRM_REMOVE}{" "}
                        {!isDesktop ? (
                            <>
                                <br />
                                <br />
                            </>
                        ) : (
                            <br />
                        )}
                        {PROFILE_IMAGE_MESSAGES.AVATAR_REPLACE_INFO}
                        <br />
                        <br />
                        {PROFILE_IMAGE_MESSAGES.SAVE_BEFORE_EXIT}
                    </p>
                </DropZoneEditorDialog>
            );
        }
    }, [showRemoveDialog]);

    const invalidUploadDialog = useMemo(() => {
        const errorType = fileRejections?.[0]?.errors?.[0]?.code || null;
        if (showUploadErrorDialog && errorType) {
            return (
                <DropZoneEditorDialog
                    title={
                        errorType === "file-invalid-type"
                            ? PROFILE_IMAGE_MESSAGES.INVALID_EXTENSION_TITLE
                            : PROFILE_IMAGE_MESSAGES.INVALID_SIZE_TITLE
                    }
                    onCancelText={PROFILE_IMAGE_MESSAGES.CANCEL}
                    onAcceptText={
                        !isDesktop
                            ? PROFILE_IMAGE_MESSAGES.CHOOSE_ANOTHER_IMG
                            : PROFILE_IMAGE_MESSAGES.CHOOSE_DIFFERENT_IMG
                    }
                    show={showUploadErrorDialog}
                    reverseButtons={true}
                    onClose={() => {
                        setShowUploadErrorDialog(false);
                    }}
                    onCancel={() => {
                        close();
                        setShowUploadErrorDialog(false);
                    }}
                    onAccept={() => {
                        setShowUploadErrorDialog(false);
                    }}>
                    <p>
                        {errorType === "file-invalid-type" ? (
                            <>
                                {PROFILE_IMAGE_MESSAGES.INVALID_EXTENSION}{" "}
                                {!isDesktop ? (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ) : (
                                    <br />
                                )}{" "}
                                {PROFILE_IMAGE_MESSAGES.INVALID_EXTENSION_INFO}
                            </>
                        ) : (
                            <>
                                {PROFILE_IMAGE_MESSAGES.INVALID_SIZE}{" "}
                                {!isDesktop ? (
                                    <>
                                        <br />
                                        <br />
                                    </>
                                ) : (
                                    <br />
                                )}{" "}
                                {PROFILE_IMAGE_MESSAGES.INVALID_SIZE_INFO}
                            </>
                        )}
                    </p>
                </DropZoneEditorDialog>
            );
        }
    }, [showUploadErrorDialog]);

    return {
        selectedImage,
        setSelectedImage,
        showDialog,
        setShowDialog,
        hasExistingAvatar,
        isEditing,
        setIsEditing,
        save,
        remove,
        close,
        scale,
        setScale,
        setShowRemoveDialog,
        removeAvatarDialog,
        invalidUploadDialog,
        invalidImageDialog,
        successSaveDialog,
        errorSaveDialog,
        successRemoveDialog,
        debouncedZoom,
        setShowSuccessMessage,
        isWaiting,
        tempAvatar,
    };
};

export default useDropZoneEditor;
