import React, { useContext } from "react";
import { Container } from "@mui/material";
import "../../pages/Home/UserList.css";
import { FilterContext } from "context/FilterContext";
import Button from "components/Button";

export const FilterControls = ({ applyFilter }) => {
    const { resultsCount, hasSelectedFilters } = useContext(FilterContext);

    // const hasResults = resultsCount > 0;
    const hasResults = false;
    const multipleResults = resultsCount > 1;

    const formattedResults = `Show ${resultsCount} result${multipleResults ? "s" : ""}`;
    const label = hasResults ? formattedResults : "Apply";
    
    return (
        <Container key={"buttons"} className={"filter-buttons"}>
            <Button
                data-testid='apply-button'
                width="150px"
                className={"show-filter-button"}
                variant="contained"
                size="large"
                onClick={applyFilter}
                disabled={!hasSelectedFilters}>
                {label}
            </Button>
        </Container>
    );
};

export default FilterControls;
