import "./GroupHero.scss";

import React from "react";

import { SecondaryApplyButton } from "@components/ApplyButton";
import { Box, Chip, SvgIconProps, Tooltip, Typography } from "@mui/material";
import { ArrowBack, ShareOutlined, AccessTimeTwoTone, LanguageTwoTone, CheckCircle } from "@mui/icons-material";

import { GroupType } from "models/group/GroupModel";
import { useNavigate } from "react-router-dom";
import { ProfileModel } from "models/ProfileModels";
import { useBreakpoint } from "hooks/useBreakpoint";
import { GoldenBorder } from "@components/StyledBorders";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { CMSLanguages } from "models/cms/fileds";
import { getWeekdayAbbreviation } from "utils/functions";
import { GroupsGameType } from "hooks/useGroupListItem";

type GroupHeroViewProps = {
    host: ProfileModel;
    game: GroupsGameType;
    group: Partial<GroupType>;
    joined: boolean;
    weekday: string;
    language: CMSLanguages | null;
    setGroup: React.Dispatch<React.SetStateAction<Partial<GroupType>>>;
    platform: { name: string; img: string };
    hostView: boolean;
    groupName: string;
    schedule_end: string;
    schedule_start: string;
    personalProfile?: ProfileModel;
};

type GroupInformation = {
    icon: React.ComponentType<SvgIconProps>;
    value: string;
    property: string;
};

export default function GroupHeroView({
    game,
    group,
    joined,
    weekday,
    platform,
    hostView,
    language,
    groupName,
    schedule_end,
    schedule_start,
}: GroupHeroViewProps) {
    /* States and Hooks */
    const navigate = useNavigate();

    const { isMobile } = useBreakpoint();

    function Chips() {
        return (
            <>
                <Box className="group-info-chips-container">
                    {groupInformation.map((info) => {
                        return (
                            <Chip
                                key={info.property}
                                className="group-info-chip"
                                icon={<info.icon fontSize="small" />}
                                label={
                                    <Box className="group-info-chip-label">
                                        <Typography className="group-info-chip-property">{info.property}</Typography>
                                        <Typography className="group-info-chip-value">{info.value}</Typography>
                                    </Box>
                                }
                            />
                        );
                    })}
                </Box>
                <Box className="group-info-chips-right-container">
                    <ShouldRender returnNull condition={!hostView && joined}>
                        <Chip
                            label="YOU ARE IN!"
                            onClick={() => navigator.clipboard.writeText(window.location.href)}
                            className="group-info-chip you-are-in-chip"
                            {...(!isMobile && { icon: <CheckCircle fontSize="small" /> })}
                        />
                    </ShouldRender>
                    <ShouldRender returnNull condition={!isMobile}>
                        <Chip
                            onClick={() => navigator.clipboard.writeText(window.location.href)}
                            className="group-info-chip group-info-chip-share"
                            icon={<ShareOutlined fontSize="small" />}
                            label="Copy Group Link"
                        />
                    </ShouldRender>
                </Box>
            </>
        );
    }

    /* Variables */
    const groupHeroViewClasses: string[] = ["group-hero-container", "profile-component", "group-viewer"];

    const groupInformation: GroupInformation[] = [
        {
            icon: AccessTimeTwoTone,
            property: getWeekdayAbbreviation(weekday),
            value: `${schedule_start} – ${schedule_end}`,
        },
        {
            icon: LanguageTwoTone,
            property: language?.languageName || "",
            value: "",
        },
    ];

    return (
        <Box className="group-hero-container-spacer">
            <GoldenBorder borderRadius={8} borderSize={0.2} enabled={group.game_hosting_type === "Paid"}>
                <Box className={`${groupHeroViewClasses.join(" ")} ${hostView ? "host-view" : ""}`}>
                    {/* Header */}
                    <Box className="group-hero-header">
                        <Box className="group-hero-title">
                            <ArrowBack onClick={() => navigate(-1)} className="back-button" fontSize="small" />
                            <Typography className="group-hero-name">{hostView ? "Your Group" : groupName}</Typography>
                        </Box>
                        {hostView && (
                            <SecondaryApplyButton
                                onClick={() => {
                                    navigate("/edit-group", { state: { groupId: group.group_id } });
                                }}>
                                edit group
                            </SecondaryApplyButton>
                        )}
                    </Box>
                    {/* Chips */}
                    {Chips()}
                    {/* Content */}
                    {game.groupsImage ? (
                        <Box
                            draggable={false}
                            component="img"
                            className="group-hero-background"
                            src={game.groupsImage}
                            alt="Group Game Background"
                        />
                    ) : (
                        <Box draggable={false} className="group-hero-background" />
                    )}
                    <Box className="group-hero-content">
                        <GoldenBorder
                            borderRadius={8}
                            borderSize={0.2}
                            enabled={group.game_hosting_type === "Paid" && isMobile}>
                            <Box className="group-hero-content-item game-item">
                                <Typography className="subtitle">Game</Typography>
                                <Typography variant="h3" className="title">
                                    {game.name}
                                </Typography>
                            </Box>
                        </GoldenBorder>
                        <ShouldRender returnNull condition={!isMobile}>
                            <Box className="group-hero-content-item">
                                <Typography className="subtitle">Platform</Typography>
                                <Tooltip title={platform.name}>
                                    <Chip
                                        className="group-hero-platform"
                                        variant="outlined"
                                        icon={
                                            <Box
                                                component={"img"}
                                                className="group-platform-logo"
                                                alt="platform logo"
                                                src={`/vtts/${platform.img || `${platform.name}.svg`}`}
                                            />
                                        }
                                        label={platform.name}
                                        style={{ border: "1px solid #4a4a4b" }}
                                    />
                                </Tooltip>
                            </Box>
                        </ShouldRender>
                    </Box>
                </Box>
            </GoldenBorder>
        </Box>
    );
}
