import { View } from "@aws-amplify/ui-react";
import Button from "components/Button";
import React from "react";

const EditComponent = ({ isEditing, onClick }) => {
    const handleEdit = () => {
        onClick();
    };

    if (isEditing) {
        return null;
    }

    return (
        <View className="message-or-edit">
            <Button
                className="edit-button"
                backgroundColor="black"
                width="11.25rem"
                sx={{ height: "2.5rem", marginTop: "13.56rem", marginRight: "1.25rem" }}
                onClick={handleEdit}>
                edit profile
            </Button>
        </View>
    );
};

export default EditComponent;
