import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileThingsLikedTTRPG from "@components/ProfileInterview/ProfileThingsLikedTTRPG";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const ThingsLikedTTRPGComponent = ({
    state,
    handleEditProfile,
    pushCounter,
    goBack,
    counter,
    length,
    goNextButton,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-things-liked-ttrpg">
                <View className="top-text"></View>
                <View className="question-container creating">
                    <ProfileThingsLikedTTRPG
                        state={state}
                        pushCounter={pushCounter}
                        handleEditProfile={handleEditProfile}
                    />
                </View>
                <BackNextBtn
                    goBack={goBack}
                    pushCounter={pushCounter}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                    pageState={state?.thingsLikedTTRPG}
                />
            </CompleteProfileContainer>
        </>
    );
};
