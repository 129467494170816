import React, { useContext } from "react";
import { Flex, Image } from "@aws-amplify/ui-react";
import { Box, SwipeableDrawer } from "@mui/material";
import SupervisorAccountTwoToneIcon from "@mui/icons-material/SupervisorAccountTwoTone";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { TagTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import MenuItem from "@components/LeftMenu/MenuItem";
import MenuLogIn from "@components/LeftMenu/MenuLogIn";
import MenuMore from "@components/LeftMenu/MenuMore";

import MoreHorizTwoToneIcon from "@mui/icons-material/MoreHorizTwoTone";
import { useBreakpoint } from "../../hooks/useBreakpoint";

import "./index.css";
import { useGlobalContext } from "context/AppContext";
import { LoginContext } from "context/LoginContext";
import { useMessagesContext } from "../../context/MessagesContext";

import MessageLogo from "components/LeftMenu/MessageLogo";

function LeftMenu({ userHasProfile, leftMenuSignOut, activeBtn, hideMenu, overrideCentering }) {
    const { isLoggedIn, personalProfile, isMobile } = useGlobalContext();
    const { isTablet, isPhone, isLaptop } = useBreakpoint();
    const { setLoginPopup } = useContext(LoginContext);
    const { setReturnInbox } = useMessagesContext();

    const navigate = useNavigate();

    const moreLinks = ["/settings", "/about", "/privacy", "/community-guidelines"];
    const isPublicPage = ["profile", "location", "game", "explore", "groups", "group"].some((page) =>
        window.location.pathname.includes(page),
    );
    const notLoggedOnProfilePage = !isLoggedIn && isPublicPage;

    const profileAction = () => {
        setLoginPopup(true);
    };

    const menuItem = [
        <MenuItem
            {...(notLoggedOnProfilePage && { btnFunc: profileAction })}
            key={"user-list-menu-item"}
            Logo={PersonOutlineIcon}
            text={"Users"}
            link={"/"}
            activeBtn={activeBtn}
        />,
        <MenuItem
            key={"groups-list-menu-item"}
            Logo={SupervisorAccountTwoToneIcon}
            text={"Groups"}
            link={"/groups"}
            activeBtn={activeBtn}
        />,
        !isMobile && (
            <MenuItem
                {...(notLoggedOnProfilePage && { btnFunc: profileAction })}
                key={"starred-list-menu-item"}
                Logo={StarBorderIcon}
                text={"Starred"}
                link={"/starred"}
                activeBtn={activeBtn}
            />
        ),
        <MenuItem
            key={"explore-menu-item"}
            Logo={TagTwoTone}
            text={"Explore"}
            link={"/explore"}
            activeBtn={activeBtn}
        />,
        <MenuItem
            key={"messages-menu-item"}
            Logo={MessageLogo}
            text={"Messages"}
            link={"/messages"}
            activeBtn={activeBtn}
            {...(notLoggedOnProfilePage && {
                btnFunc: () => {
                    profileAction();
                    setReturnInbox(true);
                },
            })}
        />,
        <MenuLogIn
            {...(notLoggedOnProfilePage && { btnFunc: profileAction })}
            key={"my-profile-menu-item"}
            isLoggedIn={isLoggedIn}
            userHasProfile={userHasProfile}
            activeBtn={activeBtn}
        />,
        <MenuMore
            {...(notLoggedOnProfilePage && { btnFunc: profileAction })}
            key={"more-menu-item"}
            Logo={MoreHorizTwoToneIcon}
            role={personalProfile?.role}
            text={"More"}
            signOutBtn={leftMenuSignOut}
            activeBtn={activeBtn}
            moreLinks={moreLinks}
            navigate={navigate}
        />,
    ];

    return (
        <React.Fragment key={"left"}>
            <SwipeableDrawer
                anchor={"left"}
                variant={"permanent"}
                sx={{
                    ".MuiDrawer-paperAnchorLeft": {
                        backgroundColor: "#171717",
                        position: "relative",
                        top: "0",
                        left: "0",
                        marginLeft: isLaptop || isTablet || overrideCentering ? "" : "-275px",
                        border: `0px solid transparent`,
                    },
                    display: hideMenu && "none",
                }}
                open={false}
                onOpen={() => {
                    /* empty */
                }}
                onClose={() => {
                    /* empty */
                }}>
                <Box
                    sx={{ "&::-webkit-scrollbar": { display: "none" } }}
                    id="hamburger-menu"
                    className="show-hamburger scroll-menu">
                    <Flex height={"100%"} direction={isPhone ? "row" : "column"} gap={"2px"}>
                        {isTablet ? (
                            <Image src={"/logo192.png"} className="hamburger-main-logo" onClick={() => navigate("/")} />
                        ) : (
                            <Image
                                src={"/main_logo.png"}
                                className="hamburger-main-logo"
                                onClick={() => navigate("/")}
                            />
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                height: "100%",
                            }}>
                            <Box className="menu-item-container">
                                {menuItem.map((value, i) => {
                                    return <React.Fragment key={i}>{value}</React.Fragment>;
                                })}
                            </Box>
                        </Box>
                    </Flex>
                </Box>
            </SwipeableDrawer>
        </React.Fragment>
    );
}

export default LeftMenu;
