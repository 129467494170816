import "../Home/UserList.css";
import React, { useEffect, useContext, useState } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { FilterContext, defaultFilters } from "context/FilterContext";
import { useGlobalContext } from "context/AppContext";
import { useFilter } from "hooks/useFilter";
import { typesOfSort, useSort } from "hooks/useSort";
import UserListComponent from "components/UserList/UserListComponent";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useFavorites } from "hooks/useFavorites";
import { useQueryClient } from "react-query";
import { LoginContext } from "context/LoginContext";
import { GameBanner } from "./GameBanner";
import { Helmet } from "react-helmet";
import { usePublicGamePage } from "./usePublicGamePage";
import { LoginBanner } from "components/LoginBanner/LoginBanner";
import { saveRedirect } from "utils/functions";
import { getDataByContentType } from "services/contentful";
export const sortingOptions = typesOfSort.map((x) => x?.displayName);

const PublicGame = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { gameName } = useParams();
    const queryClient = useQueryClient();
    const {
        userList,
        scrollTarget,
        onTargetScroll,
        isFetchingPage,
        setUsers,
        reloadFavorites,
        isChangingPage,
        favoritesToDelete,
        setFavoritesToDelete,
        gameStatus,
    } = usePaginatedContext();

    const { loginPopup, setLoginPopup } = useContext(LoginContext);
    const { isFiltering, setFilters, setPage, setCurrentFilters } = useContext(FilterContext);
    const [showLoginBanner, setShowLoginBanner] = useState(false);
    const { updateFavorites, wait } = useFavorites({
        allProfiles: userList,
        setAllProfiles: setUsers,
        reloadFavorites,
        favoritesToDelete,
        setFavoritesToDelete,
    });
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        const cache = queryClient.getQueryCache();
        let starred = [];
        let home = [];

        cache.queries.forEach((itemQuery) => {
            if (itemQuery?.state?.data?.data?.length > 0) {
                if (itemQuery.queryKey.includes("starred")) {
                    const dataToUpdate = itemQuery?.state?.data?.data.filter(
                        (item) => !favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id)),
                    );
                    starred.push(dataToUpdate);
                    return;
                }
                home.push(itemQuery?.state?.data?.data);
            }
        });

        let homeArray = [].concat.apply([], home);
        let starredArray = [].concat.apply([], starred);

        homeArray = homeArray.map((item) => {
            const found = favoritesToDelete.find((x) => x?.[0] === (item?.id?.[0] || item?.id));

            if (found) return { ...item, favorited: false };
            else return item;
        });

        if (location.pathname === "/starred") {
            setUsers([...starredArray]);
            setFilters(defaultFilters);
            setCurrentFilters(defaultFilters);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    /** HOOKS */
    const foundUsers = userList && userList.length > 0;
    const { isLoggedIn, userCompletedProfile, isLoggedInVerified } = useGlobalContext();
    const { filterFields, loading: loadingData, isSuccess } = useContext(AsyncFilterFieldsContext);

    const [isError, setIsError] = useState(false);

    const checkIfUrlExists = async () => {
        const gamesList = await getDataByContentType("game");

        const foundUrlOnGamesList = gamesList.find(
            (game) => game.pageUrlPath === location.pathname.replace("/game/", ""),
        );
        if (!foundUrlOnGamesList) {
            navigate("/error");
        }
    };

    useEffect(() => {
        checkIfUrlExists();
    }, []);

    const {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        setDefaultFilters,
        currentFilters,
        generateQueryParam,
    } = useFilter({
        filterFields,
        publicGame: true,
    });

    const { anchorEl, isSortMenuOpen, handleOpenSortMenu, handleCloseSortMenu, onSelectSort, sortingOption } = useSort({
        sortingOptions,
        setUsers,
        setFilters,
        setPage,
    });

    const sortState = {
        sortingOption,
        sortingOptions,
        anchorEl,
        isSortMenuOpen,
        handleOpenSortMenu,
        handleCloseSortMenu,
        onSelectSort,
    };

    const filterState = {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        isFiltering,
        setDefaultFilters,
        currentFilters,
        generateQueryParam,
    };

    const {
        gameId,
        pageTitle,
        description,
        image,
        badges,
        links,
        people,
        group,
        ogDescription,
        ogTitle,
        ogImage,
        HTMLTitle,
    } = usePublicGamePage(gameName);

    useEffect(() => {
        if (gameStatus === "success" && isLoggedInVerified) {
            setShowLoginBanner(!isLoggedIn);
        }
        if (gameId) {
            setCurrentFilters({ gamesPlayed: [gameId] });
            setFilters({ gamesPlayed: [gameId] });
        }
    }, [isLoggedIn, gameStatus, gameName, gameId]);

    useEffect(() => {
        return () => {
            setFilters(defaultFilters);
            setCurrentFilters(defaultFilters);
        };
    }, []);

    return (
        <>
            <Helmet>
                <title>{HTMLTitle}</title>

                <meta name="twitter:title" content={pageTitle} />
                <meta name="description" content={description} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />

                <meta property="og:title" content={ogTitle} />
                <meta property="og:description" content={ogDescription} />
                <meta property="og:image" content={ogImage} />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="RPGMatch" />
            </Helmet>

            <GameBanner
                expanded={expanded}
                expand={() => setExpanded((prev) => !prev)}
                image={image}
                title={pageTitle}
                description={description}
                badges={badges}
                links={links}
                people={people}
                group={group}
            />

            <UserListComponent
                userList={userList}
                foundUsers={foundUsers}
                wait={wait}
                updateFavorites={updateFavorites}
                setUsers={setUsers}
                userCompletedProfile={userCompletedProfile}
                isLoggedIn={isLoggedIn}
                filterState={filterState}
                sortState={sortState}
                setDefaultFilters={setDefaultFilters}
                scrollTarget={scrollTarget}
                onTargetScroll={onTargetScroll}
                isFetchingPage={isFetchingPage}
                loadingFiltersFields={loadingData || !isSuccess}
                filterFields={filterFields}
                isChangingPage={isChangingPage}
                favoritesToDelete={favoritesToDelete}
                loginPopup={loginPopup}
                setLoginPopup={setLoginPopup}
                sortingFilterIntegrated={true}
                title={"User List"}
            />
            {showLoginBanner && <LoginBanner />}
        </>
    );
};

export default PublicGame;
