export const passwordErrorMsgs = [
    { type: "hasMinlength", message: "• 6 characters minimum\n", showAfterSubmited: false },
    { type: "hasMaxlength", message: "• 256 characters maximum\n", showAfterSubmited: true },
    { type: "hasUppercase", message: "• 1 uppercase letter\n", showAfterSubmited: false },
    { type: "hasLowercase", message: "• 1 lowercase letter\n", showAfterSubmited: false },
    { type: "hasNoSpaces", message: "• Non-leading, non-trailing space characters\n", showAfterSubmited: true },
    {
        type: "hasAcceptedSpecialCharacters",
        message: "• Accepted special characters: ^ $ * . [ ] { } ( ) ? \" ! @ # % & /  , > < ' : ; | _ ~ ` = + -",
        showAfterSubmited: true,
    },
];

export const hasMinlength = (value) => value.length >= 6;
export const hasMaxlength = (value) => value.length <= 256;
export const hasUppercase = (value) => /[A-Z]/.test(value);
export const hasLowercase = (value) => /[a-z]/.test(value);
export const hasNoSpaces = (value) => value.indexOf(" ") === -1;
export const hasAcceptedSpecialCharacters = (value) => /^[A-Za-z0-9!@#$%^.(,/\\{}[\]|<>):`"=+;_'?~&*-]*$/.test(value);
