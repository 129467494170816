import React, { useEffect, useState } from "react";
import "./ProfileAvailabilityTable.css";

import { Checkbox, FormControlLabel, Switch } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

import CoffeeMakerTwoToneIcon from "@mui/icons-material/CoffeeMakerTwoTone";
import WbSunnyTwoToneIcon from "@mui/icons-material/WbSunnyTwoTone";
import NightlightTwoToneIcon from "@mui/icons-material/NightlightTwoTone";
import Brightness6TwoToneIcon from "@mui/icons-material/Brightness6TwoTone";
import BedTwoToneIcon from "@mui/icons-material/BedTwoTone";

export const ProfileAvailabilityTable = ({ state, handleEditProfile, pushCounter }) => {
    const [availability, setAvailability] = useState(state?.noAvailability[0] || false);

    const hasState = () => {
        let newState = [
            { id: "mon", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "tues", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "weds", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "thurs", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "fri", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "sat", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "sun", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
        ];

        if (state.availabilityTable) {
            newState = [
                {
                    id: "mon",
                    morning: state.availabilityTable[0][0],
                    afternoon: state.availabilityTable[0][1],
                    evening: state.availabilityTable[0][2],
                    night: state.availabilityTable[0][3],
                    late_night: state.availabilityTable[0][2],
                },
                {
                    id: "tues",
                    morning: state.availabilityTable[1][0],
                    afternoon: state.availabilityTable[1][1],
                    evening: state.availabilityTable[1][2],
                    night: state.availabilityTable[1][3],
                    late_night: state.availabilityTable[1][4],
                },
                {
                    id: "weds",
                    morning: state.availabilityTable[2][0],
                    afternoon: state.availabilityTable[2][1],
                    evening: state.availabilityTable[2][2],
                    night: state.availabilityTable[2][3],
                    late_night: state.availabilityTable[2][4],
                },
                {
                    id: "thurs",
                    morning: state.availabilityTable[3][0],
                    afternoon: state.availabilityTable[3][1],
                    evening: state.availabilityTable[3][2],
                    night: state.availabilityTable[3][3],
                    late_night: state.availabilityTable[3][4],
                },
                {
                    id: "fri",
                    morning: state.availabilityTable[4][0],
                    afternoon: state.availabilityTable[4][1],
                    evening: state.availabilityTable[4][2],
                    night: state.availabilityTable[4][3],
                    late_night: state.availabilityTable[4][4],
                },
                {
                    id: "sat",
                    morning: state.availabilityTable[5][0],
                    afternoon: state.availabilityTable[5][1],
                    evening: state.availabilityTable[5][2],
                    night: state.availabilityTable[5][3],
                    late_night: state.availabilityTable[5][4],
                },
                {
                    id: "sun",
                    morning: state.availabilityTable[6][0],
                    afternoon: state.availabilityTable[6][1],
                    evening: state.availabilityTable[6][2],
                    night: state.availabilityTable[6][3],
                    late_night: state.availabilityTable[6][4],
                },
            ];
        }

        return newState;
    };

    const [textValue, setTextValue] = useState(hasState);
    const [isLoading, setIsLoading] = useState(false);
    const [disabledValue, setDisabledValue] = useState(true);
    const [availabilityArr, setAvailabilityArr] = useState(["0"]);

    useEffect(() => {
        if (availabilityArr.includes("1") || availability) {
            setDisabledValue(false);
        } else {
            setDisabledValue(true);
        }
    }, [availabilityArr, availability]);

    const doubleCheckCheck = (val) => {
        if (String(val) === "0") {
            return false;
        } else {
            return true;
        }
    };

    const updateTable = (e) => {
        // id and times used for evaluations
        const day = e.target.name;
        const time = e.target.value;
        const checked = e.target.checked ? 1 : 0;

        // loops through state and returns changes into a temp state
        const newState = textValue.map((obj) => {
            if (obj.id === day) {
                return { ...obj, [time]: String(checked) };
            }
            return { ...obj };
        });
        // Sets new state
        setTextValue(newState);

        setAvailabilityArr(["0"]);
        newState.forEach((day) => {
            const dayArr = Object.values(day);
            setAvailabilityArr((prevAvailabilityArr) => [...prevAvailabilityArr, ...dayArr]);
        });
    };

    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        const availabilityArray = [];

        // Loop through state and construct the array to be sent. Was done this way for readability/maintainability.
        textValue.forEach((obj) => {
            availabilityArray.push([obj.morning, obj.afternoon, obj.evening, obj.night, obj.late_night]);
        });

        setIsLoading(true);
        await handleEditProfile({ availabilityTable: availabilityArray, noAvailability: [availability] });
        setIsLoading(false);
    };
    // While nice, this was out of scope for this component's rapid development. Best to assess the backend integration first.
    return (
        <View className="form-container margin-top">
            <Text as="h2">What's your availability?</Text>
            <View className="input-container availability-table-container survey-availability-view">
                <Table className=" survey-availability-table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell className="align-center">
                                <CoffeeMakerTwoToneIcon />
                                <Text className="time-blocks">8am-12pm</Text>
                            </TableCell>
                            <TableCell className="align-center">
                                <WbSunnyTwoToneIcon />
                                <Text className="time-blocks">12pm-4pm</Text>
                            </TableCell>
                            <TableCell className="align-center">
                                <NightlightTwoToneIcon />
                                <Text className="time-blocks">4pm-7pm</Text>
                            </TableCell>
                            <TableCell className="align-center">
                                <Brightness6TwoToneIcon />
                                <Text className="time-blocks">7pm-12am</Text>
                            </TableCell>
                            <TableCell className="align-center">
                                <BedTwoToneIcon />
                                <Text className="time-blocks">12am-8am</Text>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Monday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[0].morning)}
                                    name="mon"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[0].afternoon)}
                                    name="mon"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[0].evening)}
                                    name="mon"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[0].night)}
                                    name="mon"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[0].late_night)}
                                    name="mon"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Tuesday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[1].morning)}
                                    name="tues"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[1].afternoon)}
                                    name="tues"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[1].evening)}
                                    name="tues"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[1].night)}
                                    name="tues"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[1].late_night)}
                                    name="tues"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Wednesday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[2].morning)}
                                    name="weds"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[2].afternoon)}
                                    name="weds"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[2].evening)}
                                    name="weds"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[2].night)}
                                    name="weds"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[2].late_night)}
                                    name="weds"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Thursday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[3].morning)}
                                    name="thurs"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[3].afternoon)}
                                    name="thurs"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[3].evening)}
                                    name="thurs"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[3].night)}
                                    name="thurs"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[3].late_night)}
                                    name="thurs"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Friday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[4].morning)}
                                    name="fri"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[4].afternoon)}
                                    name="fri"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[4].evening)}
                                    name="fri"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[4].night)}
                                    name="fri"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[4].late_night)}
                                    name="fri"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Saturday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[5].morning)}
                                    name="sat"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[5].afternoon)}
                                    name="sat"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[5].evening)}
                                    name="sat"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[5].night)}
                                    name="sat"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[5].late_night)}
                                    name="sat"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sunday</TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[6].morning)}
                                    name="sun"
                                    value="morning"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[6].afternoon)}
                                    name="sun"
                                    value="afternoon"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[6].evening)}
                                    name="sun"
                                    value="evening"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[6].night)}
                                    name="sun"
                                    value="night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={doubleCheckCheck(textValue[6].late_night)}
                                    name="sun"
                                    value="late_night"
                                    onChange={updateTable}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </View>
            <View className="noAvailability-container">
                <FormControlLabel
                    control={<Switch checked={availability} onChange={(e) => setAvailability(e.target.checked)} />}
                    label="No availability"
                />
                <span>Note: when selected the availability table won't be shown on your profile</span>
            </View>
            <View className="button-container">
                <LoadingBtn
                    label="THAT'S IT"
                    loading={isLoading}
                    disabled={disabledValue}
                    handleOnClick={handleSaveValue}
                />
            </View>
        </View>
    );
};
export default ProfileAvailabilityTable;
