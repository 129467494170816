import React from "react";
import { Text, View } from "@aws-amplify/ui-react";
import "../ProfileBody.css";
import "./About.css";
import { timezones } from "utils/timezones";
import { getMonthAndYear } from "utils/dateTimeFormatter";
import { isFilled } from "utils/functions";
import { Link } from "react-router-dom";
import useCurrentUserInfo from "hooks/useCurrentUserInfo";

const About = ({ state, isPublicProfile }) => {
    const { email } = useCurrentUserInfo();

    if (!state) {
        return null;
    }

    const locationTextComponent = () => {
        let location = "Earth";
        let path = "Earth";

        try {
            const locations = ["city", "state", "country"]
                .map((key) => state?.[key]?.[0]?.trim?.())
                .filter((location) => !!location);

            if (isFilled(locations)) {
                location = locations.join(", ");
                path = locations
                    .slice()
                    .reverse()
                    .join("/")
                    .replaceAll(" ", "-")

                    // URL normalization
                    // .toLowerCase()
                    // .normalize("NFD")
                    // .replace(/[\u0300-\u036f]/g, '');
            }
        } catch {}

        return (
            <Link to={`/location/${path}`} className="location-link">
                {location}
            </Link>
        );
    };

    return (
        <View data-testid="about" className="profile-section">
            <Text className="about-head-text">About</Text>
            <View className="about-text">
                <View className="container">
                    <Text className="info-title">From</Text>
                    <View className="subcontainer">
                        <img src="/profileIcons/PublicTwoTone.svg" alt="icon" />
                        {locationTextComponent()}
                    </View>
                </View>
                <View className="container">
                    <Text className="info-title">Timezone</Text>
                    <View className="subcontainer">
                        <img src="/profileIcons/AccessTimeTwoTone.svg" alt="icon" />
                        <Text>{timezones[state?.timezone?.[0]]}</Text>
                    </View>
                </View>
                <View className="container">
                    <Text className="info-title">Joined</Text>
                    <View className="subcontainer">
                        <img src="/profileIcons/CalendarTodayTwoTone.svg" alt="icon" />
                        <Text>{getMonthAndYear(state?.dateCreated?.[0])}</Text>
                    </View>
                </View>
                <View className="container">
                    <Text className="info-title">Age Range</Text>
                    <View className="subcontainer">
                        <img src="/profileIcons/SocialDistanceTwoTone.svg" alt="icon" />
                        <Text>{state?.age}</Text>
                    </View>
                </View>
                <View className="container">
                    <Text className="info-title">Gender</Text>
                    <View className="subcontainer">
                        <img src="/profileIcons/WcTwoTone.svg" alt="icon" />
                        <Text>{state.gender}</Text>
                    </View>
                </View>
                {!isPublicProfile && email && (
                    <View className="container">
                        <Text className="info-title">Email</Text>
                        <View className="subcontainer about-email-user">
                            <img src="/profileIcons/MailTwoTone.svg" alt="icon" />
                            <Text>
                                {email} <br />{" "}
                                <small>
                                    <i>Only you can see this information</i>
                                </small>
                            </Text>
                        </View>
                    </View>
                )}
            </View>
        </View>
    );
};

export default About;
