import React, { useState } from "react";
import { Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import "./index.css";
import { Menu, MenuItem } from "@mui/material";

function MenuMore({ btnFunc = null, link, text, Logo, signOutBtn, moreLinks, activeBtn, navigate, role }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const isActive = moreLinks.includes(activeBtn);

    const handleOpenMoreMenu = (event) => {
        if (btnFunc) {
            btnFunc();
            return;
        }

        setAnchorEl(event.currentTarget);
    };

    const handleCloseMoreMenu = (link = null) => {
        setAnchorEl(null);
        if (link) {
            navigate(link);
        }
    };

    return (
        <>
            <Link
                data-testid="menu-more"
                onClick={handleOpenMoreMenu}
                to={link}
                className={`menu-item-link ${open || isActive ? "active" : ""}`}>
                <div className="moreLogoContainer">
                    <Logo></Logo>{" "}
                </div>
                <Text className="hamburger-menu-item">{text}</Text>
            </Link>
            <Menu
                sx={{ borderRadius: "8px" }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseMoreMenu}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}>
                {role === "admin" && (
                    <MenuItem onClick={() => handleCloseMoreMenu("/settings")}>
                        <Text className="popup-Link">Settings</Text>
                    </MenuItem>
                )}
                <MenuItem onClick={() => handleCloseMoreMenu("/about")}>
                    <Text className="popup-Link">About Us</Text>
                </MenuItem>
                <MenuItem onClick={() => handleCloseMoreMenu("/privacy")}>
                    <Text className="popup-Link">Privacy Policy</Text>
                </MenuItem>
                <MenuItem onClick={() => handleCloseMoreMenu("/community-guidelines")}>
                    <Text className="popup-Link">Community Guidelines</Text>
                </MenuItem>
                <MenuItem onClick={() => handleCloseMoreMenu("/settings/email/notifications")}>
                    <Text className="popup-Link">Email Notification Settings</Text>
                </MenuItem>
                <MenuItem onClick={(e) => signOutBtn(e)}>
                    <Text className="popup-Link">Log Out</Text>
                </MenuItem>
            </Menu>
        </>
    );
}

export default MenuMore;
