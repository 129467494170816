import "./GroupDialogs.scss";

import React from "react";

import { TransitionProps } from "@mui/material/transitions";
import { Warning, Close, PersonRemoveAlt1TwoTone } from "@mui/icons-material";
import { Dialog, DialogActions, DialogContent, DialogTitle, Box, Typography, Slide } from "@mui/material";

import { PlayerProps } from "models/group/PlayerProps";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    player: PlayerProps;
    groupId: string;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    handleRemovePlayer: (player: PlayerProps) => Promise<void>;
}

export const RemovePlayerDialog = ({ open, onClose, player, groupId, handleRemovePlayer }: Props) => {
    async function handleSubmit() {
        await handleRemovePlayer(player);
        onClose(false);
    }
    return (
        <Dialog
            open={open}
            onClose={() => onClose(false)}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="remove-player-dialog"
            className="group-dialog">
            <DialogContent className="group-dialog-content">
                <Warning />
                <Box>
                    <DialogTitle className="group-title">Remove Player</DialogTitle>

                    <Typography className="dialog-text">
                        Are you sure you want to remove {player.name} from this group?
                    </Typography>
                    <Typography className="dialog-text">
                        This action will open a seat in your group, though the user will lose access to all group
                        information and activities.
                    </Typography>
                    <DialogActions className="dialog-actions">
                        <SecondaryApplyButton
                            icon={<Close />}
                            customClass="cancel-button"
                            onClick={() => onClose(false)}>
                            Cancel
                        </SecondaryApplyButton>
                        <ApplyButton icon={<PersonRemoveAlt1TwoTone />} onClick={handleSubmit}>
                            Remove Player
                        </ApplyButton>
                    </DialogActions>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
