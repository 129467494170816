import React, { useCallback, useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/AppContext";
import useSettings from "./useSettings";
import { Box, Container, TextField, Button } from "@mui/material";
import BackBtnLine from "components/Profile/components/ProfileHead/BackBtnLine/BackBtnLine";
import { Text, View } from "@aws-amplify/ui-react";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddIcon from "@mui/icons-material/Add";
import { api } from "services/api";
import { useSnackbarContext } from "context/SnackbarContext";
import { capitalizeFirstLetter } from "utils/functions";
import LinearProgress from "@mui/material/LinearProgress";
import { endpoints } from "services/endpoints";
import { useQueryClient } from "react-query";

function LinearIndeterminate() {
    return (
        <Box sx={{ width: "100%" }}>
            <LinearProgress />
        </Box>
    );
}

const Settings = () => {
    const [collapseGames, setCollapseGames] = useState(false);
    const [loadingChange, setLoadingChange] = useState(false);
    const [changedId, setChangedId] = useState(-1);
    const { gameInput, setGameInput, vttInput, setVttInput, badgeInput, setBadgeInput } = useSettings();
    const navigate = useNavigate();
    const { personalProfile } = useGlobalContext();
    const { filterFields = { games: [], vtt: [] } } = useContext(AsyncFilterFieldsContext);
    const { showSnackbar } = useSnackbarContext();
    const queryClient = useQueryClient();

    useEffect(() => {
        (async () => {
            if (personalProfile && personalProfile?.role !== "admin") {
                navigate("/");
            }
        })();
    }, [personalProfile, navigate]);

    const changeItem = async (item, type, enabled) => {
        if (loadingChange) return;
        try {
            setLoadingChange(true);
            setChangedId(item.id);
            const body = {
                ...item,
                enabled,
            };

            let url = "/games/update";
            /**
             * Switch just in case we will ad more endpoints
             */
            switch (type) {
                case "vtt":
                    url = endpoints.cmsVTTUpdate;
                    break;
                case "badge":
                    url = endpoints.cmsBadges;
                    body.img = `${item.name}.svg`;
                    break;
                default:
                    url = endpoints.cmsGamesUpdate;
                    break;
            }

            const response = await api.put(url, body);

            queryClient.invalidateQueries({ queryKey: ["badgesOptions"] });

            showSnackbar({
                title: `${capitalizeFirstLetter(type)} updated!`,
                description: response.data,
                severity: "success",
                duration: 4000,
            });
            setLoadingChange(false);
            setChangedId(-1);
        } catch (error) {
            setLoadingChange(false);
            setChangedId(-1);
            showSnackbar({
                title: `${capitalizeFirstLetter(type)} update failed!`,
                description: `The ${type} edit failed!`,
                severity: "error",
                duration: 4000,
            });
        }
    };
    const onGameTextChange = useCallback(
        (event, type) => {
            if (type === "game") {
                setGameInput(event.target.value);
            }
            if (type === "vtt") {
                setVttInput(event.target.value);
            }

            if (type === "badge") {
                setBadgeInput(event.target.value);
            }
        },
        [gameInput, vttInput],
    );

    const renderItem = (item, type) => {
        return (
            <Box key={item.name + item.id}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        m: 3,
                        backgroundColor: item.enabled ? "" : "#a61f38",
                    }}>
                    <Text>{item.name}</Text>
                    {item.enabled ? (
                        <DeleteIcon onClick={() => changeItem(item, type, false)} sx={{ cursor: "pointer" }} />
                    ) : (
                        <AddIcon onClick={() => changeItem(item, type, true)} sx={{ cursor: "pointer" }} />
                    )}
                </Box>
                {loadingChange && changedId === item.id && <LinearIndeterminate />}
            </Box>
        );
    };

    return (
        <View className="desktop-width fullscreen-mobile profile-root ">

            <View className="container-row">
                <Text as="h2">Add the Game Name to edit or add it to the Game list</Text>
                <Box sx={{ display: "flex" }}>
                    <TextField
                        sx={{ width: "80%", m: 1 }}
                        onChange={(event) => onGameTextChange(event, "game")}
                        value={gameInput}
                        id="Games"
                        label="Games"
                        variant="outlined"
                    />
                    <Button
                        sx={{ m: 1 }}
                        className="red-button"
                        variant="contained"
                        onClick={() => changeItem({ name: gameInput }, "game", true)}>
                        ADD GAME
                    </Button>
                </Box>
            </View>

            <Container>
                <Box>{!filterFields?.games && <CircularProgress color="inherit" />}</Box>
                <Box
                    onClick={() => setCollapseGames(!collapseGames)}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        m: 1,
                        cursor: "pointer",
                    }}>
                    <Text>Collapse Games</Text>
                    {collapseGames ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </Box>
                <Box sx={{ height: !collapseGames ? "100%" : 0, overflow: "hidden" }}>
                    {filterFields &&
                        filterFields?.games
                            ?.filter((item) => item?.name?.toLowerCase()?.includes(gameInput.toLowerCase()))
                            ?.map((item) => renderItem(item, "game"))}
                </Box>
            </Container>
            <View className="container-row">
                <Text as="h2">Add the Platform Name to edit or add it to the Platform list</Text>
                <Box sx={{ display: "flex" }}>
                    <TextField
                        sx={{ width: "80%", m: 1 }}
                        onChange={(event) => onGameTextChange(event, "vtt")}
                        value={vttInput}
                        id="vtt"
                        label="Gaming platform"
                        variant="outlined"
                    />
                    <Button
                        sx={{ m: 1 }}
                        className="red-button"
                        variant="contained"
                        onClick={() => changeItem({ name: vttInput }, "vtt", true)}>
                        ADD
                    </Button>
                </Box>
            </View>

            <Container>
                <Box>{!filterFields?.vtt && <CircularProgress color="inherit" />}</Box>

                <Box>{filterFields && filterFields?.vtt?.map((item) => renderItem(item, "vtt"))}</Box>
            </Container>

            <View className="container-row">
                <Text as="h2">Add the the badge you want to provide for the users</Text>
                <Box sx={{ display: "flex" }}>
                    <TextField
                        sx={{ width: "80%", m: 1 }}
                        onChange={(event) => onGameTextChange(event, "badge")}
                        value={badgeInput}
                        id="vtt"
                        label="Type the badge name"
                        variant="outlined"
                    />
                    <Button
                        sx={{ m: 1 }}
                        className="red-button"
                        variant="contained"
                        onClick={() => changeItem({ name: badgeInput }, "badge", true)}>
                        ADD BADGE
                    </Button>
                </Box>
            </View>

            <Container>
                <Box>{!filterFields?.vtt && <CircularProgress color="inherit" />}</Box>

                <Box>{filterFields && filterFields?.badgesOptions?.map((item) => renderItem(item, "badge"))}</Box>
            </Container>
        </View>
    );
};

export default Settings;
