import { isBoth, isOnlyGameMaster, isOnlyPlayer } from "./isGameMaster";

export function checkPlayerType(questionnaireTransitionDM) {

    let playerType = null;

    if (isOnlyGameMaster({ questionnaireTransitionDM })) {
        playerType = "GM";
    }

    else if (isOnlyPlayer({ questionnaireTransitionDM })) {
        playerType = "Player";
    }

    else if (isBoth({ questionnaireTransitionDM })) {
        playerType = "Player + GM";
    }
    return playerType;
}
