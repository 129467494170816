import { atom } from "jotai";

type SurveyAtomType = {
    groupCreated: boolean;
    groupId?: string;
};

export const surveyAtom = atom<SurveyAtomType>({
    groupCreated: false,
    groupId: "",
});
