import React from "react";
import { Button, Menu, MenuItem } from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import { Text } from "@aws-amplify/ui-react";

export const SortUserList = ({
    onSelect,
    open,
    anchorEl,
    onClose,
    onOpen,
    sortingOptions,
    filterActive,
    listOrder,
}) => {
    const renderSortStarred = () => {
        const shouldDisplayButton = window.location.pathname !== "/starred";
        if (shouldDisplayButton) {
            return (
                <Button
                    className={`user-list-header-btn active selected`}
                    variant="outlined"
                    startIcon={<ImportExportIcon />}
                    onClick={onOpen}>
                    {listOrder.toUpperCase()}
                </Button>
            );
        }
    };
    return (
        <>
            {renderSortStarred()}
            <Menu
                sx={{ boxShadow: "none", ".MuiOutlinedInput-notchedOutline": { border: 0 } }} //removes outline
                id="sort-menu"
                className="select-user-list"
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                MenuListProps={{
                    "aria-labelledby": "sort-button",
                }}>
                {sortingOptions.map((option, index) => {
                    return (
                        <MenuItem key={option} onClick={() => onSelect(index)} className="selec-user-list-item">
                            {option}
                        </MenuItem>
                    );
                })}
            </Menu>
        </>
    );
};
