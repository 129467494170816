import React from "react";
import { Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import "./index.css";

function MenuItem({ btnFunc = null, link, text, Logo, activeBtn }) {
    function handleClick(event) {
        if (!btnFunc) return;

        event.preventDefault();
        btnFunc();
    }

    return (
        <Link onClick={handleClick} to={link} className={`menu-item-link ${checkActiveButton(activeBtn, link)}`}>
            <Logo active={activeBtn === link ? "active" : ""} />
            <Text className="hamburger-menu-item">{text}</Text>
        </Link>
    );
}

function checkActiveButton(activeBtn, link) {
    if ((link === "/messages" && activeBtn.includes(link)) || activeBtn === link) {
        return "active";
    }

    return ""
}

export default MenuItem;
