const GAME_TYPE_WORDS = {
    player: ["Player only.", "Not right now."],
    gameMaster: ["Yes. Bring 'em on.", "GM only.", "DM only."],
    both: ["Either/Both."],
    professionalGameMaster: ["professionalGM"],
};

function isPlayerType(item, type) {
    if (!item) {
        console.warn("No item given to isPlayerType function");
        return;
    }

    const { questionnaireTransitionDM, playerQuestionnaireTransition } = item;

    if (!questionnaireTransitionDM) return;

    let questionnaire, playerQuestionnaire;

    if (Array.isArray(playerQuestionnaireTransition))
        playerQuestionnaire = playerQuestionnaireTransition?.some((x) => type.includes(x));
    else playerQuestionnaire = type.includes(playerQuestionnaireTransition);

    if (Array.isArray(questionnaireTransitionDM))
        questionnaire = questionnaireTransitionDM?.some((x) => type.includes(x));
    else questionnaire = type.includes(questionnaireTransitionDM);

    return questionnaire || playerQuestionnaire;
}



export const isOnlyGameMaster = (item) => (item.isGM ? item.isGM : isPlayerType(item, GAME_TYPE_WORDS.gameMaster));

export const isOnlyPlayer = (item) => isPlayerType(item, GAME_TYPE_WORDS.player);

export const isBoth = (item) => isPlayerType(item, GAME_TYPE_WORDS.both);

export const isProfessionalGameMaster = (item) => isPlayerType(item, GAME_TYPE_WORDS.professionalGameMaster);
