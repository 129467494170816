import { createContext, useState } from "react";
import SignInSection from "@pages/SignInSection";

export const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
    const [loginPopup, setLoginPopup] = useState(false);

    return (
        <LoginContext.Provider value={{ loginPopup, setLoginPopup }}>
            {loginPopup && <SignInSection setOverflow={setLoginPopup} overflow={true} />}
            {children}
        </LoginContext.Provider>
    );
};
