import { PrivateProfileModel } from "models/ProfileModels";

const checkAvailability = (state: PrivateProfileModel) => {
    return !!(
        (state?.availabilityTable && state?.availabilityTable.flat().includes("1")) ||
        (state?.noAvailability && state?.noAvailability[0])
    );
};

export default checkAvailability;
