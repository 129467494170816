import { checkPlayerType } from "./checkPlayerType";

export function setProfile(personalProfile, setState, navigate, validateText, redirect = false) {
    const sanitizeResponse = personalProfile;
    const keys = Object.keys(personalProfile);

    keys.forEach((item) => {
        if (sanitizeResponse[item] === "No Response" || !sanitizeResponse[item]) {
            sanitizeResponse[item] = validateText(sanitizeResponse[item]);
        }
    });

    // a number of elements on the profile display are expected and will throw an error if they don't exist so send the user to complete their profile

    if (!sanitizeResponse.availabilityTable) {
        sanitizeResponse.availabilityTable = [
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
            [0, 0, 0, 0, 0],
        ];
        /*navigate("/createprofile");*/
    }

    setState({
        profile: sanitizeResponse,
        flags: {
            upperSectionClass:
                checkPlayerType(sanitizeResponse.questionnaireTransitionDM) === "GM" ||
                checkPlayerType(sanitizeResponse.questionnaireTransitionDM) === "Player + GM"
                    ? "upper-section upper-section-dm"
                    : "upper-section",
        },
        playerDataCards: [
            {
                title: "Favorite things about TTRPGs",
                response: sanitizeResponse.thingsLikedTTRPG,
                key: "thingsLikedTTRPG",
            },
            {
                title: "Top things brought as player",
                response: sanitizeResponse.thingsBroughtAsPlayer,
                key: "thingsBroughtAsPlayer",
            },
            {
                title: "Years Playing",
                response: sanitizeResponse.yearsPlaying + " year(s)",
                key: "yearsPlaying",
            },
            {
                title: "How important are rules?",
                response: sanitizeResponse.ruleImportance,
                key: "ruleImportance",
            },
            {
                title: "Open to homebrew?",
                response: sanitizeResponse.homebrewRuleChanges,
                key: "homebrewRuleChanges",
            },
            {
                title: "Profanity Level preferred",
                response: sanitizeResponse.profanityLevel,
                key: "profanityLevel",
            },
            {
                title: "How important are safety tools?",
                response: sanitizeResponse.safetyTools,
                key: "safetyTools",
            },
            {
                title: "Would you play a streamed game?",
                response: sanitizeResponse.streamedTTRPG,
                key: "streamedTTRPG",
            },
            {
                title: "My Favorite Character",
                response: sanitizeResponse.favoriteCharacterDescription,
                key: "favoriteCharacterDescription",
            },
            {
                title: "What style of campaign do you prefer?",
                response: sanitizeResponse.preferredCampaignStyle,
                key: "preferredCampaignStyle",
            },
            {
                title: "How long do you like games?",
                response: sanitizeResponse.gameLengthPreference,
                key: "gameLengthPreference",
            },
            {
                title: "How picky when looking for games",
                response: sanitizeResponse.gamePickiness,
                key: "gamePickiness",
            },
            {
                title: "Ok joining existing game",
                response: sanitizeResponse.joiningExistingGames,
                key: "joiningExistingGames",
            },
            {
                title: "Do you want to share your gender?",
                response: sanitizeResponse.gender,
                key: "gender",
            },
        ],
        dmDataCards: [
            {
                title: "Number of games run",
                response: sanitizeResponse.numberOfGamesRun,
                key: "numberOfGamesRun",
            },
            {
                title: "Opportunities created",
                response: sanitizeResponse.opportunitiesCreated,
                key: "opportunitiesCreated",
            },
            {
                title: "Campaign style",
                response: sanitizeResponse.campaignStyle,
                key: "campaignStyle",
            },
            {
                title: "Years running games",
                response: sanitizeResponse.numberOfYearsRunningGame,
                key: "numberOfYearsRunningGame",
            },
            {
                title: "Strengths as a Game Master",
                response: sanitizeResponse.strengths,
                key: "strengths",
            },
            {
                title: "As a Game Master how important are rules?",
                response: sanitizeResponse.ruleImportanceDM,
                key: "ruleImportance",
            },
            {
                title: "Do you homebrew as a Game Master?",
                response: sanitizeResponse.homebrewDM,
                key: "homebrewDM",
            },
            {
                title: "Do you use safety tools?",
                response: sanitizeResponse.safetyToolsDM,
                key: "safetyTools",
            },
            {
                title: "DO you stream games?",
                response: sanitizeResponse.streamDM,
                key: "streamGames",
            },
            {
                title: "Would you run a new TTRPG?",
                response: sanitizeResponse.newGameDM,
                key: "newGameDM",
            },
            {
                title: "What tools do you use?",
                response: sanitizeResponse.toolsUsed,
                key: "toolsUsed",
            },
            {
                title: "Which VTT do you use?",
                response: sanitizeResponse.virtualTableTops,
                key: "virtualTableTops",
            },
            {
                title: "Do characters die in your games?",
                response: sanitizeResponse.characterKillingDM,
                key: "characterKillingDM",
            },
        ],
    });
}
