export const timezones = {
    "-12:00": "(GMT -12:00) Eniwetok, Kwajalein ",
    "-11:00": "(GMT -11:00) Midway Island, Samoa",
    "-10:00": "(GMT -10:00) Hawaii",
    "-09:50": "(GMT -9:30) Taiohae",
    "-09:00": "(GMT -9:00) Alaska",
    "-08:00": "(GMT -8:00) Pacific Time (US & Canada)",
    "-07:00": "(GMT -7:00) Mountain Time (US & Canada)", 
    "-06:00": "(GMT -6:00) Central Time (US & Canada), Mexico City",
    "-05:00": "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima",
    "-04:50": "(GMT -4:30) Caracas",
    "-04:00": "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz",
    "-03:50": "(GMT -3:30) Newfoundland",
    "-03:00": "(GMT -3:00) Brazil, Buenos Aires, Georgetown",
    "-02:00": "(GMT -2:00) Mid-Atlantic",
    "-01:00": "(GMT -1:00) Azores, Cape Verde Islands",
    "+00:00": "(GMT) Western Europe Time, London, Lisbon, Casablanca",
    "+01:00": "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris",
    "+02:00": "(GMT +2:00) Kaliningrad, South Africa",
    "+03:00": "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg",
    "+03:50": "(GMT +3:30) Tehran",
    "+04:00": "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi",
    "+04:50": "(GMT +4:30) Kabul",
    "+05:00": "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent",
    "+05:50": "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi",
    "+05:75": "(GMT +5:45) Kathmandu, Pokhara",
    "+06:00": "(GMT +6:00) Almaty, Dhaka, Colombo",
    "+06:50": "(GMT +6:30) Yangon, Mandalay",
    "+07:00": "(GMT +7:00) Bangkok, Hanoi, Jakarta",
    "+08:00": "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong",
    "+08:75": "(GMT +8:45) Eucla",
    "+09:00": "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk",
    "+09:50": "(GMT +9:30) Adelaide, Darwin",
    "+10:00": "(GMT +10:00) Eastern Australia, Guam, Vladivostok",
    "+10:50": "(GMT +10:30) Lord Howe Island",
    "+11:00": "(GMT +11:00) Magadan, Solomon Islands, New Caledonia",
    "+11:50": "(GMT +11:30) Norfolk Island",
    "+12:00": "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka",
    "+12:75": "(GMT +12:45) Chatham Islands",
    "+13:00": "(GMT +13:00) Apia, Nukualofa",
    "+14:00": "(GMT +14:00) Line Islands, Tokelau",
};

 