import React from "react";

//import our UI elements
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { Link } from "@mui/material";
import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import { useCompleteProfileStyles } from "hooks/useCompleteProfileStyles";
import { useNavigate } from "react-router-dom";

export const Welcome = ({ pushCounter }) => {
    const classes = useCompleteProfileStyles();
    let navigate = useNavigate();

    const answerLater = () => {
        navigate({ pathname: "/" });
    };

    return (
        <>
            <CompleteProfileContainer
                rootContainerClass="question-welcome app-inner-gutters"
                hasDarkClass={false}
                style={{ backgroundImage: "url('/darkHill.png')" }}>
                <View className="top-text"></View>
                <View className="question-container">
                    <Text as="h1">Hey, it’s nice to meet you!</Text>
                    <Text>Let’s dig a little deeper so we can find the perfect matches for you. Are you ready?</Text>
                    <View className="start-button">
                        <Link className="arrow-forward" data-testid="next-button" onClick={pushCounter}>
                            <ArrowCircleRightRoundedIcon sx={{ fontSize: 40 }} />
                        </Link>
                    </View>
                </View>
                <View className={`skip-survey ${classes.backButton} on-bottom`}>
                    <Link sx={{ cursor: "pointer" }} onClick={answerLater}>
                        Answer the survey later
                    </Link>
                </View>
            </CompleteProfileContainer>
        </>
    );
};
