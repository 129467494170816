import React, { lazy, Suspense, useEffect, useRef } from "react";
import "./style.css";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import AddAPhotoTwoToneIcon from "@mui/icons-material/AddAPhotoTwoTone";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CreateIcon from "@mui/icons-material/Create";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { CircularProgress, Slider } from "@mui/material";
import Button from "components/Button";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import useDropZoneEditor from "hooks/useDropZoneEditor";
import { useBreakpoint } from "hooks/useBreakpoint";
import Backdrop from "@mui/material/Backdrop";

const LazyGenericDialogRoot = lazy(() => import("components/GenericDialog/GenericDialog.Root"));
const LazyGenericDialogTop = lazy(() => import("components/GenericDialog/GenericDialog.Top"));
const LazyGenericDialogContent = lazy(() => import("components/GenericDialog/GenericDialog.Content"));
const LazyGenericDialogBottom = lazy(() => import("components/GenericDialog/GenericDialog.Bottom"));
const DropZoneEditorMobileZoom = lazy(() => import("components/DropZoneEditor/DropZoneEditorMobileZoom"));

export const DropZoneEditor = ({
    defaultImg = "",
    setTemporaryAvatar,
    changeEditState,
    profileId,
    removeTemporarilyAvatar,
}) => {
    /*
    ---- Refs
    */
    const AvatarEditorRef = useRef();

    /*
    ---- Hooks
    */
    const { isMobile, isTablet } = useBreakpoint();

    const { getRootProps, getInputProps, open, isDragActive, fileRejections } = useDropzone({
        accept: {
            "image/*": [".jpeg", ".png", ".jpg"],
        },
        validator: (file) => {
            return file.size > 10000000
                ? {
                      code: "file-exceeds",
                      message: `File exceeds the maximum size (${10}MB)`,
                  }
                : null;
        },
        maxSize: 10000000,
        maxFiles: 1,
        onDrop: (files) => {
            setSelectedImage(files[0]);
        },
    });

    const {
        selectedImage,
        setSelectedImage,
        showDialog,
        setShowDialog,
        hasExistingAvatar,
        isEditing,
        setIsEditing,
        save,
        remove,
        close,
        scale,
        setScale,
        removeAvatarDialog,
        invalidUploadDialog,
        invalidImageDialog,
        successSaveDialog,
        successRemoveDialog,
        debouncedZoom,
        isWaiting,
        tempAvatar,
    } = useDropZoneEditor(defaultImg, fileRejections, changeEditState, profileId, removeTemporarilyAvatar);

    /*
    ---- useEffect
    */

    useEffect(() => {
        // After successfully avatar upload AND before the user saves the profile changes,
        // shows as a temporary image on users profile page (ProfileAvatarBox.jsx).
        if (tempAvatar) {
            setTemporaryAvatar(tempAvatar);
        }
    }, [tempAvatar]);

    return (
        <div
            className={`drop-zone-editor drop-zone-editor---${isDragActive ? "drag-active" : "default"} ${
                !defaultImg && "drop-zone-editor---new"
            }`}>

            {/*Shows backdrop if it's waiting (saving)*/}
            <Backdrop sx={{ color: "#fff", zIndex: "9999" }} open={isWaiting}>
                <CircularProgress color="inherit" />
            </Backdrop>

            {/*Trigger Drop Zone Editor Main Dialog*/}
            <div className={`drop-zone-editor_button`}>
                {!showDialog && (
                    <button
                        className={`drop-zone-editor_button---${!!defaultImg ? "edit" : "new"}`}
                        onClick={() => {
                            setIsEditing(hasExistingAvatar);
                            setShowDialog(true);
                        }}>
                        {!!defaultImg ? <CreateIcon /> : <AddAPhotoTwoToneIcon />}
                    </button>
                )}
            </div>
            {/*Drop Zone Editor Main Dialog*/}
            {showDialog && (
                <Suspense
                    fallback={
                        <LoadingBtn
                            variant="contained"
                            disabled={false}
                            loading={true}
                            label={""}
                            handleOnClick={() => null}
                        />
                    }>
                    {/*Dialog*/}
                    <LazyGenericDialogRoot name={"dialog_drop-zone-editor"} open={showDialog} handleClose={close}>
                        {/*Dialog Header*/}
                        <LazyGenericDialogTop>
                            {/*Dialog Header Title*/}
                            {`${hasExistingAvatar ? "Edit" : "Upload"} Avatar`}
                            {/*Render close button if backdrop is not opened*/}
                            {!isWaiting && (
                                <button className={"drop-zone-editor_close-btn"} onClick={close}>
                                    <img src="/closeIcon.png" alt="Close dialog" />
                                </button>
                            )}
                        </LazyGenericDialogTop>
                        {/*Dialog Content*/}
                        <LazyGenericDialogContent>
                            <>
                                {isEditing && !fileRejections.length ? (
                                    // Shows the editing image view (if there's no rejected file)
                                    <div
                                        className={`drop-zone-editor_edit ${
                                            !selectedImage ? "image-only" : "edit-mode"
                                        }`}>
                                        {!selectedImage ? (
                                            // Show default image as static html if user doesn't select any image yet
                                            <div
                                                className={"drop-zone-editor_edit-image"}
                                                style={{ backgroundImage: `url(${defaultImg})` }}
                                            />
                                        ) : (
                                            // Otherwise, show the Avatar Editor
                                            <div className={"drop-zone-editor-avatar"}>
                                                {(isMobile || isTablet) && (
                                                    // Shows custom scale feature on mobile
                                                    <Suspense
                                                        fallback={
                                                            <LoadingBtn
                                                                variant="contained"
                                                                disabled={false}
                                                                loading={true}
                                                                label={""}
                                                                handleOnClick={() => null}
                                                            />
                                                        }>
                                                        <DropZoneEditorMobileZoom
                                                            zoomHandler={(zoom) => debouncedZoom(zoom)}
                                                        />
                                                    </Suspense>
                                                )}
                                                {/*React Avatar Editor Component (Crop and Scale)*/}
                                                <AvatarEditor
                                                    width={250}
                                                    height={250}
                                                    image={selectedImage}
                                                    scale={scale}
                                                    borderRadius={(isMobile || isTablet) ? 0 : 250}
                                                    ref={AvatarEditorRef}
                                                    color={[23, 23, 23, 1]}
                                                    border={1}
                                                    disableBoundaryChecks={false}
                                                    disableHiDPIScaling={true}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    // Otherwise, shows the upload file view (Drag n drop upload)
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div
                                            className={`drop-zone-editor_drop-area ${
                                                isDragActive ? "drop-zone-editor_drop-area---active" : ""
                                            }`}>
                                            {(!isMobile && !isTablet) ? (
                                                <div>
                                                    <UploadFileIcon />
                                                    <p>
                                                        <span>Click to upload</span> or drag and drop{" "}
                                                    </p>
                                                    <p>PNG or JPG (max. 10MB)</p>
                                                </div>
                                            ) : (
                                                <p>
                                                    Choose an image from your device to upload as an avatar.
                                                    <br />
                                                    <br />
                                                    Image requirements: PNG or JPG, 10MB maximum size.
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        </LazyGenericDialogContent>
                        {/*Dialog Bottom*/}
                        <LazyGenericDialogBottom>
                            {isWaiting && (
                                // Shows a placeholder button if backdrop is visible
                                <Button disabled sx={{ width: "100% !important" }}>
                                    Please wait...
                                </Button>
                            )}
                            {!isWaiting && !isEditing && (
                                // Shows buttons if it's not editing and backdrop is hidden
                                <>
                                    <Button sx={{ width: "100% !important" }} onClick={open}>
                                        Choose Image
                                    </Button>
                                    {(isMobile || isTablet) && (
                                        <Button
                                            backgroundColor="#171717"
                                            labelColor={"#DE4A65"}
                                            sx={{ width: "100% !important", marginTop: "8px !important" }}
                                            onClick={close}>
                                            Cancel
                                        </Button>
                                    )}
                                </>
                            )}
                            {!isWaiting && isEditing && selectedImage && (
                                // Shows drag n drop instructions and buttons if:
                                // already selected an image, is editing and backdrop is hidden
                                <div className={"drop-zone-editor_actions"}>
                                    <p>
                                        <small>
                                            {(isMobile || isTablet) ? (
                                                <>Zoom & Crop: Use corners to resize and drag image to frame it</>
                                            ) : (
                                                <>
                                                    Click and drag image to frame it
                                                    <br />
                                                    Drag slider left or right to resize image
                                                </>
                                            )}
                                        </small>
                                    </p>
                                    {(!isMobile && !isTablet) && (
                                        <div className={"drop-zone-editor_slider"}>
                                            <ZoomOutIcon />
                                            <Slider
                                                size="small"
                                                defaultValue={1}
                                                aria-label="Small"
                                                min={0.2}
                                                step={0.1}
                                                max={10}
                                                color="primary"
                                                valueLabelDisplay="auto"
                                                onChange={(e) => {
                                                    setScale(e.target.value || 1);
                                                }}
                                            />
                                            <ZoomInIcon />
                                        </div>
                                    )}

                                    <Button
                                        onClick={() => {
                                            save(AvatarEditorRef);
                                        }}>
                                        Save Changes
                                    </Button>
                                    <Button
                                        backgroundColor="#171717"
                                        labelColor={"#DE4A65"}
                                        onClick={() => {
                                            setIsEditing(false);
                                        }}>
                                        Choose Another Image
                                    </Button>
                                </div>
                            )}
                            {!isWaiting && !selectedImage && isEditing && (
                                // Shows buttons if it's editing, with no image selected and backdrop is hidden
                                <div
                                    className={`drop-zone-editor_actions-btn-row ${
                                        (!isMobile && !isTablet) ? "drop-zone-editor_actions-btn-row--full" : ""
                                    }`}>
                                    <Button
                                        backgroundColor="#171717"
                                        labelColor={"#fff"}
                                        onClick={remove}>
                                        Remove
                                    </Button>
                                    <Button
                                        backgroundColor="#171717"
                                        labelColor={"#fff"}
                                        onClick={() => {
                                            setIsEditing(false);
                                        }}>
                                        Change
                                    </Button>
                                </div>
                            )}
                        </LazyGenericDialogBottom>
                    </LazyGenericDialogRoot>
                </Suspense>
            )}
            {/*Memoized Dialogs*/}
            {removeAvatarDialog}
            {invalidUploadDialog}
            {successSaveDialog}
            {successRemoveDialog}
            {invalidImageDialog}
        </div>
    );
};
