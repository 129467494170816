import { useEffect } from 'react';
import {  useNavigate } from 'react-router-dom';

export default function CompleteProfileRedirect({ testNavigation }) {

    const navigate = useNavigate()

    useEffect(() => {
        testNavigation ? testNavigation('/createprofile/0') : navigate('/createprofile/0')
    }, []);

    return null;
}
