import "./ApplyButton.scss";

import React from "react";
import { LoadingButton } from "@mui/lab";
import { Tooltip, Avatar, Button, CircularProgress, SxProps, Theme } from "@mui/material";

export interface ApplyButtonProps {
    id?: string;
    icon?: React.ReactNode;
    loading?: boolean;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    disabled?: boolean;
    children?: React.ReactNode;
    spanClass?: string;
    fullWidth?: boolean;
    useLegacy?: boolean;
    customClass?: string;
    tooltipTitle?: string;
    sx?: SxProps<Theme>;
}

const ApplyButton: React.FC<ApplyButtonProps> = ({
    sx,
    id,
    icon,
    onClick,
    disabled,
    children,
    spanClass,
    customClass,
    tooltipTitle,
    loading = false,
    fullWidth = false,
    useLegacy = false,
}) => {
    const [buttonLoading, setButtonLoading] = React.useState(false);

    /**
     * Handles the button click event, invoking the onClick callback function
     * and managing the button loading state for async functions.
     *
     * @param {React.MouseEvent<HTMLButtonElement>} event - The button click event
     * @return {Promise<void>} A promise that resolves when the onClick callback function is finished
     */
    const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = async (
        event: React.MouseEvent<HTMLButtonElement>,
    ) => {
        if (!onClick || typeof onClick !== "function") return;

        // Considering it's an async function, it should have loaders
        setButtonLoading(true);
        try {
            await onClick(event);
        } catch (error) {
            // Propagate error to be handled by the parent
            throw error;
        } finally {
            // Reset loader state inconditionally
            setButtonLoading(false);
        }
    };

    const isDisabled: boolean = disabled || loading || buttonLoading;
    const isLoading = loading === true || buttonLoading === true;

    const buttonClasses: string[] = ["apply-button", customClass || "", isDisabled ? "apply-button-disabled" : ""];
    const spanClasses: string[] = ["apply-button-container", customClass || "", spanClass || ""];

    if (useLegacy)
        return (
            <Tooltip title={tooltipTitle || ""}>
                <span className={spanClasses.join(" ")}>
                    <LoadingButton
                        id={id}
                        loading={isLoading}
                        onClick={handleOnClick}
                        className={buttonClasses.join(" ")}
                        fullWidth={fullWidth}
                        disabled={isDisabled}>
                        {!isLoading && icon && icon}
                        {!isLoading && children}
                    </LoadingButton>
                </span>
            </Tooltip>
        );

    return (
        <Tooltip title={tooltipTitle || ""}>
            <Button
                sx={sx}
                id={id}
                onClick={handleOnClick}
                disabled={isDisabled}
                className={buttonClasses.join(" ")}
                fullWidth>
                {!isLoading && icon && icon}
                {isLoading ? (
                    <CircularProgress size={20} sx={{ width: "100%", height: "100%", color: "grey" }} />
                ) : (
                    children
                )}
            </Button>
        </Tooltip>
    );
};

export default ApplyButton;
