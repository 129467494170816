import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Image } from "@aws-amplify/ui-react";
import Styles from "./Styles.module.css";

export default function MatchScoreLoader() {
    return (
        <Box>
            <Backdrop
                data-testid="loader"
                open={true}
                className={Styles["match-score"]}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Box className={Styles["match-score-header"]}>
                    <Image className={Styles["match-score-logo-mobile"]} src="/main_logo.png" />
                </Box>
                <Image src={"/sphinx_animated.svg"} className={Styles["match-score-sphinx"]} />
                <Box className={Styles["match-score-message-container"]}>
                    <Typography className={Styles["match-score-title"]} variant="h3">
                        Hold on a moment
                    </Typography>
                    <Typography className={Styles["match-score-message"]} variant="h5">
                        We're finding your best companions
                    </Typography>
                    <Image className={Styles["match-score-logo"]} src="/main_logo.png" />
                </Box>
            </Backdrop>
        </Box>
    );
}
