import { useMemo, useState } from "react";

const useSettings = () => {
    const [gameInput, setGameInput] = useState("");
    const [vttInput, setVttInput] = useState("");
    const [badgeInput, setBadgeInput] = useState("");
    const value = useMemo(() => ({ gameInput, setGameInput, vttInput, setVttInput, badgeInput, setBadgeInput }), [gameInput, vttInput, badgeInput, setBadgeInput]);
    return value;
};
export default useSettings;
