import React from "react";
import { Box, Slider, Tooltip } from "@mui/material/";
import Text from "@mui/material/Typography";
import View from "@mui/material/Box";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import { InfoTwoTone } from "@mui/icons-material";
import { ComponentSwitch } from "utils/functions";
import { PopupConfirmation } from "components/PopupConfirmation";

function SliderQuestions({ questions, isMobile, setStyleOfPlayModal }) {
    function QuestionTitle({ title, description, setStyleOfPlayModal }) {
        return (
            <Text className="slider-label">
                {title}
                {ComponentSwitch(
                    // Case is mobile
                    isMobile,
                    <InfoTwoTone
                        onClick={() => {
                            setStyleOfPlayModal((prev) => ({
                                ...prev,
                                open: true,
                                title: title,
                                description: description,
                            }));
                        }}
                    />,
                    // Default behavior
                    <Tooltip placement="top-start" title={description}>
                        <InfoTwoTone />
                    </Tooltip>,
                )}
            </Text>
        );
    }
    function QuestionSlider({ value, title }) {
        return (
            <Slider
                key={Number(value)}
                className="slider-slider"
                defaultValue={Number(value)}
                disabled={true}
                name={title}
                min={0}
                max={4}
            />
        );
    }

    return questions
        .filter((question) => question.value) // Check if question has a value
        .map((question, index) => {
            
            return (
                <View key={question.title + index} className="slider-row">
                    <QuestionTitle {...question} setStyleOfPlayModal={setStyleOfPlayModal} />
                    <QuestionSlider {...question} />
                </View>
            );
        });
}

function TextQuestions({ body }) {
    return (
        <ul>
            {body.map((data) => (
                <li key={data}>{data}</li>
            ))}
        </ul>
    );
}

const ThingsLikedTTRPG = ({ thingsLikedTTRPG: body, isMobile }) => {
    const [styleOfPlayModal, setStyleOfPlayModal] = React.useState({ open: false, title: "", description: "" });

    if (!body) return <></>;

    const [roleplaying, combat, exploring, optimizing_mechanics, social_intrigue, puzzles, player_driven_story] = body;

    const questions = [
        {
            title: "Roleplaying",
            value: roleplaying,
            description:
                "The roleplaying style is about stepping into another world, embracing a character's identity, and collaboratively creating a narrative that is shaped by the choices and actions of the participants. It's a dynamic and engaging form of storytelling that allows for a high degree of personal and group creativity.",
        },
        {
            title: "Combat",
            value: combat,
            description:
                "The combat style involves turn-based actions, dice rolls to determine success, and resource management. Characters have various abilities and actions, and combat may be grid-based or in the mind's theater. Environmental factors and teamwork play important roles. The GM's narration and storytelling contribute to the immersive experience, and the ultimate goal is to advance the campaign's narrative through strategic and role-playing interactions.",
        },
        {
            title: "Exploring",
            value: exploring,
            description:
                " Exploring style involves characters investigating the game world. It can be both structured or open-ended. Players may use skills and abilities to uncover secrets, solve puzzles, etc. The GM's descriptions and storytelling are key to creating an immersive exploration experience. The goal is to reveal the game world's depth, uncover plot details, and advance the narrative. ",
        },
        {
            title: "Optimizing Mechanics",
            value: optimizing_mechanics,
            description:
                "The optimizing/mechanics style focuses on maximizing character efficiency and effectiveness. Players delve into rules and character build details, seeking the best combinations of abilities and equipment. They often aim to min-max character stats for combat and other challenges. This style emphasizes game mechanics over narrative immersion, and success is measured by character optimization and overcoming in-game challenges.",
        },
        {
            title: "Social Intrigue",
            value: social_intrigue,
            description:
                "The social intrigue style centers on character interactions, diplomacy, and politics. Players use their characters' social skills, wits, and alliances to navigate intricate plots, negotiate, and uncover secrets. The game master's storytelling and role-playing are crucial for creating a rich and immersive social landscape. Success in this style is often defined by achieving personal or factional goals, manipulating alliances, and unraveling complex narratives.",
        },
        {
            title: "Puzzles",
            value: puzzles,
            description:
                "The puzzle style revolves around solving puzzles and riddles within the game world. Players use problem-solving skills and creativity to overcome challenges. The game master designs puzzles that require logic, lateral thinking, and clever solutions. Success is measured by unraveling mysteries, progressing the story, and achieving in-game goals by solving puzzles.",
        },
        {
            title: "Player Driven Story",
            value: player_driven_story,
            description:
                "Player-driven story style puts the narrative in the hands of the players. They actively shape the plot through their character's choices and actions. The game master provides a flexible world and adapts to player decisions. Success is defined by the depth of character development and the richness of the emergent story, where the players' choices have a profound impact on the campaign's direction.",
        },
    ];

    return (
        <ShouldRender>
            <>
                <PopupConfirmation
                    open={styleOfPlayModal.open}
                    message={styleOfPlayModal.description}
                    setOpen={(bool) => setStyleOfPlayModal({ ...styleOfPlayModal, open: bool })}
                    btnReject="Close"
                    disableBtnAccept
                />
                <View data-testid="game-preferences-slider" className="profile-response">
                    <Text className="game-preferences-subtitle">Style of play</Text>
                    <Box className="game-preferences-slider-container">
                        {typeof roleplaying == "string" ? (
                            <TextQuestions body={body} />
                        ) : (
                            <SliderQuestions
                                questions={questions}
                                isMobile={isMobile}
                                setStyleOfPlayModal={setStyleOfPlayModal}
                            />
                        )}
                    </Box>
                </View>
            </>
        </ShouldRender>
    );
};

export default ThingsLikedTTRPG;
