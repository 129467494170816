import { contentfulEnvs, matchGameName } from "utils/functions";
var contentfulApi = require("contentful");

const contentful = contentfulApi.createClient(contentfulEnvs?.());

export const getDataByContentType = async (contentType, page = 0) => {
    // 1000 is the max limit for contentful
    const limitEntries = 1000;
    var all_entries = [];
    var entries = await contentful.getEntries({
        content_type: contentType,
        limit: limitEntries,
        skip: page * limitEntries,
    });
    all_entries = all_entries.concat(entries.items.map((item) => item.fields));
    if (entries.total > limitEntries && page < Math.round(entries.total / limitEntries) - 1) {
        const moreData = await getDataByContentType(contentType, page + 1);

        all_entries = all_entries.concat(moreData);
    }
    // localeCompare method provides a correct alphabetical sort order that takes into account the case of the letters.
    return all_entries.sort((a, b) => a.name.localeCompare(b.name));
};

export const contentfullGetByName = async (contentType, gameName) => {
    const res = await contentful.getEntries({
        "fields.name[match]": gameName,
        content_type: contentType,
    });

    return matchGameName(gameName, res.items)?.[0];
};

export const getGameByUrl = async (url) => {
    const res = await contentful.getEntries({
        "fields.pageUrlPath": url,
        content_type: "game",
    });

    return res?.items?.[0]?.fields;
};
