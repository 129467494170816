import { checkLoggedIn, paramsToObject } from "@utils/functions";
import { Auth, Hub, Logger } from "aws-amplify";
import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import "../../pages/Home/UserList.css";
import { profileProvider } from "services/profileProvider";
import { useGlobalContext } from "context/AppContext";
import { checkCompletedProfile } from "utils/checkProfile";
import { useAuth } from "hooks/useAuth";
import { AuthScreens } from "components/AuthScreens";
import { FilterContext } from "context/FilterContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";

const SignInSection = ({ overflow, setOverflow }) => {
    let navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { updatePersonalProfile, updateUserCompletedProfile, updatePrivateProfileId, updateIsLoggedIn } =
        useGlobalContext();
    const { setFilterSignIn } = useContext(FilterContext);
    const { setUsers } = usePaginatedContext();
    const { setMessage } = useAuth();

    const getPathname = () => {
        switch (location.pathname) {
            case "/signup":
                return "signUp";
            case "/password-recovery":
                return "password-recovery";
            case "/signup-confirmation":
                return "confirm";
            default:
                return "login";
        }
    };

    const initialState = getPathname();

    const click = (e) => {
        if (location.pathname === "/login" && e.target.innerHTML === "Create Account") {
            navigate({ pathname: "/signup", search: `?${searchParams}` });
        } else if (location.pathname === "/signup" && e.target.innerHTML === "Sign In") {
            navigate({ pathname: "/login", search: `?${searchParams}` });
        }
    };

    const saveBadgeParam = () => {
        const badge = searchParams.get("badge");

        if (badge) {
            sessionStorage.setItem("redeem-badge", badge);
        }
    };

    useEffect(() => {
        window.addEventListener("click", click);

        return () => {
            window.removeEventListener("click", click);
        };
    });

    const logger = new Logger("Logger", "INFO");
    const listener = async (data) => {
        switch (data.payload.event) {
            case "signIn":
                logger.info("user signed in");
                const paramsList = Object.keys(paramsToObject(searchParams.entries()));
                const navigationState = {
                    pathname: "/",
                };
                if (paramsList.length > 0) {
                    navigationState.search = `?${searchParams}`;
                }
                const isLoggedIn = await checkLoggedIn();
                if (!isLoggedIn) {
                    navigationState.pathname = "/login";
                }
                const personalProfileData = await profileProvider.getPersonalProfile();
                const user = await Auth.currentUserInfo();
                updateIsLoggedIn(isLoggedIn);
                const profileCompletenessCheck = checkCompletedProfile(personalProfileData);
                updateUserCompletedProfile(profileCompletenessCheck);
                updatePrivateProfileId(personalProfileData?.id);
                updatePersonalProfile({ ...personalProfileData, role: user?.attributes["custom:role"] });

                const redirect = sessionStorage.getItem("redirect");
                setUsers([]);

                if (redirect) {
                    navigationState.pathname = redirect;
                    if (!redirect.includes("#")) {
                        sessionStorage.removeItem("redirect");
                    }
                    navigate(redirect);
                    setOverflow?.(false);
                    return;
                }
                if (!profileCompletenessCheck) {
                    setFilterSignIn(paramsToObject(searchParams.entries()));
                    navigationState.pathname = "/createprofile";
                } else if (searchParams.get("badge")) {
                    navigationState.pathname = "/profile";
                }

                if (!overflow) navigate(navigationState);
                setOverflow(false);
                break;
            case "signUp":
                logger.info("user signed up");
                // creates a new user in the DynamoDB table
                //NOTE: this NUKE any existing content which is why we do it on user creation
                let email = data.payload.data.user.username;
                profileProvider.createProfile(email).catch(function (error) {
                    console.log("ERROR CREATING USER", error);
                });
                // we do an emaiil verification, so no need redirect
                // the Authenticator component below is going to be transformed into the code send/receive
                break;
            case "signOut":
                logger.info("user signed out");
                break;
            case "signIn_failure":
                logger.info("user sign in failed");
                break;
            case "configured":
                logger.info("the Auth module is configured");
                break;
            case "signUp_failure":
                logger.info("Sign Up failed.");
                break;
            case "confirmSignUp":
                logger.info("User confirmed");
                break;
            case "autoSignIn":
                logger.info("autoSignIn after confirmation");
                break;
            case "autoSignIn_failure":
                logger.info("autoSignIn failure, please log in again");
                setMessage("Account verified. Please, log in.");
                navigate("/login");
                break;
            case "forgotPassword":
                logger.info("User initiated forgot password flow");
                break;
            case "forgotPasswordSubmit":
                logger.info("forgotPasswordSubmit successful");
                navigate("/login");
                break;
            case "forgotPasswordSubmit_failure":
                logger.info("forgotPasswordSubmit successful");
                break;
            default:
                logger.error("Something went wrong, look at data object", data);
        }
    };

    useEffect(() => {
        saveBadgeParam();

        Hub.listen("auth", listener);

        return () => Hub.remove("auth", listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <AuthScreens overflow={overflow} setOverflow={setOverflow} screen={initialState} setMessage={setMessage} />;
};

export default SignInSection;
