import React, { useContext, useMemo } from "react";
import { RightCard, RightCardProps } from "../RightCard";
import { locationFriendlyAddr } from "utils/functions";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useLocation } from "react-router-dom";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { FilterContext } from "context/FilterContext";

export const LocationCard = () => {
    const location = useLocation();

    const { totalResults, isFetchingPage, isChangingPage }: {
        totalResults: number;
        isFetchingPage: boolean;
        isChangingPage: boolean;
    } = usePaginatedContext() as any;

    const { isFiltering } = useContext<{ isFiltering: boolean }>(FilterContext as any);
    const { loading: loadingData, isSuccess } = useContext(AsyncFilterFieldsContext);

    const loadingLocationPage = useMemo(() => {
        return isFiltering || loadingData || !isSuccess || (isFetchingPage && !isChangingPage);
    }, [isChangingPage, isFetchingPage, isFiltering, isSuccess, loadingData]);

    const locationCardInfo: RightCardProps = useMemo(() => {
        const address = location.pathname.replace("/location", "");
        const locationText = locationFriendlyAddr(address);

        return {
            loading: loadingLocationPage,
            banner: "/banner-dice.png",
            title: `TTRPG Players in ${locationText}`,
            subtitle: "Find players near you!",
            description: `RPGMatch has ${totalResults} players in ${locationText} looking to play with other folks. Games people are looking to play include Dungeons & Dragons, Pathfinder, Call of Cthulhu, ALIEN: The Roleplaying Game, and many more.`,
        };
    }, [loadingLocationPage, location.pathname, totalResults]);

    return <RightCard {...locationCardInfo} />;
};
