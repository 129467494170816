import React, { useEffect, useState } from "react";
import { PrivateProfileModel } from "models/ProfileModels";
import { Chip } from "@mui/material";
import View from "@mui/material/Box";
import style from "./ProfileSocialMedias.module.css";
import { profileSocialMedia } from "utils/constants";
import { PopupAddFollowMe } from "components/Profile/components/ProfileBody/FollowMe/PopupAddFollowMe.tsx";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { savedUsernamesInterface } from "models/popupAddFollowMe/savedUsernames";
import { popupInfoInterface } from "models/popupAddFollowMe/popupInfo";
import { hasErrorInterface } from "models/popupAddFollowMe/hasError";
import { saveSocialMediaRequest } from "models/popupAddFollowMe/saveSocialMediaRequest";

interface ProfileSocialMediasInterface {
    state: PrivateProfileModel;
    handleEditProfile: (request: saveSocialMediaRequest) => Promise<void>;
    pushCounter: () => void;
}

const savedUsernamesInitialState = {
    bluesky: "",
    instagram: "",
    mastodon: "",
    startplaying: "",
    threads: "",
    twitter: "",
};

const popupInfoInitialState = { icon: null, key: "", message: "", title: "", url: "", warning: "" };

export const ProfileSocialMedias = ({ handleEditProfile }: ProfileSocialMediasInterface) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [popupInfo, setPopupInfo] = useState<popupInfoInterface>(popupInfoInitialState);
    const [savedUsernames, setSavedUsernames] = useState<savedUsernamesInterface>(savedUsernamesInitialState);
    const [hasError, setHasError] = useState<hasErrorInterface>({ check: false, message: "" });

    const handleClickChip = (socialMediaInfo: popupInfoInterface) => {
        setHasError({
            check: false,
            message: "",
        });
        setPopupInfo(socialMediaInfo);
        setOpenPopup(true);
    };

    const handleThatsItButton = () => {
        handleEditProfile(savedUsernames);
    };

    return (
        <>
            <View className="form-container">
                <h2>Would you like to add your social media links to your profile? </h2>
                <View id={style["socialMedias-container"]}>
                    {profileSocialMedia.map((socialMediaInfo) => {
                        const username = savedUsernames?.[socialMediaInfo.key];

                        if (socialMediaInfo.key === "startplaying") {
                            return null;
                        }
                        return (
                            <Chip
                                className="social-container"
                                onClick={() => handleClickChip(socialMediaInfo)}
                                key={socialMediaInfo.title}
                                icon={socialMediaInfo.icon}
                                label={username ? `${username}` : `Add ${socialMediaInfo.title}`}
                            />
                        );
                    })}
                </View>
                <View className="button-container">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={false}
                        disabled={false}
                        handleOnClick={handleThatsItButton}
                    />
                </View>
            </View>
            <PopupAddFollowMe
                open={openPopup}
                setOpen={setOpenPopup}
                savedUsernames={savedUsernames}
                setSavedUsernames={setSavedUsernames}
                popupInfo={popupInfo}
                hasError={hasError}
                setHasError={setHasError}
                isSurvey={true}
            />
        </>
    );
};
