import { useState } from "react";
import "../Cards/Cards.css";
import {
    Container,
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
    Typography,
} from "@mui/material";
import { Loader, Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import Button from "components/Button";
import {
    hasAcceptedSpecialCharacters,
    hasLowercase,
    hasMaxlength,
    hasMinlength,
    hasNoSpaces,
    hasUppercase,
    passwordErrorMsgs,
} from "utils/signUpConstants";
import { emailRegex } from "utils/regexPatterns";

const CardCreateAccount = ({
    TitleCard = "Create Account",
    ButtonText1 = "CREATE ACCOUNT",
    Text1 = "Already have an account?",
    Text2 = "Log In",
    onSignUp,
    message,
    cleanMessage,
    loading,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [hasSubmited, setHasSubmited] = useState(false);
    const [hasTouchedPasswordField, setHasTouchedPasswordField] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const {
        handleSubmit,
        register,
        formState: { errors },
        setValue,
    } = useForm({
        criteriaMode: "all",
    });

    const handleClickSubmitButton = () => {
        setHasSubmited(true);
    };

    const handlePasswordChange = (e) => {
        if (!hasTouchedPasswordField) {
            setHasTouchedPasswordField(true);
        }
        setValue("password", e.target.value, { shouldValidate: true });
    };

    const hasErrorType = (type) => {
        if (!errors?.password?.types) {
            return false;
        }
        return Object.keys(errors?.password?.types)?.includes(type);
    };

    const getPasswordColorClass = (type) => {
        return `error-msg-single ${!hasTouchedPasswordField || hasErrorType(type) ? "" : "green-msg"}`;
    };

    const passwordColorClass = () => {
        if (hasTouchedPasswordField) {
            return errors.password ? "red-msg" : "green-msg";
        }
        return "";
    };

    return (
        <Container className="card auth-container" data-testid="create-account-container">
            <form onSubmit={handleSubmit(onSignUp)}>
                <Text className="card_title">{TitleCard}</Text>
                <TextField
                    variant="outlined"
                    type="email"
                    label="Email*"
                    {...register("email", {
                        required: "Required",
                        pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email address.",
                        },
                    })}
                    disabled={loading}
                />
                {errors.email && <Text className="error-msg red-msg">{errors.email.message}</Text>}
                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
                    <OutlinedInput
                        {...register("password", {
                            required: "Required",
                            validate: {
                                hasMinlength: (value) => hasMinlength(value),
                                hasMaxlength: (value) => hasMaxlength(value),
                                hasUppercase: (value) => hasUppercase(value),
                                hasLowercase: (value) => hasLowercase(value),
                                hasNoSpaces: (value) => hasNoSpaces(value),
                                hasAcceptedSpecialCharacters: (value) => hasAcceptedSpecialCharacters(value),
                            },
                        })}
                        error={errors.password}
                        data-testid="sign-up-password"
                        type={showPassword ? "text" : "password"}
                        inputProps={{ "data-testid": "sign-up-password-input" }}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end">
                                    {showPassword ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password*"
                        disabled={loading}
                        onChange={handlePasswordChange}
                    />
                </FormControl>
                <Typography
                    className={`error-msg ${passwordColorClass()}`}
                    data-testid="password-error-msg"
                    sx={{ whiteSpace: "pre-line" }}>
                    {!loading &&
                        (hasErrorType("required") ? (
                            errors?.password?.message
                        ) : (
                            <>
                                {passwordErrorMsgs.map((error) => {
                                    if (error.showAfterSubmited && !hasSubmited) {
                                        return null;
                                    }

                                    return (
                                        <span
                                            key={`pwd-error-msg-${error.type}`}
                                            className={getPasswordColorClass(error.type)}>
                                            {error.message}
                                        </span>
                                    );
                                })}
                            </>
                        ))}
                </Typography>
                {message && <Text className="error-msg red-msg">{message}</Text>}
                <Button data-testid="submit-button" type="submit" disabled={loading} onClick={handleClickSubmitButton}>
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px 0",
                            }}>
                            <Loader width={"6rem"} height={"6rem"} filledColor={"white"} emptyColor={"red"} />
                        </div>
                    )}
                    {!loading && ButtonText1}
                </Button>
                <Text className="card_text">
                    {Text1}{" "}
                    <Link to="/login" onClick={cleanMessage} style={{ color: "#4da3cb" }}>
                        {Text2}
                    </Link>
                </Text>
            </form>
        </Container>
    );
};

export default CardCreateAccount;
