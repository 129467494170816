import React, { useEffect, useState } from "react";
import "./CompletYourProfile.scss";
import { View, Text } from "@aws-amplify/ui-react";
import { useGlobalContext } from "context/AppContext";
import { profileSocialMedia } from "utils/constants";

const PROPERTIES_TEXT = {
    bio: {
        text: "Fill in your *|LINK|*",
        anchor: "biography",
        linkText: "Bio",
    },
    gamesPlayed: {
        text: "Tell others which *|LINK|*",
        anchor: "games-played",
        linkText: "games you’ve played",
    },
    vtt: {
        text: "What are your *|LINK|*?",
        anchor: "gaming-platforms",
        linkText: "gaming platforms",
    },
    lateness: {
        text: "*|LINK|*?",
        anchor: "availability",
        linkText: "How late is late",
    },
    about: {
        text: "We’re missing crucial information *|LINK|*, like your",
        anchor: "about",
        linkText: "about you",
    },
    social: {
        text: "Do you have any *|LINK|*?",
        anchor: "follow-me",
        linkText: "social media",
    },
    identityTags: {
        text: "What are your *|LINK|*?",
        anchor: "identity-tags",
        linkText: "identity tags",
    },
    languages: {
        text: "Why not share which *|LINK|*?",
        anchor: "languages-i-speak",
        linkText: "languages you speak",
    },
    LGBTQ: {
        text: "Are you okay with *|LINK|*?",
        anchor: "games-preferences",
        linkText: "LGBTQI+ games",
    },
    characterDeath: {
        text: "How do you feel about *|LINK|*?",
        anchor: "games-preferences",
        linkText: "characters dying",
    },
    streamedTTRPG: {
        text: "Would you *|LINK|*?",
        anchor: "additional-questions",
        linkText: "play streamed games",
    },
    preferredCampaignStyle: {
        text: "Why not share your preferred *|LINK|*?",
        anchor: "additional-questions",
        linkText: "campaign style",
    },
    numberOfGamesRun: {
        text: "How many games *|LINK|*?",
        anchor: "additional-questions",
        linkText: "have you run",
    },
};

export const CompletYourProfile = () => {
    const { personalProfile } = useGlobalContext();

    const [isComplete, setIsComplete] = useState<boolean>(true);
    const [propertiesToFill, setPropertiesToFill] = useState<Array<string>>([]);
    const [aboutText, setAboutText] = useState<string>("");

    useEffect(() => {
        if (personalProfile) {
            const propertiesList: Array<string> = [];

            Object.keys(PROPERTIES_TEXT).forEach((key) => {
                if (key === "about") {
                    const aboutItems = [];

                    if (!personalProfile["questionnaireTransitionDM"]) aboutItems.push("player type");
                    if (!personalProfile["gender"]) aboutItems.push("gender");

                    if (aboutItems.length > 0) {
                        setAboutText(aboutItems.join(", "));
                        propertiesList.push(key);
                    }
                    return;
                }

                if (key === "social") {
                    if (profileSocialMedia.slice(0, -1).every((profile) => !personalProfile[profile.key])) {
                        propertiesList.push(key);
                    }
                    return;
                }

                const value = personalProfile[key];
                if (!value || value.length === 0 || value?.[0].length === 0) {
                    propertiesList.push(key);
                }
            });

            if (propertiesList.length === 0) {
                return;
            }

            setIsComplete(false);
            setPropertiesToFill(propertiesList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [personalProfile]);

    if (isComplete) {
        return null;
    }

    return (
        <View>
            <div className="profile-section complete-profile-container">
                <Text className="head-text">Complete your profile</Text>
                <Text className="legend-text">
                    <i>Provide the following information for a full profile, guaranteeing better matches</i>
                </Text>
                <ul>
                    {propertiesToFill.map((item) => {
                        const property = PROPERTIES_TEXT[item as keyof typeof PROPERTIES_TEXT];
                        const link = <a href={`/edit-profile#${property.anchor}`}>{property.linkText}</a>;

                        const textParts = property.text.split("*|LINK|*");

                        return (
                            <li key={`complete-profile-property-${item}`}>
                                <span className="list-item">
                                    {textParts[0]}
                                    {link}
                                    {`${textParts[1]} ${property.anchor === "about" ? aboutText : ""}`}
                                </span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </View>
    );
};
