import "./InboxNewMessageButton.scss";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Button, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import { useMessagesContext } from "@context/MessagesContext";
import { useUser } from "@pages/Inbox/hooks/useUser";
import Popup from "components/Popup";
import { useProfileEdit } from "hooks/useProfileEdit";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { NewMessagePopup } from "../NewMessagePopup/NewMessagePopup";

export const InboxNewMessageButton = () => {
    const { loadingUsers, filteredProfiles, newMessageOpen, setNewMessageOpen, messageDisabled } = useMessagesContext();
    const {
        loadingInboxMessages,
        loadingInboxBlockedMessages,
        clickHandler,
        handleNewMessagePopupClick,
        getPersonalProfile,
    } = useMessages();
    const { handleSearch } = useUser();
    const { isLoadingSave, enableMessaging } = useProfileEdit({ getPersonalProfile });

    const [openMessageDisabledPopup, setOpenMessageDisabledPopup] = useState(false);

    const onClickAdd = () => {
        if (messageDisabled) {
            setOpenMessageDisabledPopup(true);
        } else {
            clickHandler();
        }
    };

    const handleEnableMessaging = async () => {
        await enableMessaging({ setPopup: () => setOpenMessageDisabledPopup(false) });
    };

    return (
        <Container className="inbox-new-message-button-container position-absolute d-flex justify-content-end p-0">
            <Fab
                className="floating-add-button"
                disabled={loadingInboxMessages || loadingInboxBlockedMessages}
                onClick={onClickAdd}>
                <AddIcon className="add-icon" />
            </Fab>
            <NewMessagePopup
                loading={loadingUsers}
                handleSearch={handleSearch}
                profiles={filteredProfiles}
                open={newMessageOpen}
                setOpen={setNewMessageOpen}
                handleNewMessagePopupClick={handleNewMessagePopupClick}
            />
            <Popup
                classes="message-disabled-modal"
                open={openMessageDisabledPopup}
                setOpen={setOpenMessageDisabledPopup}
                hideCloseBtn>
                <Typography className="message">
                    Hail adventurer! To send a new message you must enable messaging.
                </Typography>
                <Col>
                    <Row className="m-0 pb-2">
                        <LoadingBtn
                            className="enable-button"
                            variant="contained"
                            handleOnClick={handleEnableMessaging}
                            loading={isLoadingSave}
                            label="ENABLE MESSAGING"
                        />
                    </Row>
                    <Row className="m-0">
                        <Button className="cancel-button" onClick={() => setOpenMessageDisabledPopup(false)}>
                            CANCEL
                        </Button>
                    </Row>
                </Col>
            </Popup>
        </Container>
    );
};
