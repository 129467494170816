import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileCharacterDeath from "@components/ProfileInterview/ProfileCharacterDeath";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const CharacterDeathComponent = ({
    state,
    handleEditProfile,
    pushCounter,
    skipButton,
    goBack,
    counter,
    length,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-character-death">
                <View className="top-text"></View>
                <View className="question-container">
                    <ProfileCharacterDeath
                        state={state}
                        pushCounter={pushCounter}
                        handleEditProfile={handleEditProfile}
                    />
                </View>
                <BackNextBtn
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={skipButton}
                    pageState={state.characterDeath}
                />
            </CompleteProfileContainer>
        </>
    );
};
