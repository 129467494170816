import React from "react";
// import videojs from "video.js";
import { Box } from "@mui/material";
import { View, Image } from "@aws-amplify/ui-react";

import "./TwitterPost.css";

import TwitterUserData from "./TwitterUserData";
import TwitterContent from "./TwitterContent";
import TwitterMetrics from "./TwitterMetrics";
// import TwitterMedia from "./TwitterMedia";

export default function TwitterPost({ post, handleModal }) {
    // const playerRef = React.useRef(null);

    // const handlePlayerReady = (player) => {
    //     playerRef.current = player;

    //     // You can handle player events here, for example:
    //     player.on("waiting", () => {
    //         videojs.log("player is waiting");
    //     });

    //     player.on("dispose", () => {
    //         videojs.log("player will dispose");
    //     });
    // };

    const renderMedia = (post) => {
        const attachments = post?.attachmentsURL;

        if (attachments?.length > 0) {
            return (
                <>
                    {attachments.map((item) => {
                        if (item?.M?.url_type?.S === "photo") {
                            const height = item?.M?.height?.N;
                            const url = item?.M?.media_url?.S;

                            return (
                                <Box
                                    key={item?.M?.media_key?.S}
                                    onClick={redirectToPost}
                                    sx={{ height: height || "auto" }}
                                    component="img"
                                    src={url}
                                />
                            );
                        }

                        return null;
                    })}
                </>
            );
        }

        // CODE FOR VIDEO
        // const videoJsOptions = {
        //         autoplay: false,
        //         controls: true,
        //         responsive: true,
        //         fluid: true,
        //         sources: [
        //             {
        //                 src: "https://twitter.com/dimension20show/status/1711475416618131655/video/1", //TODO: CHECK WHERE WE GONNA TAKE THE VIDEO URL
        //                 type: "video/webm",
        //             },
        //         ],
        //     };
        //     return <TwitterMedia options={videoJsOptions} onReady={handlePlayerReady} />;
    };

    const redirectToProfile = () => handleModal(`https://twitter.com/${post?.username}`);
    const redirectToPost = () => handleModal(`https://twitter.com/${post?.username}/status/${post?.id}`);
    const redirectToHashTag = (hashtag) => handleModal(`https://twitter.com/hashtag/${hashtag.replace("#", "")}`);
    return (
        <View className="twitter-post" data-testid="twitter-post">
            <Image
                onClick={redirectToProfile}
                src={post?.avatar || "/space_dragons.jpg"}
                className="twitter-post-image"
            />
            <View className="content">
                <TwitterUserData {...post} redirectToProfile={redirectToProfile} />

                {/* {
                    //TODO: VERIFY HOW WE GONNA CHECK IF ITS VIDEO OR NOT
                    post?.isVideo && <TwitterMedia options={videoJsOptions} onReady={handlePlayerReady} />
                }
                {
                    //TODO: VERIFY HOW WE GONNA CHECK IF ITS Image OR NOT
                    post?.isImage && (
                        <Box
                            component="img"
                            src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2" // CHANGE THE URL
                        />
                    )
                } */}
                <TwitterContent
                    {...post}
                    redirectToPost={redirectToPost}
                    handleModal={handleModal}
                    redirectToHashTag={redirectToHashTag}
                />
                {renderMedia(post)}
                <TwitterMetrics {...post} handleModal={handleModal} />
            </View>
        </View>
    );
}
