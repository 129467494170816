import React from "react";

import "./GroupDialogs.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box, Typography } from "@mui/material";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import GroupsTwoToneIcon from "@mui/icons-material/GroupsTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineTwoToneIcon from "@mui/icons-material/CheckCircleOutlineTwoTone";
import { conditionalClassName } from "utils/functions";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
    open: boolean;
    onConfirm: () => void | Promise<void>;
    onClose: () => void | Promise<void>;
}

export const OpenSeatDialog = ({ open, onClose, onConfirm }: Props) => {
    /* States and Hooks */
    const { isMobile } = useBreakpoint();

    /* Variables */
    const className = conditionalClassName(["group-dialog-container"], [isMobile, "group-dialog-mobile"]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            TransitionComponent={Transition}
            keepMounted
            aria-describedby="remove-player-dialog"
            className="group-dialog">
            <DialogContent className={className}>
                <ShouldRender returnNull condition={!isMobile}>
                    <GroupsTwoToneIcon />
                </ShouldRender>
                <Box className="group-content">
                    <Typography variant="h3" className="group-alert-title">
                        Join the Group
                    </Typography>
                    <Typography className="group-alert-description">Would you like to join this group?</Typography>

                    <Box className="group-dialog-buttons">
                        <SecondaryApplyButton onClick={onClose} icon={<CloseIcon />}>
                            NO
                        </SecondaryApplyButton>
                        <ApplyButton onClick={onConfirm} icon={<CheckCircleOutlineTwoToneIcon />}>
                            Yes, let's play!
                        </ApplyButton>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};
