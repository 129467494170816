import { Text } from "@aws-amplify/ui-react";
import { Box } from "@mui/material";
import ClearButton from "components/ClearButton";
import "./UserList.css";
import Button from "components/Button";
import { useNavigate } from "react-router-dom";

export const NoUsersFound = ({ onBackFilter, onTryAgain, filters, personalProfile }) => {
    const navigate = useNavigate();

    const hasAvailabilityFilter = filters["availability"] === true || filters["availability"] === "true";
    const hasNoAvailability = personalProfile?.noAvailability?.[0] || false;
    const hasAvailabilityChecked = personalProfile?.availabilityTable?.some((day) => day?.includes("1")) || false;

    const toProfilePage = () => {
        navigate("/edit-profile");
    };

    if (hasAvailabilityFilter && (hasNoAvailability || !hasAvailabilityChecked)) {
        return (
            <Box className="no-found-users-box">
                <Text as="h1">Oops! You have not selected any availability on your profile :(</Text>
                <Text as="span">You can add your availability on the Edit Profile Page.</Text>
                <Box className="no-found-users-box-buttons">
                    <Button width="250px" onClick={toProfilePage}>
                        Go to Edit Profile Page
                    </Button>
                </Box>
            </Box>
        );
    }

    return (
        <Box className="no-found-users-box">
            <Text as="h1">Oops! no results :(</Text>
            <Text as="span">There are no results with the filters you set.</Text>
            <Box className="no-found-users-box-buttons">
                <Button width="250px" onClick={onBackFilter}>
                    Back to filter list
                </Button>
                <ClearButton sx={{ width: "auto" }} onClick={onTryAgain}>
                    Clear all filters and try again?
                </ClearButton>
            </Box>
        </Box>
    );
};
