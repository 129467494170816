import React from "react";

import { LinearProgress } from "@mui/material";

export default function ProgressBar({ progress, total = 100 }) {

    const normalise = () => ((progress - 0) * 100) / (total - 0);

    return <LinearProgress
        id="question-progressbar"
        data-testid='question-progressbar'
        variant="buffer"
        value={normalise()}
        valueBuffer={100}
        sx={{
            "& .MuiLinearProgress-barColorPrimary": {
                backgroundColor: "#A61F38",
            },
            "& .MuiLinearProgress-colorPrimary": {
                backgroundColor: "#DBA5AF",
            }
        }}
    />
}