import React, { useEffect, useState } from "react";

import "./GroupPlayers.scss";
import { Box, Slider, Typography } from "@mui/material";
import { PlayerChipProps } from "models/group/PlayerChipProps";
import { MINIMUM_GROUP_SIZE } from "@hooks/useManageGroup";

interface Props {
    playerAmount: number;
    setPlayerAmount: React.Dispatch<React.SetStateAction<number>>;
    playersList: PlayerChipProps[];
}

export default function PlayerAmountSlider({ playerAmount, setPlayerAmount, playersList }: Props) {
    const [isDisabled, setIsDisabled] = useState(false);

    useEffect(() => {
        if (playerAmount === playersList.length) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [playerAmount, playersList]);

    const handlePlayerAmount = (newValue: number | number[]) => {
        if ((newValue as number) < playersList.length) {
            setPlayerAmount(playersList.length);
        } else {
            setPlayerAmount(newValue as number);
        }
    };

    const handleSignButton = (type: "sum" | "subtraction") => {
        switch (type) {
            case "sum":
                if (playerAmount + 1 > 8) break;
                setPlayerAmount((prev) => prev + 1);
                break;
            case "subtraction":
                if (playerAmount - 1 < playersList.length || playerAmount - 1 < MINIMUM_GROUP_SIZE) break;
                setPlayerAmount((prev) => prev - 1);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <Box className="player-amount-slider">
                <Box
                    component={"span"}
                    draggable={false}
                    className="sign minus-sign"
                    onClick={() => handleSignButton("subtraction")}>
                    -
                </Box>
                <Slider
                    className={`${isDisabled ? "slider-disabled" : ""}`}
                    aria-label="Player amount"
                    value={playerAmount}
                    onChange={(event, newValue) => handlePlayerAmount(newValue)}
                    step={1}
                    min={MINIMUM_GROUP_SIZE}
                    max={8}
                    marks
                    valueLabelDisplay="on"
                />
                <Box
                    component={"span"}
                    draggable={false}
                    className="sign plus-sign"
                    onClick={() => handleSignButton("sum")}>
                    +
                </Box>
            </Box>
            {isDisabled && (
                <Box className="warning-info">
                    <Typography variant="caption">
                        Note: You can't decrease the party size below the current number of joined players. To reduce
                        the size, please remove players manually.
                    </Typography>
                </Box>
            )}
        </>
    );
}
