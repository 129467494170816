import { useEffect, useState } from "react";

export const useEditAvailability = ({ table, changeEditState, editState }) => {
    const hasQuestionsState = () => {
        let newStateQuestions = {
            lateness: "",
        };
        if (table.lateness) {
            newStateQuestions.lateness = table.lateness;
        }

        return newStateQuestions;
    };

    const hasState = () => {
        let newState = [
            { id: "mon", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "tues", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "weds", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "thurs", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "fri", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "sat", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
            { id: "sun", morning: 0, afternoon: 0, evening: 0, night: 0, late_night: 0 },
        ];

        if (table.availabilityTable) {
            newState = [
                {
                    id: "mon",
                    morning: table.availabilityTable[0][0],
                    afternoon: table.availabilityTable[0][1],
                    evening: table.availabilityTable[0][2],
                    night: table.availabilityTable[0][3],
                    late_night: table.availabilityTable[0][4],
                },
                {
                    id: "tues",
                    morning: table.availabilityTable[1][0],
                    afternoon: table.availabilityTable[1][1],
                    evening: table.availabilityTable[1][2],
                    night: table.availabilityTable[1][3],
                    late_night: table.availabilityTable[1][4],
                },
                {
                    id: "weds",
                    morning: table.availabilityTable[2][0],
                    afternoon: table.availabilityTable[2][1],
                    evening: table.availabilityTable[2][2],
                    night: table.availabilityTable[2][3],
                    late_night: table.availabilityTable[2][4],
                },
                {
                    id: "thurs",
                    morning: table.availabilityTable[3][0],
                    afternoon: table.availabilityTable[3][1],
                    evening: table.availabilityTable[3][2],
                    night: table.availabilityTable[3][3],
                    late_night: table.availabilityTable[3][4],
                },
                {
                    id: "fri",
                    morning: table.availabilityTable[4][0],
                    afternoon: table.availabilityTable[4][1],
                    evening: table.availabilityTable[4][2],
                    night: table.availabilityTable[4][3],
                    late_night: table.availabilityTable[4][4],
                },
                {
                    id: "sat",
                    morning: table.availabilityTable[5][0],
                    afternoon: table.availabilityTable[5][1],
                    evening: table.availabilityTable[5][2],
                    night: table.availabilityTable[5][3],
                    late_night: table.availabilityTable[5][4],
                },
                {
                    id: "sun",
                    morning: table.availabilityTable[6][0],
                    afternoon: table.availabilityTable[6][1],
                    evening: table.availabilityTable[6][2],
                    night: table.availabilityTable[6][3],
                    late_night: table.availabilityTable[6][4],
                },
            ];
        }

        return newState;
    };

    useEffect(() => {
        if ("timezone" in editState && !("availabilityTable" in editState)) {
            const editStateValue = [];

            hasState().forEach((obj) => {
                editStateValue.push([
                    String(obj.morning),
                    String(obj.afternoon),
                    String(obj.evening),
                    String(obj.night),
                    String(obj.late_night),
                ]);
            });

            changeEditState({ availabilityTable: editStateValue });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editState]);

    const [textValue, setTextValue] = useState(hasState);
    const [questionsValue, setQuestionsValue] = useState(hasQuestionsState);

    const updateTable = (e) => {
        // id and times used for evaluations
        const day = e.target.name;
        const time = e.target.value;
        const checked = e.target.checked ? 1 : 0;

        // loops through state and returns changes into a temp state
        let newState = textValue.map((obj) => {
            if (obj.id === day) {
                return { ...obj, [time]: Number(checked) };
            } else {
                return { ...obj };
            }
        });
        // Sets new state
        setTextValue(newState);

        const editStateValue = [];

        newState.forEach((obj) => {
            editStateValue.push([
                String(obj.morning),
                String(obj.afternoon),
                String(obj.evening),
                String(obj.night),
                String(obj.late_night),
            ]);
        });

        changeEditState({ availabilityTable: editStateValue });
    };

    const changeObj = (e) => {
        setQuestionsValue((textValue) => ({
            ...textValue,
            [e.target.name]: e.target.value,
        }));

        changeEditState({ [e.target.name]: e.target.value });
    };

    return {
        updateTable,
        changeObj,
        questionsValue,
    };
};
