import { ArrowBack } from "@mui/icons-material";
import "./styles.css";

export const BackButton = ({ onClickBack, showContainer = false }) => {
    const css = "back-button " + (showContainer ? "back-button-container" : "");
    return (
        <div data-testid="BackButton" className={css} onClick={onClickBack}>
            <ArrowBack />
        </div>
    );
};
