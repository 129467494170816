import React from "react";
import View from "@mui/material/Box";

import { Checkbox } from "@mui/material";
import { timeRange, weekDays } from "utils/constants";
import { useBreakpoint } from "hooks/useBreakpoint";

const Availability = ({ availabilityTable, isEditing }) => {
    return (
        <View className="availability-table-container">
            <AvailabilityTable body={availabilityTable} isEditing={isEditing} />
        </View>
    );
};

const AvailabilityTable = ({ body, isEditing }) => {
    const { isMobile } = useBreakpoint();
    const { availabilityTable } = body;

    return (
        <>
            <View className="time-row-container header">
                <View className="time-description-container">Time</View>
                <View className="time-row">
                    <View className="days">
                        {weekDays.map((day) => (
                            <View className="day-labels" key={day.day}>
                                {day.day}
                            </View>
                        ))}
                    </View>
                </View>
            </View>
            <div data-testid="availability-table" className="availability-table">
                {timeRange.map((time) => {
                    const timeIsSelected = weekDays.some((day) => availabilityTable[day.column][time.row] == 1);

                    return (
                        <View
                            key={time.start}
                            className={`time-row-container ${timeIsSelected ? "time-selected" : ""}`}>
                            <View className="time-description-container">
                                {!isMobile && <View className={"time-icon"}>{time.icon}</View>}
                                <Time time={time} />
                            </View>
                            <View className="time-row">
                                <Days time={time} weekDays={weekDays} availabilityTable={availabilityTable} />
                            </View>
                        </View>
                    );
                })}
            </div>
        </>
    );
};

export function Days({ time, weekDays, availabilityTable, isEditing = false, onChange = () => {}, isTablet }) {
    return (
        <View className={`days`}>
            {weekDays
                .sort((a, b) => a.column - b.column)
                .map((day) => {
                    const isSelected = availabilityTable[day.column][time.row] == 1;

                    return (
                        <Day
                            time={time}
                            day={day}
                            key={day.day}
                            isEditing={isEditing}
                            onChange={onChange}
                            checked={isSelected}
                            isTablet={isTablet}
                        />
                    );
                })}
        </View>
    );
}

export function Day({ time, day, isEditing, onChange, checked, isTablet = false }) {
    return (
        <View className="day-checkbox">
            <Checkbox
                checked={checked}
                name={day.id}
                value={time.description}
                onChange={onChange}
                disabled={!isEditing}
            />
        </View>
    );
}

export function Time({ time }) {
    return (
        <View className="time">
            {time.start} - {time.end}
        </View>
    );
}

export default Availability;
