const getGameURL = (gameName) => {
    return `/game/${gameName
        .replaceAll("&", "and")
        .replaceAll(":", "")
        .replaceAll(" ", "-")
        .replaceAll(".", "-")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .toLowerCase()}`;
};

export default getGameURL;
