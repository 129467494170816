import React from "react";
import "./InboxHeader.scss";
import { Container, Row, Col } from "react-bootstrap";
import { AppTitleIcons } from "@utils/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useMessagesContext } from "@context/MessagesContext";
import { useGlobalContext } from "context/AppContext";

export const InboxHeader = () => {
    const navigate = useNavigate();
    const { isBlockedUserList, setIsBlockedUserList, setReturnInbox } = useMessagesContext();
    const { setBlockedUser } = useGlobalContext()

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    
    const inboxHeaderTitle = isBlockedUserList ? "Blocked Users" : "Inbox";

    const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeDropdown = () => {
        setAnchorEl(null);
    };

    const goBack = () => {
        isBlockedUserList ? setIsBlockedUserList(false) : navigate("/");
    };

    const onMenuItemClick = () => {
        setIsBlockedUserList(!isBlockedUserList);
        setBlockedUser(false)
        setReturnInbox(true)
        closeDropdown();
    };

    return (
        <Container fluid className="inbox-header-container">
            <Row xs="auto" className="align-items-center justify-content-between h-100">
                <Col>
                    <IconButton onClick={goBack}>
                        <ArrowBack className="icon" />
                    </IconButton>
                </Col>

                <Col>
                    <Row xs="auto" className="align-items-center">
                        <Col className="pe-2">{AppTitleIcons.messages}</Col>
                        <Col className="ps-0">
                            <div className="title">{inboxHeaderTitle}</div>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <IconButton onClick={openDropdown}>
                        <ExpandMoreIcon className="icon" />
                    </IconButton>
                    <Menu open={open} onClose={closeDropdown} anchorEl={anchorEl}>
                        <MenuItem onClick={onMenuItemClick}>
                            {isBlockedUserList ? "Unblocked Users" : "Blocked Users"}
                        </MenuItem>
                    </Menu>
                </Col>
            </Row>
        </Container>
    );
};
