import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import { ProfileLocation } from "components/ProfileInterview/ProfileLocation";

export const LocationComponent = ({ state, handleEditProfile, pushCounter, goNextButton, goBack, counter, length }) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-location">
                <View className="top-text"></View>
                <View className="question-container">
                    <ProfileLocation state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
                </View>
                <BackNextBtn
                    pushCounter={pushCounter}
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                    pageState={state?.country}
                />
            </CompleteProfileContainer>
        </>
    );
};
