import "./ProfileAvatarBox.scss";

import React, { useMemo, useState } from "react";

import { View } from "@aws-amplify/ui-react";
import useImageSrc from "hooks/useImageSrc";
import FavoritedStarButton from "components/FavoritedStarButton/FavoritedStarButton";
import { DropZoneEditor } from "components/DropZoneEditor";
import { AVATAR_REMOVED_PLACEHOLDER } from "utils/constants";
import { Box, Chip } from "@mui/material";
import { matchColor } from "utils/matchColor";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import { useGlobalContext } from "context/AppContext";

const ProfileAvatarBox = ({
    allowSendMessage,
    favoritesTooltipTitle,
    updateFavorites,
    favorited,
    changeFavorited,
    state,
    isPublicProfile,
    id,
    wait,
    isEditing,
    changeEditState,
    matchScore,
    isLoggedIn,
}) => {
    const { onError, imgRef, imgSrc } = useImageSrc(state.profile);

    const [newAvatarUnsaved, setNewAvatarUnsaved] = useState(null);
    const [tempRemovedAvatar, setTempRemovedAvatar] = useState(false);
    const sourceImage =
        !!state?.profile?.profileURL?.[0] &&
        state?.profile?.profileURL?.[0] !== AVATAR_REMOVED_PLACEHOLDER &&
        !tempRemovedAvatar
            ? imgSrc
            : null;

    const { personalProfile } = useGlobalContext();

    const matchScoreColor = matchColor(matchScore);
    const showMatchscore = Boolean(isLoggedIn) && !Boolean(isEditing) && personalProfile?.id !== state?.profile?.id;

    const RenderAvatarImage = useMemo(() => {
        const replaceAvatarTemp = tempRemovedAvatar ? "/avatars/deleted.png" : null;
        return (
            <Box className="avatar-box">
                {allowSendMessage && (
                    <FavoritedStarButton
                        updateFavorites={updateFavorites}
                        favorited={favorited}
                        changeFavorited={changeFavorited}
                        id={id}
                        wait={wait}
                        favoritesTooltipTitle={favoritesTooltipTitle}
                    />
                )}
                <Box
                    ref={imgRef}
                    onError={onError}
                    component={"img"}
                    draggable={false}
                    className={`avatar-l user-avatar-inner-img ${newAvatarUnsaved ? "unsaved-avatar" : ""}`}
                    sx={{
                        width: "170px",
                        height: "170px",
                        ...(showMatchscore && {
                            border: "5px solid",
                            borderColor: matchScoreColor.borderColor,
                        }),
                    }}
                    src={replaceAvatarTemp || newAvatarUnsaved ? newAvatarUnsaved : imgSrc}
                    alt="user_profile_image"
                />
                <ShouldRender returnNull condition={showMatchscore}>
                    <Chip
                        sx={{
                            color: matchScoreColor.textColor,
                            border: "1px solid",
                            borderColor: matchScoreColor.bgColor,
                            backgroundColor: matchScoreColor.borderColor,
                        }}
                        label={`${matchScore?.toFixed?.(0) || 0}% Match`}
                        className="matchscore-chip"
                    />
                </ShouldRender>
            </Box>
        );
    }, [tempRemovedAvatar, newAvatarUnsaved, imgSrc, imgRef, matchScoreColor]);

    return (
        <View className="avatar-box-wrap">
            <View className="avatar-box">
                {RenderAvatarImage}
                {!isPublicProfile && isEditing && (
                    <DropZoneEditor
                        profileId={state?.profile?.id}
                        changeEditState={changeEditState}
                        setTemporaryAvatar={(b64) => {
                            setNewAvatarUnsaved(b64);
                        }}
                        removeTemporarilyAvatar={(bol) => {
                            setTempRemovedAvatar(bol);
                        }}
                        defaultImg={newAvatarUnsaved || sourceImage}
                    />
                )}
            </View>
        </View>
    );
};

export default ProfileAvatarBox;
