import React, { useState } from "react";
import "./ChatHeader.scss";
import { Container, Row, Col } from "react-bootstrap";
import { Avatar, CircularProgress, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { Close, MoreVert } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useMessagesContext } from "@context/MessagesContext";
import { useUser } from "@pages/Inbox/hooks/useUser";
import PlayerVerifiedIcon from "components/Profile/components/PlayerVerifiedIcon";
import { useSnackbarContext } from "context/SnackbarContext";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import useImageSrc from "hooks/useImageSrc";

const anchor = { vertical: "top", horizontal: "left" };

export const ChatHeader = () => {
    const navigate = useNavigate();
    const { setProfiles, setPublicID, profiles } = useMessagesContext();
    const { blockedUser, unblockUser, blockUser } = useUser();
    const { showSnackbar } = useSnackbarContext();
    const { deleteChat } = useMessages();
    const { onError, imgRef, imgSrc } = useImageSrc(profiles?.public);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [loadingBlockUnblock, setLoadingBlockUnblock] = useState(false);
    const [loadingDeleteChat, setLoadingDeleteChat] = useState(false);

    const openDropdown = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeDropdown = () => {
        setAnchorEl(null);
    };

    const goBack = () => {
        const path = "/messages";

        setProfiles(null);
        setPublicID("");
        navigate(path);
    };

    const handleBlockUnblock = async () => {
        let action = "";
        setLoadingBlockUnblock(true);

        try {
            if (blockedUser) {
                action = "unblocked";
                await unblockUser({});
            } else {
                action = "blocked";
                await blockUser();
            }

            showSnackbar({
                title: "Success",
                description: `You ${action} this user.`,
                severity: "success",
                anchor,
            });
        } catch {
            showSnackbar({ anchor });
        } finally {
            setLoadingBlockUnblock(false);
            closeDropdown();
        }
    };

    const handleDeleteChat = async () => {
        setLoadingDeleteChat(true);

        try {
            await deleteChat();

            showSnackbar({
                title: "Success",
                description: "Your chat was deleted.",
                severity: "success",
                anchor,
            });
        } catch {
            showSnackbar({ anchor });
        } finally {
            setLoadingDeleteChat(false);
            closeDropdown();
        }
    };
    const userIsDeleted = !!!profiles?.public?.id && !!profiles;
    const headerTitle = userIsDeleted ? "Deleted User" : profiles?.public?.name?.toString() ?? "...";
    const userProfileUrl = userIsDeleted ? "" : profiles?.public?.vanityUrl?.toString() ?? "";
    return (
        <Container fluid className="chat-header-container">
            <Row className="align-items-center justify-content-between h-100 flex-nowrap">
                <Col className="col-auto">
                    <IconButton onClick={goBack}>
                        <Close className="icon" />
                    </IconButton>
                </Col>

                <Col>
                    <Row className="align-items-center flex-nowrap gx-2">
                        <Col className="col-auto pe-2">
                            <Link to={`/profile/${userProfileUrl}`}>
                                <Avatar className="avatar" src={imgSrc} alt="Avatar">
                                    <img
                                        ref={imgRef as any}
                                        onError={onError}
                                        width={32}
                                        height={32}
                                        src={imgSrc}
                                        alt="Default Avatar"
                                    />
                                </Avatar>
                            </Link>
                        </Col>
                        <Col className="profile-name-col">
                            <Typography className="profile-name">{headerTitle}</Typography>
                        </Col>
                        {profiles?.public?.verified && (
                            <Col className="col-auto p-0">
                                <PlayerVerifiedIcon />
                            </Col>
                        )}
                    </Row>
                </Col>

                <Col className="col-auto">
                    <IconButton onClick={openDropdown}>
                        <MoreVert className="icon" />
                    </IconButton>
                    <Menu open={open} onClose={closeDropdown} anchorEl={anchorEl}>
                        <MenuItem onClick={handleBlockUnblock} disabled={loadingBlockUnblock}>
                            {loadingBlockUnblock && <CircularProgress size={20} className="me-2" color="inherit" />}
                            {blockedUser ? "Unblock User" : "Block User"}
                        </MenuItem>
                        <MenuItem disabled={loadingDeleteChat} onClick={handleDeleteChat}>
                            {loadingDeleteChat && <CircularProgress size={20} className="me-2" color="inherit" />}
                            Delete Chat
                        </MenuItem>
                    </Menu>
                </Col>
            </Row>
        </Container>
    );
};
