import { View, Text } from "@aws-amplify/ui-react";
import "./OptionalQuestions.css";
import React, { useMemo } from "react";
import { optional_questions } from "@utils/constants";
import { FormControl, InputLabel, Select, MenuItem, TextField } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";

const OptionalQuestions = ({ state, isEditing = false, changeEditState, editState, renderQuestions }) => {
    const renderElements = (optionalQuestions, isEditing) => {
        if (!isEditing) {
            return renderQuestions([...optional_questions], state);
        }

        return optionalQuestions
            .filter((item) => (isEditing ? item.editable : true))
            .map((questionObject) => {
                const { title, key, type } = questionObject;
                const questionResponse = state.profile[key];
                const responseType = typeof questionResponse;

                const onSelectChange = (event) => {
                    const value = event.target.value;

                    if (type === "number") {
                        const regexp = /^\d+$/;

                        if (regexp.test(parseInt(value))) {
                            changeEditState({ [key]: parseInt(value) });
                            return;
                        }

                        if (value === "") {
                            changeEditState?.({ [key]: "" });
                            return;
                        }
                    }

                    changeEditState?.({ [key]: value });
                };

                const generateMenuItems = () => {
                    return questionObject.answers?.map((option, index) => (
                        <MenuItem key={`${key}-option-${index}`} value={option}>
                            {option}
                        </MenuItem>
                    ));
                };

                const getInputByType = () => {
                    let numberValue = state.profile?.[key]?.[0];
                    if (numberValue !== undefined) numberValue = parseInt(numberValue);

                    let editStateValue = editState?.[key];
                    if (editStateValue === "") editStateValue = undefined;

                    const numberComponent = () => (
                        <TextField
                            type="number"
                            fullWidth
                            data-testid={`profile-${key}-input`}
                            onChange={onSelectChange}
                            value={editState?.[key] !== undefined ? editStateValue : numberValue}
                            id={`${key}-picker`}
                            label={title}
                            variant="outlined"
                            inputProps={{ maxLength: 20 }}
                        />
                    );

                    const textComponent = () => (
                        <>
                            <InputLabel id={`${key}-picker`} {...{ "data-testid": `profile-${key}-select` }}>
                                {title}
                            </InputLabel>
                            <Select
                                labelId={`${key}-picker`}
                                id={`${key}-picker`}
                                value={editState?.[key] || state.profile?.[key]?.[0]}
                                label={title}
                                inputProps={{ "data-testid": `${key}-picker-input` }}
                                onChange={(e) => onSelectChange(e)}>
                                {generateMenuItems()}
                            </Select>
                        </>
                    );

                    switch (type) {
                        case "number":
                            return numberComponent();
                        case "string":
                            return textComponent();
                        default:
                            return textComponent();
                    }
                };

                if (isEditing) {
                    return (
                        <View
                            className={`form-container edit-optional-question ${
                                state?.profile?.[key] ? "" : "not-completed-item"
                            }`}
                            key={key}
                            data-testid={`profile-${key}-select`}>
                            <FormControl fullWidth>{getInputByType()}</FormControl>
                        </View>
                    );
                }

                if (responseType === "string") {
                    return (
                        <>
                            <Text key={key} as="h3" className="profile-questions">
                                {title}
                                <br />
                                <strong>{questionResponse}</strong>
                            </Text>
                        </>
                    );
                } else if (responseType === "object") {
                    return (
                        <View key={key}>
                            <Text as="h3" className="profile-questions">
                                {title}
                                {questionResponse.map((response) => {
                                    const realResponse = response || response === 0 ? response : "No Response";
                                    return (
                                        <React.Fragment key={response}>
                                            <br />
                                            <strong key={response}>{realResponse}</strong>
                                        </React.Fragment>
                                    );
                                })}
                            </Text>
                        </View>
                    );
                } else {
                    return null;
                }
            });
    };

    const showComplete = useMemo(() => {
        return (
            isEditing &&
            ["streamedTTRPG", "preferredCampaignStyle", "numberOfGamesRun"].some((item) => !state?.profile?.[item])
        );
    }, [isEditing, state?.profile]);

    if (optional_questions.filter((question) => state.profile?.[question.key]).length === 0 && !isEditing) return null;
    return (
        <View
            id="additional-questions"
            data-testid="optional-questions"
            className={`optional-questions-container profile-component ${showComplete ? "not-completed" : ""}`}>
            <Text as="h2" className="optional-questions-head-text">
                <span> Additional Questions</span> {showComplete && <WarningIcon sx={{ color: "#FFB74D" }} />}
            </Text>
            {renderElements(optional_questions, isEditing)}
        </View>
    );
};

export default OptionalQuestions;
