import { TextField, Typography } from "@mui/material";
import Popup from "components/Popup";
import React from "react";
import View from "@mui/material/Box";
import "./styles.css";
import Button from "components/Button";

interface PopupDeleteAccountProps {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    title: string;
    warning: string;
    warningDescription: string;
    onClickConfirm: () => void;
    onClickCancel: () => void;
    validationString: string;
    helperText: string;
    applyBtnText: string;
    cancelBtnText: string;
    value: string;
    onChangeValue: () => void;
}

const PopupDeleteAccount = ({
    open = true,
    setOpen,
    title,
    warning,
    warningDescription,
    validationString,
    helperText,
    applyBtnText,
    cancelBtnText,
    onClickConfirm,
    onClickCancel,
    value,
    onChangeValue,
}: PopupDeleteAccountProps) => {
    const enableCloseAccount = validationString === value;

    return (
        <Popup open={open} setOpen={setOpen} classes="popup-delete" hideCloseBtn>
            <Typography className="delete-title" variant="h1">
                {title}
            </Typography>
            <Typography className="delete-warning">
                <span className="gray-text">{warning}</span>
                {warningDescription}
            </Typography>
            <View className="delete-input-container">
                <Typography className="delete-helper-text unselectable">{helperText}</Typography>
                <TextField
                    className="delete-textfield"
                    variant="standard"
                    value={value}
                    onChange={onChangeValue}
                    color="info"
                    fullWidth
                    autoComplete="off"
                />
            </View>
            <View className="popup-delete-buttons">
                <Button onClick={onClickConfirm} disabled={!enableCloseAccount} disableRipple>
                    <Typography>{applyBtnText}</Typography>
                </Button>
                <Button onClick={onClickCancel} disableRipple>
                    <Typography>{cancelBtnText}</Typography>
                </Button>
            </View>
        </Popup>
    );
};

export default PopupDeleteAccount;
