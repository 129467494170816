import "./GroupPlayers.scss";

import React, { useEffect, useState } from "react";

import { Box, Typography, Chip, Backdrop, Dialog } from "@mui/material";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";

import PlayerChip from "./PlayerChip";
import RenderOpenSeats from "./RenderOpenSeats";
import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";
import { SecondaryApplyButton } from "@components/ApplyButton";
import { GroupInvitedUsersManager } from "../GroupInvitedUsers";
import { LeaveGroupDialog, OpenSeatDialog } from "../GroupDialogs";

import { PlayerProps } from "models/group/PlayerProps";
import { GroupType } from "models/group/GroupModel";
import { ProfileModel } from "models/ProfileModels";
import { removeMember } from "@hooks/useGroups";
import { useBreakpoint } from "hooks/useBreakpoint";
import { Send } from "@mui/icons-material";
import { InvitedUserType } from "atoms/groupsAtoms";
import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { inviteToGroup } from "@services/groupsProvider";
import useGroupInvite from "@hooks/useGroupInvite";
import { groupIsFull } from "utils/functions";

type GroupPlayersViewProps = {
    group: Partial<GroupType>;
    joined: boolean;
    setGroup: React.Dispatch<React.SetStateAction<Partial<GroupType>>>;
    hostView: boolean;
    personalProfile?: ProfileModel;
    handleJoinGroup: () => Promise<void> | void;
};
export default function GroupPlayersView({
    group,
    joined,
    setGroup,
    hostView,
    personalProfile,
    handleJoinGroup,
}: GroupPlayersViewProps) {
    /* States and Hooks */
    const [showErrors, setShowErrors] = useState(false);

    const [leaveGroupModalOpen, setLeaveGroupModalOpen] = useState(false);
    const [openDialog_OpenSeat, setOpenDialog_OpenSeat] = useState(false);

    const { isMobile } = useBreakpoint();
    const { popup: popupController, showPopup } = usePopupController();
    const { validateUsers, openInvitedUsers, setOpenInvitedUsers, setInvitedUsers, resetState } = useGroupInvite({
        group,
        variant: "popup",
    });

    /* Functions */

    async function handleRemovePlayer(player: PlayerProps) {
        return;
    }

    async function invitePlayers(users: Array<InvitedUserType>): Promise<void> {
        return (
            new Promise<void>((resolve, reject) => {
                // Checking if the data is complete to send it
                if (!group.id) throw new Error("Missing group ID");

                const filtered = validateUsers(users);

                if (filtered.find((x) => Boolean(x.error))) {
                    setInvitedUsers(filtered);
                    return reject(new Error("Please, type a valid email address."));
                }

                // Grab only the useful data
                const usersToInvite = users.map((user) => (Boolean(user.id) ? user.id : user.name)).filter(Boolean);

                if (usersToInvite.length === 0)
                    return reject(new Error("Please, select at least one player to invite."));

                // Invite players to group
                inviteToGroup(group.id as string, usersToInvite)
                    // Propagate results to parent promise
                    .then(resolve)
                    .catch(reject);
            })
                // In case of success show popup and close the dialog
                .then(() => {
                    showPopup({
                        title: "Success",
                        severity: "success",
                        description: "Your group invitations have been sent successfully.",
                    });
                    setOpenInvitedUsers(false);
                    resetState(true);
                })
                // In case of errors show error popup
                .catch((err) => {
                    showPopup({
                        title: "Error",
                        severity: "error",
                        description: (err as Error).message || "Something went wrong. Please try again.",
                    });
                })
        );
    }

    /* Variables */
    const userIsHost = group?.creator_id === personalProfile?.id;

    return (
        <React.Fragment>
            <OpenSeatDialog
                open={openDialog_OpenSeat && !hostView}
                onClose={() => setOpenDialog_OpenSeat(false)}
                onConfirm={async () => {
                    await handleJoinGroup();
                    setOpenDialog_OpenSeat(false);
                }}
            />
            <AlertPopUpSmall {...popupController} />
            <Backdrop
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, opacity: "1 !important" }}
                open={openInvitedUsers}>
                <Box className="group-invited-users-container">
                    <GroupInvitedUsersManager
                        group={group}
                        variant="popup"
                        groupSize={group.group_size || 0}
                        showErrors={showErrors}
                        onClose={() => setOpenInvitedUsers(false)}
                        onSubmit={invitePlayers}
                    />
                </Box>
            </Backdrop>
            <Box className="profile-component">
                <LeaveGroupDialog
                    onConfirm={() => {
                        setLeaveGroupModalOpen(false);
                        removeMember({
                            memberId: personalProfile?.id as string,
                            groupState: group,
                            setGroupState: setGroup,
                        });
                    }}
                    open={leaveGroupModalOpen}
                    onCancel={() => setLeaveGroupModalOpen(false)}
                    groupId={group.group_id || ""}
                />
                <Box className="group-players-header">
                    <Box className="players-title-container">
                        <Typography variant="h3">Players</Typography>
                        <ShouldRender returnNull condition={isMobile}>
                            <Chip
                                icon={<PeopleAltTwoToneIcon />}
                                label={`${group.members?.length || 0} / ${group.group_size} Players`}
                                className="group-players-chip"
                            />
                        </ShouldRender>
                    </Box>
                    <Box className="group-players-buttons-container">
                        <ShouldRender returnNull condition={joined && !userIsHost && !isMobile}>
                            <SecondaryApplyButton onClick={() => setLeaveGroupModalOpen(true)}>
                                Leave Group
                            </SecondaryApplyButton>
                        </ShouldRender>
                        <ShouldRender returnNull condition={!isMobile && !hostView}>
                            <Chip
                                className="group-players-chip"
                                label={`${group.members?.length || 0} / ${group.group_size} Players`}
                                icon={<PeopleAltTwoToneIcon />}
                            />
                        </ShouldRender>
                        <ShouldRender returnNull condition={!isMobile && hostView && !groupIsFull(group)}>
                            <Chip
                                icon={<Send />}
                                label={`Invite Players`}
                                onClick={() => setOpenInvitedUsers(true)}
                                className="group-players-invite-chip"
                            />
                        </ShouldRender>
                    </Box>
                </Box>
                <Box className="players-container">
                    {group.members?.map((player) => (
                        <PlayerChip
                            key={player?.id}
                            paid={group.game_hosting_type === "Paid" && player.member_type === "host"}
                            player={player}
                            groupId={group.id || ""}
                            hostView={hostView}
                            matchScore={player?.matchScore}
                            memberType={player?.member_type}
                            personalProfile={personalProfile}
                            handleJoinGroupDialog={() => {
                                // Just open the join group modal if the user is not already in the group
                                // And the user is not the group host
                                if (!joined && !userIsHost) setOpenDialog_OpenSeat(true);
                            }}
                            handleRemovePlayer={handleRemovePlayer}
                        />
                    ))}
                    <RenderOpenSeats
                        groupId={group.group_id || ""}
                        hostView={hostView}
                        groupSize={group.group_size || 0}
                        filledSeats={group.members?.length || 0}
                        handleJoinGroupDialog={() => {
                            // Just open the join group modal if the user is not already in the group
                            // And the user is not the group host
                            if (userIsHost) setOpenInvitedUsers(true);
                            if (!joined && !userIsHost) setOpenDialog_OpenSeat(true);
                        }}
                    />
                </Box>
                <ShouldRender returnNull condition={joined && !userIsHost && isMobile}>
                    <SecondaryApplyButton onClick={() => setLeaveGroupModalOpen(true)}>
                        Leave Group
                    </SecondaryApplyButton>
                </ShouldRender>
            </Box>
        </React.Fragment>
    );
}
