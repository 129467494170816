import "./RightCards.scss";
import React, { useContext, useMemo } from "react";
import { useMediaQuery } from "@mui/material";
import { LocationCard } from "./RightCard/LocationCard/LocationCard";
import { AboutCard } from "./RightCard/AboutCard/AboutCard";
import { GeneralCard } from "./RightCard/GeneralCard/GeneralCard";
import { FilterContext } from "context/FilterContext";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useGamePageContext } from "@context/GamePageContext";
import PlayerVerifiedIcon from "components/Profile/components/PlayerVerifiedIcon";
import { useGlobalContext } from "context/AppContext";

const LocationPageCards = () => {
    return (
        <>
            <LocationCard />
            <AboutCard />
        </>
    );
};

const GamePageCards = () => {
    const { currentGameInfo } = useGamePageContext();
    const { isFiltering }: any = useContext(FilterContext);
    const { isFetchingPage, isChangingPage } = usePaginatedContext();
    const { loading: loadingData, isSuccess } = useContext(AsyncFilterFieldsContext);
    const { isLoggedIn } = useGlobalContext();

    const loading = useMemo(() => {
        return isFiltering || loadingData || !isSuccess || (isFetchingPage && !isChangingPage);
    }, [isChangingPage, isFetchingPage, isFiltering, isSuccess, loadingData]);

    return (
        <>
            {currentGameInfo?.rightCards?.map((card) => {
                const canUnlockBadge = card.contentType === "badgeUserList" && card.description?.includes("Unlock");

                const onBeforeNavigate = () => {
                    if (canUnlockBadge) {
                        sessionStorage.setItem("redeem-badge", card.path?.split("=")[1] ?? "");
                    }

                    if (!isLoggedIn) {
                        sessionStorage.removeItem("redirect");
                    }
                };

                return (
                    <GeneralCard
                        key={card.title}
                        {...card}
                        loading={loading}
                        titleIcon={card.verified ? <PlayerVerifiedIcon /> : undefined}
                        style={
                            loading
                                ? undefined
                                : {
                                      backgroundColor: card.backgroundColor ?? undefined,
                                  }
                        }
                        contentStyle={
                            loading
                                ? undefined
                                : {
                                      color: card.textColor ?? undefined,
                                  }
                        }
                        onBeforeNavigate={onBeforeNavigate}
                    />
                );
            })}
        </>
    );
};

export const RightCards = ({ page }: { page: string }) => {
    const isVisibleWithDrawerOpen = useMediaQuery("(min-width: 1070px)");
    const isVisibleWithDrawerClosed = useMediaQuery("(min-width: 870px) and (max-width: 992px)");
    if (!isVisibleWithDrawerOpen && !isVisibleWithDrawerClosed) {
        return null;
    }

    return (
        <div className="right-cards">
            <div className="cards-wrapper" style={{ paddingTop: page === "game" ? "41px" : "8px" }}>
                {page === "location" && <LocationPageCards />}
                {page === "game" && <GamePageCards />}
            </div>
        </div>
    );
};
