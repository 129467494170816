import React, { useMemo } from "react";
import {
    Autocomplete,
    Box,
    ButtonBase,
    Checkbox,
    Chip,
    Container,
    FormControlLabel,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Button from "components/Button";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import { FilterAccordion } from "components/FilterNew/FilterAccordion";
import { Flex, View } from "@aws-amplify/ui-react";
import LocationFilter from "components/FilterNew/LocationFilter/LocationFilter";
import { CheckCircleTwoTone, RadioButtonUnchecked } from "@mui/icons-material";
import { SortingOptions } from "../SortingOptions";

const GroupFilterPopup = ({
    showFilter,
    filters,
    filterFields,
    handleFilters,
    applyFilter,
    anchorEl,
    setAnchorEl,
    handleClearFilter,
    handleCloseFilter,
    sortingOptions,
}) => {
    const gameHostingOptions = [
        {
            label: "All types",
            value: "All",
        },
        {
            label: "Free campaign",
            value: "Free",
        },
        {
            label: "Paid campaign",
            value: "Paid",
        },
    ];
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const gamesPlayed = useMemo(
        function () {
            return (
                filterFields?.games?.map((game) => ({
                    id: game.id,
                    label: game.name,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.games?.[0]?.id],
    );

    const vttSystem = useMemo(
        function () {
            return (
                filterFields.vtt?.map((option, key) => ({
                    id: option.id,
                    label: option.name,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.vtt?.[0]?.id],
    );

    const languages = useMemo(
        function () {
            return (
                filterFields?.languages?.map((option, key) => ({
                    id: option.id,
                    label: option.languageName,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.languages?.[0]?.id],
    );

    const renderCheckboxes = (props, option, { selected }) => {
        return (
            <li {...props} key={`${option.id}-${option.label}`}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
            </li>
        );
    };

    const parseRenderTags = (list, type) => {
        try {
            const fields = {
                gamesPlayed,
                vttSystem,
                languages,
            };

            const fieldList = fields[type];
            return list.map((item) => {
                let find = item;
                if (item !== "" && (typeof item == "string" || typeof item == "number")) {
                    find = fieldList.find((thing) => thing.id === +item);
                }
                return find;
            });
        } catch (error) {
            return list;
        }
    };

    const renderTags = (tagValue, getTagProps, type) => {
        const currentTagValue = parseRenderTags(tagValue, type);
        return currentTagValue.map((option, index) => {
            if (!option) {
                return null;
            }
            if (index < 3) {
                return <Chip key={option.id} label={`${option.label}`} {...getTagProps({ index })} />;
            } else if (index === tagValue.length - 1) {
                return <div key={option.id}>+{tagValue.length - 3}</div>;
            } else {
                return null;
            }
        });
    };

    const renderInput = (params) => (
        <TextField variant="standard" placeholder="Select" className="filter-input" {...params} label={null} />
    );

    const checkSafetyTools = (value) => {
        if (!filters && filters?.safetyTools) {
            return false;
        }
        if (filters?.safetyTools) {
            return filters.safetyTools.includes(value);
        }

        return false;
    };

    return (
        <Menu open={showFilter} anchorEl={anchorEl} onClose={handleCloseFilter} className="group-filter-menu">
            <Box className={"filter-box-separator"}>
                <Flex alignItems={"flex-end"} className="header-filter-popup">
                    <Typography variant="h4" className="primary-heading">
                        Filters
                    </Typography>
                    <View
                        data-testid="clear-filters"
                        onClick={(e) => handleClearFilter(e, { preserveFilters: ["sort"] })}
                        className={"clear-filters"}>
                        Clear All
                    </View>
                    <ButtonBase onClick={handleCloseFilter}>
                        <ClearIcon data-testid="close-icon" className="close-filter-button" />
                    </ButtonBase>
                </Flex>
                <Box sx={{ maxWidth: "100%", margin: "24px 0 26px 0" }}>
                    <Container className="filter-container">
                        <Stack className="filter-group" spacing={2}>
                            <SortingOptions filters={filters} handleFilters={handleFilters} options={sortingOptions} />

                            {/* Commented to be implemented in another moment */}
                            {/* <FormControlLabel
                                className="filter-availability"
                                control={
                                    <Checkbox
                                        data-testid="filter-availability"
                                        checked={filters?.availability === "true" || filters?.availability || false}
                                        onChange={(_, checked) => handleFilters("availability", checked)}
                                        checkedIcon={<CheckCircleTwoTone />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                }
                                label="Show only game schedules matching my availability"
                            /> 

                            <hr />
                            */}
                            <LocationFilter filters={filters} handleFilters={handleFilters} />

                            <hr />
                            <FilterAccordion label="Games" id="Games" filterChosen={Boolean(filters?.game?.filter(item => item !== "")?.length)}>
                                <Autocomplete
                                    disablePortal
                                    onKeyDownCapture={(event) => event.stopPropagation()}
                                    value={filters?.game ? parseRenderTags(filters?.game, "gamesPlayed") : []}
                                    multiple
                                    disableCloseOnSelect
                                    onChange={(e, val) => {
                                        console.log(val)
                                        handleFilters("game", val);
                                    }}
                                    limitTags={3}
                                    className="filter-select"
                                    ListboxProps={{ style: { maxHeight: "200px" } }}
                                    options={gamesPlayed}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderTags={(value, getTagProps) => renderTags(value, getTagProps, "gamesPlayed")}
                                    renderOption={renderCheckboxes}
                                    renderInput={renderInput}
                                />
                            </FilterAccordion>

                            <hr />
                            <FilterAccordion
                                label="Platforms"
                                id="platform-label"
                                filterChosen={Boolean(filters?.platform?.filter(item => item !== "")?.length)}>
                                <Autocomplete
                                    disablePortal
                                    value={filters?.platform ? parseRenderTags(filters?.platform, "vttSystem") : []}
                                    onKeyDownCapture={(event) => event.stopPropagation()}
                                    multiple
                                    disableCloseOnSelect
                                    onChange={(e, val) => {
                                        handleFilters("platform", val);
                                    }}
                                    limitTags={3}
                                    className="filter-select"
                                    ListboxProps={{ style: { maxHeight: "200px" } }}
                                    options={vttSystem}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderTags={(value, getTagProps) => renderTags(value, getTagProps, "vttSystem")}
                                    renderOption={renderCheckboxes}
                                    renderInput={renderInput}
                                />
                            </FilterAccordion>

                            <hr />
                            <FilterAccordion
                                label="Campaign type"
                                id="campaign-type"
                                filterChosen={Boolean(filters?.game_hosting_type)}>
                                {gameHostingOptions.map((gameHosting, index) => {
                                    return (
                                        <FormControlLabel
                                            key={gameHosting.value + index}
                                            className="filter-campaign-type-option"
                                            control={
                                                <Checkbox
                                                    data-testid="filter-campaign-type-option"
                                                    checked={filters?.game_hosting_type === gameHosting.value}
                                                    onChange={() =>
                                                        handleFilters("game_hosting_type", gameHosting.value)
                                                    }
                                                    checkedIcon={<CheckCircleTwoTone />}
                                                    icon={<RadioButtonUnchecked />}
                                                />
                                            }
                                            label={gameHosting.label}
                                        />
                                    );
                                })}
                            </FilterAccordion>

                            <hr />
                            <FilterAccordion
                                label="Advanced filters"
                                id="advanced-filter"
                                filterChosen={Boolean(filters?.safetyTools) || Boolean(filters?.languages?.filter(item => item)?.length)}>
                                <section className="group-advanced-filter">
                                    <hr />
                                    <FilterAccordion
                                        label="Safety Tools"
                                        id="safetyTools"
                                        filterChosen={Boolean(filters?.safetyTools)}>
                                        <MenuItem
                                            onClick={() => {
                                                handleFilters("safetyTools", "Yes");
                                            }}>
                                            <Checkbox
                                                value={"Yes"}
                                                checked={checkSafetyTools("Yes")}
                                                onChange={(e) => handleFilters("safetyTools", e.target.value)}
                                            />
                                            <ListItemText primary={"Yes"} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                handleFilters("safetyTools", "No");
                                            }}>
                                            <Checkbox
                                                value={"No"}
                                                checked={checkSafetyTools("No")}
                                                onChange={(e) => handleFilters("safetyTools", e.target.value)}
                                            />
                                            <ListItemText primary={"No"} />
                                        </MenuItem>
                                    </FilterAccordion>
                                    <hr />
                                    <FilterAccordion
                                        label="Group Language"
                                        id="Languages"
                                        filterChosen={Boolean(filters?.languages?.filter(item => item)?.length)}>
                                        <Autocomplete
                                            disablePortal
                                            onKeyDownCapture={(event) => event.stopPropagation()}
                                            value={
                                                filters?.languages
                                                    ? parseRenderTags(filters?.languages, "languages")
                                                    : []
                                            }
                                            multiple
                                            disableCloseOnSelect
                                            onChange={(e, val) => {
                                                handleFilters("languages", val);
                                            }}
                                            limitTags={3}
                                            className="filter-select"
                                            ListboxProps={{ style: { maxHeight: "200px" } }}
                                            options={languages}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderTags={(value, getTagProps) =>
                                                renderTags(value, getTagProps, "languages")
                                            }
                                            renderOption={renderCheckboxes}
                                            renderInput={renderInput}
                                        />
                                    </FilterAccordion>
                                </section>
                            </FilterAccordion>
                        </Stack>
                    </Container>
                </Box>

                <Container key={"buttons"} className={"filter-buttons"}>
                    <Button
                        data-testid="apply-button"
                        width="150px"
                        className={"show-filter-button"}
                        variant="contained"
                        size="large"
                        onClick={() => {
                            applyFilter(filters);
                            setAnchorEl(null);
                        }}>
                        Apply
                    </Button>
                </Container>
            </Box>
        </Menu>
    );
};

export default GroupFilterPopup;
