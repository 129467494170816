import React, { useContext, useEffect, useState } from "react";

import "./GroupGamePlayed.scss";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { GameType } from "models/cms/fileds";

type GroupGamePlayedManagerProps = {
    game: number;
    variant: "CREATE" | "EDIT";
    setGame: (game: number) => void;
    showErrors?: boolean;
};

export default function GroupGamePlayedManager({
    game,
    setGame,
    variant,
    showErrors = false,
}: GroupGamePlayedManagerProps) {
    const { filterFields } = useContext(AsyncFilterFieldsContext);
    const [selectedGame, setSelectedGame] = useState(null);

    const isIllPlayAnythingOption = (id: number) => id === 715;

    const gameOptions = filterFields?.games?.filter(
        (game: GameType) => !isIllPlayAnythingOption(game.id) && game.enabled,
    );

    const handleGame = (game: GameType | null) => {
        if (game?.id !== undefined) setGame(game?.id);
    };

    useEffect(() => {
        setSelectedGame(filterFields?.games?.find((currentGame: GameType) => currentGame?.id === game));
    }, [game]);

    return (
        <Box className="profile-component">
            <Typography variant="h3">Game</Typography>
            <Autocomplete
                value={selectedGame}
                options={gameOptions}
                id="group-game-played"
                placeholder="Select Game or Type to Search"
                getOptionLabel={(game: GameType) => game.name}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Game"
                        error={showErrors && !selectedGame}
                        placeholder="Select Game or Type to Search"
                        helperText={showErrors && !selectedGame && "Please select a game"}
                    />
                )}
                onChange={(event, value: GameType | null) => handleGame(value)}
            />
        </Box>
    );
}
