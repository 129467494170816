import React from "react";

import { Tooltip } from "@mui/material";

/** Only badges for which we possess the image are shown */
const VTTIcon = ({ tooltipTitle, vttName, vttImage }) => {
    if (!vttImage) {
        return null;
    }

    return (
        <Tooltip placement="top-start" title={tooltipTitle}>
            <img src={'/vtts/' + vttImage} alt={vttName} />
        </Tooltip>
    );
};

export default VTTIcon;
