import { View, Text } from "@aws-amplify/ui-react";
import "./TwitterUserData.css";

/**
 * Renders the Twitter user data.
 *
 * @param {string} username - The username of the Twitter user.
 * @param {string} tag - The tag of the Twitter user.
 * @param {string} timestamp - The timestamp of the Twitter user data.
 * @return {JSX.Element} The rendered Twitter user data component.
 */
export default function TwitterUserData({ username, userTag, timestamp, redirectToProfile }) {
    const formatUserTag = (userTag) => {
        if (userTag.length >= 10 && window.screen.width <= 425) {
            return `${userTag.replace("@", "").slice(0, 10)}...`;
        } else {
            return userTag.replace("@", "");
        }
    };
    //username and userTag are inverted in the back-end
    return (
        <View className="twitter-user-data">
            <Text onClick={redirectToProfile} as="strong" className="username" data-testid="tag">
                {formatUserTag(userTag)}
            </Text>

            <Text onClick={redirectToProfile} className="tag" data-testid="username">
                @{username}
            </Text>
            <View className="dot"></View>
            <Text className="timestamp" data-testid="timestamp">
                {timestamp}
            </Text>
        </View>
    );
}
