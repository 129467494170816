import React from "react";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";

const favoriteStarColor = "#FF9800";

const Favorited = ({ favorited, changeFavorited }) => {
    if (favorited) {
        return <StarIcon sx={{ color: favoriteStarColor }} onClick={changeFavorited} />;
    }
    return <StarOutlineIcon style={{ color: "rgba(255, 255, 255, 0.7)" }} onClick={changeFavorited} />;
};

export default Favorited;
