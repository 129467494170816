import { atom } from "jotai";

export type InvitedUserType = {
    id: string;
    name: string;
    image: string;
    error?: string;
};

export const invitedUsersAtom = atom<Array<InvitedUserType>>([]);
export const groupSizeAtom = atom<Number>(0);
export const loadingInvitationAtom = atom<boolean>(false);
export const inviteAcceptedAtom = atom<boolean>(false);
