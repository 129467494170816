import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useEffect, useState } from "react";

import { useLocation, useParams } from "react-router-dom";
import { profileProvider } from "services/profileProvider";
import { locationFriendlyAddr } from "utils/functions";
import { SEOHelmet } from "./SEOHelmet";

export default function SEOMiddleWare({ children, route }) {
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const { vanityUrl } = useParams();
    const { totalResults } = usePaginatedContext();
    const [documentMeta, setDocumentMeta] = useState({
        title: "",
        description: "",
    });

    const getPlayerMetadata = async () => {
        const currentProfile =
            route === "personal"
                ? await profileProvider.getPersonalProfile()
                : (await profileProvider.getProfileByVanityURLAsync(vanityUrl)).data;

        const {
            gamesPlayed,
            name: [playerName],
        } = currentProfile;

        const filteredGames = gamesPlayed?.filter((name) => name !== "I'll play anything!") || [];

        const [firstGame] = filteredGames || [];
        const quantityOfGames = filteredGames?.length || 0;

        const description =
            quantityOfGames > 0
                ? `Play ${firstGame} and ${quantityOfGames} other games with ${playerName} by connecting with them on RPGMatch.`
                : `Play Tabletop RPG games and join adventures with ${playerName} by connecting with them on RPGMatch.`;
        return {
            description,
            title: `${playerName} | RPGMatch`,
        };
    };

    async function updateMeta() {
        try {
            let metaObject;
            if (route === "explore") {
                metaObject = {
                    title: "Explore |  New TTRPG Content from Top Publishers & Influencers | RPGMatch",
                    description:
                        "Level up your imagination! Stay inspired & informed with the latest happenings in the TTRPG world.",
                };
            } else if (route === "location") {
                const address = location.pathname.replace("/location", "");
                const friendlyAddr = locationFriendlyAddr(address);
                const qtyUsersFound = totalResults;
                const playerWord = qtyUsersFound > 1 ? "players" : "player";
                const title = `TTRPG Players in ${friendlyAddr}`;
                const description = `RPGMatch has ${totalResults} ${playerWord} in ${friendlyAddr} looking to play with other folks. Games people are looking to play include Dungeons & Dragons, Pathfinder, Call of Cthulhu, ALIEN: The Roleplaying Game, and many more.`;

                metaObject = { title, description };
            } else if (route === "groups") {
                metaObject = {
                    title: "Find your Perfect TTRPG Group | RPGMatch",
                    description:
                        "The Best Place to find your Perfect TTRPG Group. Connect with players who share your interests and discover your ideal gaming community today!",
                };
            } else {
                const { title, description } = await getPlayerMetadata();
                metaObject = {
                    title,
                    description,
                };
            }

            if (metaObject) {
                setDocumentMeta(metaObject);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        updateMeta();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vanityUrl, totalResults]);

    return (
        <ShouldRender
            disableErrorBoundary
            returnNull
            loaderClass="seo-loader"
            loading={loading}
            alternativeComponent={children}>
            <>
                <SEOHelmet {...documentMeta} />
                {children}
            </>
        </ShouldRender>
    );
}
