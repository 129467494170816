import React, { useEffect, useState } from "react";
import "./ProfileInterview.css";
import {
    Select,
    Checkbox,
    InputLabel,
    FormControl,
    OutlinedInput,
    Box,
    Chip,
    MenuItem,
    ListItemText,
    TextField,
} from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

export const ProfileVTTQuestion = ({
    state,
    handleEditProfile,
    pushCounter,
    isEditing = false,
    changeEditState = () => {},
    editState,
    loading: loadingFiltersFields,
    filterFields,
}) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [otherVTT, setOtherVTT] = useState("");
    const [disabledValue, setDisabledValue] = useState(false);
    const [option, setOption] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (state.vtt) {
            setDisabledValue(false);
            const otherVTTValue = state.vtt.filter((vtt) => !filterFields?.vtt?.map((item) => item.name).includes(vtt));
            const validOptions = state.vtt.filter((vtt) => filterFields?.vtt?.map((item) => item.name).includes(vtt));
            if (otherVTTValue[0] === "Other") {
                setSelectedOptions([...validOptions, "Other"]);
                setOtherVTT(otherVTTValue[1]);
                setOption(true);
            } else {
                setSelectedOptions(validOptions);
            }
        }
    }, [state]);

    const handleVTTChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOptions(value);

        if (isEditing) {
            if (!editState.hasOwnProperty("otherVTT") && !!otherVTT) {
                changeEditState({ vtt: value, otherVTT });
            } else {
                changeEditState({ vtt: value });
            }
        }
    };

    const changeOtherVTT = (event) => {
        if (event.target.value !== otherVTT) {
            Able();
        }
        setOtherVTT(event.target.value);

        if (isEditing) {
            changeEditState({ vtt: selectedOptions, otherVTT: event.target.value });
        }
    };

    const handleSaveValue = async (e) => {
        const vttValues = selectedOptions;
        if (otherVTT) {
            vttValues.push(otherVTT);
            setSelectedOptions(vttValues);
        }

        setIsLoading(true);
        await handleEditProfile({ vtt: vttValues });
        setIsLoading(false);
    };

    const OptioOn = () => setOption(!option);
    const Able = () => setDisabledValue(false);

    if (loadingFiltersFields) return null;

    return (
        <View data-testid="profile-vtt-question" className={`form-container ${isEditing && "container-vtt-question"}`}>
            {!isEditing && <Text as="h2">Which gaming platform do you use?</Text>}
            <View className={`button-container ${isEditing && "button-container-isEditing"}`}></View>
            <FormControl className={isEditing ? "vtt-form-control-edit-mode" : "vtt-form-control-survey-mode"}>
                <InputLabel id="VTT-label">Select</InputLabel>
                <Select
                    className="filter-input"
                    labelId="VTT-label"
                    label="VTT Range"
                    id="VTT"
                    multiple
                    value={selectedOptions}
                    onChange={handleVTTChange}
                    input={<OutlinedInput label="Tag" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                    style={{ minWidth: "210px" }}>
                    {filterFields?.vtt?.map((name) => (
                        <MenuItem key={name.id} value={name.name} onClick={Able}>
                            <Checkbox checked={selectedOptions.indexOf(name.name) > -1} />
                            <ListItemText primary={name.name} />
                        </MenuItem>
                    ))}
                    <MenuItem key={"Other"} value={"Other"} onClick={Able}>
                        <Box
                            onClick={() => setOption(OptioOn)}
                            style={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <Checkbox checked={selectedOptions.indexOf("Other") > -1} />
                            <ListItemText primary="Other" />
                        </Box>
                    </MenuItem>
                </Select>
            </FormControl>
            <TextField
                id="standard-basic"
                className={`${isEditing && "other-textField"}`}
                label="Other"
                variant="standard"
                disabled={!option}
                onChange={changeOtherVTT}
                value={otherVTT}
                style={{ marginTop: "12px", width: isEditing ? "100%" : "210px" }}
            />
            {!isEditing && (
                <View className="button-container games-played-submit">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={handleSaveValue}
                    />
                </View>
            )}
        </View>
    );
};
export default ProfileVTTQuestion;
