import { useGlobalContext } from "context/AppContext";
import { useEffect, useState } from "react";
import { profileProvider } from "services/profileProvider";
import { isNil } from "lodash";
import { checkCompletedProfile } from "utils/checkProfile";
import { useNavigate } from "react-router-dom";

export const useProfileCheck = () => {
    const {
        personalProfile,
        privateProfileId,
        updatePrivateProfileId,
        updateUserCompletedProfile,
        userCompletedProfile,
        isLoggedIn,
    } = useGlobalContext();
    const navigate = useNavigate();
    const [userHasProfile, setUserHasProfile] = useState(false);

    const checkProfile = async () => {
        const lastSurveyRequiredQuestion = "safetyTools";

        if (personalProfile) {
            setUserHasProfile(!!personalProfile?.[lastSurveyRequiredQuestion]);
        } else {
            const personalProfileData = await profileProvider.getPersonalProfile();
            setUserHasProfile(!!personalProfileData?.[lastSurveyRequiredQuestion]);
        }
    };

    useEffect(() => {
        (async () => {
            if (isLoggedIn) {
                await checkProfile();
            }
        })();
    }, [isLoggedIn, personalProfile]);

    useEffect(() => {
        (async () => {
            if (privateProfileId) {
                return;
            }
            if (personalProfile) {
                if (isNil(userCompletedProfile)) {
                    const profileCompletenessCheck = checkCompletedProfile(personalProfile);
                    updateUserCompletedProfile(profileCompletenessCheck);

                    /**
                     * Changing redirect if  profileCompletenessCheck is false here instead of profile route
                     */
                    if (!profileCompletenessCheck) {
                        navigate("/createprofile/0");
                    }
                }
            }
        })();
    }, [personalProfile, privateProfileId, updatePrivateProfileId, updateUserCompletedProfile, userCompletedProfile]);

    return {
        userHasProfile,
    };
};
