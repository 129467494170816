import React, { useState } from "react";
import { InputLabel, Select, FormControl, MenuItem, TextField } from "@mui/material";
import View from "@mui/material/Box";
import { generateProfileImage } from "utils/functions";

/*
This form can be used to update the profile gender
*/

const genderOptions = [
    { value: "female", label: "Female" },
    { value: "male", label: "Male" },
    { value: "other", label: "Other" },
    { value: "decline", label: "Decline" },
];

export const ProfileGenderSelect = ({ state, changeEditState }) => {
    const hasState = () => {
        if (!state?.gender) {
            return "";
        }

        if (genderOptions.findIndex((option) => option.value === String(state?.gender)) === -1) {
            return "other";
        }

        return state?.gender;
    };

    const getTextValue = () => {
        if (state?.gender && genderOptions.findIndex((option) => option.value === String(state?.gender)) === -1) {
            return String(state?.gender);
        }
        return "";
    };

    const [selectedValue, setSelectedValue] = useState(hasState);
    const [textValue, setTextValue] = useState(getTextValue);

    const onSelectChange = (e) => {
        setSelectedValue(e.target.value);
        changeEditState({ gender: e.target.value, avatar_id: generateProfileImage({ gender: e.target.value }) });
        setTextValue("");
    };

    const onTextChange = (e) => {
        setTextValue(e.target.value);
        changeEditState({ gender: e.target.value });
    };

    return (
        <>
            <View className="form-container" data-testid="profile-gender-select">
                <FormControl fullWidth>
                    <InputLabel id="gender-picker">Gender</InputLabel>
                    <Select
                        labelId="gender-picker"
                        id="gender-picker"
                        value={selectedValue}
                        label="Gender"
                        inputProps={{ "data-testid": "gender-picker-input" }}
                        onChange={onSelectChange}>
                        {genderOptions.map((option, index) => (
                            <MenuItem key={`gender-option-${index}`} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </View>
            <View className="form-container">
                <TextField
                    fullWidth
                    value={textValue}
                    onChange={onTextChange}
                    label="Other"
                    variant="standard"
                    disabled={selectedValue !== "other"}
                />
            </View>
        </>
    );
};
export default ProfileGenderSelect;
