import React from "react";
import { Button } from "@mui/material";
import "./EditComponent.css";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

const SaveCancelBtn = ({ onSave, onCancel, isLoadingSave, editState }) => {
    const handleSaveProfile = () => {
        onSave();
    };
    const handleCancelEdit = () => {
        onCancel();
    };

    return (
        <div data-testid="save-cancel-btn" className=" edit-btn-save-container">
            <LoadingBtn
                className="edit-btn-save"
                label="SAVE"
                loading={isLoadingSave}
                disabled={Object.keys(editState).length === 0}
                handleOnClick={handleSaveProfile}
            />
            <Button className="edit-btn-cancel" variant="contained" onClick={handleCancelEdit}>
                CANCEL
            </Button>
        </div>
    );
};

export default SaveCancelBtn;
