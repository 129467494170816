import React, { useMemo } from "react";
import "./InboxList.scss";
import { Container } from "react-bootstrap";
import { useMessagesContext } from "@context/MessagesContext";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import { Loader } from "@aws-amplify/ui-react";
import { useGlobalContext } from "@context/AppContext";
import { InboxMessageDataModel } from "models/inbox/InboxMessageDataModel";
import { completeUserData, isFilled } from "@utils/functions";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { InboxItem } from "../InboxItem/InboxItem";
import { InboxNewMessageButton } from "../InboxNewMessageButton/InboxNewMessageButton";
import { InboxSkeleton } from "../InboxSkeleton/InboxSkeleton";
import { NoMessage } from "../NoMessage/NoMessage";

export const InboxList = () => {
    const { personalProfile, isDesktop } = useGlobalContext();
    const { isBlockedUserList, scrollTarget, pageNumber, publicProfiles, blockedProfiles } = useMessagesContext();
    const { onTargetScroll, loadingInboxMessages, inboxMessages, clickHandler, loadingInboxBlockedMessages } =
        useMessages();

    const mapMessages = () => {
        if (
            (!isBlockedUserList && (publicProfiles?.length <= 0 || !inboxMessages)) ||
            (isBlockedUserList && blockedProfiles?.length <= 0)
        ) {
            return null;
        }

        if (isBlockedUserList) {
            return blockedProfiles.map((profile, index) => {
                const data: InboxMessageDataModel = profile ? completeUserData(profile) : {};

                return <InboxItem key={`inbox-item-${index}`} profileData={data} />;
            });
        }

        return inboxMessages.map((message, index) => {
            const publicProfile = message.from_user;
            const data: InboxMessageDataModel = publicProfile ? completeUserData(publicProfile) : {};
            return <InboxItem key={`inbox-item-${index}`} profileData={data} messageData={message} />;
        });
    };

    const renderNoMessages = useMemo(() => {
        const isLoadingInbox =
            !isBlockedUserList && loadingInboxMessages && pageNumber === 1 && !isFilled(inboxMessages);
        const isLoadingBlockedInbox = isBlockedUserList && loadingInboxBlockedMessages && !isFilled(blockedProfiles);

        const showSkeleton = !personalProfile || isLoadingInbox || isLoadingBlockedInbox;

        const condition =
            (isBlockedUserList && !isFilled(blockedProfiles)) || (!isBlockedUserList && !isFilled(inboxMessages));

        return (
            <ShouldRender
                returnNull
                disableErrorBoundary
                condition={condition}
                loading={showSkeleton}
                alternativeLoadingComponent={<InboxSkeleton />}>
                <NoMessage onClick={clickHandler} />
            </ShouldRender>
        );
    }, [
        blockedProfiles,
        clickHandler,
        inboxMessages,
        isBlockedUserList,
        loadingInboxBlockedMessages,
        loadingInboxMessages,
        pageNumber,
        personalProfile,
    ]);

    return (
        <Container fluid className="inbox-list-container p-0 position-relative d-flex h-100 overflow-hidden">
            <Container
                className="overflow-auto d-flex flex-column p-0"
                fluid
                id="scrollable-inbox-list"
                ref={scrollTarget}
                onScroll={onTargetScroll}>
                {renderNoMessages}
                {mapMessages()}
            </Container>

            {loadingInboxMessages && pageNumber > 1 && (
                <Container fluid className="scroll-loader justify-content-center position-absolute d-flex p-0">
                    <Loader width={"3rem"} height={"3rem"} filledColor={"white"} emptyColor={"red"} />
                </Container>
            )}

            {!isDesktop && <InboxNewMessageButton />}
        </Container>
    );
};
