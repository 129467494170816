import React, { useEffect, useMemo, useState } from "react";
import { Text, View } from "@aws-amplify/ui-react";
import "./FollowMe.css";
import { profileSocialMedia } from "@utils/constants";
import formatUsername from "utils/formatUsername.ts";
import { Chip, Tooltip } from "@mui/material";
import { PopupAddFollowMe } from "./PopupAddFollowMe";
import WarningIcon from "@mui/icons-material/Warning";

const FollowMe = ({ state, isEditing, changeEditState }) => {
    const [open, setOpen] = useState(false);
    const [popupInfo, setPopupInfo] = useState({});
    const [savedUsernames, setSavedUsernames] = useState({});
    const [numberOfSocialMedias, setNumberOfSocialMedias] = useState(0);
    const [hasError, setHasError] = useState({ check: false, message: "" });

    const getUsernames = () => {
        let counter = 0;
        const usernamesObj = {};

        profileSocialMedia.forEach((socialMedia) => {
            const usernameReceivedBackend = state?.profile?.[socialMedia.key]?.[0];

            if (usernameReceivedBackend) {
                counter += 1;
                usernamesObj[socialMedia.key] = state?.profile?.[socialMedia.key]?.[0];
            }
        });
        setNumberOfSocialMedias(counter);
        setSavedUsernames({ ...usernamesObj });
    };

    useEffect(() => {
        getUsernames();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditing]);

    const showComplete = useMemo(() => {
        return isEditing && profileSocialMedia.slice(0, -1).every((profile) => !state?.profile?.[profile.key]);
    }, [isEditing, state?.profile]);

    if (numberOfSocialMedias <= 0 && !isEditing) {
        return null;
    }

    const handleClickChip = (item, userTag = "") => {
        setHasError({
            check: false,
            message: "",
        });
        if (!isEditing) {
            window.open(item?.url + formatUsername(userTag, item), "_blank");
        } else {
            setPopupInfo(item);
            setOpen(true);
        }
    };

    return (
        <View
            id="follow-me"
            data-testid="follow-me"
            className={`profile-component  ${showComplete ? "not-completed" : ""}`}>
            <Text className="followMe-head-text">
                <span>Follow Me </span> {showComplete && <WarningIcon sx={{ color: "#FFB74D" }} />}
            </Text>
            <View className="socialMedia-container">
                {profileSocialMedia.map((item) => {
                    const userTag = savedUsernames?.[item.key] || "";
                    const tagTooBig = userTag?.length > 25;
                    const formatItemTag = (title) => {
                        if (title.length > 25) {
                            return title.substring(0, 25) + "...";
                        }
                    };
                    if (!isEditing) {
                        if (!userTag) {
                            return null;
                        }

                        if (tagTooBig) {
                            return (
                                <Tooltip title={userTag}>
                                    <Chip
                                        onClick={() => handleClickChip(item, userTag)}
                                        className="social-container"
                                        key={item.title}
                                        icon={item.icon}
                                        label={formatItemTag(userTag)}
                                    />
                                </Tooltip>
                            );
                        }

                        return (
                            <Chip
                                onClick={() => handleClickChip(item, userTag)}
                                className="social-container"
                                key={item.title}
                                icon={item.icon}
                                label={`${userTag}`}
                            />
                        );
                    } else {
                        if (item.key === "startplaying") {
                            return null;
                        }
                        return (
                            <Chip
                                onClick={() => handleClickChip(item, userTag)}
                                className="social-container"
                                key={item.title}
                                icon={item.icon}
                                label={userTag ? `${userTag}` : `Add ${item.title}`}
                            />
                        );
                    }
                })}
            </View>
            <PopupAddFollowMe
                open={open}
                setOpen={setOpen}
                savedUsernames={savedUsernames}
                setSavedUsernames={setSavedUsernames}
                popupInfo={popupInfo}
                hasError={hasError}
                setHasError={setHasError}
                changeEditState={changeEditState}
            />
        </View>
    );
};

export default FollowMe;
