import React, { useContext, useState } from "react";
import "../Cards/Cards.css";
import {
    Container,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Box,
    TextField,
    FormControl,
    InputLabel,
} from "@mui/material";
import { Loader, Text } from "@aws-amplify/ui-react";
import { Link, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import AuthGenericMessages from "components/AuthGenericMessages";
import Button from "components/Button";
import { emailRegex } from "utils/regexPatterns";
import { Close } from "@mui/icons-material";
import { LoginContext } from "context/LoginContext";

const CardLogIn = ({
    title = "Log In",
    buttonText = "LOG IN",
    forgotPasswordLink = "/password-recovery",
    joinRPGMatchLink = "/signup",
    onClickLogin = () => {},
    onLogIn,
    loading,
    message,
    cleanMessage,
    cleanIcon = false,
    closePopup = () => {},
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [searchParams] = useSearchParams();
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    const { setLoginPopup } = useContext(LoginContext);

    return (
        <Container className="card login">
            <Text className="card_title">
                {title}
                {cleanIcon && <Close className="clean-icon" width={20} height={20} onClick={() => closePopup()} />}
            </Text>
            <AuthGenericMessages onError={errors.email || errors.password}>{message}</AuthGenericMessages>
            <Box component="form" onSubmit={handleSubmit(onLogIn)} className="card-login-form">
                <TextField
                    variant="outlined"
                    type="email"
                    label="Email*"
                    {...register("email", {
                        required: "Required",
                        pattern: {
                            value: emailRegex,
                            message: "Please enter a valid email address.",
                        },
                    })}
                    disabled={loading}
                    onFocus={() => cleanMessage()}
                />
                {errors.email && <Text className="error-msg red-msg">{errors.email.message}</Text>}

                <FormControl variant="outlined">
                    <InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
                    <OutlinedInput
                        {...register("password", {
                            required: "Required",
                        })}
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePasswordVisibility}
                                    onMouseDown={handleTogglePasswordVisibility}
                                    edge="end">
                                    {showPassword ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password*"
                        disabled={loading}
                        onFocus={() => cleanMessage()}
                    />
                </FormControl>
                {errors.password && <Text className={`error-msg ${errors.password ? "red-msg" : ""}`}>Required</Text>}

                <Text className="card_text">
                    <Link
                        onClick={cleanMessage}
                        to={forgotPasswordLink}
                        style={{ color: "#4da3cb", float: "right", fontSize: "13px", textDecoration: "underline" }}>
                        Forgot Password?
                    </Link>
                </Text>

                <Button disabled={loading} type="submit" onClick={onClickLogin} className="card-login-submit-button">
                    {loading && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px 0",
                            }}>
                            <Loader width={"6rem"} height={"6rem"} filledColor={"white"} emptyColor={"red"} />
                        </div>
                    )}
                    {!loading && buttonText}
                </Button>
            </Box>
            <Text className="card_text">
                {"Are you a new adventurer? "}
                <Link
                    onClick={() => {
                        cleanMessage();
                        setLoginPopup();
                    }}
                    to={joinRPGMatchLink + `?${searchParams}`}
                    style={{ color: "#4da3cb" }}>
                    Join RPGMatch
                </Link>
            </Text>
        </Container>
    );
};

export default CardLogIn;
