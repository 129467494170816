import "./NoMessages.scss";
import React from "react";
import { Text, View } from "@aws-amplify/ui-react";

type NoMessagesProps = {
    center?: boolean;
    message: string;
};

export const NoMessages = ({ center, message }: NoMessagesProps) => {
    return (
        <View data-testid="no-messages" className={`no-messages ${center ? "no-messages-center" : ""}`}>
            <Text>{message}</Text>
        </View>
    );
};
