import { MessageModel } from "models/inbox/MessageModel";
import { api } from "./api";
import { endpoints } from "./endpoints";

// This function sends a notification to the destination user when a new message is sent.
// The notification is sent only if the message is unread.
// The notification is sent by Mailchimp.
// The function is called when a message is sent, and it is passed as a parameter the current message.
type SendNotificationProps = {
    messages: Array<MessageModel>;
    publicID: string;
    privateName: string;
    currentMessage: string;
};
// imagem do usuario que nao esta na lista nao aparece
async function sendNotification({ messages, publicID, privateName, currentMessage }: SendNotificationProps) {
    let messageIdToNotify = undefined;

    const messageToNotify = messages
        .filter((message) => message.read === "False" && message.body === currentMessage)
        .map((message) => ({ id: message.id, threadId: message.threadID, date: message.date, body: message.body }));

    messageIdToNotify = messageToNotify[messageToNotify.length - 1];

    // Send notification to the destination user
    const url = window.location.origin;
    const substringUrl = `${url}/messages`;

    const notificationBody = {
        to: publicID,
        messageId: messageIdToNotify?.id || "",
        senderName: privateName,
        messageLink: `${substringUrl}/${messageIdToNotify?.threadId}`,
        messageDate: messageIdToNotify.date,
        messageBody: messageIdToNotify.body,
    };
    return api.post(endpoints.mailchimp, notificationBody);
}

const mailProvider = {
    sendNotification,
};

export { mailProvider };
