import React, { useState, useEffect } from "react";
import { Radio, RadioGroup, TextField } from "@mui/material";
import View from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { generateProfileImage } from "../../utils/functions";

/*
This form can be used to update the profile age
*/

export const ProfileGender = ({ state, handleEditProfile, pushCounter }) => {
    const [isLoading, setIsLoading] = useState(false);
    const radioVals = ["female", "male", "decline"];

    const hasOther = () => {
        if (state.gender) {
            if (!radioVals.includes(state.gender)) {
                return true;
            }
        } else {
            setDisabledValue(true);
            return false;
        }
    };

    const hasOtherText = () => {
        if (!radioVals.includes(state.gender)) {
            return state.gender;
        } else {
            return "";
        }
    };

    const [textValue, setTextValue] = useState(hasOtherText);
    const [disabledValue, setDisabledValue] = useState(false);
    // tracks state of "other" input field value and updates final value to be sent accordingly
    const [finalValue, setFinalValue] = useState(state.gender);
    // boolean tracking whether or not to display "other" text field
    const [otherTicked, setOtherTicked] = useState(hasOther);

    const onChange = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setFinalValue(e.target.value);

        if (e.target.value !== "other") {
            setDisabledValue(false);
            setOtherTicked(false);
            setTextValue("");
        } else {
            setOtherTicked(true);
            setDisabledValue(true);
        }
    };

    const onTextChange = (e) => {
        setTextValue(e.target.value);

        if (e.target.value.length === 0) {
            setDisabledValue(true);
        } else {
            setDisabledValue(false);
        }
    };

    //change other radio button
    const [other, setOther] = useState(false);

    useEffect(() => {
        if (Array.isArray(state.gender)) {
            setDisabledValue(false);

            const value = state.gender.join("");
            const clickedState = localStorage.getItem("clickedState");

            if (value !== "female" && value !== "male" && value !== "decline") {
                setOther(clickedState);
            }

            setFinalValue(value);
        }
        const clickedState = localStorage.getItem("clickedState");
        if (clickedState) {
            setOther(JSON.parse(clickedState));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ChangeOther = () => {
        setOther(true);
        localStorage.setItem("clickedState", JSON.stringify(true));
    };

    const ChangeOtherClick = () => {
        setOther(false);
        localStorage.removeItem("clickedState");
    };

    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        let request;
        if (otherTicked) {
            request = { gender: String(textValue) };
        } else {
            request = { gender: String(finalValue) };
        }

        request.avatar_id = generateProfileImage({ gender: request.gender });

        setIsLoading(true);
        await handleEditProfile(request);
        setIsLoading(false);
    };

    return (
        <View className="form-container">
            <Text as="h2">Do you want to share your gender?</Text>
            <View className="input-container">
                <FormControl>
                    <RadioGroup
                        aria-labelledby="gender-selector"
                        name="gender-selector"
                        onChange={onChange}
                        value={finalValue}
                        className={!otherTicked ? "gender-list gender-visible-field" : "gender-list"}>
                        <FormControlLabel
                            value="female"
                            control={<Radio checked={"female" === finalValue} onClick={ChangeOtherClick} />}
                            label="Female"
                            name="female"
                            key="female"
                        />
                        <FormControlLabel
                            value="male"
                            control={<Radio checked={"male" === finalValue} onClick={ChangeOtherClick} />}
                            label="Male"
                            name="male"
                            key="male"
                        />
                        <FormControlLabel
                            value="other"
                            control={
                                <Radio
                                    checked={
                                        finalValue !== "female" &&
                                        finalValue !== "male" &&
                                        finalValue !== "decline" &&
                                        finalValue
                                    }
                                    onClick={ChangeOther}
                                />
                            }
                            label="Other"
                            name="other"
                            key="other"
                        />
                    </RadioGroup>
                </FormControl>
            </View>
            <div className={!otherTicked ? "gender-hidden-field" : "gender-visible-field"}>
                {other && (
                    <View className="input-container">
                        <TextField
                            value={textValue}
                            onChange={onTextChange}
                            id="gender"
                            label="Other"
                            variant="standard"
                        />
                    </View>
                )}
            </div>

            <View className="button-container">
                <LoadingBtn
                    label="THAT'S IT"
                    loading={isLoading}
                    disabled={disabledValue}
                    handleOnClick={handleSaveValue}
                />
            </View>
        </View>
    );
};
export default ProfileGender;
