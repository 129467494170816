import "./GroupSchedule.scss";

import React, { useEffect, useState } from "react";

import { Dayjs } from "dayjs";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

import { weekDays } from "utils/constants";
import { parseSchedule } from "@utils/schedule";
import { ProfileModel } from "models/ProfileModels";

interface WeekdayInterface {
    id: string;
    day?: string;
    row?: number;
    label: string;
    column?: number;
}

type GroupScheduleManagerProps = {
    variant: "CREATE" | "EDIT";
    showErrors?: boolean;
    personalProfile?: Partial<ProfileModel>;
    groupScheduleEnd: string;
    groupScheduleStart: string;
    setGroupScheduleEnd: (value: string) => void;
    setGroupScheduleStart: (value: string) => void;
};

export default function GroupScheduleManager({
    variant,
    showErrors = false,
    personalProfile,
    groupScheduleEnd,
    groupScheduleStart,
    setGroupScheduleEnd,
    setGroupScheduleStart,
}: GroupScheduleManagerProps) {
    /* States and Hooks */
    const [endTime, setEndTime] = useState<Dayjs | null>(null);
    const [weekday, setWeekDay] = useState<WeekdayInterface | null>(null);
    const [startTime, setStartTime] = useState<Dayjs | null>(null);

    /* Lifecycle */

    // Handle the schedule mount when the variables are changed
    useEffect(() => {
        const gmt = personalProfile?.timezone?.[0]?.replace(":", "") || "00:00";

        // Update start time
        if (startTime?.hour() !== undefined && weekday?.label)
            setGroupScheduleStart(`${weekday.label} ${startTime.format("hh:mm A")} ${gmt}`);

        // Update end time
        if (endTime?.hour() !== undefined && weekday?.id) {
            setGroupScheduleEnd(`${weekday.label} ${endTime.format("hh:mm A")} ${gmt}`);
        }
    }, [startTime, weekday, endTime]);

    // Handle the first render and variables fulfillment
    useEffect(() => {
        const parsedScheduleStart = parseSchedule(groupScheduleStart);
        const parsedScheduleEnd = parseSchedule(groupScheduleEnd);

        if (parsedScheduleStart) {
            setWeekDay(weekDays.find((day) => day.label === parsedScheduleStart.weekday) || null);
            setStartTime(parsedScheduleStart.time);
        }

        if (parsedScheduleEnd) {
            setWeekDay(weekDays.find((day) => day.label === parsedScheduleEnd.weekday) || null);
            setEndTime(parsedScheduleEnd.time);
        }
    }, [groupScheduleStart, groupScheduleEnd]);

    /* Variables */
    const showErrorComponent = showErrors && !(startTime?.hour() && endTime?.hour() && weekday);

    const classes: Array<string> = [
        "profile-component",
        "group-schedule-container",
        showErrorComponent ? "error-group-schedule" : "",
    ];

    return (
        <Box className={classes.join(" ")}>
            <Typography variant="h3">Session schedule</Typography>
            <Box className="group-schedule-content">
                <Autocomplete
                    id="group-schedule-weekday"
                    value={weekday}
                    options={weekDays}
                    onChange={(_, value: WeekdayInterface | null) => setWeekDay(value)}
                    getOptionLabel={(weekday: WeekdayInterface) => weekday.label}
                    renderInput={(params) => (
                        <TextField {...params} label="Day" required placeholder="Choose Weekday" />
                    )}
                />
                <Box className="time-container">
                    <TimePicker
                        label="Start*"
                        value={startTime}
                        maxTime={endTime || undefined}
                        onChange={(newValue) => setStartTime(newValue)}
                        className="clock-modal"
                        shouldDisableTime={(time) => time.isSame(endTime)}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                    />
                    <TimePicker
                        label="End*"
                        value={endTime}
                        onChange={(newValue) => setEndTime(newValue)}
                        className="clock-modal"
                        shouldDisableTime={(time) => time.isSame(startTime)}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                    />
                </Box>
            </Box>
            {showErrorComponent && <Typography className="group-schedule-error-text">Invalid schedule</Typography>}
        </Box>
    );
}
