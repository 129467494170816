import { View } from "@aws-amplify/ui-react";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import ProfileLanguages from "components/ProfileInterview/ProfileLanguages";
import { useState } from "react";

export const Languages = ({
    state,
    isEditing,
    isCreatingProfile,
    changeEditState,
    handleEditProfile,
    pushCounter,
    goBack,
    counter,
    length,
    goNextButton,
}) => {
    const [disabledValue, setDisabledValue] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(state?.languages || state?.profile?.languages || [1]);
    const [isLoading, setIsLoading] = useState(false);

    async function handleSaveValue() {
        const updatedData = selectedOptions.map((x) => x?.id || x);

        setIsLoading(true);
        await handleEditProfile({ languages: updatedData });
        setIsLoading(false);
    }

    return (
        <CompleteProfileContainer rootContainerClass="question-languages">
            <View className="question-container">
                <ProfileLanguages
                    state={state}
                    isEditing={isEditing}
                    isCreatingProfile={isCreatingProfile}
                    changeEditState={changeEditState}
                    handleEditProfile={handleEditProfile}
                    disabledValue={disabledValue}
                    setDisabledValue={setDisabledValue}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    handleSaveValue={handleSaveValue}
                    isLoading={isLoading}
                />
            </View>
            <BackNextBtn
                pushCounter={pushCounter}
                goBack={goBack}
                counter={counter}
                length={length}
                nextBtn={goNextButton}
                pageState={!disabledValue && !isLoading}
                nextButtonOnClick={handleSaveValue}
            />
        </CompleteProfileContainer>
    );
};
