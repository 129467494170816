import { Authenticator, Text, View } from "@aws-amplify/ui-react";
import { Typography } from "@mui/material";
import { checkLoggedIn, paramsToObject } from "@utils/functions";
import { Auth, Hub, Logger } from "aws-amplify";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import "../../pages/Home/UserList.css";
import { useGlobalContext } from "context/AppContext";
import { checkCompletedProfile } from "utils/checkProfile";
import { profileProvider } from "services/profileProvider";
import Button from "components/Button";

const authenticatorComponents = {
    ConfirmResetPassword: {
        Header() {
            return (
                <>
                    <Typography variant="h3" sx={{ p: "1rem" }}>
                        Reset your password
                    </Typography>
                    <Typography>
                        We’ve sent an account confirmation email to your inbox. Please enter the code below.
                    </Typography>
                </>
            );
        },
    },
};

const IframeLogin = () => {
    const navigate = useNavigate();
    const navigateWindow = (url) => window.open(url);
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const { updatePersonalProfile, updateUserCompletedProfile, updatePrivateProfileId, updateIsLoggedIn, isLoggedIn } =
        useGlobalContext();

    const authenticatorServices = {
        async handleForgotPasswordSubmit(formData) {
            let { username, password, code } = formData;
            let forgotPass = await Auth.forgotPasswordSubmit(username, code, password);
            if (forgotPass === "SUCCESS") {
                await Auth.signIn(username, password)
                    .then(() => {
                        checkLoggedIn().then(() => {
                            navigateWindow("/profile");
                        });
                    })
                    .catch((e) => console.log("ERROR SIGN IN: ", e));
            }
        },
    };

    const initialState = location.pathname === "/signup" ? "signUp" : "login";

    const click = (e) => {
        if (location.pathname === "/login" && e.target.innerHTML === "Create Account") {
            navigate({ pathname: "/signup", search: `?${searchParams}` });
        } else if (location.pathname === "/signup" && e.target.innerHTML === "Sign In") {
            navigate({ pathname: "/login", search: `?${searchParams}` });
        }
    };

    useEffect(() => {
        window.addEventListener("click", click);

        return () => {
            window.removeEventListener("click", click);
        };
    });

    const logger = new Logger("Logger", "INFO");
    const listener = async (data) => {
        switch (data.payload.event) {
            case "signIn":
                logger.info("user signed in");
                const paramsList = Object.keys(paramsToObject(searchParams.entries()));
                const navigationState = {
                    pathname: "/profile",
                };
                if (paramsList.length > 0) {
                    navigationState.search = `?${searchParams}`;
                    navigationState.pathname = "/";
                }
                const isLoggedIn = await checkLoggedIn();
                if (!isLoggedIn) {
                    navigationState.pathname = "/login";
                }
                const personalProfileData = await profileProvider.getPersonalProfile();

                updateIsLoggedIn(isLoggedIn);
                const profileCompletenessCheck = checkCompletedProfile(personalProfileData);
                updateUserCompletedProfile(profileCompletenessCheck);
                updatePrivateProfileId(personalProfileData?.id);
                updatePersonalProfile(personalProfileData);

                navigateWindow("/profile");

                break;
            case "signUp":
                logger.info("user signed up");
                // creates a new user in the DynamoDB table
                //NOTE: this NUKE any existing content which is why we do it on user creation
                let email = data.payload.data.user.username;
                profileProvider.createProfile(email).catch(function (error) {
                    console.log("ERROR CREATING USER", error);
                });
                // we do an emaiil verification, so no need redirect
                // the Authenticator component below is going to be transformed into the code send/receive
                break;
            case "signOut":
                logger.info("user signed out");
                break;
            case "signIn_failure":
                logger.info("user sign in failed");
                break;
            case "configured":
                logger.info("the Auth module is configured");
                break;
            default:
                logger.error("Something went wrong, look at data object", data);
        }
    };

    useEffect(() => {
        Hub.listen("auth", listener);
        return () => Hub.remove("auth", listener);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderIfIsLoggedIn = () => {
        if (isLoggedIn) {
            return (
                <div
                    style={{
                        height: "400px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}>
                    <Text style={{ marginBottom: "20px" }}>
                        It looks like you are already connected. Visit RPGMatch clicking the button below.
                    </Text>
                    <Button width="150px" onClick={() => navigateWindow("/")}>
                        Go to RPGMatch
                    </Button>
                </div>
            );
        }
        return null;
    };
    return (
        <>
            <div className="login-overlay">
                <View className="login-box app-inner-gutters">
                    <Text className="login-welcome-text">
                        We’d love to connect you with the TTRPG group of your dreams. Register today to join our
                        community.
                    </Text>
                    {renderIfIsLoggedIn()}
                    <Authenticator
                        loginMechanisms={["email"]}
                        initialState={initialState}
                        services={authenticatorServices}
                        components={authenticatorComponents}></Authenticator>
                </View>
            </div>
        </>
    );
};

export default IframeLogin;
