import React, { useEffect } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import "./index.css";
import { BackButton } from "components/BackButton";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const AboutUsPage = ({ title }) => {
    useEffect(() => {
        document.title = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <View className="fullscreen-mobile">
            <View className="section-header section_header_about">
                <BackButton onClickBack={() => window.history.back()} />
                <Text as="h1">About Us</Text>
            </View>
            <View className="section-body">
                <Text as="h1" className="title_about">
                    Our values
                </Text>

                <Text>In support of our mission, RPGMatch operates under the following values:</Text>

                <ul className={"left-ul"}>
                    <li>
                        <strong>Safety First.</strong> Connecting with other people should always be safe.
                    </li>
                    <li>
                        <strong>Privacy Second.</strong> Part of staying safe is preserving our members' privacy and
                        control over who they connect with and when. Decisions made to share personal information are
                        individual choices.
                    </li>
                    <li>
                        <strong>Everyone deserves to play the way they want.</strong>
                        There is no one right way to play TTRPGs. There are many ways to play and ALL of them are right
                        for the right people!
                    </li>
                    <li>
                        <strong> Time is precious.</strong> Time is the one thing we don’t get more of, we’re here to
                        help you make the most out of it!
                    </li>
                </ul>

                <Text as="h1" className="title_about">
                    Our Story
                </Text>
                <Text>
                    The origin of RPGMatch was driven by a need that our founder, Joaquin Lippincott, felt was
                    unaddressed in the marketplace. After returning to playing TTRPGs in 2020 after a 15 year break, he
                    no longer had a collection of friends that he could play with. People had moved, schedules had
                    changed, and he had played with enough people over the years to know that a bad match can be a
                    painful process.
                </Text>
                <Text>
                    Looking around he found lots of resources on facilitating online play (Virtual Table Tops, Discord,
                    Zoom, etc) but nothing other than Reddit forums or Discord channels that shared little more than a
                    day/time with the hope someone might be available. But what about style? Safety tools? Roleplaying
                    vs. Combat? Forum posts left little opportunity to ask people about what they liked without it
                    feeling like a personal rejection.
                </Text>
                <Text>
                    With RPGMatch all members fill in an extended survey about the way THEY like to play, and the system
                    facilitates making connections.
                </Text>
                <Text as="h1" className="title_about">
                    Learn More
                </Text>
                <View className="buttons_about">
                    <Box className="button_about">
                        <Link to="/community-guidelines">Community Guideliness</Link>
                    </Box>
                    <Box className="button_about">
                        <Link to="/privacy">Privacy Policy</Link>
                    </Box>
                </View>
                <br />
            </View>
        </View>
    );
};

export default AboutUsPage;
