import { useGlobalContext } from "context/AppContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkCompletedProfile } from "utils/checkProfile";
import { profileProvider } from "services/profileProvider";
import MatchScoreLoader from "components/MatchScoreLoader/MatchScoreLoader";
import { storage } from "@services/sessionStorage";

export default function MatchScore() {
    const navigate = useNavigate();
    const { personalProfile, updateUserCompletedProfile, updatePersonalProfile } = useGlobalContext();

    const putMatchProjection = async () => {
        try {
            await profileProvider.putMatchProjection(personalProfile.thingsLikedTTRPG);
            const newPersonalProfile = await profileProvider.getPersonalProfile();
            updatePersonalProfile(newPersonalProfile);

            const profileCompletenessCheck = checkCompletedProfile(newPersonalProfile);
            updateUserCompletedProfile(profileCompletenessCheck);
        } catch (error) {
            console.log(error);
        } finally {
            // In case we have a survey redirect, then it should overwrite the normal redirection
            const surveyRedirect = storage.sessionStorage.surveyRedirect.use();
            const redirect = surveyRedirect || storage.sessionStorage.redirect.use();

            if (redirect) navigate(redirect);
            else navigate("/");
        }
    };

    useEffect(() => {
        (async () => {
            await putMatchProjection();

            // In case we have a survey redirect, then it should overwrite the normal redirection
            const surveyRedirect = storage.sessionStorage.surveyRedirect.use();
            const redirect = surveyRedirect || storage.sessionStorage.redirect.use();

            if (redirect) navigate(redirect);
            else navigate("/");
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <MatchScoreLoader />;
}
