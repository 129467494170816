import { useEffect } from "react";

const useAutosizeTextarea = (textAreaRef, value) => {
    useEffect(() => {
        if (textAreaRef) {
            textAreaRef.style.height = "0px";
            const scrollHeight = textAreaRef.scrollHeight;

            if (scrollHeight < 150) {
                textAreaRef.style.height = scrollHeight + "px";
            } else {
                textAreaRef.style.height = "150px";
                textAreaRef.style.overflowX = "scroll";
            }
        }
    }, [textAreaRef, value]);
};

export default useAutosizeTextarea;
