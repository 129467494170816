const AuthGenericMessages = ({ children, setMargin = "bottom", onError = false }) => {
    if (!children) return <></>;

    const style = setMargin === "top" ? { marginTop: "10px" } : { marginBottom: "10px" };

    return (
        <div className={`auth-generic-messages ${onError ? "on-error" : ""}`} style={style}>
            {children}
        </div>
    );
};

export default AuthGenericMessages;
