import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileName from "@components/ProfileInterview/ProfileName";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const NameComponent = ({ state, handleEditProfile, pushCounter, goBack, counter, length, goNextButton }) => {
    return (
        <CompleteProfileContainer rootContainerClass="question-name">
            <View className="top-text"></View>
            <View className="question-container">
                <ProfileName state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
            </View>
            <BackNextBtn
                pushCounter={pushCounter}
                goBack={goBack}
                counter={counter}
                length={length}
                nextBtn={goNextButton}
                pageState={state.name}
            />
        </CompleteProfileContainer>
    );
};
