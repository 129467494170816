import MuiButton from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const Button = styled(MuiButton)(({ width, disabled, backgroundColor, labelColor }) => ({
    backgroundColor: `${switchBgColor(backgroundColor, disabled)}`,
    color: `${disabled ? "#5b5b5b" : labelColor || "#ffff"}`,
    weight: "500",
    width: width || "100%",
    border: `${backgroundColor === "black" ? "1px solid #9E9E9E" : "none"}`,
    transition: "0.5s",

    fontFamily: "'Open Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "1rem",
    lineHeight: "1.625rem",
    letterSpacing: "0.029rem",
    textTransform: "uppercase",

    "&:hover": {
        backgroundColor: `${switchBgColor(backgroundColor)}`,
        opacity: "0.8",
        transition: "0.5s",
    },
    " && .MuiTouchRipple-child": {
        backgroundColor: "black",
    },
}));

export default Button;

function switchBgColor(backgroundColor, disabled) {
    if (disabled) {
        return "#2c2c2c";
    } else {
        switch (backgroundColor) {
            case "red":
                return "#A61F38";
            case "black":
                return "#242424";
            case undefined:
                return "#A61F38";
            default:
                return backgroundColor;
        }
    }
}
