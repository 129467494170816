import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileLGBTQ from "@components/ProfileInterview/ProfileLGBTQ";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const LGBTQComponent = ({ state, handleEditProfile, pushCounter, goBack, skipButton, counter, length }) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-lgbtq">
                <View className="top-text"></View>
                <View className="question-container">
                    <ProfileLGBTQ state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
                </View>
                <BackNextBtn
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={skipButton}
                    pageState={state.LGBTQ}
                />
            </CompleteProfileContainer>
        </>
    );
};
