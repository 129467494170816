import "./GroupChatLink.scss";

import React from "react";

import { Typography, Box, TextField } from "@mui/material";
import { linkRegexp } from "utils/constants";

type GroupChatLinkManagerProps = {
    link: string;
    setLink: (link: string) => void;
    showErrors: boolean;
};

export default function GroupChatLinkManager({ link, setLink, showErrors }: GroupChatLinkManagerProps) {
    return (
        <Box component="section" className="profile-component group-chat-link-container">
            <Typography variant="h3">Group chat</Typography>
            <TextField
                label="Group Chat Link"
                value={link}
                error={!linkRegexp.test(link) && !!link}
                required={false}
                onChange={(e) => setLink(e.target.value)}
                className="group-chat-link-input"
                helperText={!linkRegexp.test(link) && !!link ? "Invalid link" : ""}
                placeholder="Paste your Discord or any other VoIP link here"
            />
        </Box>
    );
}
