import { Tooltip } from "@mui/material";
import { awsFileURL } from "utils/functions";

export default function StartPlayingIcon({ hasTooltip = true }) {
    return (
        <Tooltip
            placement="top-start"
            title={
                hasTooltip
                    ? "This user proudly carries the title of Professional Game Master, endorsed by the esteemed Startplaying community."
                    : null
            }>
            <img
                alt="StartPlaying Icon"
                data-testid="start-playing-icon"
                src={`${awsFileURL()}/badges/startplaying.svg`}
            />
        </Tooltip>
    );
}
