import React, { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const CompleteComponent = ({ handleSaveSurvey }) => {
    return (
        <>
            <CompleteProfileContainer
                rootContainerClass="question-done"
                className="backgroundImageCompleteProfile"
                style={{ backgroundImage: "url('/darkHill.png')", backgroundSize: "cover" }}>
                <View className="question-container">
                    <Text as="h1">Yay, you're all done!</Text>
                    <Button
                        variant="contained"
                        onClick={() => {
                            // navigate("/matchscore");
                            handleSaveSurvey();
                        }}>
                        GO TO USER LIST
                    </Button>
                </View>
            </CompleteProfileContainer>
        </>
    );
};
