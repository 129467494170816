import React from "react";
import { Typography } from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useNavigate } from "react-router-dom";
import "./index.css";
import getGameURL from "utils/getGameURL";

const useFormatter = () => {
    const { isMobile } = useBreakpoint();

    const format = (value = {}) => {
        let _text = value.text;
        let _title = value.title;

        if (!isMobile) {
            _title = _text;
        }

        if (_text.startsWith("(GMT")) {
            const chr = _text.indexOf(")");
            _text = _text.substring(0, chr + 1);
        }

        if (isMobile) {
            if (_text.endsWith("other game") || _text.endsWith("other games")) {
                _text = _text.replace("other games", "").replace("other game", "");
            }
        }

        return {
            text: _text,
            title: _title,
        };
    };
    return {
        format,
    };
};

const getLocationsFilterURLsList = (locationsStr) => {
    const locationsArr = locationsStr.split(", ");
    const filterByCountry = {};
    const filterByState = {};
    const filterByCity = {};

    switch (locationsArr.length) {
        case 1:
            filterByCountry.text = locationsArr[0];
            filterByCountry.link = `?country=${locationsArr[0]}`;

            return [filterByCountry];
        case 2:
            filterByCountry.text = `${locationsArr[1]}`;
            filterByCountry.link = `?country=${locationsArr[1]}`;

            filterByState.text = locationsArr[0];
            filterByState.link = `?country=${locationsArr[1]}&state=${locationsArr[0]}`;

            return [filterByCountry, filterByState];
        case 3:
            filterByCountry.text = `${locationsArr[2]}`;
            filterByCountry.link = `?country=${locationsArr[2]}`;

            filterByState.text = `${locationsArr[1]}`;
            filterByState.link = `?country=${locationsArr[2]}&state=${locationsArr[1]}`;

            filterByCity.text = locationsArr[0];
            filterByCity.link = `?country=${locationsArr[2]}&state=${locationsArr[1]}&city=${locationsArr[0]}`;

            return [filterByCountry, filterByState, filterByCity];
        default:
            return false;
    }
};

const getGamesURLsList = (gamesStr) => {
    const gamesArr = gamesStr.split(" + ");

    const getGameObject = (gamesArr) => {
        if (gamesArr[0] === "I'll play anything!" || gamesArr[0] === "I'll play anything") {
            return {
                text: "I'll play anything!",
                link: "",
            };
        } else if (gamesArr[0] === "-") {
            return {
                text: "No games selected yet",
                link: "",
            };
        } else {
            return {
                text: gamesArr[0],
                link: getGameURL(gamesArr[0]),
            };
        }
    };
    const game = getGameObject(gamesArr);

    switch (gamesArr.length) {
        case 1:
            return [game];
        case 2:
            const otherGames = {
                text: gamesArr[1],
                link: "",
            };
            return [game, otherGames];
        default:
            return false;
    }
};

const renderCommasOrPlus = (list, index, isGames = false) => {
    const isOdd = index % 2 !== 0;
    const isZero = index === 0;
    const isNotTheLastItem = list.length - 1 !== index;

    if ((isOdd || isZero) && isNotTheLastItem) {
        if (isGames) {
            return <span className="gapPlus">+</span>;
        } else {
            return <span>, </span>;
        }
    }
};

function UserListIconText({ item, id }) {
    const { format } = useFormatter();
    const navigate = useNavigate();

    const isTest = process.env.NODE_ENV === "test";

    const handleNavigate = (e, link) => {
        e.preventDefault();
        navigate(link);
        window.location.reload();
    };
    return (
        <React.Fragment>
            {item
                .filter((item) => !!item.text)
                .map((item, index) => {
                    const { text, title } = format(item);
                    const isLocationText = index === 0;
                    const isGames = index === 2;

                    if (isLocationText) {
                        const locationsList = getLocationsFilterURLsList(text);
                        return locationsList ? (
                            <React.Fragment key={item.title + id + index}>
                                <Typography className="user-information" title={title}>
                                    {!isTest && item.icon}{" "}
                                    {locationsList?.map((location, locationIndex) => {
                                        return (
                                            <React.Fragment key={`${locationIndex} - ${location.text}`}>
                                                <span
                                                    className="user-card-link"
                                                    onClick={(e) => handleNavigate(e, location.link)}>
                                                    {location.text}
                                                </span>
                                                {renderCommasOrPlus(locationsList, locationIndex)}
                                            </React.Fragment>
                                        );
                                    })}
                                </Typography>
                            </React.Fragment>
                        ) : null;
                    } else if (isGames) {
                        const gamesList = getGamesURLsList(text);
                        return (
                            <React.Fragment key={item.title + id + index}>
                                <Typography className="user-information crop-game-name" title={title}>
                                    {!isTest && item.icon}
                                    {gamesList.map((game, gameIndex) => {
                                        return (
                                            <React.Fragment key={`${gameIndex} - ${game.text}`}>
                                                <span
                                                    className={`${game.link ? "user-card-link" : ""} game-ellipsis`}
                                                    onClick={(e) => handleNavigate(e, game.link)}>
                                                    {game.text}
                                                </span>
                                                {renderCommasOrPlus(gamesList, gameIndex, isGames)}
                                            </React.Fragment>
                                        );
                                    })}
                                </Typography>
                            </React.Fragment>
                        );
                    } else {
                        return (
                            <React.Fragment key={item.title + id + index}>
                                <Typography className="user-information" title={title}>
                                    {!isTest && item.icon} {text}
                                </Typography>
                            </React.Fragment>
                        );
                    }
                })}
        </React.Fragment>
    );
}

export default UserListIconText;
