import "./GroupDialogs.scss";

import React from "react";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { SecondaryApplyButton } from "@components/ApplyButton";
import { Close } from "@mui/icons-material";

type GamePreferencesDialogProps = {
    open: boolean;
    title: string;
    description: string;
    onClose: () => void;
};

export function GamePreferencesDialog({ open, title, description, onClose }: GamePreferencesDialogProps) {
    return (
        <Dialog open={open}>
            <DialogContent className="group-preferences-dialog-content">
                <DialogContentText className="group-preferences-dialog-description">{description}</DialogContentText>
                <DialogActions className="group-preferences-dialog-actions">
                    <SecondaryApplyButton onClick={onClose} icon={<Close fontSize="small" />}>
                        Close
                    </SecondaryApplyButton>
                </DialogActions>
            </DialogContent>
        </Dialog>
    );
}
