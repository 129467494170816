import React, { PropsWithChildren, createContext, useContext, useMemo, useRef, useState, useCallback } from "react";
import { profileProvider } from "services/profileProvider";
import { messageProvider } from "@services/messageProvider";
import { useGlobalContext } from "./AppContext";
import { MessageModel } from "models/inbox/MessageModel";
import { PrivatePublicProfileModel, ProfileModel, PublicProfileModel } from "models/ProfileModels";

type MessagesContextProps = {
    profiles: PrivatePublicProfileModel | null;
    setProfiles: React.Dispatch<React.SetStateAction<PrivatePublicProfileModel | null>>;
    searchInput: string;
    setSearchInput: React.Dispatch<React.SetStateAction<string>>;
    publicProfiles: PublicProfileModel[];
    blockedProfiles: PublicProfileModel[];
    isBlockedUserList: boolean;
    setIsBlockedUserList: React.Dispatch<React.SetStateAction<boolean>>;
    publicID: string;
    setPublicID: React.Dispatch<React.SetStateAction<string>>;
    newMessageOpen: boolean;
    setNewMessageOpen: React.Dispatch<React.SetStateAction<boolean>>;
    loadingUsers: boolean;
    setLoadingUsers: React.Dispatch<React.SetStateAction<boolean>>;
    profilesList: ProfileModel[];
    setProfilesList: React.Dispatch<React.SetStateAction<ProfileModel[]>>;
    filteredProfiles: ProfileModel[];
    setFilteredProfiles: React.Dispatch<React.SetStateAction<ProfileModel[]>>;
    messages: MessageModel[];
    setMessages: React.Dispatch<React.SetStateAction<MessageModel[]>>;
    currentMessage: string;
    setCurrentMessage: React.Dispatch<React.SetStateAction<string>>;
    notifyReadMessages: (messages: Array<MessageModel>, privateID: string) => Promise<void>;
    updateMissingProfiles: (isBlockedList: boolean, arrayList: Array<MessageModel>) => Promise<null | undefined>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setReturnInbox: React.Dispatch<React.SetStateAction<boolean>>;
    returnInbox: boolean;
    pageNumber: number;
    setPageNumber: React.Dispatch<React.SetStateAction<number>>;
    scrollTarget: React.RefObject<HTMLDivElement>;
    currentArrayList: Array<MessageModel>;
    setCurrentArrayList: React.Dispatch<React.SetStateAction<MessageModel[]>>;
    loadingUser: boolean;
    setLoadingUser: React.Dispatch<React.SetStateAction<boolean>>;
    messageDisabled: boolean;
    setBlockedProfiles: React.Dispatch<React.SetStateAction<PublicProfileModel[]>>;
    setPublicProfiles: React.Dispatch<React.SetStateAction<PublicProfileModel[]>>;
};

const MessagesContext = createContext<MessagesContextProps | null>(null);

export const MessagesProvider = ({ children }: PropsWithChildren<{}>) => {
    const { personalProfile } = useGlobalContext();
    const [profiles, setProfiles] = useState<PrivatePublicProfileModel | null>(null);
    const [searchInput, setSearchInput] = useState("");
    const [publicProfiles, setPublicProfiles] = useState<Array<PublicProfileModel>>([]);
    const [blockedProfiles, setBlockedProfiles] = useState<Array<PublicProfileModel>>([]);
    const [returnInbox, setReturnInbox] = useState(false);
    const [isBlockedUserList, setIsBlockedUserList] = useState(false);
    const [publicID, setPublicID] = useState("");
    const [newMessageOpen, setNewMessageOpen] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [profilesList, setProfilesList] = useState<Array<ProfileModel>>([]);
    const [filteredProfiles, setFilteredProfiles] = useState<Array<ProfileModel>>(profilesList);
    const [currentArrayList, setCurrentArrayList] = useState<Array<MessageModel>>([]);
    const [loadingUser, setLoadingUser] = useState<boolean>(false);
    const [messages, setMessages] = useState<Array<MessageModel>>([]);
    const [currentMessage, setCurrentMessage] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const scrollTarget = useRef<HTMLDivElement>(null);

    const messageDisabled = useMemo(() => {
        return personalProfile?.messageDisabled?.[0] ?? false;
    }, [personalProfile?.messageDisabled]);

    return (
        <MessagesContext.Provider
            value={
                {
                    profiles,
                    setProfiles,
                    searchInput,
                    setSearchInput,
                    publicProfiles,
                    setPublicProfiles,
                    isBlockedUserList,
                    setIsBlockedUserList,
                    publicID,
                    setPublicID,
                    newMessageOpen,
                    setNewMessageOpen,
                    loadingUsers,
                    setLoadingUsers,
                    filteredProfiles,
                    setFilteredProfiles,
                    setProfilesList,
                    profilesList,
                    messages,
                    setMessages,
                    currentMessage,
                    setCurrentMessage,
                    blockedProfiles,
                    setBlockedProfiles,
                    returnInbox,
                    setReturnInbox,
                    pageNumber,
                    setPageNumber,
                    scrollTarget,
                    currentArrayList,
                    setCurrentArrayList,
                    loadingUser,
                    setLoadingUser,
                    messageDisabled,
                } as MessagesContextProps
            }>
            {children}
        </MessagesContext.Provider>
    );
};

export const useMessagesContext = () => {
    const currentContext = useContext(MessagesContext);
    const { personalProfile } = useGlobalContext();

    const updateMissingProfiles = useCallback(
        async (isBlockedList: boolean, arrayList: Array<MessageModel>) => {
            if (!personalProfile) {
                return null;
            }
            let allMessagesProfileIds: any[] = [];
            if (arrayList.length <= 0 && isBlockedList) {
                currentContext?.setBlockedProfiles([]);
            } else if (arrayList.length <= 0 && !isBlockedList) {
                currentContext?.setPublicProfiles([]);
            } else if (isBlockedList) {
                allMessagesProfileIds = [...arrayList];
                const blockedProfilesID = currentContext?.blockedProfiles.map((profile) => profile.id);
                const previousBlockedProfilesID = blockedProfilesID?.filter((id) => allMessagesProfileIds.includes(id));
                const previousBlockedProfiles = currentContext?.blockedProfiles.filter((profile) =>
                    previousBlockedProfilesID?.includes(profile?.id),
                );
                const newProfileIds = allMessagesProfileIds.filter((id) => !previousBlockedProfilesID?.includes(id));
                const newProfiles = await Promise.all(
                    newProfileIds.map(async (profileId) => {
                        const response = await profileProvider.getProfile(profileId);
                        return response?.data;
                    }),
                );

                if (allMessagesProfileIds.length > 0) {
                    currentContext?.setBlockedProfiles([...(previousBlockedProfiles ?? []), ...(newProfiles ?? [])]);
                }
            } else {
                const currentArrayList: Array<MessageModel> = [...(arrayList as Array<MessageModel>)];

                const msgSentProfiles = currentArrayList
                    .filter((msg) => msg.from === personalProfile.id)
                    .map((msg) => msg.to);
                const msgReceivedProfiles = currentArrayList
                    .filter((msg) => msg.from !== personalProfile.id)
                    .map((msg) => msg.from);
                allMessagesProfileIds = [...msgSentProfiles, ...msgReceivedProfiles];
                const publicProfilesID = currentContext?.publicProfiles.map((profile) => profile?.id);
                const profilesToPull = allMessagesProfileIds.filter(
                    (profileId) => !publicProfilesID?.includes(profileId),
                );

                const newProfiles: any = arrayList.map((item) => item.from_user);

                if (profilesToPull.length > 0) {
                    currentContext?.setPublicProfiles((oldProfiles) => [...oldProfiles, ...newProfiles]);
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [personalProfile, profileProvider],
    );

    const notifyReadMessages = useCallback(
        async (messages: Array<MessageModel>, privateID: string) => {
            const messagesToNotify: any = messages
                ?.filter((message) => message.from !== privateID && message.read === "False")
                ?.map((message) => message.id);
            if (messagesToNotify?.length && document.hasFocus()) {
                await messageProvider.flagMessagesAsRead(messagesToNotify as string[]);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [messageProvider],
    );

    if (!currentContext) {
        throw new Error("useMessagesContext has to be used within <MessagesContext.Provider>");
    }

    return {
        ...currentContext,
        notifyReadMessages,
        updateMissingProfiles,
    };
};
