import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { profileProvider } from "services/profileProvider";
// import SnackbarAlert from "components/SnackbarAlert/SnackbarAlert";

/*
Profile names have the following requirements:
1. at least 4 characters
2. no more than 20 characters
3. only alphanumeric, dashes, hypens and spaces
*/

export const ProfileName = ({
    state,
    handleEditProfile,
    pushCounter,
    isEditing = false,
    changeEditState = () => {},
}) => {
    const [disabledValue, setDisabledValue] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const hasState = () => {
        if (state.name) {
            return state.name;
        }
        return "";
    };

    const [textValue, setTextValue] = useState(hasState);

    //set the text value and enable the submit button
    const onTextChange = (e) => {
        setTextValue(e.target.value);

        if (isEditing) {
            changeEditState({ name: e.target.value });
        }
    };

    useEffect(() => {
        const name = textValue.toString();
        if (name.length > 3) {
            setDisabledValue(false);
        } else {
            setDisabledValue(true);
        }
    }, [textValue]);

    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        setIsLoading(true);
        const nameOnDatabase = (await profileProvider.getPersonalProfile()).name;
        if (String(textValue) === String(nameOnDatabase)) {
            pushCounter();
        } else {
            await handleEditProfile({ name: String(textValue) });
        }
        setIsLoading(false);
    };

    //prevent any names with characters that aren't a-z, numbers, underscores or spaces
    const allowed_characters = /^[a-zA-Z0-9-_ ]+$/;

    return (
        <View className="form-container">
            {!isEditing && <Text as="h2">Create your RPGMatch Username</Text>}
            <View className={isEditing ? "" : "input-container"}>
                <TextField
                    fullWidth={isEditing}
                    onChange={onTextChange}
                    value={textValue}
                    id="name"
                    variant="outlined"
                    label={isEditing ? "Username" : ""}
                    className={`name-component ${textValue.length > 0 ? "filled" : ""} `}
                    inputProps={{ maxLength: 20 }}
                    onKeyDown={(event) => {
                        if (!allowed_characters.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                />
            </View>
            {!isEditing && (
                <View className="button-container">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={handleSaveValue}
                    />
                </View>
            )}
        </View>
    );
};
export default ProfileName;
