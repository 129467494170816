import "./AboutCard.scss";
import React, { useMemo } from "react";
import { RightCard, RightCardProps } from "../RightCard";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "context/AppContext";
import { usePaginatedContext } from "context/PaginatedUserListContext";

export const AboutCard = () => {
    const navigate = useNavigate();
    const { isLoggedIn } = useGlobalContext();

    const {
        locationStatus,
    }: {
        locationStatus: string;
    } = usePaginatedContext() as any;

    const aboutCardInfo: RightCardProps = useMemo(() => {
        return {
            title: "About RPGMatch",
            description:
                "RPGMatch is an application where you can register and meet other TTRPG players who like to play like you do. We ask the important questions about safety tools, character death, and stylistic preferences so you are guaranteed a good fit before you sit down at the table or VTT. RPGMatch is more than a matching service, we are building a community online from the ground up with table top gamers in mind. Best of all? It’s all free.",
        };
    }, []);

    if (isLoggedIn === true || locationStatus !== "success") {
        return null;
    }

    return (
        <RightCard {...aboutCardInfo}>
            <div className="about-card-actions">
                <Button className="action-button" variant="contained" onClick={() => navigate("/signup")}>
                    JOIN NOW!
                </Button>
                <Button className="action-button signin" variant="outlined" onClick={() => navigate("/login")}>
                    SIGN IN
                </Button>
            </div>
        </RightCard>
    );
};
