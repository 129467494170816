import React, { useEffect, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import View from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Text from "@mui/material/Typography";
import { timezones } from "utils/timezones";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

export const ProfileTimezone = ({
    state,
    handleEditProfile,
    pushCounter,
    isEditing = false,
    changeEditState = () => {},
}) => {
    const [disabledValue, setDisabledValue] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const hasState = () => {
        if (state.timezone) {
            return state.timezone;
        } else {
            return null;
        }
    };

    const [selectValue, setSelectValue] = useState(hasState);

    //set the selector value and enable the submit button
    const onSelectChange = (e) => {
        setSelectValue(e.target.value);
        setDisabledValue(false);

        if (isEditing) {
            changeEditState({ timezone: e.target.value });
        }
    };

    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        if (String(selectValue) === String(state?.timezone)) {
            pushCounter();
            return;
        }

        setIsLoading(true);
        await handleEditProfile({ timezone: String(selectValue) });
        setIsLoading(false);
    };

    useEffect(() => {
        if (selectValue) {
            setDisabledValue(false);
        } else {
            setDisabledValue(true);
        }
    }, [selectValue]);

    return (
        <View className="form-container">
            {!isEditing && <Text as="h2">What's your time zone?</Text>}
            <View className={isEditing ? "" : "input-container"}>
                <FormControl fullWidth className="timezone-selector" sx={isEditing ? {} : { m: 1, minWidth: 120 }}>
                    <InputLabel id="time-zone">Timezone</InputLabel>
                    <Select
                        labelId="time-zone"
                        label="Time Zone"
                        onChange={onSelectChange}
                        value={selectValue}
                        inputProps={{ "data-testid": "timezone-picker-input" }}
                        id="time-zone">
                        <MenuItem value={"-12:00"}>{timezones["-12:00"]}</MenuItem>
                        <MenuItem value={"-11:00"}>{timezones["-11:00"]}</MenuItem>
                        <MenuItem value={"-10:00"}>{timezones["-10:00"]}</MenuItem>
                        <MenuItem value={"-09:50"}>{timezones["-09:50"]}</MenuItem>
                        <MenuItem value={"-09:00"}>{timezones["-09:00"]}</MenuItem>
                        <MenuItem value={"-08:00"}>{timezones["-08:00"]}</MenuItem>
                        <MenuItem value={"-07:00"}>{timezones["-07:00"]}</MenuItem>
                        <MenuItem value={"-06:00"}>{timezones["-06:00"]}</MenuItem>
                        <MenuItem value={"-05:00"}>{timezones["-05:00"]}</MenuItem>
                        <MenuItem value={"-04:50"}>{timezones["-04:50"]}</MenuItem>
                        <MenuItem value={"-04:00"}>{timezones["-04:00"]}</MenuItem>
                        <MenuItem value={"-03:50"}>{timezones["-03:50"]}</MenuItem>
                        <MenuItem value={"-03:00"}>{timezones["-03:00"]}</MenuItem>
                        <MenuItem value={"-02:00"}>{timezones["-02:00"]}</MenuItem>
                        <MenuItem value={"-01:00"}>{timezones["-01:00"]}</MenuItem>
                        <MenuItem value={"+00:00"}>{timezones["+00:00"]}</MenuItem>
                        <MenuItem value={"+01:00"}>{timezones["+01:00"]}</MenuItem>
                        <MenuItem value={"+02:00"}>{timezones["+02:00"]}</MenuItem>
                        <MenuItem value={"+03:00"}>{timezones["+03:00"]}</MenuItem>
                        <MenuItem value={"+03:50"}>{timezones["+03:50"]}</MenuItem>
                        <MenuItem value={"+04:00"}>{timezones["+04:00"]}</MenuItem>
                        <MenuItem value={"+04:50"}>{timezones["+04:50"]}</MenuItem>
                        <MenuItem value={"+05:00"}>{timezones["+05:00"]}</MenuItem>
                        <MenuItem value={"+05:50"}>{timezones["+05:50"]}</MenuItem>
                        <MenuItem value={"+05:75"}>{timezones["+05:75"]}</MenuItem>
                        <MenuItem value={"+06:00"}>{timezones["+06:00"]}</MenuItem>
                        <MenuItem value={"+06:50"}>{timezones["+06:50"]}</MenuItem>
                        <MenuItem value={"+07:00"}>{timezones["+07:00"]}</MenuItem>
                        <MenuItem value={"+08:00"}>{timezones["+08:00"]}</MenuItem>
                        <MenuItem value={"+08:75"}>{timezones["+08:75"]}</MenuItem>
                        <MenuItem value={"+09:00"}>{timezones["+09:00"]}</MenuItem>
                        <MenuItem value={"+09:50"}>{timezones["+09:50"]}</MenuItem>
                        <MenuItem value={"+10:00"}>{timezones["+10:00"]}</MenuItem>
                        <MenuItem value={"+10:50"}>{timezones["+10:50"]}</MenuItem>
                        <MenuItem value={"+11:00"}>{timezones["+11:00"]}</MenuItem>
                        <MenuItem value={"+11:50"}>{timezones["+11:50"]}</MenuItem>
                        <MenuItem value={"+12:00"}>{timezones["+12:00"]}</MenuItem>
                        <MenuItem value={"+12:75"}>{timezones["+12:75"]}</MenuItem>
                        <MenuItem value={"+13:00"}>{timezones["+13:00"]}</MenuItem>
                        <MenuItem value={"+14:00"}>{timezones["+14:00"]}</MenuItem>
                    </Select>
                </FormControl>
            </View>
            {!isEditing && (
                <View className="button-container">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={handleSaveValue}
                    />
                </View>
            )}
        </View>
    );
};
export default ProfileTimezone;
