/**
 * @description Memory pool of promises to be memoized
 */
const promisesPool: Record<string, any> = {};
/**
 * Execute a function and memoize its result, so if it is called again with the same parameters, it will return the same result for a short time.
 * @param fn The function to memoize
 * @param delay The time in milliseconds after which the function should be called
 * @returns The memoized promise so it can be awaited
 */
export function preventDoubleCall<T>(fn: () => Promise<T>, delay = 2000): Promise<T> {
    const functionKey = JSON.stringify(fn);
    if (!promisesPool[functionKey]) {
        promisesPool[functionKey] = fn().then((result) => {
            setTimeout(() => delete promisesPool[functionKey], delay);
            return result;
        });
    }
    return promisesPool[functionKey];
}

/**
 * Lock a promise so it can be called only once
 * @param fn the promise to be locked
 * @returns new locked promise to be used
 */
export function lock<T>(fn: () => Promise<T>): Promise<T> {
    const functionKey = JSON.stringify(fn);
    if (!promisesPool[functionKey]) {
        promisesPool[functionKey] = fn().finally(() => delete promisesPool[functionKey]);
    }
    return promisesPool[functionKey];
}
