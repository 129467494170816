import React, { useState } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import RequiredQuestion from "components/RequiredQuestion";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { handleSaveRequiredValues } from "@utils/functions";

export const ProfileSafetyTools = ({ state, handleEditProfile, pushCounter }) => {
    const hasState = () => {
        if (!state?.safetyTools) {
            return "";
        }

        return String(state?.safetyTools);
    };

    const [textValue, setTextValue] = useState(hasState);
    const [isLoading, setIsLoading] = useState(false);

    const safetyToolsOptions = [
        "They are super important",
        "I keep lines of communication open",
        "I don't know what safety tools are",
        "I don't use safety tools",
    ];

    return (
        <View className="form-container">
            <Text as="h2">
                How important are safety tools (session 0, lines & veils, etc.) to games you participate in?
                <small>*</small>
            </Text>
            {
                // loop through the array and build button options
                Object.values(safetyToolsOptions).map((item, index) => (
                    <View className="button-container" key={index}>
                        <LoadingBtn
                            label={item}
                            variant="outlined"
                            loading={textValue === item && isLoading}
                            handleOnClick={() =>
                                handleSaveRequiredValues({
                                    key: "safetyTools",
                                    item,
                                    setTextValue,
                                    setIsLoading,
                                    handleEditProfile,
                                })
                            }
                            className={textValue === item ? "selected-but" : "safety-but"}
                        />
                    </View>
                ))
            }
            <RequiredQuestion />
        </View>
    );
};
export default ProfileSafetyTools;
