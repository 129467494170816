import "./FilterPopup.css";
import React, { useMemo } from "react";
import {
    Autocomplete,
    Box,
    ButtonBase,
    Checkbox,
    Chip,
    Container,
    FormControlLabel,
    ListItemText,
    Menu,
    MenuItem,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ClearIcon from "@mui/icons-material/Clear";
import FilterControls from "./FilterControls";
import { agesConstants, playerTypes } from "utils/constants";
import { FilterAccordion } from "./FilterAccordion";
import { Flex, View } from "@aws-amplify/ui-react";
import LocationFilter from "./LocationFilter/LocationFilter";
import { CheckCircleTwoTone, RadioButtonUnchecked } from "@mui/icons-material";
import { SortingOptions } from "./SortingOptions";
import { useGlobalContext } from "context/AppContext";
import { ExpandBtn } from "components/ExpandBtn";
import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/useBreakpoint";

const FilterPopup = ({
    showFilter,
    filters,
    handleFilters,
    clearFilter,
    applyFilter,
    anchorEl,
    handleClearFilter,
    handleCloseFilter,
    filterFields,
    hasFilter,
    showClearAll,
    sortingOptions,
    sortingFilterIntegrated,
    pathToName,
}) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    const { usingAdvancedFilter, toggleAdvancedFilter } = useGlobalContext();
    const navigate = useNavigate();
    const { isMobile } = useBreakpoint();
    const gamesPlayed = useMemo(
        function () {
            return (
                filterFields.games?.map((game) => ({
                    id: game.id,
                    label: game.name,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.games?.[0]?.id],
    );

    const vttSystem = useMemo(
        function () {
            return (
                filterFields.vtt?.map((option, key) => ({
                    id: option.id,
                    label: option.name,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.vtt?.[0]?.id],
    );

    const languages = useMemo(
        function () {
            return (
                filterFields.languages?.map((option, key) => ({
                    id: option.id,
                    label: option.languageName,
                })) || []
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filterFields?.languages?.[0]?.id],
    );

    const renderCheckboxes = (props, option, { selected }) => {
        return (
            <li {...props} key={`${option.id}-${option.label}`}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {option.label}
            </li>
        );
    };
    const parseRenderTags = (list, type) => {
        try {
            const fields = {
                gamesPlayed,
                vttSystem,
                languages,
            };

            const fieldList = fields[type];
            return list.map((item) => {
                let find = item;
                if (typeof item == "string" || typeof item == "number") {
                    find = fieldList.find((thing) => thing.id === +item);
                }
                return find;
            });
        } catch (error) {
            return list;
        }
    };

    const renderTags = (tagValue, getTagProps, type) => {
        const currentTagValue = parseRenderTags(tagValue, type);
        return currentTagValue.map((option, index) => {
            if (!option) {
                return null;
            }
            if (index < 3) {
                return <Chip key={option.id} label={`${option.label}`} {...getTagProps({ index })} />;
            } else if (index === tagValue.length - 1) {
                return <div key={option.id}>+{tagValue.length - 3}</div>;
            } else {
                return null;
            }
        });
    };

    const renderInput = (params) => (
        <TextField variant="standard" placeholder="Select" className="filter-input" {...params} label={null} />
    );

    const checkSafetyTools = (value) => {
        if (!filters && filters?.safetyTools) {
            return false;
        }
        if (hasFilter && filters?.safetyTools) {
            return filters.safetyTools.includes(value);
        }

        return false;
    };

    const hasAdvancedFilters = useMemo(() => {
        return (
            Boolean(filters?.safetyTools) ||
            Boolean(filters?.playerType?.length) ||
            Boolean(filters?.age?.length) ||
            Boolean(filters?.languages?.length)
        );
    }, [filters?.age?.length, filters?.playerType?.length, filters?.safetyTools, filters?.languages?.length]);

    return (
        <Menu open={showFilter} anchorEl={anchorEl} onClose={handleCloseFilter} className="filter-menu">
            <Box className={"filter-box-separator"}>
                <Flex alignItems={"flex-end"} className="header-filter-popup">
                    {isMobile && (
                        <Typography variant="h4" className="primary-heading">
                            Filters & Sort
                        </Typography>
                    )}
                    {showClearAll && (
                        <View
                            data-testid="clear-filters"
                            onClick={(e) => handleClearFilter(e, { preserveFilters: ["sort"] })}
                            className={"clear-filters"}>
                            Clear All
                        </View>
                    )}
                    <ButtonBase onClick={handleCloseFilter}>
                        <ClearIcon data-testid="close-icon" className="close-filter-button" />
                    </ButtonBase>
                </Flex>
                <Box sx={{ maxWidth: "100%", margin: "24px 0 26px 0" }}>
                    <Container className="filter-container">
                        <Stack className="filter-group" spacing={2}>
                            {sortingFilterIntegrated && (
                                <SortingOptions
                                    filters={filters}
                                    handleFilters={handleFilters}
                                    options={sortingOptions}
                                />
                            )}

                            <FormControlLabel
                                className="filter-availability"
                                control={
                                    <Checkbox
                                        data-testid="filter-availability"
                                        checked={filters?.availability === "true" || filters?.availability || false}
                                        onChange={(_, checked) => handleFilters("availability", checked)}
                                        checkedIcon={<CheckCircleTwoTone />}
                                        icon={<RadioButtonUnchecked />}
                                    />
                                }
                                label="Show only matches w/ time overlap"
                            />

                            <hr />
                            <LocationFilter filters={filters} handleFilters={handleFilters} />

                            <hr />
                            <FilterAccordion
                                label="Games"
                                id="Games"
                                filterChosen={Boolean(filters?.gamesPlayed?.length)}>
                                <Autocomplete
                                    disablePortal
                                    onKeyDownCapture={(event) => event.stopPropagation()}
                                    value={
                                        hasFilter && filters?.gamesPlayed
                                            ? parseRenderTags(filters?.gamesPlayed, "gamesPlayed")
                                            : []
                                    }
                                    multiple
                                    disableCloseOnSelect
                                    onChange={(e, val) => {
                                        handleFilters("gamesPlayed", val);
                                    }}
                                    limitTags={3}
                                    className="filter-select"
                                    ListboxProps={{ style: { maxHeight: "200px" } }}
                                    options={gamesPlayed}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderTags={(value, getTagProps) => renderTags(value, getTagProps, "gamesPlayed")}
                                    renderOption={renderCheckboxes}
                                    renderInput={renderInput}
                                />
                            </FilterAccordion>

                            <hr />
                            <FilterAccordion
                                label="Platforms"
                                id="vtt-label"
                                filterChosen={Boolean(filters?.vtt?.length)}>
                                <Autocomplete
                                    disablePortal
                                    value={hasFilter && filters?.vtt ? parseRenderTags(filters?.vtt, "vttSystem") : []}
                                    onKeyDownCapture={(event) => event.stopPropagation()}
                                    multiple
                                    disableCloseOnSelect
                                    onChange={(e, val) => {
                                        handleFilters("vtt", val);
                                    }}
                                    limitTags={3}
                                    className="filter-select"
                                    ListboxProps={{ style: { maxHeight: "200px" } }}
                                    options={vttSystem}
                                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                    renderTags={(value, getTagProps) => renderTags(value, getTagProps, "vttSystem")}
                                    renderOption={renderCheckboxes}
                                    renderInput={renderInput}
                                />
                            </FilterAccordion>

                            {usingAdvancedFilter && (
                                <section>
                                    <hr />
                                    <FilterAccordion
                                        label="Safety Tools"
                                        id="safetyTools"
                                        filterChosen={Boolean(filters?.safetyTools)}>
                                        <MenuItem
                                            onClick={() => {
                                                handleFilters("safetyTools", "Yes");
                                            }}>
                                            <Checkbox
                                                value={"Yes"}
                                                checked={checkSafetyTools("Yes")}
                                                onChange={(e) => handleFilters("safetyTools", e.target.value)}
                                            />
                                            <ListItemText primary={"Yes"} />
                                        </MenuItem>

                                        <MenuItem
                                            onClick={() => {
                                                handleFilters("safetyTools", "No");
                                            }}>
                                            <Checkbox
                                                value={"No"}
                                                checked={checkSafetyTools("No")}
                                                onChange={(e) => handleFilters("safetyTools", e.target.value)}
                                            />
                                            <ListItemText primary={"No"} />
                                        </MenuItem>
                                    </FilterAccordion>

                                    <hr />
                                    <FilterAccordion
                                        label="Player Type"
                                        id="typeOfPlayer"
                                        filterChosen={filters?.playerType?.length}>
                                        {playerTypes.map((type) => {
                                            const { label } = type;
                                            let isSelected = false;

                                            if (filters?.playerType) {
                                                isSelected = filters?.playerType?.includes(label);
                                            }

                                            return (
                                                <MenuItem
                                                    key={type.id}
                                                    className="filter-option"
                                                    onClick={() => handleFilters("playerType", type.label)}>
                                                    <Checkbox
                                                        data-testid={`filter-${type.label}`}
                                                        checked={isSelected}
                                                    />
                                                    <ListItemText primary={type.label === "DM" ? "GM" : type.label} />
                                                </MenuItem>
                                            );
                                        })}
                                    </FilterAccordion>

                                    <hr />
                                    <FilterAccordion
                                        label="Age Range"
                                        id="ages"
                                        filterChosen={Boolean(filters?.age?.length)}>
                                        {agesConstants.map((name) => {
                                            return (
                                                <MenuItem
                                                    onClick={() => handleFilters("age", name.label)}
                                                    key={name.id}
                                                    value={name.label}>
                                                    <Checkbox
                                                        value={name.label}
                                                        checked={
                                                            filters?.age ? filters?.age?.includes(name.label) : false
                                                        }
                                                    />
                                                    <ListItemText primary={name.label} />
                                                </MenuItem>
                                            );
                                        })}
                                    </FilterAccordion>

                                    <hr />
                                    <FilterAccordion
                                        label="Languages"
                                        id="Languages"
                                        filterChosen={Boolean(filters?.languages?.length)}>
                                        <Autocomplete
                                            disablePortal
                                            onKeyDownCapture={(event) => event.stopPropagation()}
                                            value={
                                                hasFilter && filters?.languages
                                                    ? parseRenderTags(filters?.languages, "languages")
                                                    : []
                                            }
                                            multiple
                                            disableCloseOnSelect
                                            onChange={(e, val) => {
                                                handleFilters("languages", val);
                                            }}
                                            limitTags={3}
                                            className="filter-select"
                                            ListboxProps={{ style: { maxHeight: "200px" } }}
                                            options={languages}
                                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                            renderTags={(value, getTagProps) =>
                                                renderTags(value, getTagProps, "languages")
                                            }
                                            renderOption={renderCheckboxes}
                                            renderInput={renderInput}
                                        />
                                    </FilterAccordion>
                                </section>
                            )}

                            {/* <hr /> */}
                            {/* <VerifiedFilter filters={filters} handleFilters={handleFilters} /> */}
                            {/* <hr /> */}
                            {/* <BadgesFilter filters={filters} handleFilters={handleFilters} /> */}
                        </Stack>
                    </Container>
                </Box>

                <ExpandBtn
                    expandedLabel={"BASIC FILTERS"}
                    collapsedLabel={"ADVANCED FILTERS"}
                    isHighlighted={hasAdvancedFilters}
                    expanded={usingAdvancedFilter}
                    onClick={toggleAdvancedFilter}
                />

                <FilterControls
                    clearFilter={clearFilter}
                    applyFilter={() => {
                        if (
                            window.location.pathname.includes("location") ||
                            window.location.pathname.includes("game")
                        ) {
                            const params = pathToName();
                            navigate("/user-list?" + params?.toString());
                        } else {
                            handleCloseFilter();
                            applyFilter();
                        }
                    }}
                />
            </Box>
        </Menu>
    );
};

export default FilterPopup;
