import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileGamesPlayed from "@components/ProfileInterview/ProfileGamesPlayed";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const GamesPlayedComponent = ({
    state,
    handleEditProfile,
    pushCounter,
    goNextButton,
    goBack,
    counter,
    length,
    filterFields,
    loading,
    updateFiltersFieldsAsync,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-games-played">
                <View className="top-text">
                    <Text>It's time for the games...</Text>
                </View>
                <View className="question-container">
                    <ProfileGamesPlayed
                        state={state}
                        pushCounter={pushCounter}
                        handleEditProfile={handleEditProfile}
                        filterFields={filterFields}
                        updateFiltersFieldsAsync={updateFiltersFieldsAsync}
                        loading={loading}
                    />
                </View>
                <BackNextBtn
                    pushCounter={pushCounter}
                    goBack={goBack}
                    counter={counter}
                    length={length}
                    nextBtn={goNextButton}
                    pageState={state.gamesPlayed}
                />
            </CompleteProfileContainer>
        </>
    );
};
