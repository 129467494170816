import React, { useState } from "react";
import View from "@mui/material/Box";
import { FormControlLabel, Switch } from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useEditAvailability } from "hooks/useEditAvailability";
import AvailabilityAccordion from "./AvailabilityAccordion";
import { AvailabilityQuestions, AvailabilityTableBody, AvailabilityTableHeader } from "./AvailabilityTableEdit";

const AvailabilityEdit = ({ availabilityTable, changeEditState, noAvailability, editState }) => {
    const [availability, setAvailability] = useState(noAvailability);
    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={availability}
                        onChange={(e) => {
                            setAvailability(e.target.checked);
                            changeEditState({ noAvailability: [e.target.checked] });
                        }}
                    />
                }
                label="No availability"
            />
            <View className="availability-table-container edit">
                {!availability && (
                    <AvailabilityTable
                        body={availabilityTable}
                        changeEditState={changeEditState}
                        editState={editState}
                    />
                )}
            </View>
        </>
    );
};

const AvailabilityTable = ({ body, changeEditState, editState }) => {
    const { isMobile, isTablet } = useBreakpoint();
    const table = body;

    const availabilityTable = editState?.availabilityTable ? editState.availabilityTable : table.availabilityTable;

    const { updateTable, changeObj, questionsValue } = useEditAvailability({
        table,
        changeEditState,
        editState,
    });

    if (isMobile) {
        return (
            <>
                <div data-testid="availability-table">
                    <AvailabilityAccordion availabilityTable={availabilityTable} updateTable={updateTable} />
                </div>
                <AvailabilityQuestions questionsValue={questionsValue} changeObj={changeObj} />
            </>
        );
    }

    return (
        <>
            <div data-testid="availability-table">
                <AvailabilityTableHeader />
                <AvailabilityTableBody
                    availabilityTable={availabilityTable}
                    updateTable={updateTable}
                    isTablet={isTablet}
                />
            </div>
            <AvailabilityQuestions questionsValue={questionsValue} changeObj={changeObj} />
        </>
    );
};

export default AvailabilityEdit;
