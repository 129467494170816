const DAY_HOURS = 24;

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export const formatTime = (date, timezone) => {
    return getTimeFormatter(timezone).format(date).toLowerCase();
};

export const formatFullDate = (date, timezone) => {
    return getDateFormatter(timezone).format(date);
};

export const getMonthAndYear = (unixTimestamp) => {
    const milisecondsTimestamp = unixTimestamp * 1000;
    const date = new Date(milisecondsTimestamp);
    return `${months[date.getMonth()]}, ${date.getFullYear()}`;
};

export const formatTimestamp = (diff, date, timezone) => {
    if (diff > DAY_HOURS * 7) {
        return formatFullDate(date, timezone) + ", " + formatTime(date, timezone);
    }
    if (diff > DAY_HOURS) {
        return getDayWeek(date) + ", " + formatTime(date, timezone);
    }
    return formatTime(date, timezone);
};

export const getDayWeek = (date) => {
    const now = new Date().getDay();
    const ref = date.getDay();

    if (now === ref) {
        return "Today";
    }

    if (now - 1 === ref) {
        return "Yesterday";
    }

    return new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(date);
};

export const getDateDescription = (diff, date, timezone) => {
    if (diff > DAY_HOURS * 7) {
        return formatFullDate(date, timezone);
    }
    return getDayWeek(date);
};

const getDateFormatter = (timezone) => {
    return new Intl.DateTimeFormat("en-US", {
        timezone,
        month: "short",
        day: "2-digit",
    });
};

const getTimeFormatter = (timezone) => {
    return new Intl.DateTimeFormat("en-US", {
        timezone,
        hourCycle: "h12",
        hour: "2-digit",
        minute: "2-digit",
    });
};
