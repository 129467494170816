import { Alert, Snackbar, Typography } from "@mui/material";
import React, { createContext, useContext, useState } from "react";
import "../index.css";

/*
    This component encapsulates the logic and presentation of the Snackbar system,
    allowing other components to easily trigger and display snackbars with custom content and behavior.
*/

const DEFAULTS = {
    title: "Sorry",
    description: "An error has occurred, try again later!",
    severity: "error",
    duration: 6000,
    anchor: { vertical: "bottom", horizontal: "center" },
    reason: "clickaway",
    open: false,
};

/*
    SnackbarContext:
    A context created using createContext to manage the state and behavior of the Snackbar component.
 */
export const SnackbarContext = createContext(DEFAULTS);

/*
    useSnackbarContext:
    A custom hook that allows consuming components to interact with the SnackbarContext, providing a method to show a
    snackbar with customizable title, description, severity, duration, and anchor.
 */
export const useSnackbarContext = () => {
    const { setTitle, setDescription, setSeverity, setDuration, setAnchor, setOpen } = useContext(SnackbarContext);

    const showSnackbar = ({
        title = DEFAULTS.title,
        description = DEFAULTS.description,
        severity = DEFAULTS.severity,
        duration = DEFAULTS.duration,
        anchor = DEFAULTS.anchor,
    }) => {
        setTitle(title);
        setDescription(description);
        setSeverity(severity);
        setDuration(duration);
        setAnchor(anchor);
        setOpen(true);
    };
    return {
        showSnackbar,
    };
};

/*
    A memoized component that renders the actual Snackbar using Material-UI's Snackbar and Alert components.
    It uses the state values from the context to display the snackbar with the specified title, description,
    severity, and other properties.
 */
const SnackbarContextComponent = React.memo(({ open, duration, anchor, severity, title, description, setOpen }) => {
    const handleClose = (_, reason) => {
        if (reason === DEFAULTS.reason) {
            return;
        }
        setOpen(false);
    };

    return (
        <Snackbar
            className="snackbar-context"
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={anchor}>
            <Alert onClose={handleClose} severity={severity} className={`alert ${severity}`}>
                <div className="alert-info">
                    <Typography className="title">{title}</Typography>
                    <Typography className="description">{description}</Typography>
                </div>
            </Alert>
        </Snackbar>
    );
});

/*
    A provider component that wraps the entire application, providing the SnackbarContext to its children.
    It manages the state of the Snackbar and exposes the necessary methods and state values through the context.
 */
export const SnackbarProvider = ({ children }) => {
    const [open, setOpen] = useState(DEFAULTS.open);
    const [title, setTitle] = useState(DEFAULTS.title);
    const [description, setDescription] = useState(DEFAULTS.description);
    const [duration, setDuration] = useState(DEFAULTS.duration);
    const [severity, setSeverity] = useState(DEFAULTS.severity); /** error | warning | info */
    const [anchor, setAnchor] = useState(DEFAULTS.anchor);

    return (
        <SnackbarContext.Provider
            value={{
                setTitle,
                setDescription,
                setSeverity,
                setDuration,
                setAnchor,
                setOpen,
            }}>
            <SnackbarContextComponent
                open={open}
                duration={duration}
                setOpen={setOpen}
                anchor={anchor}
                severity={severity}
                title={title}
                description={description}
            />
            {children}
        </SnackbarContext.Provider>
    );
};
