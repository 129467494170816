import {useGlobalContext} from "context/AppContext";
import {useCallback, useContext, useEffect, useState} from "react";
import {useQueries} from "react-query";
import {api} from "services/api";
import {endpoints} from "services/endpoints";
import {getDataByContentType} from "services/contentful";
import {checkLoggedIn} from "utils/functions";
import {AsyncFilterFieldsContext} from "context/AsyncFilterFieldsContext";

const ENDPOINT_PATHS = {
    badges: {
        private: "cmsBadges",
        public: "publicBadges",
    },
    vtt: {
        private: "vtt",
        public: "publicVtt",
    },
    identityTags: {
        private: "identityTags",
        public: "publicIdentityTags",
    },
    languages: {
        private: "languages",
        public: "publicLanguages",
    },
    games: {
        public: "game",
    },
};

// Custom hook for managing async filter fields
export const useAsyncFilterFields = () => {
    // Access global context
    const { personalProfile } = useGlobalContext();

    // Access state and functions from the context
    const { setLoading, setIsSuccess, isSuccess, setFilterFields } = useContext(AsyncFilterFieldsContext);

    // Define local state variables
    const [isLoggedIn, setIsLoggedIn] = useState(undefined);
    const [enableQueries, setEnableQueries] = useState(false);

    // Use react-query to manage parallel queries
    const dynamicParallelQueries = useQueries([
        {
            queryKey: ["badgesOptions", personalProfile?.id],
            queryFn: useCallback(async () => {
                return (await api.get(endpoints[ENDPOINT_PATHS.badges[isLoggedIn ? "private" : "public"]]))?.data || [];
            }, [api, endpoints, isLoggedIn]),
            enable: enableQueries,
        },
        {
            queryKey: ["vttOptions", personalProfile?.id],
            queryFn: useCallback(async () => {
                return (await api.get(endpoints[ENDPOINT_PATHS.vtt[isLoggedIn ? "private" : "public"]]))?.data || [];
            }, [api, endpoints, isLoggedIn]),
            enable: enableQueries,
        },
        {
            queryKey: ["getGamesOptionsAsync", personalProfile?.id],
            queryFn: useCallback(async () => {
                return (await getDataByContentType(ENDPOINT_PATHS.games.public)) || [];
            }, [getDataByContentType, ENDPOINT_PATHS.games]),
            enable: enableQueries,
        },
        {
            queryKey: ["identityTags", personalProfile?.id],
            queryFn: useCallback(async () => {
                return (
                    (await api.get(endpoints[ENDPOINT_PATHS.identityTags[isLoggedIn ? "private" : "public"]]))?.data ||
                    []
                );
            }, [api, endpoints, isLoggedIn]),
            enable: enableQueries,
        },
        {
            queryKey: ["languages", personalProfile?.id],
            queryFn: useCallback(async () => {
                return (
                    (await api.get(endpoints[ENDPOINT_PATHS.languages[isLoggedIn ? "private" : "public"]]))?.data || []
                );
            }, [api, endpoints, isLoggedIn]),
            enable: enableQueries,
        },
    ]);

    // Trigger queries if "isLoggedIn" are set to FALSE OR if an "personalProfile id" is found
    useEffect(() => {
        (async () => {
            if (isLoggedIn === undefined) {
                try {
                    const res = await checkLoggedIn();
                    setIsLoggedIn(res);
                    if (!res) setEnableQueries(true);
                } catch (er) {
                    console.error(er);
                }
            } else if (personalProfile || !isLoggedIn) {
                setEnableQueries(!!personalProfile?.id || !isLoggedIn);
            }
        })();
    }, [personalProfile?.id]);

    // Update loading/success state based on parallel queries results
    useEffect(() => {
        setLoading(dynamicParallelQueries.some((query) => !!query.isLoading));
        setIsSuccess(dynamicParallelQueries.every((query) => !!query.isSuccess));
    }, [dynamicParallelQueries]);

    // Update filter fields when all queries are successful
    useEffect(() => {
        if (isSuccess) {
            const [badgesOptions, vtt, games, identityTags, languages] = dynamicParallelQueries;
            setFilterFields({
                badgesOptions: badgesOptions?.data || [],
                vtt: vtt?.data || [],
                games: games?.data || [],
                identityTags: identityTags?.data || [],
                languages: languages?.data || [],
            });
        }
    }, [isSuccess]);
};
