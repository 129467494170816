// ErrorScreen.js
import React from "react";

import "./ErrorScreen.css";
import Button from "components/Button";

import { useNavigate } from "react-router-dom";

const ErrorScreen = ({ setResetApp, resetApp }) => {
    const navigate = useNavigate();
    return (
        <div className="error-screen">
            <img src="/dice.svg" alt="" className="dice" />
            <div className="content">
                <h1>Ooops! Sorry, we rolled a 1.</h1>
                <p>
                    Please try again, and if your problem persists, don’t hesitate to send a raven to report the issue
                    to us at <a href="mailto:errors@rpgmatch.org">errors@rpgmatch.org</a>. Our guild master will
                    dispatch assistance posthaste.
                </p>
                <div className="button-wrapper">
                    <Button
                        onClick={() => {
                            setResetApp?.(!resetApp) || navigate("/");
                        }}>
                        back to home page
                    </Button>
                </div>
                <img src="/main_logo.png" alt="" className="logo" />
            </div>
        </div>
    );
};

export default ErrorScreen;
