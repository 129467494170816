import { useMediaQuery, useTheme } from "@mui/material";

export const useBreakpoint = () => {
    const theme = useTheme();
    const { innerWidth } = window;

    const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));
    const isTablet = useMediaQuery(theme.breakpoints.between("tablet", "laptop"));
    const isLaptop = useMediaQuery(theme.breakpoints.between("laptop", "desktop"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"));

    return { isMobile, isTablet, isLaptop, isDesktop };
};
