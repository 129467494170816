import { LoadingButton } from "@mui/lab";
import { CircularProgress, Icon } from "@mui/material";

export const LoadingBtn = ({
    variant = "contained",
    disabled = false,
    loading = false,
    handleOnClick = (e) => {},
    label = "",
    className = "",
    icon = <></>,
}) => {
    return (
        <LoadingButton
            className={className || "loading-button"}
            data-testid="loading-button"
            variant={variant}
            disabled={disabled || loading}
            loading={loading}
            startIcon={loading ? <Icon /> : icon ?? undefined}
            loadingIndicator={<CircularProgress data-testid="loading-icon" color="inherit" size={16} />}
            loadingPosition={loading ? "start" : "center"}
            onClick={handleOnClick}>
            {label}
        </LoadingButton>
    );
};
export default LoadingBtn;
