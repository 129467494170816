import React, { PropsWithChildren, useMemo } from "react";
import { Avatar, Card, CardActionArea, CardMedia, Skeleton, Typography } from "@mui/material";
import "./RightCard.scss";

export type RightCardProps = {
    banner?: string;
    title?: string;
    subtitle?: string;
    description?: string;
    loading?: boolean;
    style?: React.CSSProperties | undefined;
    contentStyle?: React.CSSProperties | undefined;
    image?: string | React.JSX.Element;
    titleIcon?: React.JSX.Element;
    descriptionStyle?: React.CSSProperties | undefined;
    path?: string;
    onBeforeNavigate?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
    externalLink?: string;
    background?: string;
};

export const RightCard = ({
    banner,
    title,
    subtitle,
    description,
    loading,
    image,
    path,
    titleIcon,
    contentStyle,
    descriptionStyle,
    style,
    children,
    onBeforeNavigate,
    externalLink,
    background,
}: PropsWithChildren<RightCardProps>) => {
    const bgImage = useMemo(() => {
        return banner || background;
    }, [background, banner]);

    const link = useMemo(() => {
        return path || externalLink;
    }, [externalLink, path]);

    const Content = () => {
        const Image = () => {
            if (typeof image === "string" || image instanceof String) {
                return <Avatar alt="Card Avatar" src={image as string} sx={{ width: 120, height: 120 }} />;
            }

            return image;
        };

        const Expander = () => {
            const expander = new Array(5).fill("expander").join(" ");
            return <div className="expander">{expander}</div>;
        };

        return (
            <div className="content-wrapper">
                <CardMedia
                    sx={{
                        visibility: !bgImage ? "hidden" : undefined,
                        position: contentStyle?.position === "absolute" || !!bgImage ? undefined : "absolute",
                    }}
                    height={banner ? 90 : "100%"}
                    component="img"
                    image={bgImage ?? "/banner-dice.png"}
                    alt="Card Header Image"
                />

                {contentStyle?.position === "absolute" && <Expander />}

                <div className="text-wrapper" style={contentStyle}>
                    {image && <Image />}
                    {title && (
                        <Typography className="title" variant="h2">
                            {title}
                            {titleIcon}
                        </Typography>
                    )}
                    {subtitle && (
                        <Typography variant="caption" className="right-card-subtitle">
                            {subtitle}
                        </Typography>
                    )}
                    {description && (
                        <Typography variant="body2" className="right-card-description" style={descriptionStyle}>
                            {description}
                        </Typography>
                    )}

                    {children}
                </div>
            </div>
        );
    };

    return (
        <Card className={`right-card ${loading ? "loading" : ""}`} elevation={loading ? 0 : 1} style={style}>
            {link ? (
                <a href={link} onClick={onBeforeNavigate} target={externalLink ? "_blank" : undefined} rel="noreferrer">
                    <CardActionArea className="card-action-area">
                        <Content />
                    </CardActionArea>
                </a>
            ) : (
                <Content />
            )}

            {loading && (
                <div className="skeleton-wrapper" data-testid="skeleton">
                    <Skeleton variant="rectangular" height={90} />
                    <div className="text-wrapper">
                        <Skeleton />
                        <Skeleton width="60%" />
                        <Skeleton height={140} />
                    </div>
                </div>
            )}
        </Card>
    );
};
