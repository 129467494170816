import { Text, View, Button } from "@aws-amplify/ui-react";
import { BackButton } from "components/BackButton";
import React from "react";
import { AppTitleIcons } from "utils/icons";

import SaveCancelBtn from "../EditComponent/SaveandCancelBtn";
import { useNavigate } from "react-router-dom";

const BackBtnLine = ({
    state,
    isEditing,
    onSave,
    onCancel,
    isLoadingSave,
    editState,
    isProfessionalGM,
    isLoggedIn,
    setLoginPopup,
}) => {
    const EditComponent = () => {
        return (
            <>
                <View className="content">
                    <BackButton onClickBack={() => navigate(`/`)} />
                    {AppTitleIcons.myProfile}
                    <Text className="back-button-profile-edit">{"Edit Profile"}</Text>
                </View>

                <SaveCancelBtn
                    onSave={onSave}
                    onCancel={onCancel}
                    isLoadingSave={isLoadingSave}
                    editState={editState}
                />
            </>
        );
    };

    const ViewComponent = () => {
        return (
            <>
                <View className="content">
                    <BackButton onClickBack={() => navigate(`/?user+Join=`)} />
                    {AppTitleIcons.myProfile}

                    <>
                        <Text data-testid="back-button-profile-name" className="back-button-profile-name">
                            {state?.profile?.name}
                        </Text>
                    </>
                </View>

                {!isLoggedIn && (
                    <Button className="sign-in-button" onClick={() => setLoginPopup(true)}>
                        sign in
                    </Button>
                )}
            </>
        );
    };

    const navigate = useNavigate();
    return (
        <View data-testid="back-btn" className="back-button-view">
            {isEditing ? <EditComponent /> : <ViewComponent />}
        </View>
    );
};

export default BackBtnLine;
