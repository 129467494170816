import { MessageModel } from "@models/inbox/MessageModel";
import { api } from "./api";
import { endpoints } from "./endpoints";
import { mailProvider } from "./mailProvider";

// This function gets a list of messages for a given user ID,
// and returns the response data as an array. If the unread parameter
// is true, the function will only return unread messages.
async function getMessages({
    userID,
    unread = false,
    pageNumber,
}: {
    userID: string;
    unread?: boolean;
    pageNumber?: number;
}) {
    const params: any = { userID };

    if (unread) {
        params.unread = "true";
    }

    if (pageNumber) {
        params.page_number = pageNumber;
    }

    const { data } = await api.get(endpoints.messagesList, { params });
    return data;
}

// This function gets a list of messages for a given user ID,
// and returns the response data as an array. If the unread parameter
// is true, the function will only return unread messages.
async function getUnreadMessages({
    userID
}: {
    userID: string;
}) {
    const params: any = { userID };

    const { data } = await api.get(endpoints.messagesUnread, { params });
    return data;
}

// This function gets a list of messages for a given Blocked user ID,
// and returns the response data as an array. If the unread parameter
// is true, the function will only return unread messages.
async function getBlockedMessages(userID: string, unread: boolean) {
    try {
        const params: any = { userID };

        if (unread) {
            params.unread = "true";
        }

        const { data } = await api.get(endpoints.blockedMessages, { params });
        return data;
    } catch {
        return [];
    }
}

// This function fetches the chat messages for a given thread.
// Input parameters: privateID, publicID
// Output: array of messages for the thread
async function getChatMessages(privateID: string, publicID: string) {
    try {
        const threadID = privateID + publicID;
        const params = { threadID };

        const { data } = await api.get(endpoints.messages, { params });
        return data;
    } catch {}
}

// This function is used to get the number of unread messages in a thread
// The threadID is passed as a parameter
// The number of unread messages is returned
async function getThreadMessageCount(threadID: string, isBlocked: boolean = false) {
    const endpoint = isBlocked ? endpoints.blockedMessages : endpoints.messages;
    const unread = !isBlocked;

    try {
        const { data } = await api.get(endpoint, { params: { threadID, unread } });
        return { threadID, ...data };
    } catch {}
}

// This function sends a message to the server and returns the messages in the chat
// The function takes in messageData, publicID, privateID, privateName, publicName, currentMessage as parameters
// The function calls the getChatMessages function to get the messages to send to the server
// The function calls the sendNotification function to send a notification to the user
type SendMessageProps = {
    messageData: MessageModel;
    publicID: string;
    privateID: string;
    privateName: string;
    currentMessage: string;
};

async function sendMessage({ messageData, publicID, privateID, privateName, currentMessage }: SendMessageProps) {
    try {
        await api.post(endpoints.message, messageData);
        const messages = await getChatMessages(publicID, privateID);
        await mailProvider.sendNotification({ messages, publicID, privateName, currentMessage });
        return messages;
    } catch {}
}

// This function is used to flag a message as read by sending a post request to the API.
// The function takes in an array of message ids and sends a post request to the API with the message id and read status.
async function flagMessagesAsRead(ids: Array<string>) {
    try {
        const data = {
            messageid: ids,
            read: "True",
        };
        await api.post(endpoints.message, data);
    } catch {}
}

// This function deletes all messages in a thread. The thread is specified by its privateID and publicID.
// The function returns an empty array if the message is not deleted, and the response result otherwise.
async function deleteChatMessages(privateID: string, publicID: string) {
    try {
        const threadID = privateID + publicID;

        const { data } = await api.delete(endpoints.message, { params: { threadID } });
        return data;
    } catch {
        throw Error();
    }
}

const messageProvider = {
    getMessages,
    getChatMessages,
    deleteChatMessages,
    sendMessage,
    flagMessagesAsRead,
    getThreadMessageCount,
    getBlockedMessages,
    getUnreadMessages
};

export { messageProvider };
