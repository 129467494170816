import { throwError } from "utils/throwError";
import { api } from "./api";
import { endpoints } from "./endpoints";

const API_CALL_DELAY = 100; //ms This time will be used to block the API call from being called again faster

let isTimerActive = false;

async function getLocations(search) {
    if (isTimerActive || !search || search?.length < 3) return;

    isTimerActive = true;

    try {
        return await api.get(`${endpoints.locations}?location=${search}`);
    } catch (error) {
        throwError(error);
    } finally {
        setTimeout(() => {
            isTimerActive = false;
        }, API_CALL_DELAY);
    }
}

const locationProvider = {
    getLocations,
};

export { locationProvider };
