import React, { useContext } from "react";
import { Loader, Text, View } from "@aws-amplify/ui-react";
import { NoUsersFound } from "components/UserList/NoUsersFound";
import { SortUserList } from "components/UserList/SortUserList";
import { MemoizedUserList } from "components/UserList/MemoizedUserList";
import FilterNew from "@components/FilterNew";
import { SortContext } from "context/SortContext";
import UserListSkeleton from "./UserListSkeleton";
import { AppTitleIcons } from "utils/icons";
import FilterChips from "./FilterChips/FilterChips";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "context/AppContext";
import { LoginContext } from "context/LoginContext";

const UserListComponent = ({
    userList,
    foundUsers,
    updateFavorites,
    isLoggedIn,
    userCompletedProfile,
    filterState,
    sortState,
    isFetchingPage,
    scrollTarget,
    onTargetScroll,
    loadingFiltersFields,
    filterFields,
    isChangingPage,
    sortingFilterIntegrated,
    title,
}) => {
    const {
        filters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        isFiltering,
        setDefaultFilters,
        currentFilters,
        generateQueryParam,
    } = filterState;
    const { sortingOptions, anchorEl, isSortMenuOpen, handleOpenSortMenu, handleCloseSortMenu, onSelectSort } =
        sortState;

    const _title = title || (window.location.pathname === "/starred" ? "Starred" : "User List");
    const { sortingOption, listOrder, setListOrder } = useContext(SortContext);
    const location = useLocation();
    const { setLoginPopup } = useContext(LoginContext);
    const { personalProfile } = useGlobalContext();

    const loading = isFiltering;

    const filterKeys = Object.keys(filters);

    // Shows 'clear filter' button if there is at least one filter selected
    const showClearFilterButton = filterKeys.some((key) => {
        if (key === "sort") {
            return false;
        }
        if (Array.isArray(filters[key])) {
            return Boolean(filters[key].length);
        }
        return Boolean(filters[key]);
    });
    const pathToName = (delteFilters, shouldDelete = false) => {
        if (filters && shouldDelete) {
            delteFilters.forEach((item) => {
                delete filters[item];
            });
        }
        return generateQueryParam(filters, false);
    };
    const renderFilterHasNoResults = () => {
        if (!foundUsers && !isFetchingPage) {
            return (
                <View className="user-list no-results">
                    <NoUsersFound
                        onBackFilter={() => setShowFilter(!showFilter)}
                        onTryAgain={clearFilter}
                        filters={currentFilters}
                        personalProfile={personalProfile}
                    />
                </View>
            );
        }

        return null;
    };

    if (loading || loadingFiltersFields || (isFetchingPage && !isChangingPage)) {
        return <UserListSkeleton />;
    }
    return (
        <View className="desktop-width fullscreen-mobile" id="view-user-list">
            <View>
                <View className="section-header-new">
                    {window.location.pathname === "/starred" ? AppTitleIcons.starred : AppTitleIcons.userList}
                    <Text as="h1" className="results-title">
                        {_title}
                    </Text>

                    <View className="user-filter col2of2">
                        {!sortingFilterIntegrated && (
                            <SortUserList
                                open={isSortMenuOpen}
                                anchorEl={anchorEl}
                                onSelect={onSelectSort}
                                onOpen={handleOpenSortMenu}
                                onClose={handleCloseSortMenu}
                                sortingOptions={sortingOptions}
                                selectedOption={sortingOption}
                                filterActive={showClearFilterButton}
                                listOrder={listOrder}
                            />
                        )}
                        <FilterNew
                            pathToName={pathToName}
                            filterFields={filterFields}
                            filters={filters}
                            clearFilter={clearFilter}
                            applyFilter={applyFilter}
                            usernameValues={usernameValues}
                            setUsernameVal={setUsernameVal}
                            showFilter={showFilter}
                            setShowFilter={setShowFilter}
                            handleFilters={handleFilters}
                            isLoggedIn={isLoggedIn}
                            setDefaultFilters={setDefaultFilters}
                            showClearFilterButton={showClearFilterButton}
                            filterActive={hasFilter}
                            setListOrder={setListOrder}
                            hasFilter={hasFilter}
                            showClearAll={showClearFilterButton}
                            sortingOptions={sortingOptions}
                            sortingFilterIntegrated={sortingFilterIntegrated}
                            setLoginPopup={setLoginPopup}
                        />
                    </View>
                </View>
                {/* THE SEARCH BAR WILL BE REMOVED UNTIL FURTHER NOTICE
                
                <View className="searchbar-wrapper">
                    <SearchBar
                        value={filters?.name}
                        onChange={(e) => {
                            handleFilters("name", e);
                        }}
                        setUsernameVal={setUsernameVal}
                        usernameValues={usernameValues}
                        clearFilter={() => clearFilter({ removeFilters: ["name"] })}
                    />
                </View> */}
                <View className="clear"></View>
            </View>
            <FilterChips
                pathToName={pathToName}
                clearFilter={clearFilter}
                currentFilters={currentFilters}
                filterActive={hasFilter}
                sortingOption={sortingOption}
                filterFields={filterFields}
                setLoginPopup={setLoginPopup}
                isLoggedIn={isLoggedIn}
            />
            <View className={"user-list"} ref={scrollTarget} onScroll={onTargetScroll}>
                {renderFilterHasNoResults()}
                <MemoizedUserList
                    userCompletedProfile={userCompletedProfile}
                    updateFavorites={updateFavorites}
                    userList={userList}
                    VTTOptions={filterFields.vtt}
                    pathname={location.pathname}
                    personalId={personalProfile?.id}
                />
                <div className="infinite-scroll-loader">
                    {isFetchingPage && (
                        <div>
                            <Loader width={"3rem"} height={"3rem"} filledColor={"white"} emptyColor={"red"} />
                        </div>
                    )}
                </div>
            </View>
        </View>
    );
};

export default UserListComponent;
