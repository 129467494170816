import "./GroupDialogs.scss";

import React from "react";

import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import { ArrowForwardIos, Close, Warning } from "@mui/icons-material";

import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { useBreakpoint } from "hooks/useBreakpoint";
import { conditionalClassName } from "utils/functions";

type CannotJoinGroupDialogProps = {
    open: boolean;
    onUndo: () => Promise<void> | void;
    onConfirm: () => Promise<void> | void;
};

export function CannotJoinGroupDialog({ open, onUndo, onConfirm }: CannotJoinGroupDialogProps) {
    const { isMobile } = useBreakpoint();

    const className = conditionalClassName(["group-dialog-container"], [isMobile, "group-dialog-mobile"]);

    return (
        <>
            <Dialog open={open}>
                <DialogContent className={className}>
                    <ShouldRender returnNull condition={!isMobile}>
                        <Warning
                            className="group-icon"
                            sx={{ background: "transparent", color: "#A61F38", width: "35px", height: "35px" }}
                        />
                    </ShouldRender>
                    <Box className="group-content">
                        <Typography variant="h3" className="group-alert-title">
                            Cannot join group
                        </Typography>
                        <Typography className="group-alert-description">
                            You must be logged in before you can join a group.
                        </Typography>
                        <Box className="group-dialog-buttons">
                            <SecondaryApplyButton onClick={onUndo} icon={<Close fontSize="small" />}>
                                cancel
                            </SecondaryApplyButton>
                            <ApplyButton onClick={onConfirm}>
                                Log In <ArrowForwardIos fontSize="small" />
                            </ApplyButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
