import { useState } from "react";
import { profileProvider } from "services/profileProvider";
import { useQueryClient } from "react-query";

export const useFavorites = ({
    allProfiles,
    setAllProfiles,
    reloadFavorites,
    favoritesToDelete,
    setFavoritesToDelete,
}) => {
    const [currentUserIsFavorite] = useState(false);
    const [favoritesList, setFavoritesList] = useState([]);
    const [wait, setWait] = useState(false);
    const queryClient = useQueryClient();

    const getFavorites = async () => {
        const favorites = await profileProvider.getFavorites();
        setFavoritesList(favorites);
    };

    const updateFavorites = async (id) => {
        setWait(true);
        const payload = { favorite: String(id) };
        const updateProfile = await profileProvider.putFavorite(payload);
        const isFav = updateProfile?.data?.includes("Added");

        setFavoritesToDelete((prev) => prev.filter((item) => item?.[0] !== id?.[0]));

        if (!isFav)
            setFavoritesToDelete((prev) => {
                return [...prev, id];
            });

        queryClient.invalidateQueries({ queryKey: ["userlist"] });
        if (!window.location.pathname.includes("/profile")) {
            const found = allProfiles?.find((item) => item.id?.[0]?.includes(id));

            if (found) {
                found.favorited = isFav;
            }

            const indexOfFound = allProfiles?.indexOf(found);

            const newProfiles = [...allProfiles];

            newProfiles[indexOfFound].favorited = isFav;

            if (window.location.pathname === "/starred") {
                newProfiles.splice(indexOfFound, 1);
            }

            setAllProfiles(newProfiles);

            reloadFavorites();
        }

        setWait(false);
    };

    return {
        currentUserIsFavorite,
        updateFavorites,
        wait,
        favoritesList,
        getFavorites,
        favoritesToDelete,
    };
};
