import "./GroupCampaignTier.scss";

import React, { useCallback, useEffect, useState } from "react";

import { ClearOutlined } from "@mui/icons-material";
import { Box, Typography, RadioGroup, FormControlLabel, Radio, TextField, IconButton } from "@mui/material";

import { CampaignTierType } from "models/group/GroupModel";
import { startPlayingRegexp } from "utils/constants";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { GroupsActionBar } from "../GroupsActionBar";
import { number } from "prop-types";

type GroupCampaignTierManagerProps = {
    cost: number;
    tier: CampaignTierType;
    setCost: (cost: number) => void;
    setTier: (tier: CampaignTierType) => void;
    variant: "CREATE" | "EDIT";
    showErrors?: boolean;
    handleSubmit: () => Promise<void>;
    submitDisabled: boolean;
    startPlayingProfileLink: string;
    setStartPlayingProfileLink: (link: string) => void;
};

export default function GroupCampaignTierManager({
    cost,
    tier,
    setTier,
    setCost,
    variant,
    showErrors = false,
    handleSubmit,
    submitDisabled = false,
    startPlayingProfileLink,
    setStartPlayingProfileLink,
}: GroupCampaignTierManagerProps) {
    const tiers: CampaignTierType[] = ["Free", "Paid"];

    /* States and Hooks */
    const { isDesktop, isLaptop } = useBreakpoint();
    const [internalCost, setInternalCost] = useState(`${cost}.00`);

    /* Lifecycle */

    useEffect(() => {
        setCost(parseFloat(internalCost.replace(".00", "")));
    }, [internalCost]);

    /* Functions */
    function handleCost(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        const [numberValue] = value.split(".");

        setInternalCost(`${numberValue}.00`);
    }

    const Tiers = () =>
        tiers.map((tierName) => (
            <FormControlLabel
                value={tierName}
                label={tierName}
                className="group-campaign-tier-radio"
                control={<Radio />}
                checked={tier === tierName}
                onChange={() => setTier(tierName)}
            />
        ));

    const PaidComponent = () => (
        <Box className="group-campaign-tier-paid">
            <Typography className="group-campaign-tier-hint">
                For campaigns with a price, share your StartPlaying Campaign Link to handle the gold and the glory
            </Typography>
            <Box className="group-campaign-tier-input-container">
                <TextField
                    required
                    value={startPlayingProfileLink}
                    label="StartPlaying Campaign Link"
                    error={
                        showErrors && (!startPlayingProfileLink || !startPlayingRegexp.test(startPlayingProfileLink))
                    }
                    helperText={showErrors && !startPlayingProfileLink && "Required Field"}
                    placeholder="http://startplaying.games/adventure/link"
                    onChange={(event: { target: { value: string } }) => setStartPlayingProfileLink(event.target.value)}
                    InputProps={{
                        endAdornment: (
                            <IconButton onClick={() => setStartPlayingProfileLink("")}>
                                <ClearOutlined fontSize="small" sx={{ color: "#FFFFFF8F" }} />
                            </IconButton>
                        ),
                    }}
                />
            </Box>
            <Box className="group-campaign-tier-input-container">
                <TextField
                    required
                    value={internalCost}
                    label="Price per session (USD)"
                    error={(showErrors && cost < 1) || isNaN(Number(cost))}
                    helperText={showErrors && cost < 1 && "A paid campaign must have a cost of at least 1 USD"}
                    onChange={handleCost}
                />
            </Box>
        </Box>
    );
    return (
        <Box className="profile-component">
            <ShouldRender returnNull condition={isDesktop || isLaptop}>
                <GroupsActionBar
                    disabled={submitDisabled}
                    title={variant === "CREATE" ? "Create group" : "Edit group"}
                    buttonText={variant === "CREATE" ? "Create Group" : "Save Changes"}
                    handleSubmit={handleSubmit}
                />
            </ShouldRender>
            <Box className="group-campaign-tier-container">
                <Box className="group-campaign-tiers">
                    <Typography className="group-campaign-tier-title" variant="h3">
                        Campaign type
                    </Typography>
                    <RadioGroup className="group-campaign-tier-radio-group">
                        <Tiers />
                    </RadioGroup>
                </Box>
                <ShouldRender returnNull condition={tier === "Paid"}>
                    {PaidComponent()}
                </ShouldRender>
            </Box>
        </Box>
    );
}
