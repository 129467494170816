import "./GroupPlatform.scss";

import React, { useContext, useEffect, useState } from "react";

import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { locationProvider } from "services/locationProvider";
import { LocationType, PlatformType } from "models/cms/fileds";
import { platformIsATable, formatLocation, generateLocationOptions } from "utils/functions";
import { GroupType } from "models/group/GroupModel";

type GroupPlatformManagerProps = {
    platform: number;
    setGroup: React.Dispatch<React.SetStateAction<Partial<GroupType>>>;
    groupCity?: string;
    groupState?: string;
    showErrors?: boolean;
    setPlatform: (platform: number) => void;
    groupCountry: string;
};

export default function GroupPlatformManager({
    platform,
    setGroup,
    groupCity,
    groupState,
    showErrors = false,
    setPlatform,
    groupCountry,
}: GroupPlatformManagerProps) {
    /* States and Hooks */
    const { filterFields }: { filterFields: { vtt: Array<PlatformType> } } = useContext(AsyncFilterFieldsContext);
    const [search, setSearch] = useState("");
    const [selectedPlatform, setSelectedPlatform] = useState<PlatformType | null>(null);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(false);
    const [locations, setLocations] = useState<Array<LocationType>>([]);
    const [location, setLocation] = useState<LocationType>({
        country: groupCountry,
        ...(groupState && { state: groupState }),
        ...(groupCity && { city: groupCity }),
    });

    /* Functions */
    const handlePlatform = (platform: PlatformType | null) => {
        if (platform?.id !== undefined) setPlatform(platform?.id);
    };


    const getLocations = async (searchRequest = search) => {
        try {
            setLoadingLocations(true);

            const response = await locationProvider.getLocations(searchRequest);
            const locations = generateLocationOptions(response?.data);
            setLocations(locations);
        } catch (error) {
            setLocations([]);
        } finally {
            setLoadingLocations(false);
        }
    };

    /* Lifecycle */

    useEffect(() => {
        const found = filterFields.vtt.find((vttPlatform: PlatformType) => platform === vttPlatform.id);
        if (found) setSelectedPlatform(found);
    }, [platform]);

    useEffect(() => {
        setGroup((prev) => ({ ...prev, state: location.state, city: location.city, country: location.country }));
    }, [location]);

    /* Variables */
    const platformOptions = filterFields?.vtt?.filter((platform: PlatformType) => platform.enabled);
    const tablePlatform: boolean = platformIsATable(selectedPlatform);

    const handleIsOptionEqualToValue = (option: LocationType, value: LocationType) => {
        const formattedOption = formatLocation(option, true);
        const formattedValue = formatLocation(value, true);

        return formattedValue === formattedOption;
    };

    useEffect(() => {
        if (search === "") {
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            getLocations();
        }, 450);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const handleOnChange = (newValue: string) => {
        setSearch(newValue);
    };


    return (
        <Box className="profile-component platform-component">
            <Typography variant="h3" className="platform-title ">
                Gaming platform
            </Typography>
            <Autocomplete
                id="group-platform"
                value={selectedPlatform}
                options={platformOptions}
                placeholder="Choose Platform"
                getOptionLabel={(platform: PlatformType) => platform.name}
                onChange={(_, value: PlatformType | null) => handlePlatform(value)}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Platform"
                        error={showErrors && !selectedPlatform}
                        helperText={showErrors && !selectedPlatform && "Please choose a platform"}
                        placeholder="Choose Platform"
                    />
                )}
            />
            <ShouldRender returnNull condition={tablePlatform}>
                <Box className="group-platform-location">
                    <Autocomplete
                        value={location}
                        onKeyDownCapture={(e) => e.stopPropagation()}
                        filterOptions={(options) => options}
                        disablePortal
                        options={locations}
                        isOptionEqualToValue={handleIsOptionEqualToValue}
                        loading={loadingLocations}
                        onChange={(_, value) => value && setLocation(value)}
                        getOptionLabel={(option) =>
                            [option.country, option.city, option.state].filter(Boolean).join(", ")
                        }
                        renderInput={(params) => (
                            <TextField
                                label="Location"
                                required
                                error={showErrors && !location}
                                helperText={showErrors && !location && "Please choose a location"}
                                placeholder="Choose Location"
                                onChange={(e) => handleOnChange(e.target.value)}

                                {...params}
                            />
                        )}
                    />
                    <Typography className="hint">
                        Note: if no location is set, it will default to the location displayed on your profile.
                    </Typography>
                </Box>
            </ShouldRender>
        </Box>
    );
}
