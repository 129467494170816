import React from "react";

import { CircularProgress } from "@mui/material";
import { ErrorBoundary } from "react-error-boundary";
import { ComponentSwitch } from "utils/functions";

import { View } from "@aws-amplify/ui-react";

function FallbackComponent({ error, resetErrorBoundary, setResetApp, resetApp }) {
    console.log("Error here");
    console.error(error);
    return <></>;
}

/**
 * Renders the appropriate component based on the given conditions.
 *
 * @param {Object} options - The options object.
 * @param {ReactNode} options.children - The component to render.
 * @param {boolean} [options.loading=false] - Indicates if the component is currently loading.
 * @param {boolean} [options.condition=true] - The condition to check for rendering.
 * @param {ReactNode} [options.alternativeComponent=null] - The alternative component to render if the condition is not fulfilled and not loading.
 * @param {ReactNode} [options.alternativeLoadingComponent=null] - The alternative loading component to render if the condition is not fulfilled and not loading.
 * @param {boolean} [options.returnNull] - Indicates if null should be returned instead of the loading spinner.
 * @param {string} [options.loaderClass=""] - The CSS class for the loading spinner.
 * @param {Object} [options.errorBoundary] - The error boundary configuration.
 * @param {Function} [options.errorBoundary.onReset=() => {}] - The function to call when the error boundary is reset.
 * @param {ReactNode} [options.errorBoundary.FallbackComponent] - The fallback component to render when an error occurs.
 * @param {Array} [options.errorBoundary.resetKeys=[]] - The keys to trigger error boundary reset.
 * @param {boolean} [options.disableErrorBoundary=false] - Indicates if the error boundary should be disabled.
 * @param {Object} props - The props to pass to the children component.
 * @returns {ReactNode} - The rendered component.
 */
export function ShouldRender({
    children,
    loading = false,
    condition = true,
    alternativeComponent = null,
    alternativeLoadingComponent = null,
    returnNull,
    loaderClass = "",
    errorBoundary = { onReset: () => {}, FallbackComponent: FallbackComponent, resetKeys: [] },
    disableErrorBoundary = false,
    ...props
}) {
    return ComponentSwitch(
        // Case: condition is not fulfilled, not loading, and there's an alternative component
        !condition && !loading && !!alternativeComponent,
        // return the alternative component
        alternativeComponent,

        // Case: condition is not fulfilled, returnNull flag is true, and not loading
        !condition && returnNull && !loading,
        // return null instead of loading spinner
        null,

        // Case: condition is not fulfilled, not loading, and there's an alternative loading component
        loading && !!alternativeLoadingComponent,
        // return the alternative loading component
        alternativeLoadingComponent,

        // Case: condition is not fulfilled and is loading
        !condition || (loading && !alternativeLoadingComponent),
        // return the loading spinner
        <View className={loaderClass}>
            <CircularProgress />
        </View>,

        // Case: disableErrorBoundary is true
        disableErrorBoundary,
        // return children without error boundary
        React.cloneElement(children, props),

        // Default behavior
        // return children wrapped in ErrorBoundary
        <ErrorBoundary {...errorBoundary}>{React.cloneElement(children, props)}</ErrorBoundary>,
    );
}
