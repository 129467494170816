import React, { useState, useEffect } from "react";
import "../../pages/Home/UserList.css";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { defaultSorting } from "context/SortContext";
// SERVICES
import { Button } from "@mui/material";
import FilterPopup from "./FilterPopup";
import { useBreakpoint } from "hooks/useBreakpoint";
import { useNavigate } from 'react-router-dom';

const FilterNew = ({
    filters,
    clearFilter,
    applyFilter,
    usernameValues,
    setUsernameVal,
    setShowFilter,
    handleFilters,
    setDefaultFilters,
    showClearFilterButton,
    filterActive,
    filterFields,
    setListOrder,
    hasFilter,
    showClearAll,
    sortingOptions,
    sortingFilterIntegrated,
    isLoggedIn,
    setLoginPopup,
    pathToName,
}) => {
    const [ownFilterActive, setOwnFilterActive] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { isMobile } = useBreakpoint();
    const handleCloseFilter = () => {
        setAnchorEl(null);
    };
    const navigate = useNavigate();

    const handleOpenFilter = (event) => {
        if (isLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            setLoginPopup(true);
        }
    };

    const handleClearFilter = (event, clearOptions) => {
        event.stopPropagation();
        event.preventDefault();
        if (isLoggedIn) {
            const isSort = clearOptions?.preserveFilters.find((item) => Object.keys(item).includes("sort"));
            if (!isSort) {
                setListOrder(defaultSorting);
            }
            clearFilter(clearOptions);
            if (window.location.pathname.includes("location") || window.location.pathname.includes("game")) {
                return navigate("/user-list");
            }
        } else {
            setLoginPopup(true);
        }
    };

    /**
     * Apply filter only on clicking apply button
     */
    useEffect(() => {
        setOwnFilterActive(hasFilter);
    }, []);

    return (
        <>
                <Button
                    className={`user-list-header-btn ${anchorEl ? "active" : ""} ${
                        ownFilterActive ? "selected" : "not-selected"
                    }`}
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    endIcon={
                        hasFilter  && (
                            <ClearIcon
                                data-testid="clear-button"
                                onClick={(event) => handleClearFilter(event, { preserveFilters: ["sort"] })}
                            />
                        )
                    }
                    onClick={handleOpenFilter}>
                    FILTERS & SORT
                </Button>
            

            <FilterPopup
                pathToName={pathToName}
                filterFields={filterFields}
                showFilter={Boolean(anchorEl)}
                setShowFilter={setShowFilter}
                filters={filters}
                handleFilters={handleFilters}
                clearFilter={clearFilter}
                applyFilter={() => applyFilter()}
                setUsernameVal={setUsernameVal}
                usernameValues={usernameValues}
                setDefaultFilters={() => setDefaultFilters()}
                handleClearFilter={handleClearFilter}
                anchorEl={anchorEl}
                setAnchorEl={setAnchorEl}
                handleCloseFilter={handleCloseFilter}
                showClearFilterButton={showClearFilterButton}
                hasFilter={hasFilter}
                showClearAll={showClearAll}
                sortingOptions={sortingOptions}
                sortingFilterIntegrated={sortingFilterIntegrated}
            />
        </>
    );
};

export default FilterNew;
