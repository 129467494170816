import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import "./GroupLanguages.scss";

import React, { useContext } from "react";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { CMSLanguages } from "models/cms/fileds";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

type GroupLanguagesManagerProps = {
    language: number;
    setLanguages: (language: number) => void;
};
export default function GroupLanguagesManager({ language, setLanguages }: GroupLanguagesManagerProps) {
    const { filterFields }: { filterFields: { languages: Array<CMSLanguages> } } = useContext(AsyncFilterFieldsContext);

    return (
        <Box className="profile-component languages-container">
            <Typography className="languages-title" variant="h3">
                Group language
            </Typography>
            <ShouldRender condition={filterFields.languages.length > 0}>
                <Autocomplete
                    value={filterFields.languages.find((x) => x.id === language)}
                    options={filterFields.languages}
                    onChange={(_, value) => value?.id !== undefined && setLanguages(value?.id)}
                    renderInput={(params) => <TextField {...params} label="Select Language" />}
                    getOptionLabel={(option) => option.languageName}
                />
            </ShouldRender>
            <Typography className="hint">Note: if no language is set, it will default to English.</Typography>
        </Box>
    );
}
