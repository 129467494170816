import React from "react";

import PlayerChip from "./PlayerChip";
import { PlayerProps } from "models/group/PlayerProps";

interface Params {
    groupId: string;
    hostView: boolean;
    groupSize: number;
    isEditing?: boolean;
    filledSeats: number;
    handleJoinGroupDialog: () => Promise<void> | void;
}
const RenderOpenSeats = ({
    groupId,
    hostView,
    groupSize,
    isEditing = false,
    filledSeats,
    handleJoinGroupDialog,
}: Params) => {
    const openSeatsNumber = groupSize - filledSeats;

    const mockHandleRemovePlayer = async (player: PlayerProps) => {};

    return (
        <>
            {openSeatsNumber > 0 &&
                Array(openSeatsNumber)
                    .fill(true)
                    .map((_, index) => (
                        <PlayerChip
                            player={{
                                name: "",
                                id: "",
                                profileUrl: "",
                                avatar_id: 0,
                                member_type: "OPEN SEAT",
                                avatar: "",
                            }}
                            key={`open seat - ${index}`}
                            groupId={groupId}
                            hostView={hostView}
                            isEditing={isEditing}
                            memberType="OPEN SEAT"
                            handleJoinGroupDialog={handleJoinGroupDialog}
                            handleRemovePlayer={mockHandleRemovePlayer}
                        />
                    ))}
        </>
    );
};

export default RenderOpenSeats;
