import "./InboxSkeleton.scss";
import React, { useMemo } from "react";

import { Skeleton, Box } from "@mui/material";
import { useGlobalContext } from "context/AppContext";
import { useMessagesContext } from "@context/MessagesContext";

const InboxSkeletonItem = () => {
    const { isDesktop } = useGlobalContext();
    const { publicID, returnInbox } = useMessagesContext();

    const bigSkeleton = useMemo(() => {
        return (publicID === "" || returnInbox) && isDesktop;
    }, [isDesktop, publicID, returnInbox]);

    const widthProfilePicture = bigSkeleton ? 80 : 60;

    return (
        <Box className="inbox-message">
            <Box className="inbox-message-avatar">
                <Skeleton variant="circular" width={widthProfilePicture} height={widthProfilePicture} />
            </Box>
            <Box className="inbox-message-identity">
                <Skeleton variant="text" width={bigSkeleton ? 250 : 100} />
                <Skeleton variant="text" width={bigSkeleton ? 350 : 150} />
            </Box>
        </Box>
    );
};

export const InboxSkeleton = () => {
    return (
        <Box className="inbox-skeleton" data-testid="inbox-skeleton" style={{ maxHeight: "100%", overflow: "hidden" }}>
            {Array(9)
                .fill(0)
                .map((_, index) => {
                    return (
                        <React.Fragment key={index}>
                            <InboxSkeletonItem />
                        </React.Fragment>
                    );
                })}
        </Box>
    );
};
