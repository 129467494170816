import React from "react";
import Markdown from "marked-react";
import "./styles.css";
import "../../../components/UserList/FilterChips/FilterChips.css";
import { Badge } from "./Badge";
import { AccountIcon } from "./AccountIcon";
import { AccountMultipleIcon } from "./AccountMultipleIcon";

const ChipValue = ({ type, value }) => {
    return (
        <div className="chip-label" data-testid="chip-label">
            <span>{`${type}: `}</span>
            <span className="chip-value">{value}</span>
        </div>
    );
};

export const GameBanner = ({ image, title, description, badges, links, expanded, expand, people, group }) => {
    return (
        <div className="game-banner-container" data-testid="game-banner-container">
            <div className="game-banner-img-box">
                <img src={image} />
                <div className="game-banner-badges">
                    {group && <Badge icon={<AccountIcon />} value={group} />}
                    {people && <Badge icon={<AccountMultipleIcon />} value={people} />}
                </div>
            </div>
            <div className={`game-banner-content ${expanded && "expanded"}`}>
                <h1 className="no-padding">{title}</h1>
                <Markdown>{description}</Markdown>
            </div>
        </div>
    );
};

const ExpandButton = ({ expanded, onClick }) => {
    return (
        <div className={`expand-button-cover ${expanded && "no-cover"}`}>
            <div className="expand-button" onClick={onClick}>
                <div className={`arrow ${expanded && "expanded"}`}></div>
                {!expanded && <span>EXPAND</span>}
            </div>
        </div>
    );
};
