import { useGamePageContext } from "@context/GamePageContext";
import { ProfileAvatar } from "@components/ProfileAvatar/ProfileAvatar";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getGameByUrl } from "services/contentful";
import { profileProvider } from "services/profileProvider";
import { awsFileURL, gameUrlToName, locationFriendlyAddr } from "utils/functions";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { useGlobalContext } from "context/AppContext";

export const usePublicGamePage = (gameName) => {
    const location = useLocation();
    const [metadata, setMetadata] = useState({});
    const address = location.pathname.replace("/game", "");
    const friendlyAddr = locationFriendlyAddr(address);
    const defaultDescription = `Connect with TTRPG players locally and globally for adventures in ${friendlyAddr} campaigns. Start your ${friendlyAddr} campaign today and let RPGMatch pair you up with your perfect match!`;
    const deafultTitle = "Find Your Local & Global companions on RPGMatch!";
    const { setCurrentGameInfo } = useGamePageContext();
    const { filterFields } = useContext(AsyncFilterFieldsContext);
    const { personalProfile } = useGlobalContext();

    const formatRightCards = async (game) => {
        if (!game?.rightCards) {
            return [];
        }

        const cards = game.rightCards.map((card) => {
            return { ...card.fields, contentType: card.sys.contentType.sys.id };
        });
        const indexesToRemove = [];

        for (let index = 0; index < cards.length; index++) {
            if (cards[index].banner) {
                cards[index].banner = `https:${cards[index].banner.fields.file.url}`;
            }

            if (cards[index].background) {
                cards[index].background = `https:${cards[index].background.fields.file.url}`;
            }

            if (cards[index].contentType === "publisherPage") {
                try {
                    const { data: user } = await profileProvider.getProfile(cards[index].id);

                    cards[index] = {
                        ...cards[index],
                        title: user?.name?.[0] ?? "",
                        verified: user?.verified?.[0] ?? false,
                        image: <ProfileAvatar profile={user} alt="Card Avatar" />,
                    };

                    const vanityUrl = user?.vanityUrl?.[0] ?? null;
                    if (vanityUrl) {
                        cards[index].path = `/profile/${vanityUrl}`;
                    }
                } catch {
                    indexesToRemove.push(index);
                    console.error("An user with this ID does not exist, card will not be shown.");
                }
            }

            if (cards[index].contentType === "badgeUserList") {
                const badge = filterFields?.badgesOptions?.find((x) => x.id === cards[index].id) ?? null;

                if (badge) {
                    cards[index].image = `${awsFileURL()}/badges/${badge?.img}`;

                    const hasBadge = personalProfile?.badges?.includes(badge?.id) ?? false;
                    cards[index].description = hasBadge ? "Explore Badge Allies!" : "Unlock your free badge!";
                    cards[index].path = hasBadge ? `/user-list?badges=${badge?.id}` : `/profile?badge=${badge?.name}`;
                    delete cards[index].title;
                } else {
                    indexesToRemove.push(index);
                    console.error("A badge with this ID does not exist, card will not be shown.");
                }
            }

            if (cards[index].contentType === "partner") {
                delete cards[index].title;
            }
        }

        return cards.filter((_, index) => !indexesToRemove.includes(index));
    };

    useEffect(() => {
        if (filterFields?.badgesOptions?.length > 0) {
            (async () => {
                const game = await getGameByUrl(gameName);
                const gameNameToShow = game?.name ?? gameUrlToName(gameName);
                const rightCards = await formatRightCards(game);

                const mountPage = {
                    gameId: game?.id,
                    image: getPageImage(game),
                    pageTitle: getFallbackTitle(gameNameToShow, deafultTitle, game?.pageTitle),
                    HTMLTitle: `${game?.pageTitle || gameNameToShow} | RPGMatch`,
                    description: game?.pageDescription || defaultDescription,
                    ogDescription: game?.ogDescription || game?.pageDescription || defaultDescription,
                    ogTitle: getFallbackTitle(gameNameToShow, deafultTitle, game?.ogTitle),
                    ogImage: getOgImage(game),
                    rightCards,
                };

                setMetadata(mountPage);
                setCurrentGameInfo(mountPage);
            })();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameName, filterFields?.badgesOptions]);

    return {
        ...metadata,
    };
};

const getPageImage = (game) => game?.pageImage?.fields?.file?.url || "/banner-dice.jpeg";
const getOgImage = (game) =>
    game?.ogImage?.fields?.file?.url || game?.pageImage?.fields?.file?.url || "/banner-dice.jpeg";
const getFallbackTitle = (gameName, deafultTitle, pageTitle) => {
    return pageTitle || `Play ${gameName} : ${deafultTitle}`;
};
