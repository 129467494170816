import "./AlertPopupSmall.scss";

import React from "react";

import { Box, Alert, AlertTitle, AlertColor, Modal, Fade, SxProps, Typography } from "@mui/material";

export interface AlertPopUpProps {
    severity: AlertColor;
    title: string;
    description: React.ReactElement | string;
    open: boolean;
}

export const AlertPopUpSmall: React.FC<AlertPopUpProps> = ({ severity, title, description, open }) => {
    const styles: Record<string, SxProps> = {
        alertSmallContainerSx: {
            maxWidth: 320,
            margin: "2rem auto auto auto",
            outline: "none",

            ".MuiSvgIcon-root": {
                color: "#fff",
            },

            // Set background and border color based on severity
            // TODO: Remove when we have themes

            ...(severity === "success" && {
                backgroundColor: "#182B1A",
                borderColor: "#4CAF50",
            }),

            ...(severity === "warning" && {
                backgroundColor: "#F28705",
                borderColor: "#F29F05",
            }),
            ...(severity === "error" && {
                backgroundColor: "#4D1818",
                borderColor: "#FF1744",
            }),
        },

        alertTitle: {
            color: "#fff",
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "24px",
            letterSpacing: "0.15000000596046448px",
            textAlign: "left",
        },

        alertDescription: {
            color: "#fff",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20.02px",
            textAlign: "left",
        },
    };

    return (
        <Modal className="alert-small" open={open} hideBackdrop>
            <Fade in={open}>
                <Box sx={styles.alertSmallContainerSx}>
                    <Alert severity={severity} variant="outlined">
                        <AlertTitle sx={styles.alertTitle}>{title}</AlertTitle>
                        <Typography sx={styles.alertDescription}>{description}</Typography>
                    </Alert>
                </Box>
            </Fade>
        </Modal>
    );
};
