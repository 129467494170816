/* eslint-disable react-hooks/exhaustive-deps */
import { defaultFilters, FilterContext } from "context/FilterContext";
import { useContext, useEffect, useState } from "react";
import {
    clearFalsyFromObject,
    paramsToObject,
    clearFalsyFromObject2,
    gameNameToURL,
    titleCase,
    checkLoggedIn,
} from "@utils/functions";
import { profileProvider } from "services/profileProvider";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isEqual } from "lodash";
import { defaultSorting, SortContext } from "context/SortContext";
import getDeeplink from "utils/getDeeplink";
import { usePaginatedContext } from "context/PaginatedUserListContext";
import { useGlobalContext } from "context/AppContext";

export const useFilter = ({ filterFields, publicLocation = false, isUserList = false, publicGame = false }) => {
    const {
        filters,
        setFilters,
        showFilter,
        setShowFilter,
        setHasFilter,
        hasFilter,
        isFiltering,
        setIsFiltering,
        isOrdered,
        setResultsCount,
        currentFilters,
        setCurrentFilters,
        setPage,
        setPreviousFilters,
        previousFilters,
        isFirstSessionEntry,
        setIsFirstSessionEntry,
    } = useContext(FilterContext);
    const [searchParams, setSearchParams] = useSearchParams();
    const { setSortingOption, setListOrder } = useContext(SortContext);
    const { setUsers } = usePaginatedContext();
    const location = useLocation();
    const { personalProfile } = useGlobalContext();

    const clearFilter = async ({ preserveFilters, removeFilters } = { preserveFilters: [], removeFilters: [] }, cb) => {
        if (preserveFilters?.length > 0 && removeFilters?.length > 0) {
            console.error("You can not use removeFilters and preserveFilters at the same time");
            return;
        }

        setUsers([]);
        setIsFiltering(true);

        const copyOfFilters = global.structuredClone(filters);

        let newSearchParams = { sort: "" },
            newFilters = global.structuredClone(defaultFilters);

        if (removeFilters?.length > 0) {
            newSearchParams = paramsToObject(searchParams.entries()) || [["sort", ""]];

            removeFilters.forEach((filterKey) => {
                try {
                    if (filterKey.value !== undefined) {
                        const searchParamsKeyCopy = newSearchParams[filterKey.key].split(",");

                        const filtersIndex = copyOfFilters[filterKey.key].findIndex(
                            (x) => x?.id === filterKey.value || x === filterKey.value,
                        );
                        copyOfFilters[filterKey.key].splice(filtersIndex, 1);

                        if (copyOfFilters[filterKey.key]?.length < 1) {
                            delete copyOfFilters[filterKey?.key];
                            delete newSearchParams[filterKey?.key];
                        } else {
                            searchParamsKeyCopy.splice(searchParamsKeyCopy.indexOf(filterKey.value.toString()), 1);
                            newSearchParams[filterKey.key] = searchParamsKeyCopy.join(",");
                        }
                    } else {
                        delete copyOfFilters[filterKey];
                        delete newSearchParams[filterKey];
                    }
                } catch (error) {
                    console.log("the filter had an error");
                }
            });

            newFilters = { ...defaultFilters, ...copyOfFilters };
        } else if (preserveFilters?.length > 0) {
            newSearchParams = paramsToObject(searchParams.entries()) || [["sort", ""]];

            setListOrder(newSearchParams?.sort === "match" ? "match score" : "join date");
            preserveFilters.forEach((filterKey) => {
                delete newFilters[filterKey];
            });

            Object.keys(newFilters).forEach((filterKey) => {
                delete newSearchParams[filterKey];
                delete copyOfFilters[filterKey];
            });

            newFilters = { ...defaultFilters, ...copyOfFilters };
        }
        setDefaultSessionStorage();
        setPage(1);
        setFilters(newFilters);
        setPreviousFilters({ ...currentFilters });
        setCurrentFilters(newFilters);
        setSearchParams(newSearchParams);
        setIsFiltering(false);
        let compareNewFilters = global.structuredClone(newFilters);
        let compareDefaultFilters = global.structuredClone(defaultFilters);

        delete compareNewFilters.sort;
        delete compareDefaultFilters.sort;

        if (isEqual(compareNewFilters, compareDefaultFilters)) setHasFilter(false);
    };

    // USERNAME AUTO COMPLETE
    const [usernameValues, setUsernameValues] = useState([]); // AUTOCOMPLETE VALUES
    const [usernameVal, setUsernameVal] = useState("");

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            profileProvider.filterUsersAutocomplete(usernameVal, setUsernameValues);
        }, 450);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [usernameVal]);

    async function applyFilter(firstFilters, goBackFilters = false) {
        setDefaultSessionStorage();
        setPage(1);
        setIsFiltering(true);
        setHasFilter(false);
        setShowFilter(false);
        setResultsCount(0);
        setUsers([]);
        getDeeplink({
            setSortingOption,
            setCurrentFilters,
            setFilters,
            filters: goBackFilters ? previousFilters : filters,
            firstFilters,
            setHasFilter,
            setUsernameVal,
            setListOrder,
            generalBadges: filterFields.badgesOptions || [],
            setSearchParams,
            setPreviousFilters,
            currentFilters,
            publicLocation,
            publicGame,
        });

        setIsFiltering(false);
    }

    const onBackFilter = () => {
        applyFilter({}, true);
    };

    const setDefaultSessionStorage = () => {
        sessionStorage.setItem(`${location.pathname.replace("/", "")}_pageNumber`, 1);
        sessionStorage.setItem(`${location.pathname.replace("/", "")}_initialScrollPosition`, 0);
    };

    const generateQueryParam = (data, setParameterInUrl = true) => {
        const entries = searchParams.entries();
        const current = paramsToObject(entries);

        let params = new URLSearchParams(data);

        if (entries && !isEqual(data, current)) {
            if (data.country || data.location) {
                ["country", "state", "city", "location"].forEach((x) => delete current[x]);
            }
            params = new URLSearchParams(clearFalsyFromObject({ ...current, ...data }));
        }

        if (setParameterInUrl) {
            setSearchParams(params);
        }
        return params;
    };

    const removeQueryParam = (key) => {
        const entries = searchParams.entries();
        const params = new URLSearchParams(paramsToObject(entries));
        if (Array.isArray(key)) {
            key.forEach((k) => params.delete(k));
        } else {
            params.delete(key);
        }
        setSearchParams(params);
    };

    const setDefaultFilters = () => {
        setFilters({ ...defaultFilters });
        setPreviousFilters({ ...currentFilters });
        setCurrentFilters({ ...defaultFilters });
        setHasFilter(false);
        setListOrder(defaultSorting);
    };
    /**
     * Using this useeffect to always change hasFilter boolean as a lot of components depends on these
     */
    useEffect(() => {
        try {
            const cleanFilter = clearFalsyFromObject2(filters);
            const filterWithoutSort = { ...cleanFilter };
            delete filterWithoutSort["sort"];
            const reallyHasFilter = Object.keys(filterWithoutSort).length > 0;
            setHasFilter(reallyHasFilter);
        } catch (error) {
            setHasFilter(false);
        }
    }, [filters]);

    const handleFilters = (type, value) => {
        let params, newFilters;
        setSortingOption(["User Join"]);

        switch (type) {
            case "playerType":
                if (filters?.playerType?.includes(value)) {
                    const removedValue = filters?.playerType?.filter?.((pt) => pt !== value);
                    newFilters = { ...filters, [type]: removedValue };
                    if (removedValue?.length > 0) {
                        params = generateQueryParam({ [type]: removedValue });
                    } else {
                        removeQueryParam(type);
                    }
                } else {
                    const playerTypeFilter = filters?.playerType || [];
                    const newPlayerTypeFilter = [...playerTypeFilter, value];
                    newFilters = { ...filters, [type]: newPlayerTypeFilter };
                    params = generateQueryParam({ [type]: newPlayerTypeFilter });
                }
                setFilters(newFilters);
                break;
            case "safetyTools":
                if (filters.safetyTools === value) {
                    newFilters = { ...filters, safetyTools: "" };
                    removeQueryParam(type);
                } else {
                    newFilters = { ...filters, safetyTools: value };
                    params = generateQueryParam({ [type]: value });
                }
                setFilters(newFilters);
                break;
            case "vtt":
                const vttQuerystring = value.map((item) => item.id);
                newFilters = { ...filters, [type]: vttQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: vttQuerystring.join(",") });
                break;
            case "name":
                setUsernameVal(value);
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);

                /**
                 * Applying filter here because it was removed from filter popup
                 */
                applyFilter(newFilters);
                params = generateQueryParam({ [type]: value });
                break;
            case "age":
                if (filters?.age?.includes(value)) {
                    const removedAge = filters?.age?.filter?.((age) => age !== value);
                    newFilters = { ...filters, age: removedAge };
                    if (removedAge?.length > 0) {
                        params = generateQueryParam({ [type]: removedAge });
                    } else {
                        removeQueryParam(type);
                    }
                } else {
                    const ageFilter = filters?.age || [];
                    const newAgeFilter = [...ageFilter, value];
                    newFilters = { ...filters, age: newAgeFilter };
                    params = generateQueryParam({ [type]: newAgeFilter });
                }
                setFilters(newFilters);

                break;
            case "gamesPlayed":
                const gameQuerystring = value.map((item) => item.id);
                newFilters = { ...filters, [type]: gameQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: gameQuerystring.join(",") });
                break;
            case "availability":
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);
                if (value) {
                    params = generateQueryParam({ [type]: value });
                } else {
                    removeQueryParam(type);
                }
                break;
            case "location":
                newFilters = {
                    ...filters,
                    country: value?.country || "",
                    state: value?.state || "",
                    city: value?.city || "",
                    location: value?.location || "",
                };

                if (value === null) {
                    removeQueryParam(["country", "state", "city", "location"]);
                } else {
                    params = generateQueryParam(value);
                }

                setFilters(newFilters);
                break;
            case "badges":
                const badgeQuerystring = value.map((item) => item.id);
                newFilters = { ...filters, [type]: badgeQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: badgeQuerystring.join(",") });
                break;
            case "verified":
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);

                if (value) {
                    params = generateQueryParam({ [type]: value });
                } else {
                    removeQueryParam(type);
                }
                break;
            case "languages":
                const languageQuerystring = value.map((item) => item.id);
                newFilters = { ...filters, [type]: languageQuerystring };
                setFilters(newFilters);
                params = generateQueryParam({ [type]: languageQuerystring.join(",") });
                break;

            case "sort":
                newFilters = { ...filters, [type]: value };
                setFilters(newFilters);
                break;
            default:
                newFilters = { ...filters };
                setFilters(newFilters);
                break;
        }

        return { params, newFilters };
    };

    const pathToReadableParam = (param) => {
        if (param) {
            return decodeURIComponent(titleCase(param));
        }
        return "";
    };

    const getWorkingFilters = () => {
        const currentParams = paramsToObject(searchParams);
        const workingFilters = clearFalsyFromObject2(currentParams) || {};

        if (publicLocation) {
            try {
                const pathToParams = window?.location?.pathname.split("/").filter((item) => item !== "location");

                const country = pathToReadableParam(pathToParams?.[1]);
                const state = pathToReadableParam(pathToParams?.[2]);
                const city = pathToReadableParam(pathToParams?.[3]);
                workingFilters.country = country;
                workingFilters.state = state;
                workingFilters.city = city;
            } catch (error) {
                console.log("error", error);
            }
        }
        if (publicGame) {
            const path = window?.location?.pathname;
            if (path.startsWith("/game/")) {
                const gameName = path.split("/").slice(-1);
                const decodedGameName = gameNameToURL(gameName[0]);
                const gameFound = filterFields.games.find((game) => gameNameToURL(game.name) === decodedGameName);
                if (gameFound) {
                    const splitGames = String(gameFound.id);
                    workingFilters.gamesPlayed = splitGames;
                }
            }
        }

        if (workingFilters?.age) {
            const splitAge = workingFilters?.age?.split(",");
            workingFilters.age = splitAge;
        }

        if (workingFilters?.gamesPlayed) {
            const splitGames = workingFilters?.gamesPlayed?.split(",");
            workingFilters.gamesPlayed = splitGames.map((x) => Number(x));
        }

        if (workingFilters?.vtt) {
            const splitVtt = workingFilters?.vtt?.split(",");
            workingFilters.vtt = splitVtt.map((x) => Number(x));
        }

        if (workingFilters?.badges) {
            const splitBadges = workingFilters?.badges?.split(",");
            workingFilters.badges = splitBadges.map((x) => Number(x));
        }

        if (workingFilters?.playerType) {
            const splitPlayerType = workingFilters?.playerType?.split(",");
            workingFilters.playerType = splitPlayerType;
        }

        if (workingFilters?.languages) {
            const splitLanguages = workingFilters?.languages?.split(",");
            workingFilters.languages = splitLanguages.map((x) => Number(x));
        }

        if (workingFilters) {
            delete workingFilters.matchscore;
        }

        if (Object.keys(workingFilters).length === 0) {
            const hasCurrentFilters = JSON.stringify(currentFilters) !== JSON.stringify(defaultFilters);
            if (hasCurrentFilters) {
                return { ...currentFilters };
            }
        }

        return workingFilters;
    };

    /**
     * use effect to load search params once
     */
    useEffect(() => {
        (async () => {
            const checkIfLogged = await checkLoggedIn();
            if (((!checkIfLogged && !personalProfile) || personalProfile) && filterFields.games?.length) {
                const workingFilters = getWorkingFilters();
                const hasFilters = Object.keys(workingFilters).length > 0;

                if (hasFilters) {
                    applyFilter(workingFilters);
                }

                if (personalProfile && isUserList && isFirstSessionEntry) {
                    setIsFirstSessionEntry(false);
                    if (!hasFilters) {
                        applyFilter({ country: personalProfile?.country[0] ?? "" });
                    }
                }
            }
        })();
    }, [personalProfile, filterFields.games?.length]);

    return {
        filters,
        setFilters,
        clearFilter,
        applyFilter,
        usernameValues,
        setUsernameVal,
        showFilter,
        setShowFilter,
        handleFilters,
        hasFilter,
        isFiltering,
        isOrdered,
        setDefaultFilters,
        setIsFiltering,
        currentFilters,
        onBackFilter,
        generateQueryParam,
    };
};
