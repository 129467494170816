import { useGlobalContext } from "context/AppContext";
import { useNavigate } from "react-router-dom";
import { signOut } from "utils/functions";

export const useSignOut = () => {
    const { updatePersonalProfile, updateIsLoggedIn } = useGlobalContext();

    const navigate = useNavigate();

    const signOutAndRedirect = async () => {
        await signOut();
        updateIsLoggedIn(false);
        updatePersonalProfile(null);
        navigate("/login");
    }

    const navSignOut = async (hamburgerClick) => {
        hamburgerClick();
        await signOutAndRedirect();
    };

    const leftMenuSignOut = async (event) => {
        event.stopPropagation();
        await signOutAndRedirect();
    };

    return {
        navSignOut,
        leftMenuSignOut,
        signOutAndRedirect
    };
};
