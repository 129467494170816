import React, { useState } from "react";

import Favorited from "components/Favorited";
import { Tooltip, Box, CircularProgress } from "@mui/material";

import Styles from "./styles.module.css";

export default function FavoritedStarButton({
    updateFavorites,
    favorited,
    changeFavorited,
    favoritesTooltipTitle,
    wait,
    setPreventProfileClick,
    preventProfileClick,
    id,
}) {
    const [loading, setLoading] = useState(false);
    return (
        <Tooltip title={favoritesTooltipTitle} placement="top-start" enterDelay={500} leaveDelay={200}>
            <Box
                data-testid="favorited-star"
                className={`star-container add-to-favorites-component ${wait ? "disabled" : ""}`}
                onClick={async () => {
                    setLoading(true);
                    await updateFavorites(id);
                    setLoading(false);
                }}
                onMouseEnter={() => setPreventProfileClick?.(true)}
                onMouseLeave={() => setPreventProfileClick?.(false)}
                onFocus={() => setPreventProfileClick?.(true)}
                onBlur={() => setPreventProfileClick?.(false)}
                onKeyPress={(e) => {
                    e.code === "Enter" && updateFavorites(id);
                }}
                role="button"
                aria-pressed={wait}
                tabIndex={0}>
                {loading ? (
                    <CircularProgress size={20} className={Styles.loader} />
                ) : (
                    <Favorited favorited={favorited} changeFavorited={changeFavorited} />
                )}
            </Box>
        </Tooltip>
    );
}
