import React from "react";
import { Skeleton, Box,} from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";

const UserListSkeleton = () => {
    const { isMobile } = useBreakpoint();
    return (
            <Box data-testid="user-list-skeleton" className="user-list-skeleton fullscreen-mobile">
                <Box className="user-list-skeleton-header" >
                    <Skeleton variant="rounded" width={"50px"} height={"12px"} />
                <Box className="user-list-skeleton-header-right" >
                    <Skeleton variant="rounded" width={isMobile ? "40px" :"93px"} height={"30px"} />
                </Box>
                </Box>
                {Array(6)
                    .fill()
                    .map((item, index) => (
                        <Box key={index} className="user-list-item-skeleton">
                            <Box className="user-main-information-container">
                                <Box className="user-avatar-and-match-score-container ">
                                        <Skeleton className="user-avatar-skeleton" variant="circular" height={"100px"} width={"100px"} />
                                    <Box className="user-score-skeleton-container">
                                        <Skeleton className="user-score-skeleton" variant="rounded" width={`85px`} height={"21px"} />
                                    </Box>
                                </Box>
                                <Box
                                    className="user-text">
                                    <Box>
                                        <Skeleton className="user-item-text-skeleton text-1"variant="text" height={"10px"} width={"172px"} /> 
                                        <Skeleton className="user-item-text-skeleton text-2"variant="text" height={"10px"} width={"351px"} />
                                        <Skeleton className="user-item-text-skeleton text-3"variant="text" height={"10px"} width={"385px"} />
                                        <Skeleton className="user-item-text-skeleton text-4" variant="text" height={"10px"} width={"337px"} />
                                        <Skeleton className="user-item-badge-skeleton"variant="circular" height={21} width={21} />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    ))}
            </Box>
    );
};

export default UserListSkeleton;
