import React from "react";

import { Tooltip, Box } from "@mui/material";

const PlayerIcon = ({ className = "" }) => {
    return (
        <a href="/user-list?playerType=Player">
            <Tooltip placement="top-start" title="This user is a Player">
                <Box
                    data-testid="player-icon"
                    className={"icon player-icon " + className}
                    sx={{ display: "flex", alignItems: "center" }}>
                    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M2.3125 18.125C2.3125 18.125 0.875 18.125 0.875 16.6875C0.875 15.25 2.3125 10.9375 9.5 10.9375C16.6875 10.9375 18.125 15.25 18.125 16.6875C18.125 18.125 16.6875 18.125 16.6875 18.125H2.3125ZM9.5 9.5C10.6437 9.5 11.7406 9.04565 12.5494 8.2369C13.3581 7.42815 13.8125 6.33125 13.8125 5.1875C13.8125 4.04375 13.3581 2.94685 12.5494 2.1381C11.7406 1.32935 10.6437 0.875 9.5 0.875C8.35625 0.875 7.25935 1.32935 6.4506 2.1381C5.64185 2.94685 5.1875 4.04375 5.1875 5.1875C5.1875 6.33125 5.64185 7.42815 6.4506 8.2369C7.25935 9.04565 8.35625 9.5 9.5 9.5Z"
                            fill="#9E9E9E"
                        />
                    </svg>
                </Box>
            </Tooltip>
        </a>
    );
};

export default PlayerIcon;
