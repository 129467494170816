import React, { PropsWithChildren, useEffect, useState } from "react";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { SEOHelmet } from "./SEOHelmet";
import { GroupType } from "models/group/GroupModel";
import { GroupsGameType } from "hooks/useGroupListItem";

type SEOGroupMiddleWareProps = {
    groupState: Partial<GroupType>;
    game: GroupsGameType | null;
    platform: {
        name: string;
        img: string;
    } | null;
};

export const SEOGroupMiddleWare = ({
    groupState,
    game,
    platform,
    children,
}: PropsWithChildren<SEOGroupMiddleWareProps>) => {
    const [loading, setLoading] = useState(true);
    const [documentMeta, setDocumentMeta] = useState({
        title: "",
        description: "",
    });

    const updateMetadata = () => {
        const groupHost = groupState.Creator;
        const groupGame = game?.name;

        let description = "";

        if (groupState.game_platform === 13) {
            const groupLocation = [groupState?.city, groupState?.state, groupState?.country]
                .filter((item) => !!item)
                .join(", ");
            description = `Looking for a TTRPG Group in ${groupLocation}? Join ${groupHost}'s Group on RPGMatch to play ${groupGame}`;

            switch (groupState.game_hosting_type) {
                case "Free":
                    description += " for free! Sign up today!";
                    break;
                case "Paid":
                    description += ", run by a Professional Game Master! Sign up today!";
                    break;
                default:
                    break;
            }
        } else {
            const groupPlatform = platform?.name;
            description = `Looking for a TTRPG Group Online? Join ${groupHost}'s Group on RPGMatch to play ${groupGame}`;

            switch (groupState.game_hosting_type) {
                case "Free":
                    description += ` for free on ${groupPlatform}! Sign up today!`;
                    break;
                case "Paid":
                    description += `, run by a Professional Game Master on ${groupPlatform}! Sign up today!`;
                    break;
                default:
                    break;
            }
        }

        setDocumentMeta({
            title: `${groupHost}'s Group | ${groupGame} | RPGMatch `,
            description,
        });

        setLoading(false);
    };

    useEffect(() => {
        updateMetadata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ShouldRender
            disableErrorBoundary
            returnNull
            loaderClass="seo-loader"
            loading={loading}
            alternativeComponent={children}>
            <>
                <SEOHelmet {...documentMeta} />
                {children}
            </>
        </ShouldRender>
    );
};
