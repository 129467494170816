import React, { useState } from "react";

import "./CreateGroupSuggestion.scss";

import { Box, Typography } from "@mui/material";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { ApplyButton } from "@components/ApplyButton";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import ManageGroup from "@pages/Group/ManageGroup";
import { useSetAtom } from "jotai";
import { surveyAtom } from "@atoms/survey";
import { conditionalClassName } from "utils/functions";
import { useBreakpoint } from "hooks/useBreakpoint";

type CreateGroupSuggestionProps = {
    state: object;
    length: number;
    goBack: () => void;
    counter: number;
    skipButton: () => void;
    pushCounter: () => void;
    handleEditProfile: (request: object) => Promise<void>;
};
export default function CreateGroupSuggestion(props: CreateGroupSuggestionProps) {
    /* Stats and Hooks */

    const setSurveyState = useSetAtom(surveyAtom);
    const [createGroup, setCreateGroup] = useState(false);

    const { isMobile, isTablet, isLaptop, isDesktop } = useBreakpoint();

    /* Variables */
    const className = conditionalClassName(
        ["form-container"],
        [isMobile, "mobile-form-container"],
        [isTablet, "tablet-form-container"],
        [isDesktop || isLaptop, "desktop-form-container"],
    );

    return (
        <CompleteProfileContainer rootContainerClass="create-group-suggestion manage-group-page">
            <Box className="top-text"></Box>

            <ShouldRender
                returnNull
                condition={createGroup}
                alternativeComponent={
                    <Box className={className}>
                        <Typography variant="h2">
                            Since you’re a GM, you’ve earned one (1) free play group slot.
                            {isMobile ? (
                                <>
                                    <br />
                                    <br />
                                </>
                            ) : (
                                " "
                            )}
                            Would you like to create your group now?
                        </Typography>
                        <Box className="actions">
                            <ApplyButton onClick={() => setCreateGroup(true)}>yes, create my group</ApplyButton>
                            <ApplyButton onClick={props.pushCounter}>No, i’ll do that later</ApplyButton>
                        </Box>
                    </Box>
                }>
                <Box className="create-group-container">
                    <Typography sx={{ textAlign: "start" }} variant="h2">
                        Fill out this form to create your group:
                    </Typography>
                    <ManageGroup
                        title=""
                        variant="CREATE"
                        onSubmit={(group) => {
                            setSurveyState({ groupCreated: true, groupId: group.id });
                            props.pushCounter();
                        }}
                        onCancel={() => setCreateGroup(false)}
                        surveyState={props.state as { name: string; gender: string }}
                    />
                </Box>
            </ShouldRender>

            <BackNextBtn
                pageState={props.state}
                goBack={props.goBack}
                counter={props.counter}
                length={props.length}
                nextBtn={props.skipButton}
            />
        </CompleteProfileContainer>
    );
}
