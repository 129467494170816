import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";
import ProfileGMOrPlayer from "@components/ProfileInterview/ProfileGMOrPlayer";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const PlayerTypeComponent = ({ state, handleEditProfile, pushCounter, skipButton, goBack, counter, length }) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-dm-or-player">
                <View className="top-text"></View>
                <View className="question-container">
                    <ProfileGMOrPlayer state={state} handleEditProfile={handleEditProfile} pushCounter={pushCounter} />
                </View>
                <BackNextBtn goBack={goBack} counter={counter} length={length} nextBtn={skipButton} />
            </CompleteProfileContainer>
        </>
    );
};
