import React, { useEffect } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { BackButton } from "components/BackButton";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../AboutUs/index.css";

const PrivacyPolicyPage = ({ title }) => {
    useEffect(() => {
        document.title = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <View className="fullscreen-mobile">
            <View className="section_header_about section-header">
                <BackButton onClickBack={() => window.history.back()} />
                <Text as="h1" className="boil-center">
                    Privacy Policy
                </Text>
            </View>
            <View className="section-body">
                <br />
                <Text>
                    We appreciate that you trust us with your information and we intend to always keep that trust. This
                    starts with making sure you understand the information we collect, why we collect it, how it is used
                    and your choices regarding your information. This Policy describes our privacy practices in plain
                    language, keeping legal and technical jargon to a minimum.
                </Text>
                <Text>This Privacy Policy applies beginning January 26, 2022.</Text>

                <Text as="h1" className="title_about">
                    Where This Privacy Policy Applies
                </Text>
                <Text>
                    This Privacy Policy applies to websites, apps, events and other services operated by RPGMatch. For
                    simplicity, we refer to all of these as our “services” in this Privacy Policy. To make it extra
                    clear, we've added links to this Privacy Policy on applicable services.
                </Text>
                <Text>
                    Some services may require their own unique privacy policy. If a particular service has its own
                    privacy policy, then that policy -not this Privacy Policy- applies.
                </Text>

                <Text as="h1" className="title_about">
                    Information We Collect
                </Text>
                <Text>
                    It goes without saying, we can't help you develop meaningful connections without some information
                    about you, such as basic profile details and the types of people you'd like to meet. We also collect
                    information generated as you use our services, for example access logs, as well as information from
                    third parties, like when you access our services through a social media account. If you want
                    additional info, we go into more detail below.
                </Text>
                <Text as="h1" className="title_about">
                    Information you give us
                </Text>
                <Text>You choose to give us certain information when using our services. This includes:</Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        When you create an account, you provide us with at least your login credentials, as well as some
                        basic details necessary for the service to work, such as your contact information, timezone and
                        age.
                    </Text>
                    <Text as="li">
                        When you complete your profile, you can share with us additional information, such as details on
                        your gaming preferences, as well as content such as photos and videos. To add certain content,
                        like pictures or videos, you may allow us to access your camera or photo album. Some of the
                        information you choose to provide us may be considered “special” or “sensitive” in certain
                        jurisdictions, for example your racial or ethnic origins, sexual orientation and religious
                        beliefs. By choosing to provide this information, you consent to our processing of that
                        information.
                    </Text>
                    <Text as="li">
                        When you subscribe to a paid service or make a purchase directly from us (rather than through a
                        platform such as iOS or Android), you provide us or our payment service provider with
                        information, such as your debit or credit card number or other financial information.
                    </Text>
                    <Text as="li">
                        When you participate in surveys or focus groups, you give us your insights into our products and
                        services, responses to our questions and testimonials.
                    </Text>
                    <Text as="li">
                        {" "}
                        When you choose to participate in our promotions, events orcontests, we collect the information
                        that you use to register or enter.
                    </Text>
                    <Text as="li">
                        If you contact our customer care team, we collect the information you give us during the
                        interaction. Sometimes, we monitor or record these interactions for training purposes and to
                        ensure a high quality of service.
                    </Text>
                    <Text as="li">
                        If you ask us to communicate with or otherwise process information of other people (for example,
                        if you ask us to send an email on your behalf to one of your friends), we collect the
                        information about others that you give us in order to complete your request.
                    </Text>
                    <Text as="li">
                        Of course, we also process your chats with other users as well as the content you publish, as
                        part of the operation of the services.
                    </Text>
                </Text>
                <Text as="h3">Information we receive from others</Text>
                <Text>
                    In addition to the information you provide us directly, we receive information about you from
                    others, including:
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        <strong>Other Users.</strong> Other users may provide information about you as they use our
                        services. For instance, we may collect information about you from other users if they contact us
                        about you.
                    </Text>
                    <Text as="li">
                        <strong>Social Media.</strong> You may be able to use your social media login (such asFacebook
                        Login) to create and log into your RPGMatch account. This saves you from having to remember yet
                        another user name and password and allows you to share some information from your social media
                        account with us.
                    </Text>
                    <Text as="li">
                        <strong>Other Partners.</strong> We may receive info about you from our partners, for instance
                        where RPGMatch ads are published on a partner's websites and platforms (in which case they may
                        pass along details on a campaign's success).
                    </Text>
                </Text>
                <Text as="h1" className="title_about">
                    Information collected when you use our services
                </Text>
                <Text>
                    When you use our services, we collect information about which features you've used, how you've used
                    them and the devices you use to access our services. See below for more details:
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        <strong>Usage Information.</strong> We collect information about your activity on our services,
                        for instance how you use them (e.g., date and time you logged in, features you've been using,
                        searches, clicks and pages which have been shown to you, referring webpage address, advertising
                        that you click on) and how you interact with other users (e.g., users you connect and interact
                        with, time and date of your exchanges, number of messages you send and receive).
                    </Text>
                    <Text as="li">
                        <strong>Device information.</strong> We collect information from and about the device(s) you use
                        to access our services, including: hardware and software information such as IP address, device
                        ID and type, device-specific and apps settings and characteristics, app crashes, advertising IDs
                        (such as Googles AAID and Apple's IDFA, both of which are randomly generated numbers that you
                        can reset by going into your device' settings), browser type, version and language, operating
                        system, time zones, identifiers associated with cookies or other technologies that may uniquely
                        identify your device or browser (e.g., IMEI UDID and MAC address); information on your wireless
                        and mobile network connection, like your service provider and signal strength; information on
                        device sensors such as accelerometers, gyroscopes and compasses.
                    </Text>
                    <Text as="li">
                        <strong>Other information with your consent.</strong> If you give us permission, we can collect
                        your precise geolocation (latitude and longitude) through various means, depending on the
                        service and device you're using, including GPS, Bluetooth or Wi-Fi connections. The collection
                        of your geolocation may occur in the background even when you aren't using the services if the
                        permission you gave us expressly permits such collection. If you decline permission for us to
                        collect your geolocation, we will not collect it.
                    </Text>
                </Text>
                <Text>
                    Similarly, if you consent, we may collect your photos and videos (for instance, if you want to
                    publish a photo, video or streaming on the services).
                </Text>

                <Text as="h1" className="title_about">
                    COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES
                </Text>
                <Text>
                    We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to
                    recognize you and or your device(s).
                </Text>
                <Text>
                    Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a “Do Not Track”
                    feature that tells a website that a user does not want to have his or her online activity tracked.
                    If a website that responds to a DNT signal receives a DNT signal, the browser can block that website
                    from collecting certain information about the browser's user. Not all browsers offer a DNT option
                    and DNT signals are not yet uniform. For this reason, many businesses, including RPGMatch, do not
                    currently respond to DNT signals.
                </Text>

                <Text as="h1" className="title_about">
                    HOW WE USE INFORMATION
                </Text>
                <Text>
                    The main reason we use your information is to deliver and improve our services. Additionally, we use
                    your info to help keep you safe and to provide you with advertising that may be of interest to you.
                    Read on for a more detailed explanation of the various reasons we use your information, together
                    with practical examples.
                </Text>
                <Text as="h1" className="title_about">
                    To administer your account and provide our services to you
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">Create and manage your account</Text>
                    <Text as="li">Provide you with customer support and respond to your requests</Text>
                    <Text as="li">Complete your transactions</Text>
                    <Text as="li">Communicate with you about our services, including order management and billing</Text>
                </Text>
                <Text as="h1" className="title_about">
                    To help you connect with other users
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        Analyze your profile and that of other users to recommend meaningful connections
                    </Text>
                    <Text as="li">Show users' profiles to one another</Text>
                </Text>
                <Text as="h1" className="title_about">
                    To ensure a consistent experience across your devices
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        Link the various devices you use so that you can enjoy a consistent experience of our services
                        on all of them. We do this by linking devices and browser data, such as when you log into your
                        account on different devices or by using partial or full IP address, browser version and similar
                        data about your devices to help identify and link them.
                    </Text>
                </Text>
                <Text as="h1" className="title_about">
                    To serve you relevant offers and ads
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">Administer sweepstakes, contests, discounts or other offers.</Text>
                    <Text as="li">
                        Develop, display and track content and advertising tailored to your interests on our services
                        and other sites.
                    </Text>
                    <Text as="li">
                        Communicate with you by email, phone, social media or mobile device about products or services
                        that we think may interest you.
                    </Text>
                </Text>
                <Text as="h1" className="title_about">
                    To improve our services and develop new ones
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">Administer focus groups and surveys.</Text>
                    <Text as="li">
                        Conduct research and analysis of users' behavior to improve our services and content (for
                        instance, we may decide to change the look and feel or even substantially modify a given feature
                        based on users' behavior).
                    </Text>
                    <Text as="li">
                        Develop new features and services (for example, we may decide to build a new interests-based
                        feature further to requests received from users).
                    </Text>
                </Text>
                <Text as="h1" className="title_about">
                    To prevent, detect and fight fraud or other illegal or unauthorized activities
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">Address ongoing or alleged misbehavior on and off-platform.</Text>
                    <Text as="li">
                        Perform data analysis to better understand and design countermeasures against these activities.
                    </Text>
                    <Text as="li">Retain data related to fraudulent activities to prevent against recurrences.</Text>
                </Text>
                <Text as="h1" className="title_about">
                    To ensure legal compliance
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">Comply with legal requirements.</Text>
                    <Text as="li">Assist law enforcement.</Text>
                    <Text as="li">Enforce or exercise our rights, for example our Terms.</Text>
                </Text>
                <Text>To process your information as described above, we rely on the following legal bases:</Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        Provide our service to you: Most of the time, the reason we process your information is to
                        perform the contract that you have with us. For instance, as you go about using our service to
                        build meaningful connections, we use your information to maintain your account and your profile,
                        to make it viewable to other users and recommend other users to you.
                    </Text>
                    <Text as="li">
                        Legitimate interests: We may use your information where we have legitimate interests to do so.
                        For instance, we analyze users' behavior on our services to continuously improve our offerings,
                        we suggest offers we think might interest you, and we process information for administrative,
                        fraud detection and other legal purposes.
                    </Text>
                    <Text as="li">
                        Consent: From time to time, we may ask for your consent to use your information for certain
                        specific reasons. You may withdraw your consent at any time by contacting us at the address
                        provided at the end of this Privacy Policy.
                    </Text>
                </Text>
                <Text as="h1" className="title_about">
                    HOW WE SHARE INFORMATION
                </Text>
                <Text>
                    Since our goal is to help you make meaningful connections, the main sharing of users' information
                    is, of course, with other users. We also share some users' information with service providers and
                    partners who assist us in operating the services, with other RPGMatch Group companies and, in some
                    cases, legal authorities. Read on for more details about how your information is shared with others.
                </Text>

                <Text as="h1" className="title_about">
                    Sharing with other users
                </Text>
                <Text>
                    You share information with other users when you voluntarily disclose information on the service
                    (including your public profile). Please be careful with your information and make sure that the
                    content you share is stuff that you're comfortable being publicly viewable since neither you nor we
                    can control what others do with your information once you share it. If you choose to limit the
                    audience for all or part of your profile or for certain content or information about you, then it
                    will be visible according to your settings.
                </Text>

                <Text as="h1" className="title_about">
                    With Service Providers
                </Text>
                <Text>
                    We follow a strict vetting process prior to engaging any service provider or working with any
                    partner. All of our service providers and partners must agree to strict confidentiality obligations.
                    We use third parties, including Momentive (aka SurveyMonkey) to help us operate and improve our
                    services. These third parties assist us with various tasks, including data hosting and maintenance,
                    analytics, customer care, marketing, advertising, payment processing and security operations.
                </Text>

                <Text as="h1" className="title_about">
                    Other instances
                </Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        <strong>For corporate transactions.</strong> We may transfer your information if we are
                        involved, whether in whole or in part, in a merger, sale, acquisition, divestiture,
                        restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
                    </Text>
                    <Text as="li">
                        <strong>When required by law.</strong> We may disclose your information if reasonably necessary:
                        (i) to comply with a legal process, such as a court order, subpoena or search warrant,
                        government / law enforcement investigation or other legal requirements; (ii) to assist in the
                        prevention or detection of crime (subject in each case to applicable law); or (iii) to protect
                        the safety of any person.
                    </Text>
                    <Text as="li">
                        <strong>To enforce legal rights.</strong>We may also share information: (i) if disclosure would
                        mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our
                        legal rights and legal rights of our users, business partners or other interested parties; (iii)
                        to enforce our agreements with you; and (iv) to investigate, prevent, or take other action
                        regarding illegal activity, suspected fraud or other wrongdoing.
                    </Text>
                    <Text as="li">
                        <strong>With your consent or at your request.</strong>We may ask for your consent to share your
                        information with third parties. In any such case, we will make it clear why we want to share the
                        information.
                    </Text>
                </Text>

                <Text as="h1" className="title_about">
                    Non-Personal information
                </Text>
                <Text>
                    We may use and share non-personal information (meaning information that, by itself, does not
                    identify who you are such as device information, general demographics, general behavioral data,
                    geolocation in de-identified form), as well as personal information in hashed, non-human readable
                    form, under any of the above circumstances. We may also share this information with other RPGMatch
                    Group companies and third parties (notably advertisers) to develop and deliver targeted advertising
                    on our services and on websites or applications of third parties, and to analyze and report on
                    advertising you see. We may combine this information with additional non-personal information or
                    personal information in hashed, non-human readable form collected from other sources.
                </Text>

                <Text as="h1" className="title_about">
                    CROSS-BORDER DATA TRANSFERS
                </Text>
                <Text>
                    Sharing of information sometimes involves cross-border data transfers, for instance to the United
                    States of America and other jurisdictions. As an example, where the service allows for users to be
                    located in the European Economic Area (“EEA”), their personal information is transferred to
                    countries outside of the EEA. We use standard contract clauses approved by the European Commission
                    or other suitable safeguard to permit data transfers from the EEA to other countries. Standard
                    contractual clauses are commitments between companies transferring personal data, binding them to
                    protect the privacy and security of your data.
                </Text>

                <Text as="h1" className="title_about">
                    YOUR RIGHTS
                </Text>
                <Text>We want you to be aware of your privacy rights. Here are a few key points to remember:</Text>
                <Text as="ul" className={"privacyUls"}>
                    <Text as="li">
                        <strong>Reviewing your information.</strong> Applicable privacy laws may give you the right to
                        review the personal information we keep about you (depending on the jurisdiction, this may be
                        called right of access, right of portability or variations of those terms). You can request a
                        copy of your personal information.
                    </Text>
                    <Text as="li">
                        <strong>Updating or deleting your information.</strong> If you believe that the information we
                        hold about you is inaccurate or that we are no longer entitled to use it and want to request its
                        rectification, deletion or object to its processing, please contact us.
                    </Text>
                    <Text>
                        For your protection and the protection of all of our users, we may ask you to provide proof of
                        identity before we can answer the above requests.
                    </Text>
                </Text>

                <Text as="h1" className="title_about">
                    RESIDENTS OF CALIFORNIA
                </Text>
                <Text>
                    If you are a California resident, you can request a notice disclosing the categories of personal
                    information about you that we have shared with third parties for their direct marketing purposes
                    during the preceding calendar year. Please allow 30 days for a response to this kind of request. For
                    your protection and the protection of all of our users, we may ask you to provide proof of identity
                    before we can answer such a request.
                </Text>

                <Text as="h1" className="title_about">
                    HOW WE PROTECT YOUR INFORMATION
                </Text>
                <Text>
                    We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of
                    your personal information. As with all technology companies, although we take steps to secure your
                    information, we do not promise, and you should not expect, that your personal information will
                    always remain secure.
                </Text>
                <Text>
                    We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our
                    information collection, storage and processing practices to update our physical, technical and
                    organizational security measures.
                </Text>
                <Text>
                    We may suspend your use of all or part of the services without notice if we suspect or detect any
                    breach of security. If you believe that your account or information is no longer secure, please
                    notify us immediately.
                </Text>

                <Text as="h1" className="title_about">
                    HOW LONG WE RETAIN YOUR INFORMATION
                </Text>
                <Text>
                    We keep your personal information only as long as we need it for legitimate business purposes and as
                    permitted by applicable law. To protect the safety and security of our users on and off our
                    services, we implement a one-year safety retention window following account deletion. During this
                    period, account information will be retained although the account will of course not be visible on
                    the services anymore.
                </Text>
                <Text>
                    In practice, we delete or anonymize your information upon deletion of your account (following the
                    safety retention window) or after two years of continuous inactivity, unless:
                </Text>

                <Text as="ol">
                    <Text as="li">
                        {" "}
                        we must keep it to evidence our compliance with applicable law (for instance, records of
                        consents to our Terms, Privacy Policy and other similar consents are kept for five years);
                    </Text>
                    <Text as="li">
                        {" "}
                        there is an outstanding issue, claim or dispute requiring us to keep the relevant information
                        until it is resolved; or
                    </Text>
                    <Text as="li">
                        the information must be kept for our legitimate business interests, such as fraud prevention and
                        enhancing users' safety and security. For example, information may need to be kept to prevent a
                        user who was banned for unsafe behavior or security incidents from opening a new account.
                    </Text>
                </Text>

                <Text>
                    Keep in mind that even though our systems are designed to carry out data deletion processes
                    according to the above guidelines, we cannot promise that all data will be deleted within a specific
                    time frame due to technical constraints.
                </Text>

                <Text as="h1" className="title_about">
                    CHILDREN'S PRIVACY
                </Text>
                <Text>
                    Our services are restricted to users who are 18 years of age or older. We do not permit users under
                    the age of 18 on our platform and we do not knowingly collect personal information from anyone under
                    the age of 18. If you suspect that a user is under the age of 18, please use the reporting mechanism
                    available through the service.
                </Text>

                <Text as="h1" className="title_about">
                    PRIVACY POLICY CHANGES
                </Text>
                <Text>
                    Because we're always looking for new and innovative ways to help you build meaningful connections,
                    this policy may change over time. We will notify you before any material changes take effect so that
                    you have time to review the changes.
                </Text>

                <Text as="h1" className="title_about">
                    HOW TO CONTACT US
                </Text>
                <Text>
                    If you have questions about this Privacy Policy, here's how you can reach us:
                    <br />
                    Online: E-mail us at <a href="mailto: privacy@rpgmatch.org">privacy@rpgmatch.org</a>
                </Text>
                <View className="buttons_about">
                    <Box className="button_about">
                        <Link to="/community-guidelines">Community Guideliness</Link>
                    </Box>
                    <Box className="button_about">
                        <Link to="/about">About us</Link>
                    </Box>
                </View>
                <br />
            </View>
        </View>
    );
};

export default PrivacyPolicyPage;
