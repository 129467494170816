import React, { useMemo } from "react";
import { RightCard, RightCardProps } from "../RightCard";

export const GeneralCard = (props: RightCardProps) => {
    const generalCardInfo: RightCardProps = useMemo(() => {
        return {
            ...props,
            style: { ...props.style, maxWidth: 300, height: 250 },
            contentStyle: {
                ...props.contentStyle,
                position: "absolute",
                top: 0,
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                padding: "8px",
            },
            descriptionStyle: { fontWeight: 700, paddingTop: "12px" },
        };
    }, [props]);

    return <RightCard {...generalCardInfo} />;
};
