import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { availabilityQuestions, timeRange, weekDays } from "utils/constants";
import { View } from "@aws-amplify/ui-react";
import { Days, Time } from "./Availability";

export function AvailabilityTableHeader() {
    return (
        <View className="time-row-container header">
            <View className="time-description-container">Time</View>
            <View className="time-row">
                <View className="days">
                    {weekDays.map((day) => (
                        <View className="day-labels" key={day.day}>
                            {day.day}
                        </View>
                    ))}
                </View>
            </View>
        </View>
    );
}

export function AvailabilityTableBody({ availabilityTable, updateTable, isTablet }) {
    return (
        <div data-testid="availability-table" className="availability-table">
            {timeRange.map((time) => {
                const timeIsSelected = weekDays.some((day) => availabilityTable[day.column][time.row] == 1);

                return (
                    <View className={`time-row-container ${timeIsSelected ? "time-selected" : ""}`} key={time.row}>
                        <View className="time-description-container">
                            <View className={"time-icon"}>{time.icon}</View>
                            <Time time={time} />
                        </View>
                        <View className="time-row">
                            <Days
                                time={time}
                                weekDays={weekDays}
                                availabilityTable={availabilityTable}
                                isEditing={true}
                                onChange={updateTable}
                                isTablet={isTablet}
                            />
                        </View>
                    </View>
                );
            })}
        </div>
    );
}

export function AvailabilityQuestions({ questionsValue, changeObj }) {
    return (
        <View className="availability-questions">
            {availabilityQuestions.map((question) => {
                return (
                    <FormControl
                        data-testid="availability-related-question"
                        key={question.key + "-fc"}
                        fullWidth
                        sx={{ mt: 2 }}>
                        <InputLabel id={question.key + "-select"}>{question.title}</InputLabel>
                        <Select
                            fullWidth
                            labelId={question.key + "-select"}
                            id={question.key + "-select"}
                            name={question.key}
                            value={questionsValue[question.key]}
                            label={question.title}
                            onChange={changeObj}>
                            {question.options.map((option) => {
                                return (
                                    <MenuItem key={"LO" + option} id={"LO" + option} value={option}>
                                        {option}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                );
            })}
        </View>
    );
}
