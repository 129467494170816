import React, { useState } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { handleSaveRequiredValues } from "@utils/functions";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ApplyButton } from "@components/ApplyButton";
import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";

export const ProfileGMOrPlayer = ({ state, handleEditProfile, pushCounter }) => {
    const hasState = () => {
        if (!state?.questionnaireTransitionDM) {
            return "";
        }

        return String(state?.questionnaireTransitionDM);
    };

    const [isLoading, setIsLoading] = useState(false);
    const [textValue, setTextValue] = useState(hasState);

    const playerOptions = ["Player only.", "GM only.", "Either/Both."];

    const { isDesktop: _isDesktop, isLaptop } = useBreakpoint();

    const isDesktop = isLaptop || _isDesktop;

    return (
        <View className="form-container">
            <Text as="h2">Do you run games GM, play, or both?</Text>
            {
                // loop through the array and build button options
                playerOptions.map((item, index) => (
                    <View className="button-container" key={index}>
                        <LoadingBtn
                            label={item}
                            variant="outlined"
                            loading={textValue === item && isLoading}
                            handleOnClick={() => {
                                // In case is desktop, then only clicking the option will save the value
                                if (isDesktop)
                                    handleSaveRequiredValues({
                                        key: "questionnaireTransitionDM",
                                        item,
                                        setTextValue,
                                        setIsLoading,
                                        handleEditProfile,
                                    });
                                // If not desktop, then we will choose the option and just save when clicking the button
                                else setTextValue(item);
                            }}
                            className={textValue === item ? "selected-but" : "safety-but"}
                        />
                    </View>
                ))
            }
            <ShouldRender returnNull condition={!isDesktop}>
                <ApplyButton
                    sx={{ width: "fit-content" }}
                    onClick={async () =>
                        await handleSaveRequiredValues({
                            key: "questionnaireTransitionDM",
                            item: textValue,
                            setTextValue,
                            setIsLoading,
                            handleEditProfile,
                        })
                    }>
                    THAT’S IT
                </ApplyButton>
            </ShouldRender>
        </View>
    );
};
export default ProfileGMOrPlayer;
