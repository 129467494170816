import { useState } from "react";
import { AlertPopUpProps } from "./AlertPopUpSmall";

type PopupControllerResponse = {
    popup: AlertPopUpProps;
    setPopup: React.Dispatch<React.SetStateAction<AlertPopUpProps>>;
    showPopup: (props: Omit<AlertPopUpProps, "open">, ttl?: number) => void;
};

export default function usePopupController(): PopupControllerResponse {
    const [popup, setPopup] = useState<AlertPopUpProps>({
        open: false,
        title: "",
        description: "",
        severity: "success",
    });

    /**
     * Shows a popup with the given props and automatically closes it after a specified time.
     *
     * @param {Omit<AlertPopUpProps, "open">} props - The props for the popup, excluding the "open" prop.
     * @param {number} [ttl] - The time in milliseconds after which the popup should be automatically closed. Defaults to 3000ms.
     * @return {void}
     */
    const showPopup = (props: Omit<AlertPopUpProps, "open">, ttl?: number) => {
        // Open popup with props
        setPopup({ ...props, open: true });

        // Close popup after ttl
        setTimeout(() => {
            setPopup((prev) => ({ open: false, title: "", description: "", severity: prev.severity }));
        }, ttl || 3000);
    };

    return { popup, setPopup, showPopup };
}
