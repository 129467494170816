import React, { useState } from "react";
import RequiredQuestion from "components/RequiredQuestion";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { handleSaveRequiredValues } from "@utils/functions";

export const ProfileLGBTQ = ({ state, handleEditProfile, pushCounter }) => {
    const hasState = () => {
        if (!state?.LGBTQ) {
            return "";
        }

        return String(state?.LGBTQ);
    };

    const [textValue, setTextValue] = useState(hasState);
    const [isLoading, setIsLoading] = useState(false);
    const lgbtOptions = ["Yes, I'm LGBTQIA+.", "Yes", "I'm not sure.", "It's not a focus for us."];

    return (
        <View className="form-container">
            <Text as="h2">
                Are your games LGBTQIA+ Friendly?<small>*</small>
            </Text>
            {
                // loop through the array and build button options
                Object.values(lgbtOptions).map((item, index) => (
                    <View className="button-container" key={index}>
                        <LoadingBtn
                            label={item}
                            variant="outlined"
                            loading={textValue === item && isLoading}
                            handleOnClick={() =>
                                handleSaveRequiredValues({
                                    key: "LGBTQ",
                                    item,
                                    setTextValue,
                                    setIsLoading,
                                    handleEditProfile,
                                })
                            }
                            className={textValue === item ? "selected-but" : "safety-but"}
                        />
                    </View>
                ))
            }
        </View>
    );
};
export default ProfileLGBTQ;
