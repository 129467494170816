import { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useAuth } from "hooks/useAuth";

const useCurrentUserInfo = () => {
    const [email, setEmail] = useState(null);
    const { pureSignIn } = useAuth();

    const verifyCurrentUserPassword = async (password = null) => await pureSignIn({ email, password });

    useEffect(() => {
        (async () => {
            const { username } = await Auth.currentUserInfo();
            setEmail(username);
        })();
    }, []);

    return {
        email,
        verifyCurrentUserPassword,
    };
};

export default useCurrentUserInfo;
