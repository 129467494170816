import "./GroupsActionBar.scss";

import React from "react";

import { ArrowBack, CheckOutlined } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { ApplyButton } from "@components/ApplyButton";
import { useNavigate } from "react-router-dom";

type GroupsActionBarProps = {
    title: string;
    disabled?: boolean;
    buttonText: string;
    handleSubmit: () => void | Promise<void>;
};
export default function GroupsActionBar({ title, buttonText, handleSubmit, disabled }: GroupsActionBarProps) {
    const navigate = useNavigate();

    return (
        <Box className="groups-action-bar-container">
            <Box className="groups-action-bar-title">
                <ArrowBack onClick={() => navigate(-1)} className="groups-action-bar-back-button" fontSize="small" />
                <Typography>{title}</Typography>
            </Box>
            <ApplyButton disabled={disabled} onClick={handleSubmit} icon={<CheckOutlined fontSize="small" />}>
                {buttonText}
            </ApplyButton>
            <Box />
        </Box>
    );
}
