import React, { useContext, useState } from "react";
import { Box, Slider } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import RequiredQuestion from "components/RequiredQuestion";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { useSnackbarContext } from "context/SnackbarContext";

export const ProfileThingsLikedTTRPG = ({ state, handleEditProfile, pushCounter }) => {
    const hasState = () => {
        let newObj = {
            roleplaying: 2,
            combat: 2,
            exploring: 2,
            optimizing_mechanics: 2,
            social_intrigue: 2,
            puzzles: 2,
            player_driven_story: 2,
        };
        if (state.thingsLikedTTRPG) {
            newObj = {
                roleplaying: state.thingsLikedTTRPG[0],
                combat: state.thingsLikedTTRPG[1],
                exploring: state.thingsLikedTTRPG[2],
                optimizing_mechanics: state.thingsLikedTTRPG[3],
                social_intrigue: state.thingsLikedTTRPG[4],
                puzzles: state.thingsLikedTTRPG[5],
                player_driven_story: state.thingsLikedTTRPG[6],
            };
        }
        return newObj;
    };

    const { showSnackbar } = useSnackbarContext();
    const [textValue, setTextValue] = useState(hasState);
    const [isLoading, setIsLoading] = useState(false);

    const marks = [
        {
            value: 0,
        },
        {
            value: 1,
        },
        {
            value: 2,
        },
        {
            value: 3,
        },
        {
            value: 4,
        },
    ];

    const changeObj = (e) => {
        e.preventDefault();
        e.stopPropagation();

        setTextValue((textValue) => ({
            ...textValue,
            [e.target.name]: Number(e.target.value),
        }));
    };

    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        try {
            setIsLoading(true);
            const thingsLiked = [];
            thingsLiked.push(
                Number(textValue.roleplaying),
                Number(textValue.combat),
                Number(textValue.exploring),
                Number(textValue.optimizing_mechanics),
                Number(textValue.social_intrigue),
                Number(textValue.puzzles),
                Number(textValue.player_driven_story),
            );

            await handleEditProfile({ thingsLikedTTRPG: thingsLiked });

            setIsLoading(false);
        } catch (error) {
            showSnackbar({ description: error.message });
        }
    };

    return (
        <View className="form-container">
            <Text as="h2">
                What style of play do you prefer?<small>*</small>
            </Text>
            <View className="input-container things-liked-ttrpg-container">
                <Box className="slider-style-container">
                    <View className="slider-row">
                        <Text className="slider-label">Roleplaying</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.roleplaying)}
                            name="roleplaying"
                            key="roleplaying"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Combat</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.combat)}
                            name="combat"
                            key="combat"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                            label="Roleplaying"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Exploring</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.exploring)}
                            name="exploring"
                            key="exploring"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Optimizing / Mechanics</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.optimizing_mechanics)}
                            name="optimizing_mechanics"
                            key="optimizing_mechanics"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Social Intrigue</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.social_intrigue)}
                            name="social_intrigue"
                            key="social_intrigue"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Puzzles</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.puzzles)}
                            name="puzzles"
                            key="puzzles"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                    </View>
                    <View className="slider-row">
                        <Text className="slider-label">Player-Driven Story</Text>
                        <Slider
                            className="slider-slider"
                            value={Number(textValue.player_driven_story)}
                            name="player_driven_story"
                            key="player_driven_story"
                            onChange={changeObj}
                            marks={marks}
                            min={0}
                            max={4}
                            size="small"
                        />
                        <View className="slider-clear"></View>
                    </View>
                </Box>
                <View className="button-container">
                    <LoadingBtn label="THAT'S IT" loading={isLoading} handleOnClick={handleSaveValue} />
                </View>
            </View>
            <RequiredQuestion />
        </View>
    );
};
export default ProfileThingsLikedTTRPG;
