import React, { useMemo } from "react";
import { View } from "@aws-amplify/ui-react";
import { UserListItem } from "./UserListItem";
import { isOnlyGameMaster, isBoth, isProfessionalGameMaster } from "utils/isGameMaster";

export const MemoizedUserList = ({
    userList,
    updateFavorites,
    wait,
    userCompletedProfile,
    VTTOptions,
    pathname,
    personalId,
}) => {
    return useMemo(() => {
        return userList.map((item) => {
            item.isGM = isBoth(item) || isOnlyGameMaster(item);
            item.isProfessionalGM = isProfessionalGameMaster(item);

            if (item.vanityUrl.length === 1) {
                item.vanityUrl = item?.vanityUrl?.toString() || "";
            }
            const getClassOfPlayer = () => {
                if (item.isProfessionalGM) return "professional-gm";
                if (item.isGM) return "is-gm";
            };

            return (
                <View key={item.id} className={getClassOfPlayer()}>
                    <UserListItem
                        key={item.id}
                        userCompletedProfile={userCompletedProfile}
                        updateFavorites={updateFavorites}
                        wait={wait}
                        item={item}
                        VTTOptions={VTTOptions}
                        personalId={personalId}
                    />
                </View>
            );
        });
    }, [userList, userCompletedProfile, updateFavorites, wait, VTTOptions, pathname]);
};
