import React from "react";

import { Box } from "@mui/material";
import { ConditionalProp } from "@utils/tools";

type GoldenBorderProps = React.PropsWithChildren & {
    enabled?: boolean;
    borderSize?: number;
    borderRadius?: number;
    enableShadow?: boolean;
};

export default function GoldenBorder({
    enabled = true,
    children,
    borderSize,
    borderRadius,
    enableShadow = false,
}: GoldenBorderProps) {
    if (!enabled) return <>{children}</>;

    return (
        <Box
            className="golden-border"
            sx={{
                backgroundImage:
                    "linear-gradient(#1e1e1e, #1e1e1e), linear-gradient(180deg, #ffe193 0%, rgba(121, 106, 66, 0.39) 100%)",

                backgroundOrigin: "border-box",
                backgroundClip: "content-box, border-box",
                width: "100%",
                height: "100%",
                border: `${borderSize}px solid transparent`,
                borderRadius: `${borderRadius}px`,

                ...ConditionalProp(enableShadow, { boxShadow: "0px 0px 10px 0px #FFE19340;" }),
            }}>
            {children}
        </Box>
    );
}
