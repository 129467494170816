import { useState, useRef, useEffect, useContext } from "react";

import { View, Text, Loader } from "@aws-amplify/ui-react";
import { AppTitleIcons } from "utils/icons";

import "./ExplorePage.css";

import TwitterPost from "components/TwitterComponent/TwitterPost";
import { useQuery } from "react-query";
import { api } from "services/api";
import { endpoints } from "services/endpoints";

import { PopupConfirmation } from "components/PopupConfirmation";
import { LoginBanner } from "components/LoginBanner/LoginBanner";
import { useGlobalContext } from "context/AppContext";
import { LoginContext } from "context/LoginContext";
import { saveRedirect } from "utils/functions";
export default function ExplorePage() {
    const scrollTarget = useRef();
    const { isLoggedIn } = useGlobalContext();
    const { setLoginPopup } = useContext(LoginContext);

    const [twitterState, setTwitterState] = useState({
        posts: [],
        page: 1,
        lastEvaluatedKey: undefined,
    });
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [redirectTarget, setRedirectTarget] = useState(null);
    const [showLoginBanner, setShowLoginBanner] = useState(false);

    const getPostsAsync = async () => {
        setLoading(true);
        const response = api.get(`${endpoints.publicExplore}?page=${twitterState.page}`);

        return (await response).data;
    };

    var scrollTimeout;
    const onTargetScroll = (event) => {
        const { scrollTop } = event.currentTarget;
        const { scrollHeight, clientHeight } = scrollTarget.current;
        const targetBottom = (scrollHeight - clientHeight) * 0.95;

        clearTimeout(scrollTimeout);

        scrollTimeout = setTimeout(function () {
            if (targetBottom < scrollTop && !loading) {
                setTwitterState((prev) => {
                    return {
                        ...prev,
                        page: prev.page + 1,
                    };
                });
            }
        }, 300);
    };

    const exploreQuery = useQuery({
        queryKey: ["explore", twitterState.page],
        queryFn: () => getPostsAsync(),
        keepPreviousData: true,
        onSuccess: (data) => {
            setTwitterState((prev) => {
                return {
                    ...prev,
                    posts: [...prev?.posts, ...data?.items],
                    lastEvaluatedKey: data?.lastEvaluatedKey,
                };
            });
            setLoading(false);
        },
        onError: (error) => {
            console.log(error);
        },
    });

    const redirect = () => {
        window.open(redirectTarget, "_blank");
    };

    const TwitterPosts = () => {
        return twitterState.posts.map((item) => {
            return (
                <TwitterPost
                    key={item.id}
                    post={item}
                    handleModal={(url) => {
                        if (!isLoggedIn) {
                            setLoginPopup(true);
                            return;
                        }
                        setRedirectTarget(url);
                        setOpen(true);
                    }}
                />
            );
        });
    };

    useEffect(() => {
        if (exploreQuery?.status === "success") {
            setShowLoginBanner(!isLoggedIn);
        }
    }, [isLoggedIn, exploreQuery?.status]);

    return (
        <>
            <View data-testid="explore-page" className="desktop-width fullscreen-mobile explore-page">
                <View className="section-header-new">
                    {AppTitleIcons.explore}
                    <Text as="h1" data-testid="page-title" className="results-title">
                        RPGMatch: Your Hub for Fresh TTRPG Content
                    </Text>
                </View>
                {/* <View className="follow-section" data-testid="follow-section">
                <Text className="follow-title" as="h2" data-testid="follow-title">
                    Posts from RPGMatch Official Feed
                </Text>
                <Button
                    id="follow-button"
                    className="follow-button"
                    data-testid="follow-button"
                    onClick={() => window.open("https://twitter.com/i/lists/1660762895964315648", "_blank")}>
                    Follow on X
                </Button>
            </View> */}

                <View className="user-list" ref={scrollTarget} onScroll={onTargetScroll}>
                    <TwitterPosts />
                </View>

                <div className="infinite-scroll-loader">
                    {loading && (
                        <div data-testid="loader">
                            <Loader width={"3rem"} height={"3rem"} filledColor={"white"} emptyColor={"red"} />
                        </div>
                    )}
                </div>

                <PopupConfirmation
                    open={open}
                    setOpen={setOpen}
                    onAccept={redirect}
                    title="You will be redirected"
                    message={
                        "Stepping outside is an option, yet the RPGMatch’s spirit gently reminds you, ’Adventures await, but our doors will forever welcome you back."
                    }
                    btnAccept="CONTINUE"
                    btnReject="CANCEL"
                />
            </View>
            {showLoginBanner && <LoginBanner />}
        </>
    );
}
