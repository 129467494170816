import React, { useState } from "react";
import "../Cards/Cards.css";
import {
    Container,
    IconButton,
    InputAdornment,
    OutlinedInput,
    TextField,
    InputLabel,
    FormControl,
} from "@mui/material";
import { Loader, Text } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import VisibilityOffTwoToneIcon from "@mui/icons-material/VisibilityOffTwoTone";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import { useForm } from "react-hook-form";
import AuthGenericMessages from "components/AuthGenericMessages";
import Button from "components/Button";
import { passwordRegex, emailRegex } from "utils/regexPatterns";
const CardPassword = ({
    title = "Reset your password",
    buttonText = "SEND CODE",
    onRecoverPassword,
    message,
    step = 1,
    cleanMessage,
    resendCode,
    loading = false,
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const {
        handleSubmit,
        register,
        reset,
        setFocus,
        formState: { errors },
    } = useForm();

    return (
        <Container className="card login auth-container">
            <Text className="card_title">{title}</Text>
            <AuthGenericMessages>{message}</AuthGenericMessages>
            <form onSubmit={handleSubmit(onRecoverPassword)}>
                {step === 1 && (
                    <div className="card-password-form">
                        <TextField
                            variant="outlined"
                            type="email"
                            label="Enter your email*"
                            {...register("email", {
                                required: "Required",
                                pattern: {
                                    value: emailRegex,
                                    message: "Please enter a valid email address.",
                                },
                            })}
                            value={email}
                            onChange={(event) => setEmail(event.target.value)}
                            disabled={loading}
                        />
                        {errors.email && <Text className="error-msg red-msg">{errors.email.message}</Text>}

                        <Button type="submit" disabled={loading}>
                            {buttonText}
                        </Button>
                        <Text className="card_text">
                            <Link onClick={cleanMessage} to={"/"} style={{ color: "#4da3cb" }}>
                                Back to Sign In
                            </Link>
                        </Text>
                    </div>
                )}
                {step === 2 && (
                    <div className="card-password-form">
                        <input type="hidden" name="email" value={email} />

                        <TextField
                            variant="outlined"
                            type="text"
                            label="Code*"
                            {...register("code", {
                                required: "Required",
                            })}
                            disabled={loading}
                        />
                        {errors.code && <Text className="error-msg red-msg">{errors.code.message}</Text>}

                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password*</InputLabel>
                            <OutlinedInput
                                {...register("password", {
                                    required: "Required",
                                    pattern: {
                                        value: passwordRegex,
                                        message:
                                            "There is an issue with the password you’ve chosen. Please revise our password rules",
                                    },
                                })}
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password*"
                                disabled={loading}
                                onChange={(e) => {
                                    setPass(e.target.value);
                                }}
                            />
                        </FormControl>
                        {errors.password && (
                            <Text className={`error-msg ${errors.password ? "red-msg" : ""}`}>
                                Must be 6 or more characters and contain at least 1 uppercase and 1 lowercase letter.
                            </Text>
                        )}

                        <FormControl variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Confirm password*</InputLabel>
                            <OutlinedInput
                                {...register("confirm_password", {
                                    required: "Required",
                                })}
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end">
                                            {showPassword ? <VisibilityOffTwoToneIcon /> : <VisibilityTwoToneIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm password*"
                                disabled={loading}
                                onChange={(e) => {
                                    setConfirmPass(e.target.value);
                                }}
                            />
                        </FormControl>
                        {(errors.confirm_password || pass !== confirmPass) && (
                            <Text
                                className={`error-msg ${
                                    errors.confirm_password || pass !== confirmPass || errors.password ? "red-msg" : ""
                                }`}>
                                Required/Passwords do not match.
                            </Text>
                        )}

                        <Button type="submit" disabled={loading}>
                            {loading && (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        padding: "5px 0",
                                    }}>
                                    <Loader width={"6rem"} height={"6rem"} filledColor={"white"} emptyColor={"red"} />
                                </div>
                            )}
                            {!loading && <>Submit</>}
                        </Button>
                    </div>
                )}
            </form>
            {step === 2 && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                    <div className="auth-confirmation-code-sending">
                        <button
                            className="auth-resend-confirmation-btn"
                            onClick={() => {
                                cleanMessage();
                                setFocus("code");
                                reset((formValues) => ({
                                    ...formValues,
                                }));
                                resendCode(email);
                            }}>
                            Resend code
                        </button>
                    </div>
                    <Text className="card_text">
                        <Link onClick={cleanMessage} to={"/"} style={{ color: "#4da3cb" }}>
                            Back to Sign In
                        </Link>
                    </Text>
                </div>
            )}
        </Container>
    );
};

export default CardPassword;
