import "./GroupDialogs.scss";

import React from "react";

import { Close, Warning, Logout } from "@mui/icons-material";
import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { leaveGroup } from "@services/groupsProvider";
import { useBreakpoint } from "hooks/useBreakpoint";

type LeaveGroupDialogProps = {
    open: boolean;
    groupId: string;
    onConfirm: () => void;
    onCancel: () => void;
};

export default function LeaveGroupDialog({ open, onCancel, groupId, onConfirm }: LeaveGroupDialogProps) {
    /* States and Hooks */
    const { popup, showPopup } = usePopupController();
    const { isMobile } = useBreakpoint();

    /* Functions */
    async function leave() {
        try {
            await leaveGroup(groupId);
            showPopup({
                title: "Success",
                severity: "success",
                description: "You have left the group.",
            });
            onConfirm?.();
        } catch (error) {
            showPopup({
                title: "Error",
                severity: "error",
                description: "Something went wrong. Please try again.",
            });
        }
    }

    /* Variables */
    const classes: Array<string> = [
        "leave-group",
        "group-dialog",
        "group-dialog-container",
        isMobile ? "group-dialog-mobile" : "group-dialog-desktop",
    ];

    return (
        <>
            <AlertPopUpSmall {...popup} />
            <Dialog open={open}>
                <DialogContent className={classes.join(" ")}>
                    <Warning className="group-icon" />
                    <Box className="group-content">
                        <Typography className="group-alert-title" variant="h3">
                            Leave group
                        </Typography>
                        <Typography className="group-alert-description">
                            Are you sure you want to leave this group? You will lose your seat in this table and might
                            not be able to join back.
                        </Typography>
                        <Box className="group-dialog-buttons">
                            <SecondaryApplyButton icon={<Close fontSize="small" />} onClick={onCancel}>
                                Cancel
                            </SecondaryApplyButton>
                            <ApplyButton icon={<Logout fontSize="small" />} onClick={leave}>
                                Leave Group
                            </ApplyButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
