import React from "react";
import { Box, Typography } from "@mui/material";
import Button from "components/Button";
import { useBreakpoint } from "hooks/useBreakpoint";

import "./GroupsNoResults.scss";

interface GroupsNoResultsProps {
    clearFilters: () => void;
}

export default function GroupsNoResults({ clearFilters }: GroupsNoResultsProps) {
    const { isMobile } = useBreakpoint();

    return (
        <Box className={isMobile ? "text-left no-groups-mobile" : "text-center no-groups"}>
            <Typography className="mb-3" variant="h2">
                Empty Coffers: No Results Found!
            </Typography>
            <Typography variant="h4">It seems like your filter settings did not return any results.</Typography>
            <Box className={`mx-auto my-4 ${!isMobile ? "w-50" : ""}`}>
                <Button onClick={clearFilters}>CLEAR ALL FILTERS</Button>
            </Box>
        </Box>
    );
}
