import dayjs, { Dayjs } from "dayjs";

export interface ParsedSchedule {
    getTime: () => string;
    weekday: string;
    time: Dayjs;
}

export function parseSchedule(scheduleString: string): ParsedSchedule | null {
    if (!scheduleString) return null;

    // Split the time string to separate weekday and start time
    const [weekday, startTimeStr, ampm] = scheduleString.split(" ");

    // Adjust the start time based on the GMT offset with PM or AM
    const time = dayjs(startTimeStr + " " + ampm, "hh:mm A");

    const getTime = () => time.format("hh:mm A");

    return {
        getTime,
        weekday,
        time,
    };
}
