import React, { useEffect } from "react";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import { BackButton } from "components/BackButton";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import "../AboutUs/index.css";

const CommunityGuideLines = ({ title }) => {
    useEffect(() => {
        document.title = title;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <View className="fullscreen-mobile">
            <View className="section-header app-inner-gutters section_header_about">
                <BackButton onClickBack={() => window.history.back()} />
                <Text as="h1">Community Guidelines</Text>
            </View>
            <View className="section-body app-inner-gutters">
                <Text>
                    Welcome to the RPGMatch community. Our values are 1) Safety, 2) Privacy, 3) Enabling people to play
                    the games they want to, and 4) respecting time. Respect for your peers is key to remaining a part of
                    the community, and should you not adhere to our standards you may lose your account or your access.
                </Text>

                <Text>
                    Below is a list of our community policies. If you violate these policies, you may be banned from
                    RPGMatch and your account may be deleted. We encourage you to report any behavior that violates our
                    policies. These policies both govern public areas of the application and site (your profile, the
                    user list, forums, etc) and well as private peer-to-peer communication.
                </Text>

                <Text as="h2" className="title_about">
                    RPGMatch is not for:
                </Text>

                <Text as="h1" className="title_about">
                    Harassment &amp; Hate Speech
                </Text>
                <Text>
                    Do not engage in abuse or harassment against any other user. Reports of trolling, stalking, slander,
                    shaming, threats, bullying, or intimidation, are taken very seriously. Any content that promotes,
                    advocates for, or condones racism, bigotry, hatred, or violence against individuals or groups based
                    on factors like race, ethnicity, religious affiliation, politics, disability, gender, age, national
                    origin, sexual orientation, or gender identity is not allowed.
                </Text>

                <Text as="h1" className="title_about">
                    Private Information
                </Text>
                <Text>
                    Privacy is a key tenant at RPGMatch, and keeping your privacy requires your cooperation. Don't
                    publicly share any private information, whether it is yours or anyone else's. This includes (but is
                    not limited to) names, passwords, financial information, phone numbers, email addresses, physical
                    address. More information on privacy policy can be found here:
                </Text>
                <Text>
                    <a href="https://www.rpgmatch.org/privacy">https://www.rpgmatch.org/privacy</a>
                </Text>

                <Text as="h1" className="title_about">
                    Violence and Physical Harm
                </Text>
                <Text>
                    We do not tolerate violent, or graphic content or any actions or content that advocate for or
                    threaten violence of any sort, including threatening or promoting terrorism. Physical assault,
                    coercion, and any acts of violence are strictly prohibited. The prohibition on violent conent
                    includes content that glorifies suicide or self-harm. In these situations, we may take a number of
                    steps to assist the user, including reaching out with crisis resources.
                </Text>

                <Text as="h1" className="title_about">
                    Nudity &amp; Sexual Content
                </Text>
                <Text>
                    {" "}
                    No nudity and no sexually explicit content should be posted or shared via your RPGMatch account.
                    RPGMatch is not a dating site, so keep it clean.
                </Text>

                <Text as="h1" className="title_about">
                    Spam &amp; Solicitation
                </Text>
                <Text>
                    Don't use RPGMatch to promote commercial agendas. People on RPGMatch are here to connect with
                    people, not to be sold. If the purpose of your profile is to advertise your event or business,
                    non-profit, political campaign, contest, or to conduct research, we may delete your account.
                </Text>

                <Text as="h1" className="title_about">
                    Prostitution &amp; Trafficking
                </Text>
                <Text>
                    Promoting or advocating for commercial sexual services, human trafficking or other non-consensual
                    sexual acts is strictly prohibited and will result in your account being deleted.
                </Text>

                <Text as="h1" className="title_about">
                    Scamming
                </Text>
                <Text>
                    RPGMatch has a zero-tolerance policy on predatory behavior of any kind. Anyone attempting to get
                    other users' private information for fraudulent or illegal activity may be banned. Any user caught
                    sharing their own financial account information (PayPal, Venmo, etc.) for the purpose of receiving
                    money from other users may also be banned.
                </Text>

                <Text as="h1" className="title_about">
                    Impersonation
                </Text>
                <Text>
                    Don't pretend to be someone you are not. Do not impersonate, or otherwise misrepresent affiliation,
                    connection or association with, any person or entity. This includes parody accounts.
                </Text>

                <Text as="h1" className="title_about">
                    Minors
                </Text>
                <Text>
                    You must be 18 years of age or older to use RPGMatch. As such, we do not allow images of
                    unaccompanied minors. If you want to post photos of your children, please make sure that you are in
                    the photo as well. If you see a profile that includes an unaccompanied minor, encourages harm to a
                    minor, or depicts a minor in a sexual or suggestive way, please report it immediately
                </Text>

                <Text as="h1" className="title_about">
                    Copyright &amp; Trademark Infringement
                </Text>
                <Text>
                    If your RPGMatch profile includes any work that is copyrighted or trademarked by others, don't
                    display it, unless you are allowed to do so.
                </Text>

                <Text as="h1" className="title_about">
                    Illegal Usage
                </Text>
                <Text>Don't use RPGMatch to do anything illegal or your account may be banned.</Text>

                <Text as="h1" className="title_about">
                    One Account
                </Text>
                <Text>
                    We are trying to foster connections between PEOPLE, so RPGMatch is not intended to have more than
                    one account per person.
                </Text>

                <Text as="h1" className="title_about">
                    Account Dormancy
                </Text>

                <Text>
                    If you don't log in to your RPGMatch account for prolonged period of time, we may delete your
                    account for inactivity. We aren't in the habit of doing this regularly, but you should check in from
                    time to time.
                </Text>

                <Text as="h1" className="title_about">
                    HOW TO CONTACT US
                </Text>
                <Text>
                    If you have questions about these Community Guidelines, here's how you can reach us at{" "}
                    <a href="mailto:privacy@rpgmatch.org">privacy@rpgmatch.org</a>
                </Text>
                <View className="buttons_about">
                    <Box className="button_about">
                        <Link to="/about">About us</Link>
                    </Box>
                    <Box className="button_about">
                        <Link to="/privacy">Privacy Policy</Link>
                    </Box>
                </View>
                <br />
            </View>
        </View>
    );
};

export default CommunityGuideLines;
