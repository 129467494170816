import "./EmailNotificationSettings.scss";

import React from "react";

import { Questions } from "@components/Forms";
import { ApplyButton } from "@components/ApplyButton";
import { Box, CircularProgress, Typography } from "@mui/material";

import { Switch } from "@mui/joy";

import { useBreakpoint } from "hooks/useBreakpoint";
import { conditionalClassName } from "utils/functions";

import EmailNotificationSettingsQuestions from "./EmailNotificationSettings.questions";
import useEmailNotificationSettings, { emailNotificationSettingsFormID } from "@hooks/useEmailNotificationSettings";

import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { CssVarsProvider } from "@mui/joy/styles";

import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

export default function EmailNotificationSettings(): React.ReactElement {
    /* States and Hooks */
    const {
        state,
        isError,
        setState,
        rollback,
        isBlocked,
        isLoading,
        savePreferences,
        stateHasChanged,
        disableAllAlerts,
    } = useEmailNotificationSettings();

    const { isMobile, isTablet, isDesktop, isLaptop } = useBreakpoint();

    const { popup, showPopup } = usePopupController();

    /* Variables */
    const className: string = conditionalClassName(
        ["email-notification-settings"],
        [isMobile, "email-notification-settings-mobile"],
        [isTablet, "email-notification-settings-tablet"],
        [isDesktop || isLaptop, "email-notification-settings-desktop"],
    );

    /* Render */

    if (isLoading)
        return (
            <Box sx={{ display: "grid", placeContent: "center" }} className={className}>
                <CircularProgress color="primary" />
            </Box>
        );

    if (isError) {
        return (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} className={className}>
                <Box sx={{ width: "80%" }}>
                    <Typography variant="h3">Something went wrong.</Typography>
                    <Typography>
                        We were unable to load your email notification settings at this time. Please, try again later.
                    </Typography>
                </Box>
            </Box>
        );
    }

    return (
        <React.Fragment>
            <AlertPopUpSmall {...popup} />
            <Box className={className}>
                <Box className="header">
                    <Typography variant="h3">Email Notification Settings</Typography>
                </Box>
                <Box component="main">
                    <Typography className="description">
                        Manage your email and social notifications to stay informed without feeling overwhelmed. Adjust
                        your preferences anytime to receive only the updates that matter most to you.
                    </Typography>
                    <Questions
                        state={state}
                        formId={emailNotificationSettingsFormID}
                        setState={setState}
                        questions={EmailNotificationSettingsQuestions}
                    />
                    <Box component="footer">
                        <Box className="switch-container">
                            <Box>
                                {/* This extra box is needed for positioning reasons */}
                                <CssVarsProvider>
                                    <Switch
                                        color="danger"
                                        defaultChecked={isBlocked}
                                        endDecorator="Deactivate all alerts"
                                        onChange={() => {
                                            if (stateHasChanged) rollback();
                                            else disableAllAlerts();
                                        }}
                                    />
                                </CssVarsProvider>
                            </Box>
                            <ShouldRender returnNull condition={isBlocked}>
                                <Typography className="hint">
                                    Oh, you chose to miss out on everything. How sad :(
                                </Typography>
                            </ShouldRender>
                        </Box>
                        <ApplyButton
                            sx={{ width: "fit-content" }}
                            onClick={() =>
                                savePreferences()
                                    .then(() => {
                                        showPopup({
                                            title: "Success",
                                            severity: "success",
                                            description: "Your preferences have been saved.",
                                        });
                                    })
                                    .catch(() => {
                                        showPopup({
                                            title: "Error",
                                            severity: "error",
                                            description:
                                                "An error occurred while saving your preferences. Please try again.",
                                        });
                                    })
                            }>
                            Save preferences
                        </ApplyButton>
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    );
}
