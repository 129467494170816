import React, { useState } from "react";
export const defaultSorting = "Join Date";
export const SortContext = React.createContext();

export const SortProvider = ({ children }) => {
    const [sortingOption, setSortingOption] = useState(defaultSorting);
    const [listOrder, setListOrder] = useState(defaultSorting);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    return (
        <SortContext.Provider
            value={{
                anchorEl,
                setAnchorEl,
                sortingOption,
                setSortingOption,
                listOrder,
                setListOrder,
                isSortMenuOpen: open,
            }}>
            {children}
        </SortContext.Provider>
    );
};
