import { View, Text } from "@aws-amplify/ui-react";
import { Checkbox } from "@mui/material";
import Styles from "./DirectMessages.module.css";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export default function DirectMessages({ state, editState, isEditing, changeEditState }) {
    if (isEditing)
        return (
            <View id="messages" className={Styles["container"]} data-testid="direct-messages">
                <Text className={Styles["title"]}>Messages</Text>
                <View>
                    <View className={Styles["inner-container"]}>
                        <Checkbox
                            className={Styles["checkbox"]}
                            icon={<CheckBoxOutlineBlankIcon />}
                            checkedIcon={<CheckBoxTwoToneIcon className={Styles["checkbox-icon"]} />}
                            checked={
                                !editState.hasOwnProperty("messageDisabled")
                                    ? !state.profile?.messageDisabled?.[0]
                                    : !editState?.messageDisabled
                            }
                            onChange={() => {
                                const changed = !editState.hasOwnProperty("messageDisabled")
                                    ? !state.profile?.messageDisabled?.[0]
                                    : !editState?.messageDisabled;

                                changeEditState?.({ messageDisabled: changed });
                            }}
                        />
                        <Text className={Styles["message"]}>Allow users to message me</Text>
                    </View>
                    <Text className={Styles["note"]}>Note: when disabled you won’t be able to send new messages</Text>
                </View>
            </View>
        );
    else return <></>;
}
