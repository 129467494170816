import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { checkLoggedIn } from "utils/functions";

export const AuthenticatedRoute = ({ children, defaultRoute = "/login", alternativeRoute }) => {
    const [loggedIn, setLoggedIn] = useState(false);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            try {
                const isLoggedIn = await checkLoggedIn();
                if (isLoggedIn && alternativeRoute) {
                    navigate(alternativeRoute);
                    return;
                }

                if (!isLoggedIn && !alternativeRoute) {
                    navigate({ pathname: defaultRoute, search: `?${searchParams}` });
                    return;
                }

                isLoggedIn && setLoggedIn(isLoggedIn);
            } catch (error) {
                setLoggedIn(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.href]);

    return loggedIn || (alternativeRoute && !loggedIn) ? children : null;
};
