import React from "react";

import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { ProfileSocialMedias } from "components/ProfileInterview/ProfileSocialMedias.tsx";
import { PrivateProfileModel } from "models/ProfileModels";

interface SocialMediaComponentInterface {
    state: PrivateProfileModel;
    counter: number;
    length: number;
    goBack: () => void;
    skipButton: () => React.JSX.Element;
    handleEditProfile: (request: object) => Promise<void>;
    pushCounter: () => void;
}

export const SocialMediasComponent = ({
    state,
    counter,
    length,
    goBack,
    skipButton,
    handleEditProfile,
    pushCounter,
}: SocialMediaComponentInterface) => {
    return (
        <CompleteProfileContainer hasDarkClass={false} rootContainerClass="question-name">
            <View className="top-text"></View>
            <View className="question-container">
                <ProfileSocialMedias state={state} handleEditProfile={handleEditProfile} pushCounter={pushCounter} />
            </View>
            <BackNextBtn
                goBack={goBack}
                counter={counter}
                length={length}
                nextBtn={skipButton}
                pageState={state.twitter_name}
            />
        </CompleteProfileContainer>
    );
};
