import React, { useEffect, useState } from "react";
import { Button, View } from "@aws-amplify/ui-react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import styles from "./styles.module.css";
import formatUsername from "@utils/formatUsername.ts";
import "@components/PopupConfirmation/styles.css";
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { savedUsernamesInterface } from "models/popupAddFollowMe/savedUsernames";
import { popupInfoInterface } from "models/popupAddFollowMe/popupInfo";
import { hasErrorInterface } from "models/popupAddFollowMe/hasError";
import { saveSocialMediaRequest } from "models/popupAddFollowMe/saveSocialMediaRequest";

interface PopupAddFollowMeInterface {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    savedUsernames: savedUsernamesInterface;
    setSavedUsernames: React.Dispatch<React.SetStateAction<savedUsernamesInterface>>;
    popupInfo: popupInfoInterface;
    hasError: hasErrorInterface;
    setHasError: React.Dispatch<React.SetStateAction<hasErrorInterface>>;
    changeEditState?: (request: saveSocialMediaRequest, goToNextPage?: boolean) => void;
    isSurvey?: boolean;
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2.5),
    },
}));

const specialCharsRegex = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]+/;

export function PopupAddFollowMe({
    open,
    setOpen,
    savedUsernames,
    setSavedUsernames,
    popupInfo,
    hasError,
    setHasError,
    changeEditState = () => {},
    isSurvey = false,
}: PopupAddFollowMeInterface) {
    const [inputValue, setInputValue] = useState<string>("");

    useEffect(() => {
        setInputValue(savedUsernames[popupInfo.key]);
    }, [popupInfo.key]);

    const handleClose = () => setOpen(false);

    const handleConnectButton = (socialMediaInfo: popupInfoInterface) => {
        const socialMediaKey = socialMediaInfo.key || "";
        const checkUsername = checkValueToSave(inputValue, socialMediaKey, setHasError);

        if (!hasError.check) {
            const username = formatUsername(checkUsername, socialMediaKey);
            setSavedUsernames({ ...savedUsernames, [socialMediaKey]: username });

            if (!isSurvey) {
                const requestValue = { [socialMediaKey]: username };
                changeEditState(requestValue);
            }
        }

        setOpen(false);
    };

    const disableConnectButton = () => inputValue === "";

    return (
        <BootstrapDialog
            onClose={handleClose}
            open={open}
            className={`${styles["dialog_modal"]}`}
            sx={{ borderRadius: 0 }}
            PaperProps={{
                sx: { maxWidth: "300px" },
                style: { backgroundImage: "unset", backgroundColor: "#242424", border: "1px solid #2D2D2E" },
            }}>
            <DialogTitle className={styles["titleBox"]}>
                <Typography fontSize={24} className={`${styles["font-title"]}`}>
                    {`Connect your ${popupInfo?.title}`}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <DialogContentText fontSize={14} align="left" color={"gray.light"}>
                    {popupInfo?.message}
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                    }}>
                    <TextField
                        sx={{ mt: 1 }}
                        label={`${popupInfo?.title} Username`}
                        id="standard-size-small"
                        data-testid="textField-test"
                        size="small"
                        onChange={(e) => setInputValue(e.target.value)}
                        variant="standard"
                        error={hasError?.check}
                        helperText={hasError?.message}
                        value={inputValue}
                        fullWidth
                    />
                    <Typography fontSize={12} align="left" sx={{ mt: 1 }} color={"gray.pale"}>
                        Once you connected your account, you won't be able to remove it.
                    </Typography>
                    <View className="popup-confirmation-buttons">
                        <Button
                            onClick={() => handleConnectButton(popupInfo)}
                            color={"red.pale"}
                            disabled={disableConnectButton()}
                            data-testid="connect-btn-test"
                            className="popup-confirmation-button btn-accept">
                            <Typography>Connect</Typography>
                        </Button>
                        <Button onClick={handleClose} className="popup-confirmation-button btn-reject">
                            <Typography>Cancel</Typography>
                        </Button>
                    </View>
                </Box>
            </DialogContent>
        </BootstrapDialog>
    );
}

//Component's utils functions

function checkValueToSave(
    username: string,
    socialMediaKey: string,
    setHasError: React.Dispatch<React.SetStateAction<hasErrorInterface>>,
) {
    if (username.match(specialCharsRegex)) {
        if (username.includes("/")) {
            // check if it's URL
            username = splitSocialMediaNameReturnUsername("/", username);
        } else if (username.includes("@")) {
            //check if uses @
            if (socialMediaKey !== "mastodon") socialMediaKey = splitSocialMediaNameReturnUsername("@", username);
        } else {
            setHasError({
                check: true,
                message: "Please enter a valid format using either your @ or URL",
            });
            username = "";
        }
    }
    return username;
}

function splitSocialMediaNameReturnUsername(charToSplit: string, socialMediaName: string) {
    const arraySocialMediaName = socialMediaName.split(charToSplit);
    let socialMediaNameFormated = arraySocialMediaName.pop() || "";
    return socialMediaNameFormated.split("?")?.[0].replace(specialCharsRegex, "");
}
