import "./FilterChips.css";
import { Chip } from "@mui/material";
import { View } from "@aws-amplify/ui-react";
import { awsFileURL, isFilled } from "utils/functions";
import PlayerVerifiedIcon from "../../Profile/components/PlayerVerifiedIcon";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";

const filterValues = [
    { key: "playerType", name: "Type of Player" },
    { key: "name", name: "Name" },
    { key: "age", name: "Age" },
    { key: "safetyTools", name: "Safety Tools" },
    { key: "gamesPlayed", name: "Game" },
    { key: "availability", name: "Availability" },
    { key: "badges", name: "Badge" },
    { key: "verified", name: "Accounts" },
    { key: "country", name: "Country" },
    { key: "state", name: "State" },
    { key: "city", name: "City" },
    { key: "location", name: "Location" },
    { key: "languages", name: "Languages" },
    { key: "vtt", name: "Platform" },
];

const FiltersToShow = ({ makeObject, getLabel, currentFilters, onDelete }) => {
    return filterValues.map((filter) => {
        const filterItem = currentFilters[filter.key];

        if (!filterItem) return;
        const currentFilter = !Array.isArray(filterItem)
            ? filterItem
            : filterItem.map((item) => makeObject(item, filter.key));

        if (!Boolean(currentFilter)) return null; // Case it has no filter
        if (!Array.isArray(currentFilter)) {
            const label = getLabel(filter.name, currentFilter),
                key = `filter-chip-${filter.name}`;

            return (
                <Chip
                    key={key}
                    label={label}
                    variant="outlined"
                    data-testid="filter-chip"
                    onDelete={() => onDelete([filter.key])}
                />
            );
        }
        const Badges = (value) => {
            if (filter.key === "badges") {
                const img = value?.value?.image || value?.value?.img;
                const name = value?.value?.name;

                return (
                    <img
                        className="MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"
                        height="20px"
                        width={"20px"}
                        src={`${awsFileURL()}/badges/${img}`}
                        alt={`${name} Badge`}
                    />
                );
            }

            return null;
        };

        // Default behavior
        const isObject = typeof currentFilter[0] === "object";
        return currentFilter.map((value, index) => {
            if (index < 3) {
                const key = `filter-chip-${filter.name}-${index}`,
                    label = getLabel(filter.name, isObject ? value?.languageName || value.name : value);
                return (
                    <Chip
                        key={key}
                        data-testid="filter-chip"
                        label={label}
                        icon={<Badges value={value} />}
                        variant="outlined"
                        onDelete={() => {
                            if (
                                ["gamesPlayed", "vtt", "age", "badges", "playerType", "languages"].includes(filter.key)
                            ) {
                                onDelete([{ key: filter.key, value: isObject ? value.id : value }]);
                                return;
                            }

                            onDelete([filter.key]);
                        }}
                    />
                );
            }

            if (index === 3) {
                return <div>{`+${currentFilter.length - 3}`}</div>;
            }
        });
    });
};

const FilterChips = ({
    currentFilters = {},
    filterActive,
    clearFilter,
    filterFields,
    isLoggedIn,
    setLoginPopup,
    pathToName,
}) => {
    const navigate = useNavigate();
    if (!filterActive) {
        return null;
    }

    const { vtt, badgesOptions, games, languages } = filterFields;

    const makeObject = (currentFilter, filterKey) => {
        if (currentFilter === undefined) return;
        switch (filterKey) {
            case "vtt":
                return vtt?.find((vtt) => vtt?.id === parseInt(currentFilter));
            case "badges":
                return badgesOptions?.find((badge) => badge?.id === parseInt(currentFilter));
            case "gamesPlayed":
                return games?.find((game) => game?.id === parseInt(currentFilter));
            case "languages":
                return languages?.find((language) => language?.id === parseInt(currentFilter));
            default:
                return currentFilter;
        }
    };

    const getLabel = (title, value) => {
        let filterValue = value?.name || value?.languageName || value;
        switch (title) {
            case "Availability":
                filterValue = "Matches my availability";
                break;
            case "Type of Player":
                filterValue = value === "DM" ? "GM" : value;
                break;
            case "Accounts":
                filterValue = (
                    <View className="verified-chip">
                        <PlayerVerifiedIcon />
                        <span>Verified</span>
                    </View>
                );
                break;
            case "Languages":
                const find = filterFields.languages.find((item) => item.id === parseInt(value, 10));
                filterValue = find?.languageName || value;
                break;
            default:
                break;
        }

        return (
            <div className="chip-label" data-testid="chip-label">
                <span>{`${title}: `}</span>
                <span className="chip-value">{filterValue}</span>
            </div>
        );
    };

    const onDelete = async (filters) => {
        if (isLoggedIn) {
            if (window.location.pathname.includes("location") || window.location.pathname.includes("game")) {
                clearFilter({
                    preserveFilters: ["sort"],
                });
                navigate("/user-list");
            } else {
                clearFilter({
                    removeFilters: filters,
                });
            }
        } else {
            setLoginPopup(true);
        }
    };

    return (
        <ShouldRender returnNull condition={isFilled(vtt, badgesOptions, games, languages)}>
            <View className="filter-chips" data-testid="filter-chips">
                <FiltersToShow
                    makeObject={makeObject}
                    getLabel={getLabel}
                    currentFilters={currentFilters}
                    onDelete={onDelete}
                />
            </View>
        </ShouldRender>
    );
};

export default FilterChips;
