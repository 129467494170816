import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";

import ProfileGender from "@components/ProfileInterview/ProfileGender";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";

export const GenderComponent = ({ state, handleEditProfile, pushCounter, skipButton, goBack, counter, length }) => {
    return (
        <CompleteProfileContainer rootContainerClass="question-gender">
            <View className="top-text"></View>
            <View className="question-container">
                <ProfileGender state={state} pushCounter={pushCounter} handleEditProfile={handleEditProfile} />
            </View>
            <BackNextBtn
                goBack={goBack}
                counter={counter}
                length={length}
                nextBtn={skipButton}
                pageState={state.gender}
            />
        </CompleteProfileContainer>
    );
};
