import React from "react";
import CardLogIn from "./CardLogIn";
import CardCreateAccount from "./CardCreateAccount";
import CardConfirmation from "./CardConfirmation";
import CardPassword from "./CardPassword";
import { useAuth } from "hooks/useAuth";
import "./index.css";
import { View } from "@aws-amplify/ui-react";
export const AuthScreens = ({ screen, overflow, setOverflow }) => {
    const {
        logIn,
        signUp,
        confirmSignUp,
        resendConfirmationCode,
        recoverPassword,
        cleanMessage,
        sendForgotPasswordCode,
        passwordStep,
        tempEmailSignup,
        message,
        isDone,
        authInProgress,
    } = useAuth(overflow, setOverflow);

    const AuthCards = ({ cleanIcon }) => {
        return (
            <div className="login-overlay-content">
                <div className="ui-auth-screens">
                    {screen === "login" && (
                        <>
                            <CardLogIn
                                onLogIn={logIn}
                                email={tempEmailSignup}
                                loading={authInProgress}
                                message={message}
                                cleanMessage={cleanMessage}
                                cleanIcon={cleanIcon}
                                closePopup={() => setOverflow?.(false)}
                            />
                        </>
                    )}
                    {screen === "signUp" && (
                        <>
                            <CardCreateAccount
                                onSignUp={signUp}
                                message={message}
                                cleanMessage={cleanMessage}
                                loading={authInProgress}
                            />
                        </>
                    )}
                    {screen === "confirm" && (
                        <>
                            <CardConfirmation
                                onConfirm={confirmSignUp}
                                email={tempEmailSignup}
                                resend={resendConfirmationCode}
                                message={message}
                                loading={authInProgress}
                            />
                        </>
                    )}
                    {screen === "password-recovery" && (
                        <>
                            <CardPassword
                                onRecoverPassword={recoverPassword}
                                step={passwordStep}
                                message={message}
                                cleanMessage={cleanMessage}
                                resendCode={sendForgotPasswordCode}
                                loading={authInProgress}
                            />
                        </>
                    )}
                </div>
            </div>
        );
    };

    const DefaultBackground = () => (
        <div className={`login-overlay ${isDone ? "auth-is-done" : ""} ${authInProgress ? "auth-in-progress" : ""}`}>
            <span className="login-overlay-image" style={{ backgroundImage: `url("/optimized_auth-bg.jpeg")` }} />
            <span className="login-overlay-bg" />
            <AuthCards />
        </div>
    );

    const FloatingBackground = () => (
        <View className="login-overlay floating">
            <View onClick={() => setOverflow?.(false)} className="login-overlay-close" />
            <AuthCards cleanIcon={true} />
        </View>
    );

    return overflow ? <FloatingBackground /> : <DefaultBackground />;
};
