import { Text, View } from "@aws-amplify/ui-react";
import React from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useCompleteProfileStyles } from "hooks/useCompleteProfileStyles";
import { LinearProgress } from "@mui/material";
import ProgressBar from "components/ProgressBar/ProgressBar";

export const BackNextBtn = ({
    goBack,
    counter,
    pushCounter = null,
    length,
    nextBtn,
    pageState,
    nextButtonOnClick = null,
    options: { showActionsOnTop, showActionsBackground } = { showActionsBackground: true, showActionsOnTop: true },
}) => {
    const classes = useCompleteProfileStyles();

    const stylizeProgressBar = () => {
        let newClass = `${classes.backButton}`;

        if (showActionsOnTop) {
            newClass += " on-top";
        } else {
            newClass += " on-bottom";
        }

        if (showActionsBackground) {
            newClass += " show-actions-background";
        }

        return newClass;
    };

    return (
        <View className={stylizeProgressBar()}>
            <View className="inner-question-headers">
                <View className="back-txt" onClick={goBack}>
                    <KeyboardArrowLeftIcon />
                    <Text>Back</Text>
                </View>
                <View className="question-progress-container">
                    <ProgressBar progress={counter} total={length} />
                    <Text className="counter-txt">{counter + "/" + length}</Text>
                </View>
                {nextBtn(pageState, pushCounter)}
            </View>
        </View>
    );
};
