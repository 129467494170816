import AccessTimeTwoToneIcon from "@mui/icons-material/SwitchAccount";
import { AccountIcon } from "./AccountIcon";

export const Badge = ({ icon, value }) => {
    return (
        <div className="banner-badge-container">
            <div className="banner-badge-icon">
                {icon}
            </div>
            <div className="banner-badge-value">
                {value}
            </div>
        </div>
    )
}