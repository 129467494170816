import React, { useState } from "react";
import { Text } from "@aws-amplify/ui-react";
import { Avatar, Box, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import UserListIconText from "components/UserListIconText/UserListIconText";
// Icons
import { matchColor } from "utils/matchColor";
import { ShowPlayerBadges } from "components/ShowPlayerBadges/ShowPlayerBadges";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import useImageSrc from "hooks/useImageSrc";

import PlayerVerifiedIcon from "components/Profile/components/PlayerVerifiedIcon";
import PlayerProIcon from "components/Profile/components/PlayerProIcon";
import FavoritedStarButton from "components/FavoritedStarButton/FavoritedStarButton";
import { useGlobalContext } from "context/AppContext";

export function UserListItem({ item, updateFavorites, wait, userCompletedProfile, VTTOptions, personalId }) {
    const [favorited, setFavorited] = useState(item.favorited);
    const [preventProfileClick, setPreventProfileClick] = useState(false);
    const { onError, imgRef, imgSrc } = useImageSrc(item);
    const { isLoggedIn } = useGlobalContext();

    const favoritesTooltipTitle = favorited ? "Unfavorite User" : "Favorite User";
    const tooltipTitle = userCompletedProfile ? null : (
        <Link to="/createprofile">Complete your profile to get more consistent match scores!</Link>
    );

    const matchScoreNumber = item.id === personalId ? 100 : Math.round(item?.match_score || 0);
    const matchScoreColor = matchColor(matchScoreNumber);

    function handleLinkClick(event) {
        if (preventProfileClick) {
            event.preventDefault();
        }
    }
    const changeFavorited = () => setFavorited(!favorited);
    const userInfo = [
        {
            icon: <PublicTwoToneIcon />,
            text: item.formattedLocation?.trim?.() ? item.formattedLocation : "Earth",
            title: item.formattedLocation?.length > 30 ? item.formattedLocation : "",
        },
        {
            icon: item.formattedTimeInfo.icon,
            text: item.formattedTimeInfo.time,
            title: item.formattedTimeInfo.time,
        },
        {
            icon: <img src="/Dice.png" alt="Dice" />,
            text: item?.formattedGamesPlayed,
            title: item?.formattedGamesPlayed?.length > 30 ? item?.formattedGamesPlayed : "",
        },
    ];

    return (
        <Box key={item.id} className="user-list-item-container">
            <Link
                to={{
                    pathname: `/profile/${item.vanityUrl}`,
                }}
                onClick={handleLinkClick}>
                <Box className="list-item-user-container app-inner-gutters">
                    <Box className="user-main-information-container">
                        <Box className="user-avatar-and-match-score-container ">
                            <Box className="user-avatar-with-star">
                                {isLoggedIn && (
                                    <FavoritedStarButton
                                        updateFavorites={updateFavorites}
                                        favorited={item.favorited}
                                        changeFavorited={changeFavorited}
                                        id={item.id}
                                        wait={wait}
                                        setPreventProfileClick={setPreventProfileClick}
                                        preventProfileClick={preventProfileClick}
                                        favoritesTooltipTitle={favoritesTooltipTitle}
                                    />
                                )}
                                <Avatar className="user-avatar avatar-m">
                                    <img
                                        onError={onError}
                                        ref={imgRef}
                                        className="user-avatar avatar-m user-avatar-inner-img-list"
                                        src={imgSrc}
                                        alt="user_profile_image"
                                    />
                                </Avatar>
                            </Box>
                            {isLoggedIn && (
                                <Tooltip title={tooltipTitle}>
                                    <Box
                                        className="user-match-score-box"
                                        style={{
                                            backgroundColor: `${matchScoreColor.bgColor}`,
                                            border: `1px solid ${matchScoreColor.borderColor}`,
                                        }}>
                                        <Text
                                            className={`user-match-score-text${
                                                matchScoreNumber > 99 ? " small-font" : ""
                                            }`}>
                                            {matchScoreNumber}% Match
                                        </Text>
                                    </Box>
                                </Tooltip>
                            )}
                        </Box>
                        <Box className="user-text" sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
                            <Box className="user-title-and-badge" sx={{ display: "flex", gap: "0.5rem" }}>
                                <Text
                                    className="user-information user-name"
                                    title={item.name}
                                    isTruncated={true}
                                    as="h3">
                                    {item.name}
                                </Text>

                                {item.verified?.[0] && <PlayerVerifiedIcon />}
                                {item.isProfessionalGM && <PlayerProIcon />}
                            </Box>

                            <Box>
                                <UserListIconText id={item.id} item={userInfo} />
                                <ShowPlayerBadges
                                    VTTOptions={VTTOptions}
                                    id={item.id}
                                    item={item}
                                    vttList={item?.vtt}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Link>
        </Box>
    );
}
