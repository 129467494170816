import { profileProvider } from "services/profileProvider";
import { useSignOut } from "./useSignOut";
import { useState } from "react";

interface UseDeleteAccountProps {
    setOpenDeleteAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

const useDeleteAccount = ({ setOpenDeleteAccount }: UseDeleteAccountProps) => {
    const { closeAccount } = profileProvider;
    const { signOutAndRedirect } = useSignOut();
    const [closeAccountInput, setCloseAccountInput] = useState("");

    const handleCloseDeleteAccountPopup = () => {
        setCloseAccountInput("");
        setOpenDeleteAccount(false);
    };
    
    const handleDeleteAccount = async () => {
        await closeAccount();
        await signOutAndRedirect();
    };

    return {
        closeAccountInput,
        setCloseAccountInput,
        handleCloseDeleteAccountPopup,
        handleDeleteAccount,
    };
};

export default useDeleteAccount;
