import { Accordion, Checkbox } from "@mui/material";
import React from "react";

import { timeRange, weekDays } from "utils/constants";
import { View } from "@aws-amplify/ui-react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AvailabilityAccordion = ({ availabilityTable, updateTable }) => {
    return (
        <div data-testid="availability-table" className="availability-table edit">
            {weekDays.map((day) => {
                const defaultExpanded = day.day === "Mon";

                return (
                    <Accordion className={`edit`} key={day.day} defaultExpanded={defaultExpanded}>
                        <AccordionSummary
                            expandIcon={<div className="expand-icon-container">{<ExpandMoreIcon />}</div>}>
                            <View>{day.label}</View>
                        </AccordionSummary>
                        <AccordionDetails className="time-row accordion">
                            <AvailabilityAccordionBody
                                day={day}
                                updateTable={updateTable}
                                availabilityTable={availabilityTable}
                            />
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </div>
    );
}


function AvailabilityAccordionBody({ day, updateTable, availabilityTable }) {
    return (
        <>
            {timeRange.map((time) => {
                const isTimeSelected =
                    availabilityTable[day.column][time.row] === "1" || availabilityTable[day.column][time.row] === 1;

                return (
                    <View key={time.description}>
                        <Checkbox
                            checked={isTimeSelected}
                            name={day.id}
                            value={time.description}
                            onChange={updateTable}
                        />
                        <View>
                            {time.start} - {time.end}
                        </View>
                    </View>
                );
            })}
        </>
    );
}

export default AvailabilityAccordion;