import { useEffect } from "react";

type UseDebounceProps = {
    delay: number;
    tracker: Array<any>;
    callback: () => void;
};

export default function useDebounce({ delay, tracker, callback }: UseDebounceProps) {
    useEffect(() => {
        const timer = setTimeout(callback, delay);
        return () => clearTimeout(timer);
    }, tracker);
}
