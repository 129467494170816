import React, { useMemo, useState } from "react";

import "./TTRPGGamesPlayed.css";
import "../ProfileBody.css";
import { Text, View } from "@aws-amplify/ui-react";
import ProfileGamesPlayed from "components/ProfileInterview/ProfileGamesPlayed";
import { ShouldRender } from "../../ShouldRender/ShouldRender";
import { List, ListItem, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { Link } from "react-router-dom";
import getGameURL from "utils/getGameURL";
import WarningIcon from "@mui/icons-material/Warning";

const IWillPlayAnything = ({ gamesPlayed }) => {
    const playAnyThingOption = gamesPlayed?.find(
        (game) => game?.name?.[0] === "I'll play anything!" || game?.name?.[0] === "I'll play anything",
    );

    if (playAnyThingOption) {
        return (
            <Text className="i-will-play-anything">
                I'll play anything
                <img src="/everything_icon.svg" alt="Icon for I'll play anything" />
            </Text>
        );
    } else return null;
};

const GamesPlayedList = ({ isMobile, expanded, gamesPlayed }) => {
    const initialList = gamesPlayed
        ?.filter((game) => game?.name?.[0] !== "I'll play anything!" && game?.name?.[0] !== "I'll play anything")
        ?.sort();

    if (isMobile) {
        const listToBeRendered = expanded ? initialList : initialList?.slice(0, 6);
        return (
            <List data-testid="TTRPGGamesPlayed" className="games-played-list mobile">
                {listToBeRendered.map((game, index) => (
                    <ListItem className="list-item" data-testid="games-played" key={index}>
                        <ListItemIcon className="menu-item-icon">
                            <FiberManualRecord width={10} height={10} />
                        </ListItemIcon>
                        <Link className="game-link profile-game-name" to={getGameURL(game?.name?.[0])} target="_blank">
                            {game?.name?.[0]}
                        </Link>
                    </ListItem>
                ))}
            </List>
        );
    }

    const firstPart = initialList?.slice(0, Math.ceil(initialList?.length / 2));
    const secondPart = initialList?.slice(Math.ceil(initialList?.length / 2));

    const parts = [firstPart, secondPart];
    const className = "games-played-list";

    const getGameNameComponent = (gameName) => {
        return (
            <Link className="game-link" to={getGameURL(gameName)} target="_blank">
                <Tooltip key={gameName} placement="top-start" title={gameName}>
                    <Typography className="profile-game-name">{gameName}</Typography>
                </Tooltip>
            </Link>
        );
    };

    return (
        <View className="games-container">
            {parts.map((part, index) => (
                <List data-testid="TTRPGGamesPlayed" key={`${part} - ${index}`} className={className}>
                    {part.map((game, gameIndex) => {
                        return (
                            <ListItem
                                className="list-item"
                                data-testid="games-played"
                                key={`${game?.name?.[0]} - ${gameIndex}`}>
                                <ListItemIcon className="menu-item-icon">
                                    <FiberManualRecord />
                                </ListItemIcon>
                                {getGameNameComponent(game?.name?.[0])}
                            </ListItem>
                        );
                    })}
                </List>
            ))}
        </View>
    );
};

const TTRPGGamesPlayed = ({
    gamesPlayed,
    changeEditState,
    isEditing,
    filterFields,
    loading,
    updateFiltersFieldsAsync,
    isMobile,
}) => {
    const [expanded, setExpanded] = useState(false);

    const isEmpty = useMemo(() => {
        return !gamesPlayed || gamesPlayed?.length === 0;
    }, [gamesPlayed]);

    const showComplete = useMemo(() => {
        return isEmpty && isEditing;
    }, [isEditing, isEmpty]);

    if (isEmpty && !isEditing) {
        return null;
    }

    const ViewContainer = () => {
        return (
            <>
                <IWillPlayAnything gamesPlayed={gamesPlayed} />
                <GamesPlayedList gamesPlayed={gamesPlayed} isMobile={isMobile} expanded={expanded} />
                {isMobile && gamesPlayed?.length > 7 && (
                    <Text onClick={() => setExpanded((exp) => !exp) && alert("Here")} className="see-more">
                        {expanded ? "See less..." : "See more..."}
                    </Text>
                )}
            </>
        );
    };

    return (
        <ShouldRender returnNull>
            <View
                id="games-played"
                data-testid="ttrpg-games-played"
                className={`TTRPG-games-played-container profile-component ${showComplete ? "not-completed" : ""}`}>
                <Text className="TTRPG-games-played-head-text" as="h2">
                    <span>Games Played</span> {showComplete && <WarningIcon sx={{ color: "#FFB74D" }} />}
                </Text>
                {isEditing ? (
                    <ProfileGamesPlayed
                        filterFields={filterFields}
                        loading={loading}
                        updateFiltersFieldsAsync={updateFiltersFieldsAsync}
                        state={{ gamesPlayed }}
                        isEditing={isEditing}
                        changeEditState={changeEditState}
                    />
                ) : (
                    <ViewContainer />
                )}
            </View>
        </ShouldRender>
    );
};

export default TTRPGGamesPlayed;
