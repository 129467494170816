import React from "react";
import "./index.css";
import { Modal } from "@mui/material";

// CHange to material UI
import { Card, View } from "@aws-amplify/ui-react";

const Popup = ({ open, setOpen, classes, hideCloseBtn = false, children }) => {
    const props = { open, setOpen, classes, hideCloseBtn, children };

    if (props.open) {
        document.body.style.overflowY = "hidden";
    } else {
        document.body.style.overflowY = "auto";
    }

    return (
        <Modal open={props.open} onClose={() => props.setOpen(false)}>
            <Card className={`popup popup-style ${props.classes} ${props.open ? "active " : ""}`}>
                <View
                    className={`popup-x-parent`}
                    style={{ display: `${props.hideCloseBtn ? "none" : ""}` }}
                    onClick={() => props.setOpen(false)}>
                    <svg
                        className={"popup-x"}
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M11.8334 1.34166L10.6584 0.166664L6.00002 4.825L1.34169 0.166664L0.166687 1.34166L4.82502 6L0.166687 10.6583L1.34169 11.8333L6.00002 7.175L10.6584 11.8333L11.8334 10.6583L7.17502 6L11.8334 1.34166Z"
                            fill="white"
                            fillOpacity="0.56"
                        />
                    </svg>
                </View>
                {props.children}
            </Card>
        </Modal>
    );
};

Popup.propTypes = {};

export default Popup;
