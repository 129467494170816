import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { usePopupController } from "@components/AlertPopUp";
import { emailRegexp, linkRegexp, startPlayingRegexp, weekDaysLinked } from "utils/constants";
import { createGroup, getGroup, getGroups, inviteToGroup, removePlayer, updateGroup } from "@services/groupsProvider";

import { getPhotoID } from "utils/functions";
import { PlayerProps } from "models/group/PlayerProps";
import { ProfileModel } from "models/ProfileModels";
import { CMSLanguages } from "models/cms/fileds";
import { parseSchedule } from "@utils/schedule";
import { GameType, PlatformType } from "models/cms/fileds";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { GroupMemberType, GroupType } from "models/group/GroupModel";
import useGroupInvite from "./useGroupInvite";
import { useSetAtom } from "jotai";
import { groupSizeAtom } from "@atoms/groupsAtoms";

export const MINIMUM_GROUP_SIZE = 2;

class ToxicityError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "ToxicityError";
    }
}

type useManageGroupProps = {
    groupId: string;
    personalProfile: Partial<ProfileModel>;
    variant: "CREATE" | "EDIT";
    onSubmit?: (group: GroupType) => void;
};

type GroupKeysType = keyof GroupType;

const requiredProperties: GroupKeysType[] = [
    "game",
    "group_size",
    "schedule_end",
    "game_platform",
    "schedule_start",
    "game_hosting_type",
];

export type ManageGroupPageRedirectState =
    | undefined
    | {
          from: "manage-group";
          updatedGroup: GroupType;
      };

export default function useManageGroup({ groupId, personalProfile, variant, onSubmit }: useManageGroupProps) {
    const navigate = useNavigate();

    const { popup: popupController, showPopup } = usePopupController();

    const [games, setGames] = useState<Array<GameType> | null>(null);
    const [loading, setLoading] = useState(true);
    const [groupState, setGroupState] = useState<Partial<GroupType>>({});
    const [showErrors, setShowErrors] = useState(false);
    const [userOwnsGroup, setUserOwnsGroup] = useState(false);
    const [blockDescriptionModal, setBlockDescriptionModal] = useState(false);
    const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState(false);

    const [groupSize, setGroupSize] = useState(groupState.members?.length || MINIMUM_GROUP_SIZE);
    const setInvitedGroupSize = useSetAtom(groupSizeAtom);

    const {
        invitedUsers,
        setInvitedUsers,
        validateUsers: validateInvitedUsers,
        extractUserInformation,
        resetState: resetInvitedUsersState,
    } = useGroupInvite({ group: groupState });

    const {
        filterFields,
    }: {
        filterFields: {
            games: Array<GameType>;
            vtt: Array<PlatformType>;
            languages: Array<CMSLanguages>;
        };
    } = useContext(AsyncFilterFieldsContext);

    /* Functions */
    function updateProperty(property: GroupKeysType, value: string | number) {
        setGroupState((prev) => ({ ...prev, [property]: value }));
    }

    const handleSubmit = useCallback(async () => {
        try {
            // Clone the group state to be edited
            const groupStateClone = structuredClone(groupState);

            // Checking if the StartPlaying URL is present and is valid when the game_hosting_type is "Paid"
            if (
                groupStateClone.game_hosting_type == "Paid" &&
                !startPlayingRegexp.test(groupStateClone?.startplaying_link || "")
            )
                throw new Error("Invalid group link");

            // Check if cost is being sent with campaign type being free
            if (groupStateClone.cost !== undefined && groupStateClone.game_hosting_type === "Free")
                delete groupStateClone["cost"];

            // Check if cost exists and it is higher than 0 for paid campaigns
            if ((groupStateClone.cost || 0) <= 0 && groupStateClone.game_hosting_type === "Paid")
                throw new Error("For paid campaigns, the cost must be higher than 0");

            // Managing number of members
            groupStateClone["group_size"] = groupSize;

            // Removing all members that are OPEN SEAT
            groupStateClone["members"] = groupStateClone.members?.filter((x) => x.member_type !== "OPEN SEAT");

            if (!groupStateClone["startplaying_link"]) groupStateClone["startplaying_link"] = "";

            // Checking if all required properties are present

            for (const property of requiredProperties) {
                const formattedProperty = property
                    .split("_")
                    .map((value) => value.charAt(0).toUpperCase() + value.slice(1))
                    .join(" ");

                const propertyValue = groupStateClone[property];

                if (propertyValue === undefined || propertyValue === null || propertyValue === "")
                    throw new Error(`Missing required property: ${formattedProperty}`);
            }

            // Check if the group link is valid (Considering it does exists because the required links are present)
            if (groupStateClone.url_link && !linkRegexp.test(groupStateClone.url_link as string))
                throw new Error("Invalid group link");

            /* Check if the schedule have valid dates */

            if (
                groupStateClone.schedule_start?.includes("Invalid") ||
                groupStateClone.schedule_end?.includes("Invalid")
            )
                throw new Error("Invalid Schedule, please check the start and end dates");

            const startSchedule = parseSchedule(groupStateClone.schedule_start as string);
            const endSchedule = parseSchedule(groupStateClone.schedule_end as string);

            // If it ends after it starts then it should be valid
            // we must just consider it is going to finish in the next day
            // 10pm -> 6am (Starts monday at 10pm and ends tuesday at 6am)

            if (endSchedule?.time.isBefore(startSchedule?.time)) {
                const nextWeekday = weekDaysLinked.find((day) => day.day?.label === startSchedule?.weekday)?.nextDay();
                // Schedule Format `${weekday.label} ${endTime.format("HH:mm A")} GMT${gmt}`
                groupStateClone.schedule_end = groupStateClone.schedule_end
                    ?.split(" ")
                    .map((_, index) => {
                        if (index === 0 && nextWeekday?.label) return nextWeekday?.label;
                        else return _;
                    })
                    .join(" ");
            }

            if (startSchedule?.time.isSame(endSchedule?.time))
                throw new Error("Invalid Schedule, please check the start and end dates");

            // Check for toxicity in the group description
            // TODO: Implement with real toxicity check

            // Remove unused properties
            delete groupStateClone.id;
            delete groupStateClone.label;
            delete groupStateClone.Creator;
            delete groupStateClone.group_id;
            delete groupStateClone.creator_id;

            // Removing the host from the group
            groupStateClone["members"] = groupStateClone.members?.filter(
                (member: GroupMemberType) => member.member_type !== "host",
            );

            // Set default language to english if none is selected
            if (!groupState.languages && groupState.languages !== 0) {
                groupStateClone["languages"] = filterFields.languages.find(
                    (language) => language.languageName === "English",
                )?.id;
            }

            // Invite users to the group
            // First we need to check if the users are valid
            const filtered = validateInvitedUsers(invitedUsers);

            if (filtered.find((user) => user.error)) {
                setInvitedUsers(filtered);
                throw new Error("Invalid email address");
            }

            switch (variant) {
                case "CREATE":
                    // Getting the game name
                    const gameName = games?.find((game) => game.id === groupStateClone.game)?.name;

                    // Creating the group
                    const createResponse = {
                        ...(await createGroup(groupStateClone as GroupType)),
                        ...groupStateClone,
                        members: groupState.members,
                    };

                    // Invite users to the group

                    if (filtered.filter((user) => Boolean(user.name)).length > 0)
                        await inviteToGroup(createResponse.group_id, extractUserInformation(filtered))
                            // After a api call clear the invited users so that they can be added again
                            .finally(() => resetInvitedUsersState(true));

                    showPopup({
                        title: "Success",
                        severity: "success",
                        description: gameName
                            ? `Your ${gameName} group has been created successfully!`
                            : "Your group has been created successfully!",
                    });

                    if (onSubmit)
                        // In case the onSubmit is set then execute it without redirecting
                        onSubmit(createResponse);
                    else {
                        // In case the onSubmit is not set, then redirect to the details page
                        sessionStorage.setItem("inheiritedGroupState", JSON.stringify(createResponse));

                        setTimeout(() => {
                            navigate("/group/" + createResponse.group_id);
                        }, 1000);
                    }

                    break;

                case "EDIT":
                    // Remove the members so that we can update the group
                    delete groupStateClone.members;

                    // Updating the group
                    const editResponse = {
                        ...(await updateGroup(groupId, groupStateClone as GroupType)),
                        ...groupStateClone,
                        members: groupState.members,
                    };

                    // Invite users to the group
                    if (filtered.filter((user) => Boolean(user.name)).length > 0)
                        await inviteToGroup(editResponse.group_id, extractUserInformation(filtered))
                            // After a api call clear the invited users so that they can be added again
                            .finally(() => resetInvitedUsersState(true));

                    showPopup({
                        title: "Success",
                        severity: "success",
                        description: "Your group has been updated successfully!",
                    });

                    if (onSubmit)
                        // In case the onSubmit is set then execute it without redirecting
                        onSubmit(editResponse);
                    else {
                        // In case the onSubmit is not set, then redirect to the details page
                        sessionStorage.setItem("inheiritedGroupState", JSON.stringify(editResponse));

                        setTimeout(() => {
                            navigate("/group/" + editResponse.group_id);
                        }, 1000);
                    }

                    break;
            }
        } catch (error) {
            console.error(error);
            if (error instanceof ToxicityError) setBlockDescriptionModal(true);
            else {
                showPopup({
                    title: "Error",
                    severity: "error",
                    description:
                        (error as Error).message ||
                        "Group could not be created at this time. Please check the fields and try again.",
                });
                setShowErrors(true);
            }
        }
    }, [groupState, groupSize, invitedUsers]);

    async function fetchGroup(groupId: string): Promise<void> {
        try {
            const response = await getGroup(groupId);

            if (!response) throw new Error("Group not found");

            // If the group has fewer members than the minimum then add some open seats
            const members = response.members || [];

            if (members.length < MINIMUM_GROUP_SIZE) {
                for (let i = 0; i < MINIMUM_GROUP_SIZE - members.length; i++) {
                    members?.push({ id: "", member_type: "OPEN SEAT", name: "", avatar: "", profileUrl: "" });
                }
            }

            setGroupState({ ...response, members: members });
            setGroupSize(response.group_size || 0);
            setLoading(false);
        } catch (error) {
            console.error(error);
            showPopup(
                {
                    title: "Error",
                    severity: "error",
                    description: "Group could not be loaded at this time. Please try again.",
                },
                5000,
            );
            setTimeout(() => navigate("/"), 5000);
        }
    }

    async function handleRemovePlayer(player: PlayerProps) {
        if (!player.id) throw new Error("Missing player ID");

        try {
            // 1. Removing player from database
            await removePlayer({ group_id: groupId, member_id: player.id });

            // 2. Updating state
            const newMembers = groupState.members?.filter((member) => member.id !== player.id);
            setGroupState({ ...groupState, members: newMembers });

            // 3. Show success message
            showPopup({
                title: "Success",
                severity: "success",
                description: `${player.name} is no longer part of this group.`,
            });
        } catch (error) {
            console.error(error);
            // 4. Show error message
            showPopup({
                title: "Error",
                severity: "error",
                description: "User could not be removed from the group at this time. Please try again.",
            });
        }
    }

    /* Lifecycle */

    useEffect(() => {
        // Await for personalProfile to be available
        if (!personalProfile) return;

        // Considering the type of the page (EDIT or CREATE) assign the variables
        switch (variant) {
            case "EDIT":
                // Await for groupId to be available
                if (!groupId) return;

                fetchGroup(groupId);
                break;
            case "CREATE":
                const hostMember: GroupMemberType = {
                    id: (personalProfile?.id as string) || "",
                    name: personalProfile?.name?.[0] || "",
                    avatar: getPhotoID(personalProfile),
                    profileUrl: "/profile/" + personalProfile?.vanityUrl?.[0] || "",
                    member_type: "host",
                };

                const members = [hostMember];

                setGroupState({
                    game: 0,
                    members,
                    languages: 1,
                    game_platform: 0,
                    game_hosting_type: "Free",
                    Creator: personalProfile?.name?.[0] || "",
                });

                setInvitedGroupSize(groupSize);
                setLoading(false);
                break;

            default:
                // Error if the variant is not valid
                throw new Error("Invalid variant");
        }
        return () => setGroupState({});
    }, [groupId, personalProfile]);

    useEffect(() => {
        if (filterFields.games) setGames(filterFields.games);
    }, [filterFields]);

    // Check if the user already has groups
    useEffect(() => {
        getGroups({ QueryType: "OWNED" }).then((groups) => {
            if (groups.length > 0) setUserOwnsGroup(true);
        });
    }, []);

    return {
        loading,
        groupSize,
        showErrors,
        groupState,
        setGroupSize,
        handleSubmit,
        userOwnsGroup,
        setGroupState,
        updateProperty,
        popupController,
        handleRemovePlayer,
        requiredProperties,
        blockDescriptionModal,
        openDeleteGroupDialog,
        setBlockDescriptionModal,
        setOpenDeleteGroupDialog,
    };
}
