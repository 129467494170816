export class GroupError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "GroupError";
    }
}

export class GroupIsFullError extends GroupError {
    constructor(message: string) {
        super(message);
        this.name = "GroupIsFull";
    }
}
