import React, { useEffect, useState } from "react";
import { InputLabel, Select, FormControl, MenuItem } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";

// use a Singleton counter so we can move through the questions

/*
This form can be used to update the profile age
*/

export const ProfileAge = ({
    state,
    handleEditProfile,
    pushCounter,
    isEditing = false,
    changeEditState = () => {},
}) => {
    const [disabledValue, setDisabledValue] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const hasState = () => {
        if (!state?.age) {
            return "";
        }

        return state?.age;
    };

    const [textValue, setTextValue] = useState(hasState);

    useEffect(() => {
        if (state && state.age) {
            const currentAge = hasState();
            setTextValue(currentAge);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state?.age]);

    useEffect(() => {
        if (textValue) {
            setDisabledValue(false);
        } else {
            setDisabledValue(true);
        }
    }, [textValue]);
    //set the text value and enable the submit button
    const onTextChange = (e) => {
        setTextValue(e.target.value);

        if (isEditing) {
            changeEditState({ age: e.target.value });
        }
    };
    //on press of the submit button send the new value to the api
    const handleSaveValue = async () => {
        setIsLoading(true);
        await handleEditProfile({ age: String(textValue) });
        setIsLoading(false);
    };

    return (
        <View className="form-container">
            {!isEditing && <Text as="h2">How old are you?</Text>}

            <View className={isEditing ? "" : "input-container"}>
                <FormControl fullWidth className={isEditing ? "" : "age-picker"}>
                    <InputLabel id="age-picker">Age Range</InputLabel>
                    <Select
                        labelId="age-picker"
                        id="age-picker"
                        value={textValue}
                        inputProps={{ "data-testid": "age-picker-input" }}
                        label="Age"
                        onChange={onTextChange}>
                        <MenuItem value="18-24">18-24</MenuItem>
                        <MenuItem value="25-34">25-34</MenuItem>
                        <MenuItem value="35-44">35-44</MenuItem>
                        <MenuItem value="45+">45+</MenuItem>
                    </Select>
                </FormControl>
                {!isEditing && (
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={handleSaveValue}
                    />
                )}
            </View>
        </View>
    );
};
export default ProfileAge;
