import "./styles/GroupsListItem.scss";

import React from "react";

import { useNavigate } from "react-router-dom";
import { useBreakpoint } from "hooks/useBreakpoint";
import useGroupListItem, { GroupsGameType, GroupsListItemInternalProps } from "@hooks/useGroupListItem";

import { Box, Typography } from "@mui/material";
import { AccessTimeTwoTone, Add, ChevronRight, LanguageTwoTone, PeopleAltTwoTone } from "@mui/icons-material";

import VTTIcon from "components/Profile/VttIcon";
import PlayerProIcon from "components/Profile/components/PlayerProIcon";

import { CMSContent } from "models/cms/fileds";
import { ProfileModel } from "models/ProfileModels";
import { GroupMemberTypeInList, GroupTypeInList, photoObjectType } from "models/group/GroupModel";

import { UserAvatar } from "@components/UserAvatar";
import { ApplyButton } from "@components/ApplyButton";
import { GoldenBorder } from "@components/StyledBorders";
import { ShouldRender } from "@components/Profile/components/ShouldRender/ShouldRender";

import { ConditionalProp } from "@utils/tools";
import { getPhotoID, getWeekdayAbbreviation } from "@utils/functions";
import { AlertPopUpProps } from "components/AlertPopUp/AlertPopUpSmall";
import { useGlobalContext } from "context/AppContext";
import { GlobalContextType } from "hooks/useGroupsList";

export type SectionType = "JOINED" | "HOSTED" | "DISCOVER";

export type GroupsListItemProps = {
    group: GroupTypeInList;
    section: SectionType;
    showPopup: (props: Omit<AlertPopUpProps, "open">, ttl?: number) => void;
    filterFields: CMSContent;
    personalProfile: ProfileModel | undefined;
    handleJoinGroup: (group: GroupTypeInList) => Promise<void>;
};

export const GroupsListItem = (props: GroupsListItemProps) => {
    const { isMobile } = useBreakpoint();

    const updatedProps = useGroupListItem(props);

    if (!isMobile) return <DefaultDesktop {...updatedProps} />;

    switch (updatedProps.section) {
        case "DISCOVER":
            return <DiscoverMobile {...updatedProps} />;
        case "HOSTED":
            return <HostedMobile {...updatedProps} />;
        case "JOINED":
            return <JoinedMobile {...updatedProps} />;
        default:
            return <Typography>Unknown section</Typography>;
    }
};

type MembersComponentType = {
    group: GroupTypeInList;
    members: Array<GroupMemberTypeInList>;
    openSeatImage?: string;
    personalProfile: ProfileModel | undefined;
};
function Members({ members, group, personalProfile, openSeatImage }: MembersComponentType) {
    return (
        <Box className="group-item-members">
            {members
                .filter((member) => member.id !== group.creator_id)
                .map((member, index) => {
                    const photoObject: photoObjectType = {
                        name: [member.name],
                        profileURL: [member.profileURL || ""],
                        avatar_id: member.avatar_id,
                        gender: member.gender,
                    };

                    let variant: "OPEN SEAT" | "PLAYER" | "HOST" = "PLAYER";

                    switch (member.member_type) {
                        case "OPEN SEAT":
                            variant = "OPEN SEAT";
                            break;
                        case "host":
                            variant = "HOST";
                            break;
                    }

                    if (member.id === personalProfile?.id) variant = "HOST";

                    return (
                        <UserAvatar
                            key={`user-avatar-${member.id || group.id + index}`}
                            avatar={getPhotoID(photoObject)}
                            disabled={member.id === group.creator_id}
                            variant={variant}
                            matchScore={0}
                            disableMatchScore
                            {...ConditionalProp(Boolean(openSeatImage), { openSeatImage })}
                        />
                    );
                })}
        </Box>
    );
}

type ActionsComponentType = {
    game: GroupsGameType | undefined;
    group: GroupTypeInList;
    joined: boolean;
    userIsHost: boolean;
    groupIsFull: boolean;
    handleJoinGroup: (group: GroupTypeInList) => Promise<void>;
};
function Actions({ handleJoinGroup, joined, userIsHost, group, groupIsFull, game }: ActionsComponentType) {
    const navigate = useNavigate();
    return (
        <Box className="group-item-actions">
            {/* Case 1: Not joined and user is not host */}
            <ShouldRender returnNull condition={!joined && !userIsHost}>
                <ApplyButton
                    disabled={groupIsFull}
                    onClick={async (event) => {
                        event.stopPropagation();
                        await handleJoinGroup(group);
                    }}>
                    <React.Fragment>
                        <Add fontSize="small" />
                        join group
                    </React.Fragment>
                </ApplyButton>
            </ShouldRender>
            {/* Case 2: Joined and user is not host */}
            <ShouldRender returnNull condition={joined && !userIsHost}>
                <ApplyButton onClick={() => navigate(`/group/${group.id}`)}>
                    <React.Fragment>
                        go to group
                        <ChevronRight fontSize="small" />
                    </React.Fragment>
                </ApplyButton>
            </ShouldRender>
            {/* Case 3: User is host */}
            <ShouldRender returnNull condition={userIsHost}>
                <ApplyButton onClick={() => navigate(`/group/${group.id}`)}>
                    <React.Fragment>
                        View Your group
                        <ChevronRight fontSize="small" />
                    </React.Fragment>
                </ApplyButton>
            </ShouldRender>
        </Box>
    );
}

const DefaultDesktop = ({
    game,
    group,
    joined,
    members,
    language,
    platform,
    userIsHost,
    endSchedule,
    groupIsFull,
    startSchedule,
    handleJoinGroup,
    matchScoreColor,
    personalProfile,
    hostPhotoObject,
}: GroupsListItemInternalProps) => {
    /* States and Hooks */
    const navigate = useNavigate();
    const { isTablet: _isTablet, isLaptop } = useBreakpoint();
    const { isLoggedIn }: GlobalContextType = useGlobalContext();

    const isTablet: boolean = _isTablet || isLaptop;

    const schedule: string = `${getWeekdayAbbreviation(
        startSchedule?.weekday,
    )} ${startSchedule?.getTime()} - ${endSchedule?.getTime()}`;

    return (
        <GoldenBorder enabled={group.game_hosting_type === "Paid"} borderRadius={8} borderSize={1} enableShadow>
            <Box
                onClick={(event) => {
                    event.stopPropagation();
                    navigate(`/group/${group.id}`);
                }}
                className="group-item"
                sx={
                    game?.groupsImage
                        ? {
                              backgroundImage: `linear-gradient(256.27deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%), url(${game?.groupsImage})`,
                          }
                        : undefined
                }>
                {/* Group information */}
                <ShouldRender returnNull condition={!userIsHost && Boolean(isLoggedIn)}>
                    <Box className="group-item-information">
                        <Typography
                            className="group-item-match-score-chip"
                            sx={{ background: matchScoreColor.bgColor }}>
                            {group.matchScore?.toFixed(0)}% Match
                        </Typography>
                        <GoldenBorder enabled={group.game_hosting_type === "Paid"} borderRadius={50} borderSize={0.2}>
                            <Typography className="group-item-cost">
                                {group.game_hosting_type === "Free"
                                    ? "Free Campaign"
                                    : `USD $${parseFloat(group.cost as unknown as string).toFixed(2)}`}
                            </Typography>
                        </GoldenBorder>
                    </Box>
                </ShouldRender>
                {/* Host Information */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "space-between",
                    }}>
                    <Typography className="group-item-game-name">{game?.name}</Typography>
                    <ShouldRender returnNull condition={!Boolean(isLoggedIn)}>
                        <GoldenBorder enabled={group.game_hosting_type === "Paid"} borderRadius={50} borderSize={0.2}>
                            <Typography className="group-item-cost">
                                {group.game_hosting_type === "Free" ? "Free Campaign" : `USD $${group.cost}`}
                            </Typography>
                        </GoldenBorder>
                    </ShouldRender>
                </Box>
                <Box className="group-item-host-container">
                    <UserAvatar
                        paid={group.game_hosting_type === "Paid"}
                        avatar={getPhotoID(hostPhotoObject)}
                        variant={"HOST"}
                        matchScore={group?.matchScore || 0}
                        disableMatchScore={true}
                    />
                    <Box className="group-item-user-info">
                        <Box className="group-item-host-identifier-container">
                            <Typography className="host-identifier">HOST</Typography>
                            <ShouldRender returnNull condition={group.game_hosting_type === "Paid"}>
                                <PlayerProIcon />
                            </ShouldRender>
                        </Box>
                        <Typography variant="h3">{group.Creator}</Typography>
                    </Box>
                </Box>
                {/* Group members */}
                <Box className="group-item-members-container">
                    <Members members={members} group={group} personalProfile={personalProfile} />
                    <ShouldRender returnNull condition={isTablet}>
                        <Actions
                            game={game}
                            group={group}
                            joined={joined}
                            userIsHost={userIsHost}
                            groupIsFull={groupIsFull}
                            handleJoinGroup={handleJoinGroup}
                        />
                    </ShouldRender>
                </Box>
                {/* Group actions / details */}
                <Box className="group-item-actions-container">
                    <Box className="group-item-details">
                        <Box className="group-item-detail">
                            <AccessTimeTwoTone fontSize="small" />
                            <Typography className="group-item-details-schedule">{schedule}</Typography>
                        </Box>
                        <Box className="group-item-detail">
                            <VTTIcon
                                vttName={platform?.name}
                                vttImage={platform?.img || `${platform?.name}.svg`}
                                tooltipTitle={platform?.name}
                            />
                            <Typography className="group-item-details-platform">{platform?.name}</Typography>
                        </Box>
                        <ShouldRender returnNull condition={!userIsHost}>
                            <Box className="group-item-detail">
                                <LanguageTwoTone fontSize="small" />
                                <Typography className="group-item-details-language">{language?.abbr}</Typography>
                            </Box>
                        </ShouldRender>
                    </Box>
                    {/* Group actions */}
                    <ShouldRender returnNull condition={!isTablet}>
                        {/* In case of tablet, this will change positions */}
                        <Actions
                            game={game}
                            groupIsFull={groupIsFull}
                            group={group}
                            joined={joined}
                            handleJoinGroup={handleJoinGroup}
                            userIsHost={userIsHost}
                        />
                    </ShouldRender>
                </Box>
            </Box>
        </GoldenBorder>
    );
};

// Mobile exclusive components
// --
// Due to large changes in the mobile design of components,
// it is easier to separate them into different components,
// rather than having them in one large component controlling
// its rendering behaviour with conditions.

const DiscoverMobile = ({
    game,
    group,
    joined,
    platform,
    language,
    userIsHost,
    endSchedule,
    groupIsFull,
    startSchedule,
    hostPhotoObject,
    handleJoinGroup,
}: GroupsListItemInternalProps) => {
    const navigate = useNavigate();
    const { isLoggedIn }: GlobalContextType = useGlobalContext();

    return (
        <GoldenBorder enableShadow borderRadius={8} borderSize={1} enabled={group.game_hosting_type === "Paid"}>
            <Box
                onClick={() => navigate(`/group/${group.id}`)}
                className="group-item-discover-mobile"
                sx={
                    game?.groupsImage
                        ? {
                              backgroundImage: `linear-gradient(270deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%), url(${game?.groupsImage})`,
                          }
                        : undefined
                }>
                {/* Informations */}
                <Box className="group-item-discover-mobile-information">
                    <Box className="group-item-discover-mobile-information-schedule">
                        <AccessTimeTwoTone sx={{ color: "#CF2947" }} fontSize="small" />
                        <Typography variant="h6">{getWeekdayAbbreviation(startSchedule?.weekday)}</Typography>
                        <Typography>
                            {startSchedule?.getTime()} - {endSchedule?.getTime()}
                        </Typography>
                    </Box>
                    <GoldenBorder borderRadius={50} borderSize={0.2} enabled={group.game_hosting_type === "Paid"}>
                        <Typography className="group-item-discover-mobile-information-cost">
                            {group.game_hosting_type === "Free" ? "Free" : `USD $${group.cost}.00`}
                        </Typography>
                    </GoldenBorder>
                </Box>
                {/* Game name */}
                <Typography variant="h3" className="group-item-discover-mobile-game">
                    {game?.name}
                </Typography>
                {/* Host information */}
                <Box className="group-item-discover-mobile-host-information">
                    <UserAvatar
                        avatar={getPhotoID(hostPhotoObject)}
                        variant={!Boolean(isLoggedIn) ? "HOST" : "PLAYER"}
                        matchScore={userIsHost ? 100 : group?.matchScore || 0}
                        shrinkMatchScore
                    />
                    <Box className="host-identifier-container">
                        <Typography className="host-identifier">HOST</Typography>
                        <ShouldRender returnNull condition={group.game_hosting_type === "Paid"}>
                            <PlayerProIcon />
                        </ShouldRender>
                        <Typography className="host-identifier-name">{group.Creator}</Typography>
                    </Box>
                </Box>
                {/* Details */}
                <Box className="group-item-discover-mobile-details">
                    {/* Number of players */}
                    <Box className="group-item-discover-mobile-detail">
                        <PeopleAltTwoTone fontSize="small" sx={{ color: "#CF2947" }} />
                        <Typography className="group-item-players-count">
                            {group.members?.length || 0}/{group.group_size}
                        </Typography>
                    </Box>
                    {/* Platform */}
                    <Box className="group-item-discover-mobile-detail">
                        <VTTIcon
                            tooltipTitle={platform?.name}
                            vttName={platform?.name}
                            vttImage={platform?.img || `${platform?.name}.svg`}
                        />
                        <Typography className="group-item-discover-mobile-platform">{platform?.name}</Typography>
                    </Box>
                    {/* Language */}
                    <Box className="group-item-discover-mobile-detail">
                        <LanguageTwoTone />
                        <Typography className="group-item-discover-mobile-language">
                            {language?.languageName}
                        </Typography>
                    </Box>
                </Box>
                {/* Actions */}
                <ApplyButton
                    icon={<Add fontSize="small" />}
                    onClick={async (event) => {
                        event.stopPropagation();
                        await handleJoinGroup(group);
                    }}
                    disabled={joined || userIsHost || groupIsFull}>
                    Join
                </ApplyButton>
            </Box>
        </GoldenBorder>
    );
};

const HostedMobile = ({
    game,
    group,
    members,
    startSchedule,
    hostPhotoObject,
    personalProfile,
}: GroupsListItemInternalProps) => {
    const navigate = useNavigate();
    return (
        <Box
            onClick={() => navigate(`/group/${group.id}`)}
            className="group-item-hosted-mobile"
            sx={
                game?.groupsImage
                    ? {
                          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%), url(${game?.groupsImage})`,
                      }
                    : undefined
            }>
            {/* Game name */}
            <Typography className="group-item-hosted-mobile-game">{game?.name}</Typography>
            {/* Host and Members */}
            <Box className="group-item-hosted-mobile-members">
                <UserAvatar variant="HOST" matchScore={0} disableMatchScore avatar={getPhotoID(hostPhotoObject)} />
                <Members
                    group={group}
                    members={members}
                    personalProfile={personalProfile}
                    openSeatImage="/avatars/open_seat_avatar.svg"
                />
            </Box>
            {/* Details */}
            <Box className="group-item-hosted-mobile-details">
                {/* Number of players */}
                <Box className="group-item-hosted-mobile-detail">
                    <PeopleAltTwoTone fontSize="small" sx={{ color: "#CF2947" }} />
                    <Typography className="group-item-players-count">
                        {group.members?.length || 0}/{group.group_size}
                    </Typography>
                </Box>
                <Box className="group-item-hosted-mobile-detail">
                    <AccessTimeTwoTone sx={{ color: "#CF2947" }} fontSize="small" />
                    <Box className="group-item-hosted-mobile-schedule">
                        <Typography variant="h6">{getWeekdayAbbreviation(startSchedule?.weekday)} </Typography>
                        <Typography>{startSchedule?.getTime()}</Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const JoinedMobile = ({ game, language, startSchedule, endSchedule, group }: GroupsListItemInternalProps) => {
    const navigate = useNavigate();

    return (
        <GoldenBorder borderRadius={8} borderSize={1} enableShadow enabled={group.game_hosting_type === "Paid"}>
            <Box
                onClick={() => navigate(`/group/${group.id}`)}
                className="group-item-joined-mobile"
                sx={
                    game?.groupsImage
                        ? {
                              backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, #000000 100%), url(${game?.groupsImage})`,
                          }
                        : undefined
                }>
                <Typography className="group-item-joined-mobile-game">{game?.name}</Typography>
                <Box className="group-item-joined-mobile-details">
                    <Box className="group-item-joined-mobile-details-container">
                        {/* Language */}
                        <Box className="group-item-joined-mobile-detail">
                            <LanguageTwoTone fontSize="small" />
                            <Typography className="group-item-discover-mobile-language">
                                {language?.languageName}
                            </Typography>
                        </Box>
                        {/* Players count */}
                        <Box className="group-item-joined-mobile-detail">
                            <PeopleAltTwoTone fontSize="small" sx={{ color: "#CF2947" }} />
                            <Typography className="group-item-players-count">
                                {group.members?.length || 0}/{group.group_size}
                            </Typography>
                        </Box>
                    </Box>
                    {/* Schedule */}
                    <Box className="group-item-joined-mobile-detail">
                        <AccessTimeTwoTone sx={{ color: "#CF2947" }} fontSize="small" />
                        <Box className="group-item-joined-mobile-schedule">
                            <Typography variant="h6">{getWeekdayAbbreviation(startSchedule?.weekday)}</Typography>
                            <Typography>{`${startSchedule?.getTime()} - ${endSchedule?.getTime()}`}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </GoldenBorder>
    );
};
