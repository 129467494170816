import { useEffect, useRef, useState } from "react";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import { debounce } from "lodash";

import { PublicProfileModel } from "models/ProfileModels";

type ScrollBlockerProps = {
    safariAgent: boolean | undefined;
};

export const useScrollBlockerV2 = ({ safariAgent }: ScrollBlockerProps) => {
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const keyboardRef = useRef(false);
    const firstRef = useRef<null | HTMLDivElement>(null);

    const hamburguerMenu = document.querySelector("#hamburger-menu");
    const isIphone = safariAgent && navigator.userAgent.includes("iPhone");

    const disableScroll = (e: any) => {
        if (keyboardRef.current) {
            e.preventDefault();
        }
    };

    const onFocusInput = () => {
        if (!isIphone) return;
        // Disable scrolling on mount
        document.body.style.overflow = "hidden";
        document.addEventListener("touchmove", disableScroll, { passive: false });
        window?.visualViewport?.addEventListener("touchmove", disableScroll, { passive: false });
        setKeyboardOpen(true);
        keyboardRef.current = true;
    };

    const onBlurInput = () => {
        if (!isIphone) return;
        setKeyboardOpen(false);
        keyboardRef.current = false;
        //Show hamburger menu
        if (hamburguerMenu) hamburguerMenu.className = "show-hamburger";
        document.body.style.overflow = "auto";
        document.removeEventListener("touchmove", disableScroll);
        setTimeout(() => {
            if (firstRef?.current) {
                firstRef.current?.scrollIntoView({ block: "end", inline: "nearest" });
            }
        }, 100);
    };

    useEffect(
        () => () => {
            clearAllBodyScrollLocks();
            document.body.style.overflow = "auto";
            document.removeEventListener("touchmove", disableScroll);
        },
        [],
    );

    return { onFocusInput, onBlurInput, keyboardOpen, setKeyboardOpen, clearAllBodyScrollLocks, firstRef };
};

/**
 * Generates a scroll blocker hook that prevents scrolling when the keyboard is open on Safari.
 *
 * @param {ScrollBlockerProps} component - The component that needs scrolling blocked.
 * @param {boolean} safariAgent - A flag indicating if the browser is Safari.
 * @return {ScrollBlockerResult} An object containing the onFocusInput, onBlurInput, keyboardOpen, setKeyboardOpen, and clearAllBodyScrollLocks functions.
 */
export const useScrollBlocker = ({ safariAgent }: ScrollBlockerProps) => {
    const [keyboardOpen, setKeyboardOpen] = useState(false);
    const bodyElement = document.querySelector("body");

    const hamburguerMenu = document.querySelector("#hamburger-menu");
    const isIphone = safariAgent && navigator.userAgent.includes("iPhone");

    const onFocusInput = (event: any) => {
        if (!isIphone) return;
        const nowHeight = window?.visualViewport?.height || window.innerHeight;

        setKeyboardOpen(true);

        function filterName(el: any) {
            const isElement =
                el.className?.includes("p-0 d-flex flex-column align-items-end col") || el.className.includes("row");

            console.log("Enable", isElement);
            return isElement;
        }

        setTimeout(() => {
            disableBodyScroll(bodyElement!, {
                allowTouchMove: filterName,
            });

            const afterHeight = window?.visualViewport?.height || window.innerHeight;

            const newHeight = nowHeight - afterHeight;
            console.log("New height", newHeight);

            document.documentElement.style.setProperty("--resize-messages", `${newHeight}px`);
        }, 300);
    };

    const onBlurInput = () => {
        if (!isIphone) return;

        const nowHeight = window?.visualViewport?.height || window.innerHeight;

        // Change css variable
        document.documentElement.style.setProperty("--resize-messages", "100%");

        setKeyboardOpen(false);

        //Show hamburger menu
        if (hamburguerMenu) hamburguerMenu.className = "show-hamburger";

        enableBodyScroll(bodyElement!);

        setTimeout(() => {
            const afterHeight = window?.visualViewport?.height || window.innerHeight;
            const newHeight = nowHeight - afterHeight;
            console.log("New height", newHeight);
            document.documentElement.style.setProperty("--resize-messages", `${newHeight}px`);
        }, 300);
    };

    useEffect(() => () => clearAllBodyScrollLocks(), []);

    return { onFocusInput, onBlurInput, keyboardOpen, setKeyboardOpen, clearAllBodyScrollLocks };
};

type MobileResize = {
    safariAgent: boolean | undefined;
};
/**
 * This function is only meant for safari users. It sets the height of the app manually when the window is resized by the keyboard.
 *
 * @param {MobileResize} mobileResize - an object containing the safari agent
 * @return {void}
 **/
export const useMobileResize = ({ safariAgent }: MobileResize) => {
    const [fullHeight, setFullHeight] = useState(0);
    const [heightWithKeyboard, setHeightWithKeyboard] = useState(0);
    // This function is only meant for safari users. It sets the height of the app manually when the window is resized by the keyboard.
    useEffect(() => {
        if (window.visualViewport) {
            if (fullHeight === 0) {
                setFullHeight(window.visualViewport.height || window.innerHeight);
            }

            const resizeFunction = () => {
                if (safariAgent) {
                    const height = window?.visualViewport?.height || window.innerHeight;

                    // let currentH = heightWithKeyboard;
                    if (heightWithKeyboard === 0) {
                        setHeightWithKeyboard(height);
                        // currentH = height;
                    }
                }
            };

            window.visualViewport.addEventListener("resize", resizeFunction);

            return () => {
                window.visualViewport?.removeEventListener("resize", resizeFunction);
                if (heightWithKeyboard !== 0) {
                    setStyles(fullHeight, true);

                    const bodyElement = document.querySelector("body");
                    if (bodyElement) bodyElement.style.top = "0px";

                    const hamburgerElement: HTMLElement | null = document.querySelector("div.show-hamburger");
                    if (hamburgerElement) hamburgerElement.style.top = fullHeight - 60 + "px";
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [safariAgent, heightWithKeyboard]);

    // Sets the size of message inbox container for ios safari to scale with bottom bar
    // This function is only meant for safari users. It sets the attribute "top" of the body and hamburger to follow.
    useEffect(() => {
        const handleScroll = () => {
            const bodyElement = document.querySelector("body");
            if (bodyElement) bodyElement.style.top = window.scrollY + "px";

            const currentHeight = window.scrollY === 0 ? fullHeight : heightWithKeyboard;
            if (currentHeight !== 0) {
                const hamburgerElement: HTMLElement | null = document.querySelector("div.show-hamburger");
                if (hamburgerElement) hamburgerElement.style.top = window.scrollY + currentHeight - 60 + "px";
            }
        };

        const debouncedHandle = debounce(handleScroll, 10);

        window.addEventListener("scroll", debouncedHandle);

        return () => {
            window.removeEventListener("scroll", debouncedHandle);
        };
    }, [fullHeight, heightWithKeyboard]);

    const setStyles = (height: number, isChangingPage: boolean = false) => {
        const htmlElement = document.querySelector("html");
        const bodyElement = document.querySelector("body");

        if (htmlElement) {
            htmlElement.style.height = `${height}px`;
            htmlElement.style.position = isChangingPage ? "static" : "fixed";
            htmlElement.style.touchAction = "none";
        }

        if (bodyElement) {
            bodyElement.style.height = `${height}px`;
            bodyElement.style.position = isChangingPage ? "static" : "fixed";
            bodyElement.style.touchAction = "none";
        }

        if (!isChangingPage) {
            const msgsContainer: HTMLElement | null = document.querySelector(".message-container");
            if (msgsContainer) msgsContainer.style.height = height === fullHeight ? "-webkit-fill-available" : "auto";
        }
    };
};

type MessagesProfilesProps = {
    publicID: string;
    privateID: string;
    publicProfiles: any;
    id: string | undefined;
    returnInbox: boolean;
    navigate: (path: string) => void;
};

export const useMessagesProfiles = ({
    publicID,
    privateID,
    publicProfiles,
    id,
    returnInbox,
    navigate,
}: MessagesProfilesProps) => {
    const [recieverMessageDisabled, setRecieverMessageDisabled] = useState(false);
    useEffect(() => {
        if (publicProfiles) {
            const publicProfile = publicProfiles?.filter((profile: PublicProfileModel) => profile?.id === publicID);
            setRecieverMessageDisabled(publicProfile[0]?.messageDisabled?.[0] || false);
        }
    }, [publicProfiles, publicID]);

    useEffect(() => {
        if (publicID !== "" && !id && !returnInbox) {
            navigate(`/messages/${privateID + publicID}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        recieverMessageDisabled,
    };
};
