import React from "react";
import View from "@mui/material/Box"; //rename for portability to React Native
import "./CompleteProfile.css";
import { BackNextBtn } from "components/BackNextBtn/BackNextBtn";
import { CompleteProfileContainer } from "@components/CompleteProfileContainer/CompleteProfileContainer";
import ProfileIdentityTags from "components/ProfileInterview/ProfileIdentityTags";

export const IdentityTags = ({
    state,
    handleEditProfile,
    pushCounter,
    goBack,
    skipButton,
    counter,
    length,
    filterFields,
    loading,
    updateFiltersFieldsAsync,
}) => {
    return (
        <>
            <CompleteProfileContainer rootContainerClass="question-identity-tags">
                <View className="question-container">
                    <ProfileIdentityTags
                        state={state}
                        pushCounter={pushCounter}
                        handleEditProfile={handleEditProfile}
                        filterFields={filterFields}
                        updateFiltersFieldsAsync={updateFiltersFieldsAsync}
                        loading={loading}
                    />
                </View>
                <BackNextBtn goBack={goBack} counter={counter} length={length} nextBtn={skipButton} />
            </CompleteProfileContainer>
        </>
    );
};
