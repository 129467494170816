import "./UserAvatar.scss";

import React, { useState, useCallback, useEffect } from "react";

import { Box, Typography } from "@mui/material";
import { matchColor } from "utils/matchColor";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { GoldenBorder } from "@components/StyledBorders";
import { PersonAdd } from "@mui/icons-material";
import { getImageUrl } from "utils/functions";

type UserAvatarProps = {
    paid?: boolean;
    avatar: string;
    variant?: "HOST" | "PLAYER" | "OPEN SEAT";
    disabled?: boolean;
    matchScore: number;

    openSeatImage?: string;
    openSeatImageBorderColor?: string;

    shrinkMatchScore?: boolean;
    disableMatchScore?: boolean;
};

export default function UserAvatar({
    paid = false,
    avatar,
    variant,
    disabled,
    matchScore,
    openSeatImage,
    shrinkMatchScore = false,
    disableMatchScore = false,
    openSeatImageBorderColor = "#a61f38",
}: UserAvatarProps) {
    /* Variables */

    // The variants of the user avatar
    let className = "alternative-user-avatar";

    switch (variant) {
        case "HOST":
            className += " user-avatar-host-variant";
            break;
        case "PLAYER":
            className += " user-avatar-player-variant";
            break;
    }

    if (disabled) {
        className += " user-avatar-disabled";
    }

    // The match score is a number between 0 and 100
    const matchScoreColor = matchColor(matchScore || 100);

    /* States and Hooks */

    // Get the image source from the profile
    const [loadingImage, setLoadingImage] = useState(true);
    const [internalImage, setInternalImage] = useState(avatar);
    const [tempRemovedAvatar, setTempRemovedAvatar] = useState(false);

    /* Functions */

    type CheckImageResponse = {
        avatar: string;
        status: "ok" | "error";
    };
    const checkImage = async () =>
        new Promise<CheckImageResponse>((resolve, reject) => {
            const image = new Image();
            image.src = avatar;
            image.onload = () => resolve({ avatar, status: "ok" });
            image.onerror = () => reject({ avatar, status: "error" });
        })
            .then(({ avatar, status }) => {
                setInternalImage(avatar);
            })
            .catch(() => {
                setInternalImage("/avatars/deleted.png");
            })
            .finally(() => setLoadingImage(false));

    const RenderAvatarImage = useCallback(() => {
        switch (variant) {
            case "OPEN SEAT":
                if (openSeatImage)
                    return (
                        <Box
                            alt="user_profile_image"
                            src={openSeatImage}
                            draggable={false}
                            component={"img"}
                            className={className}
                            sx={{
                                color: "#2D2D2E",
                                border: "3px solid #2D2D2E",
                                background: "#9E9E9E",
                                borderColor: openSeatImageBorderColor,
                            }}
                        />
                    );

                return (
                    <PersonAdd
                        fontSize="small"
                        className={className}
                        sx={{
                            color: "#2D2D2E",
                            border: "3px solid #2D2D2E",
                            padding: "6px",
                            background: "#9E9E9E",
                            borderColor: "#a61f38",
                        }}
                    />
                );
            case "HOST":
                return (
                    <GoldenBorder enabled={paid} borderRadius={50} borderSize={5}>
                        <Box
                            alt="user_profile_image"
                            src={internalImage}
                            draggable={false}
                            component={"img"}
                            className={className}
                            sx={{
                                backgroundColor: "#a61f38",
                                borderColor: "#a61f38",
                            }}
                        />
                    </GoldenBorder>
                );
            default:
                return (
                    <GoldenBorder enabled={paid} borderRadius={50} borderSize={5}>
                        <Box
                            alt="user_profile_image"
                            src={internalImage}
                            draggable={false}
                            component={"img"}
                            className={className}
                            sx={{
                                backgroundColor: matchScoreColor.bgColor,
                                borderColor: matchScoreColor.borderColor,

                                ...(disabled && {
                                    backgroundColor: "#2D2D2E",
                                    borderColor: "#2D2D2E",
                                }),
                            }}
                        />
                    </GoldenBorder>
                );
        }
    }, [tempRemovedAvatar, internalImage]);

    useEffect(() => {
        if (variant === "HOST") return;

        // Setting the border color of the user avatar based on the match score
        document.documentElement.style.setProperty(
            "--alternative-user-avatar-border-color",
            matchScoreColor.borderColor,
        );
        // Setting the background color of the user avatar based on the match score
        document.documentElement.style.setProperty(
            "--alternative-user-avatar-background-color",
            matchScoreColor.bgColor,
        );
        // Setting the text color of the user avatar based on the match score
        document.documentElement.style.setProperty("--alternative-user-avatar-text-color", matchScoreColor.textColor);
    }, [matchScoreColor.bgColor, matchScoreColor.borderColor, matchScoreColor.textColor, variant]);

    useEffect(() => {
        if (avatar) checkImage();
    }, [avatar]);

    return (
        <Box className="user-avatar-container">
            <ShouldRender
                returnNull
                condition={variant !== "HOST" && !disabled && !disableMatchScore}
                loading={loadingImage}>
                <Typography
                    sx={{
                        background: `${matchScoreColor.bgColor} !important`,
                        border: `0.72px solid ${matchScoreColor.borderColor} !important`,
                        color: `${matchScoreColor.textColor} !important`,
                    }}
                    className="match-score-text">
                    {Math.round(matchScore)}
                    {shrinkMatchScore ? "%" : "% Match"}
                </Typography>
            </ShouldRender>
            <RenderAvatarImage />
        </Box>
    );
}
