import "./GroupActions.scss";

import React, { useEffect } from "react";
import { useAtom } from "jotai";
import { useBreakpoint } from "hooks/useBreakpoint";

import { Box, Chip, Tooltip, Typography } from "@mui/material";

import { GoldenBorder } from "@components/StyledBorders";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { PlatformType } from "models/cms/fileds";
import { platformIsATable } from "utils/functions";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { LocationPin, PaymentIcon, WarningShield } from "@components/icons";

import { ProfileModel } from "models/ProfileModels";
import { GroupType } from "models/group/GroupModel";
import { Add, LogoutTwoTone } from "@mui/icons-material";
import StartPlayingIcon from "components/Profile/components/StartPlayingIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGlobalContext } from "context/AppContext";
import { inviteAcceptedAtom, loadingInvitationAtom } from "@atoms/groupsAtoms";
import { storage } from "@services/sessionStorage";
import { signOut } from "@utils/functions";

type GroupActionsProps = {
    group: Partial<GroupType>;
    joined: boolean;
    hostView: boolean;
    platform: Partial<PlatformType>;
    hostProfile: ProfileModel;
    handleLogin: () => void;
    handleJoinGroup: (invitation: boolean) => void | Promise<void>;
    handleMessageHost: () => void | Promise<void>;
    handleAcceptInvite: () => void | Promise<void>;
    handleDeclineInvite: () => Promise<void> | void;
};

export default function GroupActions({
    group,
    joined,
    platform,
    hostView,
    hostProfile,
    handleJoinGroup,
    handleMessageHost,
    handleAcceptInvite,
    handleDeclineInvite,
}: GroupActionsProps) {
    /* States and Hooks */
    const navigate = useNavigate();

    const { isMobile, isTablet } = useBreakpoint();
    const { isLoggedInVerified, isLoggedIn, personalProfile } = useGlobalContext();

    const [searchParams, setSearchParams] = useSearchParams();
    const [inviteLoading, setInviteLoading] = useAtom(loadingInvitationAtom);
    const [accepted, setAccepted] = useAtom(inviteAcceptedAtom);

    /* Functions */

    function getLogo(img: string): string | undefined {
        return `/vtts/${img}`;
    }

    async function onCreate() {
        // Sign up exclusive flow
        if (invitationFlow === "signup") {
            // In case we don't know if user is logged in then jump
            if (!isLoggedInVerified) return;
            // Store the current path to deeplink
            storage.sessionStorage.surveyRedirect.update(`/group/${group.id}?invitation=accept`);
            // In case the user is logged in then sign out
            if (isLoggedIn) await signOut();
            // Redirect to sign up
            navigate("/signup");
        }

        if (!isLoggedInVerified || (invitationFlow !== "accept" && !accepted)) return;

        try {
            await handleAcceptInvite();
        } catch (error) {
            console.log(error);
        } finally {
            setAccepted(false);
        }
    }

    /* Variables */

    const variant = hostView ? "HOST" : "PLAYER";
    const groupIsFull: boolean = (group?.members?.length || 0) >= (group.group_size || 0);

    const invitationFlow = searchParams.get("invitation") as "accept" | "details" | "signup" | undefined;

    /* Lifecycle */
    useEffect(() => {
        onCreate();
    }, [isLoggedInVerified, isLoggedIn, invitationFlow, personalProfile?.id]);

    return (
        <React.Fragment>
            <GoldenBorder borderSize={0.2} borderRadius={8} enabled={isTablet && group.game_hosting_type === "Paid"}>
                <Box component="section" className="group-actions-container profile-component">
                    <Box className="group-actions-campaign-and-safety-tools">
                        <ShouldRender returnNull condition={!isMobile}>
                            <GoldenBorder
                                enabled={group.game_hosting_type === "Paid"}
                                borderSize={0.2}
                                borderRadius={8}>
                                <Box className="group-actions-item">
                                    <ShouldRender returnNull condition={!isMobile}>
                                        <PaymentIcon />
                                    </ShouldRender>
                                    <Box className="group-actions-item-info-container">
                                        <Typography className="group-actions-item-title">Campaign Type</Typography>
                                        <Typography className="group-actions-item-information">
                                            {group.game_hosting_type === "Paid"
                                                ? `Paid (${group.cost}.00 USD/session)`
                                                : "Free Campaign"}
                                        </Typography>
                                    </Box>
                                </Box>
                            </GoldenBorder>
                        </ShouldRender>
                        <ShouldRender returnNull condition={isMobile}>
                            <GoldenBorder
                                enabled={group.game_hosting_type === "Paid"}
                                borderSize={0.2}
                                borderRadius={8}>
                                <Box className="group-actions-item">
                                    <Box className="group-actions-item-info-container">
                                        <Typography className="group-actions-item-title">Platform</Typography>
                                        <Tooltip title={platform.name}>
                                            <Chip
                                                className="group-hero-platform"
                                                variant="outlined"
                                                icon={
                                                    <Box
                                                        component={"img"}
                                                        className="group-platform-logo"
                                                        alt="platform logo"
                                                        src={getLogo(platform.img || `${platform.name}.svg`)}
                                                    />
                                                }
                                                label={platform.name}
                                                style={{ border: "1px solid #4a4a4b" }}
                                            />
                                        </Tooltip>
                                    </Box>
                                </Box>
                            </GoldenBorder>
                        </ShouldRender>
                        <GoldenBorder
                            borderSize={0.2}
                            borderRadius={8}
                            enabled={group.game_hosting_type === "Paid" && isMobile}>
                            <Box className="group-actions-item">
                                <ShouldRender returnNull condition={!isMobile}>
                                    <WarningShield />
                                </ShouldRender>
                                <Box className="group-actions-item-info-container">
                                    <Typography className="group-actions-item-title">Safety tools</Typography>
                                    <Typography className="group-actions-item-information">
                                        {hostProfile.safetyTools?.[0]}
                                    </Typography>
                                </Box>
                            </Box>
                        </GoldenBorder>
                    </Box>
                    <ShouldRender returnNull condition={platformIsATable(platform) && !isMobile}>
                        <GoldenBorder
                            enabled={isMobile && group.game_hosting_type === "Paid"}
                            borderSize={0.2}
                            borderRadius={8}>
                            <Box className="group-actions-item">
                                <ShouldRender returnNull condition={!isMobile}>
                                    <LocationPin />
                                </ShouldRender>
                                <Box className="group-actions-item-info-container">
                                    <Typography className="group-actions-item-title">Table location</Typography>
                                    <Typography className="group-actions-item-information">
                                        {[group.city, group.state, group.country].filter(Boolean).join(", ")}
                                    </Typography>
                                </Box>
                            </Box>
                        </GoldenBorder>
                    </ShouldRender>
                    <GoldenBorder
                        borderSize={0.2}
                        borderRadius={8}
                        enabled={isMobile && group.game_hosting_type === "Paid"}>
                        <Box className={`group-actions-buttons-container ${isMobile ? "profile-component" : ""}`}>
                            <ShouldRender returnNull condition={isMobile}>
                                <Box>
                                    <Typography className="group-actions-item-title">Campaign type</Typography>
                                    <Typography className="group-actions-item-information">
                                        {group.game_hosting_type === "Paid"
                                            ? `Paid (${group.cost}.00 USD/session)`
                                            : "Free Campaign"}
                                    </Typography>
                                </Box>
                            </ShouldRender>
                            {/* ACTIONS RENDERING */}
                            {/* CASE 1: The user is not in the group, is not the host and it´s not on invitation flow */}
                            <ShouldRender returnNull condition={!hostView && !joined && !invitationFlow}>
                                <Box className="group-actions-buttons-inner-container">
                                    <SecondaryApplyButton onClick={handleMessageHost}>
                                        Message Host
                                    </SecondaryApplyButton>
                                    <ApplyButton
                                        icon={<Add />}
                                        onClick={() => handleJoinGroup(false)}
                                        disabled={groupIsFull}>
                                        Join Group
                                    </ApplyButton>
                                </Box>
                            </ShouldRender>
                            {/* CASE 2: User is not host, not a group member and on invitation flow */}
                            <ShouldRender returnNull condition={!hostView && !joined && Boolean(invitationFlow)}>
                                <Box className="group-actions-buttons-inner-container">
                                    <ApplyButton
                                        icon={<Add />}
                                        loading={inviteLoading || !isLoggedInVerified}
                                        onClick={handleAcceptInvite}
                                        disabled={groupIsFull}>
                                        accept join invitation
                                    </ApplyButton>
                                    <SecondaryApplyButton
                                        disabled={inviteLoading}
                                        loading={!isLoggedInVerified}
                                        onClick={handleDeclineInvite}>
                                        decline invitation
                                    </SecondaryApplyButton>
                                </Box>
                            </ShouldRender>
                            {/* CASE 3: User is the host or a group member */}
                            <ShouldRender returnNull condition={Boolean(hostView) || Boolean(joined)}>
                                <Box className="group-actions-buttons-inner-container">
                                    <ShouldRender
                                        returnNull
                                        condition={
                                            Boolean(group.startplaying_link) && group.game_hosting_type === "Paid"
                                        }>
                                        <SecondaryApplyButton
                                            onClick={() => window.open(group.startplaying_link, "_blank")}>
                                            STARTPLAYING <StartPlayingIcon />
                                        </SecondaryApplyButton>
                                    </ShouldRender>
                                    <ShouldRender returnNull condition={Boolean(group.url_link)}>
                                        <ApplyButton
                                            onClick={() =>
                                                window.open(
                                                    // Considering the URL has not a protocol it will try to open in the same domain
                                                    group.url_link?.startsWith("http")
                                                        ? group.url_link
                                                        : "https://" + group.url_link,
                                                    "_blank",
                                                )
                                            }>
                                            External Group Chat <LogoutTwoTone />
                                        </ApplyButton>
                                    </ShouldRender>
                                </Box>
                            </ShouldRender>
                        </Box>
                    </GoldenBorder>
                    <ShouldRender returnNull condition={platformIsATable(platform) && isMobile}>
                        <GoldenBorder
                            enabled={isMobile && group.game_hosting_type === "Paid"}
                            borderSize={0.2}
                            borderRadius={8}>
                            <Box className="group-actions-item">
                                <ShouldRender returnNull condition={!isMobile}>
                                    <LocationPin />
                                </ShouldRender>
                                <Box className="group-actions-item-info-container">
                                    <Typography className="group-actions-item-title">Table location</Typography>
                                    <Typography className="group-actions-item-information">
                                        {[group.city, group.state, group.country].filter(Boolean).join(", ")}
                                    </Typography>
                                </Box>
                            </Box>
                        </GoldenBorder>
                    </ShouldRender>
                </Box>
            </GoldenBorder>
        </React.Fragment>
    );
}
