import "./GroupDialogs.scss";

import React, { useContext } from "react";

import { ArrowBack, Send } from "@mui/icons-material";
import { Box, CircularProgress, Dialog, DialogContent, Typography } from "@mui/material";

import { GroupType } from "models/group/GroupModel";
import { ProfileModel } from "models/ProfileModels";
import { useBreakpoint } from "hooks/useBreakpoint";
import { conditionalClassName, getGroupName } from "utils/functions";

import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { AlertPopUpSmall, usePopupController } from "@components/AlertPopUp";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { CMSContextContent } from "models/cms/fileds";
import { inviteToGroup } from "@services/groupsProvider";
import { GroupIsFullError } from "@errors/groups";
import { storage } from "@services/sessionStorage";

type InviteToGroupDialogProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    group: Partial<GroupType>;
    profile: ProfileModel;
};
export function InviteToGroupDialog({ open, profile, group, setOpen }: InviteToGroupDialogProps) {
    /* States and Hooks */
    const { isMobile } = useBreakpoint();
    const { popup: PopupController, showPopup } = usePopupController();
    const { filterFields, loading: loadingCMS }: CMSContextContent = useContext(AsyncFilterFieldsContext);

    /* Functions */

    async function onConfirm(): Promise<void> {
        const id = Array.isArray(profile.id) ? profile.id[0] : profile.id;
        return inviteToGroup(group.id as string, [id])
            .then(() => {
                storage.sessionStorage.invitedUsers.add(id);
                showPopup({
                    title: "Invitation sent",
                    severity: "success",
                    description: `An invitation to join your group has been sent to ${profile?.name?.[0]}.`,
                });
                setOpen(false);
            })
            .catch((err) => {
                if (err instanceof GroupIsFullError)
                    showPopup({
                        title: "Group full",
                        severity: "error",
                        description: `We can't send a group invitation to ${profile?.name?.[0]} because it appears your group is currently full.`,
                    });
                else
                    showPopup({
                        title: "Error",
                        severity: "error",
                        description: (err as Error)?.message || "Something went wrong. Please try again.",
                    });
            });
    }

    /* Variables */
    const className = conditionalClassName(["group-invite-dialog-container"], [isMobile, "group-dialog-mobile"]);
    return (
        <React.Fragment>
            <AlertPopUpSmall {...PopupController} />
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogContent className={className}>
                    {loadingCMS ? (
                        <CircularProgress color="primary" />
                    ) : (
                        <React.Fragment>
                            <Typography variant="h3">Invite player to group</Typography>
                            <Typography>
                                Hey adventurer! You are inviting {profile?.name?.[0]} to join your group.
                                <br />
                                <br />
                                They will receive an invitation to join {getGroupName(group, filterFields.games)}.
                            </Typography>
                            <Box className="group-dialog-actions" component="footer">
                                <SecondaryApplyButton
                                    icon={<ArrowBack fontSize="small" />}
                                    onClick={() => setOpen(false)}>
                                    Back
                                </SecondaryApplyButton>
                                <ApplyButton onClick={onConfirm} icon={<Send fontSize="small" />}>
                                    Invite user
                                </ApplyButton>
                            </Box>
                        </React.Fragment>
                    )}
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}
