import "./GroupDialogs.scss";

import React from "react";

import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import { CheckCircle, Close, Warning } from "@mui/icons-material";

import { joinGroup, leaveGroup } from "@services/groupsProvider";
import { AlertPopUpSmall } from "@components/AlertPopUp/AlertPopUpSmall";
import { usePopupController } from "@components/AlertPopUp";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";

type JoinGroupDialogProps = {
    open: boolean;
    onUndo: () => Promise<void> | void;
    onConfirm: () => Promise<void> | void;
};

export function JoinGroupWarningDialog({ open, onUndo, onConfirm }: JoinGroupDialogProps) {
    const { popup, showPopup } = usePopupController();

    async function join() {
        await onConfirm();
    }

    return (
        <>
            <AlertPopUpSmall {...popup} />
            <Dialog open={open}>
                <DialogContent className="group-dialog-container">
                    <Warning
                        className="group-icon"
                        sx={{ background: "transparent", color: "#A61F38", width: "35px", height: "35px" }}
                    />
                    <Box className="group-content">
                        <Typography variant="h3" className="group-alert-title">
                            Location too far away
                        </Typography>
                        <Typography className="group-alert-description">
                            The group you're attempting to join gathers to play at an address that appears to be far
                            from your current location.
                            <br /> <br />
                            Are you sure that you want to join this group?
                        </Typography>
                        <Box className="group-dialog-buttons">
                            <SecondaryApplyButton onClick={onUndo} icon={<Close fontSize="small" />}>
                                cancel
                            </SecondaryApplyButton>
                            <ApplyButton
                                icon={<CheckCircle fontSize="small" sx={{ color: "#ffffff" }} />}
                                onClick={join}>
                                Join anyways
                            </ApplyButton>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}
