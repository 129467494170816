import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import "../../pages/Home/UserList.css";

const ClearButton = styled(Button)(({ theme }) => ({
    border: "1px solid #BF1304",
    color: "#ffffff",
    width: "150px",
    "&:hover": {
        borderColor: "#BF1304",
    },
}));

export default ClearButton;
