import React, { createContext, useState, Fragment } from "react";
import MatchScoreLoader from "components/MatchScoreLoader/MatchScoreLoader";

export const MatchScoreContext = createContext();

export const MatchScoreProvider = ({ children }) => {
    const [loaderState, setLoaderState] = useState({ open: false, destinationUrl: "/" });

    const openLoader = (destinationUrl = "/") => {
        setLoaderState({
            ...loaderState,
            open: true,
            destinationUrl: destinationUrl,
        });
    };

    const closeLoader = () => {
        setLoaderState({
            ...loaderState,
            open: false,
        });
    };

    return <MatchScoreContext.Provider value={{ openLoader, closeLoader }}>{children}</MatchScoreContext.Provider>;
};
