import Button from "components/Button";
import React, {lazy, Suspense, useContext} from "react";
import {Box} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {ChangeEmailContext} from "context/ChangeEmailContext";
const LazyGenericDialogRoot = lazy(() => import("components/GenericDialog/GenericDialog.Root"));
const LazyGenericDialogTop = lazy(() => import("components/GenericDialog/GenericDialog.Top"));
const LazyGenericDialogContent = lazy(() => import("components/GenericDialog/GenericDialog.Content"));
const LazyGenericDialogBottom = lazy(() => import("components/GenericDialog/GenericDialog.Bottom"));

const ProfileChangeEmailDialog = ({ children, data, show = false }) => {
    const { onCancel, onClose, onAccept, title, onCancelText, onAcceptText, hideCloseBtn = false } = data;

    const {isLocked} = useContext(ChangeEmailContext)

    return show ? (
        <Suspense fallback={<Box sx={{ display: 'flex' }}>
            <CircularProgress size={20} />
        </Box>}>
            <LazyGenericDialogRoot name={"dialog_profile-change-email"} open={show} handleClose={null}>
                <LazyGenericDialogTop>
                    {title}
                    {!hideCloseBtn && (
                        <button onClick={onClose}>
                            <img src="/closeIcon.png" alt="Close dialog" />
                        </button>
                    )}
                </LazyGenericDialogTop>
                <LazyGenericDialogContent>{children}</LazyGenericDialogContent>
                <LazyGenericDialogBottom>
                    <div>
                        {onCancelText && (
                            <Button backgroundColor="#171717" labelColor={"#DE4A65"} onClick={onCancel}>
                                {onCancelText}
                            </Button>
                        )}
                        {onAcceptText && <Button onClick={onAccept} disabled={isLocked}>{onAcceptText}</Button>}
                    </div>
                </LazyGenericDialogBottom>
            </LazyGenericDialogRoot>
        </Suspense>
    ) : null;
};

export default ProfileChangeEmailDialog;
