import "./GroupInvitedUsers.scss";

import React, { useEffect, useMemo, useState } from "react";

import useDebounce from "@hooks/useDebounce";

import { profileProvider } from "services/profileProvider";
import { GroupType, photoObjectType } from "models/group/GroupModel";
import { conditionalClassName, getPhotoID } from "utils/functions";
import { InvitedUserType } from "@atoms/groupsAtoms";

import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { ArrowBack, Check, Send } from "@mui/icons-material";
import { ApplyButton, SecondaryApplyButton } from "@components/ApplyButton";
import { Autocomplete, Avatar, Box, TextField, Typography } from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { emailRegexp } from "utils/constants";
import useGroupInvite from "@hooks/useGroupInvite";

export type GroupInvitedUsersManagerProps = {
    group: Partial<GroupType>;
    variant?: "embedded" | "popup";
    groupSize: number;
    showErrors: boolean;
    onSubmit?: (users: Array<InvitedUserType>) => Promise<void>;
    onClose?: () => void;
};

export default function GroupInvitedUsersManager({
    group,
    variant = "embedded",
    groupSize,
    onSubmit,
    onClose,
}: GroupInvitedUsersManagerProps) {
    /* States and Hooks */

    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchPool, setSearchPool] = useState<Array<InvitedUserType>>([]);
    const [activePromise, setActivePromise] = useState<AbortController | null>(null);

    const { isMobile } = useBreakpoint();
    const { setInvitedUsers, invitedUsers } = useGroupInvite({ variant, group });

    /* Functions */
    type UpdateProps = {
        user: InvitedUserType;
        index: number;
    };
    function update({ user, index }: UpdateProps) {
        setSearch("");
        setSearchPool([]);
        setInvitedUsers((prev) => {
            prev[index] = user;
            return prev;
        });
    }

    async function handleSearch(value: string) {
        setLoading(true);

        // Cancel previous search request
        if (activePromise && search.includes("@")) activePromise.abort();

        // Controlling promise aborting
        const abortController = new AbortController();
        setActivePromise(abortController);

        return profileProvider.searchProfile(value, abortController.signal).then((response) => {
            setSearchPool(
                response.users.map((user) => {
                    const image: photoObjectType = {
                        name: [user.username || ""],
                        profileURL: [user.profile_picture || ""],
                        avatar_id: user.avatar_id,
                        gender: user.gender,
                    };

                    return {
                        id: user.id,
                        name: user.username,
                        image: getPhotoID(image),
                    };
                }),
            );
            setSearch("");
            setLoading(false);
        });
    }

    /* Lifecycle */
    useDebounce({
        delay: 500,
        callback: () => {
            if (!search) return;
            handleSearch(search);
        },
        tracker: [search],
    });

    /* Variables */

    const className: string = conditionalClassName(
        ["profile-component"],
        [variant === "embedded", "group-invited-users-container-embedded"],
        [variant === "popup", "group-invited-users-container-popup"],
    );

    const TEXTS: Record<NonNullable<GroupInvitedUsersManagerProps["variant"]>, Record<string, React.JSX.Element>> = {
        embedded: {
            title: <Typography variant="h3">Invite players (optional)</Typography>,
            description: (
                <Typography className="group-invited-users-description">
                    To invite players, simply type in their username (or an email to invite external friends) to invite
                    them to join your party.
                </Typography>
            ),
        },
        popup: {
            title: isMobile ? (
                <Typography variant="h3">Invite player</Typography>
            ) : (
                <Typography variant="h3">Invite players</Typography>
            ),
            description: isMobile ? (
                <Typography className="group-invited-users-description">
                    Invite a player to fill this open seat. Simply type in their username (or an email to invite
                    external friends) to invite them to join your group.
                </Typography>
            ) : (
                <Typography className="group-invited-users-description">
                    You currently have <span className="group-invited-users-description-size">{groupSize - 1}</span>{" "}
                    open seat(s) in your group. To invite players, simply type in their username (or an email to invite
                    external friends) to invite them to join your group.
                </Typography>
            ),
        },
    };

    const identifiers = new Set(invitedUsers.map((x) => x.name).filter(Boolean));

    return (
        <Box className={className}>
            {TEXTS[variant].title}
            {TEXTS[variant].description}
            <Box className="group-invited-users-container">
                {invitedUsers.map((user, index) => {
                    const inputIsCorrect: boolean =
                        // Name is fullfilled
                        (Boolean(user?.name) &&
                            // If it is an email
                            user?.name.includes("@") &&
                            // Check if the email is valid
                            emailRegexp.test(user?.name)) ||
                        // If it is not an email but a username pass
                        (!user?.name.includes("@") && Boolean(user?.name));
                    return (
                        <Autocomplete
                            key={"autocomplete" + index}
                            value={user}
                            onBlur={() => {
                                setSearch("");
                                setSearchPool([]);
                            }}
                            options={searchPool}
                            freeSolo
                            loading={loading}
                            filterOptions={(options) => options.filter((option) => !identifiers.has(option.name))}
                            getOptionLabel={(option) => (typeof option === "string" ? option : option.name)}
                            renderOption={(props, option) => {
                                const { name, image } = option;

                                return (
                                    <Box
                                        className="group-invited-users-option"
                                        onClick={() => {
                                            update({ user: option, index });
                                        }}>
                                        <Avatar src={image} className="group-invited-users-option-image" />
                                        <Typography className="group-invited-users-option-name">{name}</Typography>
                                    </Box>
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    key={"input" + index}
                                    placeholder="Type username or email here"
                                    data-lpignore="true"
                                    label={"Player username or email"}
                                    error={Boolean(user?.error)}
                                    onBlur={() =>
                                        update({ user: { name: user?.name, image: "", id: user?.id }, index })
                                    }
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        e.preventDefault();

                                        update({ user: { name: value, image: "", id: "" }, index });
                                        // In case it is an email, no search is needed
                                        if (!value.includes("@")) setSearch(value);
                                    }}
                                    helperText={user?.error}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: inputIsCorrect && (
                                            <Check
                                                sx={{
                                                    color: "#1E1E1E",
                                                    background: "#4CAF50",

                                                    borderRadius: "50%",

                                                    width: "20px",
                                                    height: "20px",
                                                }}
                                            />
                                        ),
                                    }}
                                />
                            )}
                        />
                    );
                })}
            </Box>
            <ShouldRender returnNull condition={variant === "popup"}>
                <Box className="group-invited-users-footer">
                    <SecondaryApplyButton icon={<ArrowBack />} onClick={onClose}>
                        Back
                    </SecondaryApplyButton>
                    <ApplyButton icon={<Send />} onClick={() => onSubmit?.(invitedUsers)}>
                        Invite players
                    </ApplyButton>
                </Box>
            </ShouldRender>
        </Box>
    );
}
