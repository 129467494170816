export const endpoints = {
    profile: "/profile?id=",
    profileWithoutId: "/profile",
    profileByUrl: "/profile/url?url=",
    publicProfileByUrl: "/public/profile?url=",
    personalProfile: "/profile/me",
    profileList: "/profile/list",
    profilePaginate: "/profile/paginate",
    profileEdit: "/profile/edit",
    profileCreate: "/profile/create",
    profileImageUpload: "/profile/image-upload",
    profileSearch: "/profile/search",
    twitterUpdate: "/profile/twitter_update",
    blockUser: "/profile/blocked_update",
    blockedList: "/profile/list/blocked",
    putFavorite: "/profile/favorites_update",
    favoritesList: "/profile/favorites_list",
    putMatchProjection: "/profile/store_match_projection",
    profileTimezone: "profile/timezone",
    generateBio: "/profile/bio/generate",
    message: "/message",
    messages: "/messages",
    messagesList: "/messages/unblocked",
    messagesUnread: "/messages/unread",
    blockedMessages: "/messages/blocked",
    mailchimp: "/mailchimp",
    badge: "/profile/badges",
    vtt: "/vtt",
    games: "/games",
    publicBadges: "public/badges",
    publicVtt: "public/vtt",
    publicGames: "public/games",
    publicIdentityTags: "public/identity-tags",
    publicLanguages: "public/languages",
    publicProfilePaginate: "public/paginate",
    publicExplore: "public/explore",
    cmsGamesUpdate: "/games/update",
    cmsVTTUpdate: "/vtt/update",
    locations: "/locations",
    identityTags: "/identity-tags",
    cmsBadges: "/cms/badges",
    languages: "/cms/languages",
    x: "/x",
    changeEmail: "/change-email",
    groups: "/groups",
    publicGroups: "/public/groups",
    removeGroupPlayer: "/groups/remove_player",
    leaveGroup: "/groups/leave",
    joinGroup: "/groups/join",
    inviteToGroup: "/mailchimp/group/invite",
    declineGroupInvitation: "/groups/decline_invitation",
    emailNotificationSettings: "/profile/notification/settings",
};
