import "../GroupDialogs/GroupDialogs.scss";

import React, { useState } from "react";
import { Box, Typography, Slider, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import { InfoTwoTone } from "@mui/icons-material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { GamePreferencesDialog } from "../GroupDialogs";

type GroupHostPreferencesProps = {
    data: Array<{ name: string; value: number; tooltip: string }>;
};
export function GroupHostGroupHostPreferences({ data }: GroupHostPreferencesProps) {
    const { isMobile } = useBreakpoint();

    const [detailsDialog, setDetailsDialog] = useState({ open: false, text: "", title: "" });

    return data.map((data) => (
        <React.Fragment>
            <GamePreferencesDialog
                open={detailsDialog.open}
                title={detailsDialog.title}
                description={detailsDialog.text}
                onClose={() => setDetailsDialog((prev) => ({ ...prev, open: false }))}
            />
            <Box className="gaming-preferences-item">
                <Typography className="gaming-preferences-item-name" variant="h5">
                    {data.name}
                    <Tooltip title={data.tooltip}>
                        <InfoTwoTone
                            onClick={() => {
                                if (!isMobile) return;
                                setDetailsDialog({
                                    open: true,
                                    text: data.tooltip,
                                    title: data.name,
                                });
                            }}
                        />
                    </Tooltip>
                </Typography>
                <Slider disabled className="gaming-preferences-item-value" value={data.value} max={4} min={0} />
            </Box>
        </React.Fragment>
    ));
}
