import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Styles from "./Styles.module.css";
import { Image } from "@aws-amplify/ui-react";

export default function Interstitial({ open = false }) {
    return (
        <div>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                style={{
                    backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/darkDragon.png')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                }}
                open={open}
                className={Styles["bodyInterstitial"]}>
                <Box className={Styles["ShowLogoMobileView"]}>
                    <Image src={"/main_logo.png"} className={Styles["logo-mobile"]} />
                </Box>
                <Box className={Styles["messageInterstitial"]}>
                    <Image src={"/sphinx_animated.svg"} className={Styles["svgSphinx"]} />
                    <Typography className={Styles["titleInterstitial"]} variant="h3" sx={{ color: "#fff" }}>
                        Hold on, we’re checking for new badges
                    </Typography>
                </Box>
                <Box className={Styles["showLogoWebView"]}>
                    <Image src={"/main_logo.png"} className={Styles["logo"]} />
                </Box>
            </Backdrop>
        </div>
    );
}
