import React from "react";
import { timeRange } from "./constants";
import AccessTimeTwoToneIcon from "@mui/icons-material/AccessTimeTwoTone";

type TimeInfo = {
    time: string;
    icon: React.JSX.Element;
};

export const getUserCurrentTimeInfo = (timezone: Array<string>) => {
    if (!timezone) return { time: "-", icon: <AccessTimeTwoToneIcon /> } as TimeInfo;

    const timezoneOffset = Number(timezone[0].replaceAll(":", "."));

    const currentUtcTime = new Date();
    const offsetInMilliseconds = timezoneOffset * 60 * 60 * 1000;
    const timeInSpecifiedZone = new Date(currentUtcTime.getTime() + offsetInMilliseconds);

    // Format the time in the American format (12-hour clock)
    const americanFormattedTime = timeInSpecifiedZone.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        timeZone: "UTC",
    });

    const hour = timeInSpecifiedZone.getUTCHours();
    const currentTimeRange = timeRange.find((item) => hour >= item.startRange && hour < item.endRange);

    return { time: americanFormattedTime, icon: currentTimeRange?.icon ?? "" } as TimeInfo;
};
