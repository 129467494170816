import React, { useContext, useState, useEffect } from "react";
import { profileProvider } from "services/profileProvider";
import { checkLoggedIn } from "utils/functions";
import { FilterProvider } from "context/FilterContext";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { SortProvider } from "./SortContext";
import { MessagesProvider } from "./MessagesContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { SnackbarProvider } from "./SnackbarContext";
import { MatchScoreProvider } from "./MatchScoreContext";
import { AsyncFilterFieldsProvider } from "./AsyncFilterFieldsContext";
import { LoadingProvider } from "./LoadingContext";
import { Auth } from "aws-amplify";
import { PaginatedProvider } from "./PaginatedUserListContext";
import ErrorScreen from "pages/ErrorScreen/ErrorScreen";
import { ErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import { LoginProvider } from "./LoginContext";
import { useMediaQuery } from "@mui/material";
import { GamePageProvider } from "./GamePageContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false, // default: true
        },
    },
});

const APP_GLOBAL_VALUES = {
    unreadMessages: 0,
    privateProfileId: null,
    personalProfile: null,
    usingAdvancedFilter: false,
    isLoggedInVerified: false,
};

const GlobalContext = React.createContext(APP_GLOBAL_VALUES);

const darkTheme = createTheme({
    typography: {
        fontFamily: `"Open Sans", "Helvetica", "Arial", sans-serif`,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
    },
    palette: {
        mode: "dark",
        red: {
            pale: "#A61f38",
        },
        gray: {
            pale: "#ffffff80",
            light: "#ffffffb3",
        },
        info: {
            main: "#fff",
        },
        error: {
            main: "#FF1744",
        },
    },
    breakpoints: {
        values: {
            mobile: 0,
            tablet: 480 + 1,
            laptop: 768 + 1,
            desktop: 1200 + 1,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlinedInfo: {
                    color: "#fff",
                    borderColor: "#9E9E9E",
                },
            },
        },
    },
});

function MyFallbackComponent({ error, resetErrorBoundary, setResetApp, resetApp }) {
    return <ErrorScreen setResetApp={setResetApp} resetApp={resetApp} />;
}

export const AppContext = ({ children }) => {
    const [globalState, setGlobalState] = useState(APP_GLOBAL_VALUES);
    const [resetApp, setResetApp] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const loggedIn = await checkLoggedIn();
            if (loggedIn) {
                const personalProfile = await profileProvider.getPersonalProfile();
                const user = await Auth.currentUserInfo();

                setGlobalState((oldState) => {
                    const _personalProfile = {
                        ...personalProfile,
                    };
                    if (loggedIn) {
                        _personalProfile.role = user?.attributes?.["custom:role"];
                    }
                    return {
                        ...oldState,
                        isLoggedIn: loggedIn,
                        personalProfile: _personalProfile,
                        isLoggedInVerified: true,
                    };
                });
            } else {
                setGlobalState((oldState) => {
                    return {
                        ...oldState,
                        isLoggedInVerified: true,
                    };
                });
            }
        })();
    }, []);
    return (
        <ErrorBoundary
            onReset={() => {
                navigate("/");
            }}
            resetKeys={[resetApp]}
            FallbackComponent={() => MyFallbackComponent({ setResetApp, resetApp })}>
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MatchScoreProvider>
                        <LoadingProvider>
                            <GlobalContext.Provider value={[globalState, setGlobalState]}>
                                <AsyncFilterFieldsProvider>
                                    <ThemeProvider theme={darkTheme}>
                                        <SnackbarProvider>
                                            <FilterProvider>
                                                <SortProvider>
                                                    <PaginatedProvider>
                                                        <LoginProvider>
                                                            <MessagesProvider>
                                                                <GamePageProvider>{children}</GamePageProvider>
                                                            </MessagesProvider>
                                                        </LoginProvider>
                                                    </PaginatedProvider>
                                                </SortProvider>
                                            </FilterProvider>
                                        </SnackbarProvider>
                                    </ThemeProvider>
                                </AsyncFilterFieldsProvider>
                            </GlobalContext.Provider>
                        </LoadingProvider>
                    </MatchScoreProvider>
                </LocalizationProvider>
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export const useGlobalContext = () => {
    const [state, setState] = useContext(GlobalContext);

    const updateUnreadMessages = (qty) => setState((old) => ({ ...old, unreadMessages: qty }));

    const updatePersonalProfile = (profile) => setState((old) => ({ ...old, personalProfile: profile }));

    const updatePrivateProfileId = (id) => setState((old) => ({ ...old, privateProfileId: id }));

    const updateUserCompletedProfile = (userCompletedProfile) =>
        setState((old) => ({ ...old, userCompletedProfile: userCompletedProfile }));

    const setPublicProfile = (profile) => setState((old) => ({ ...old, publicProfile: profile }));

    const setBlockedUser = (profile) => setState((old) => ({ ...old, blockedUser: profile }));

    const updateIsLoggedIn = (isLoggedIn) => setState((old) => ({ ...old, isLoggedIn: isLoggedIn }));

    const toggleAdvancedFilter = () => setState((old) => ({ ...old, usingAdvancedFilter: !old.usingAdvancedFilter }));

    const isDesktop = useMediaQuery("(min-width:769px)");
    const isMobile = useMediaQuery("(max-width:480px)");

    return {
        privateProfileId: state?.id,
        userCompletedProfile: state.userCompletedProfile,
        unreadMessages: state.unreadMessages,
        personalProfile: state.personalProfile,
        usingAdvancedFilter: state.usingAdvancedFilter,
        updatePersonalProfile,
        updateUserCompletedProfile,
        updateUnreadMessages,
        updatePrivateProfileId,
        isLoggedIn: state.isLoggedIn,
        updateIsLoggedIn,
        toggleAdvancedFilter,
        setPublicProfile,
        publicProfile: state.publicProfile,
        blockedUser: state.blockedUser,
        setBlockedUser,
        isDesktop,
        isMobile,
        isLoggedInVerified: state.isLoggedInVerified,
    };
};
