import "./ChatNewMessageButton.scss";
import React from "react";
import { Text, View, Image } from "@aws-amplify/ui-react";
import Button from "components/Button";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { useMessages } from "@pages/Inbox/hooks/useMessages";
import { isFilled } from "utils/functions";
import { useMessagesContext } from "@context/MessagesContext";
import { useUser } from "@pages/Inbox/hooks/useUser";
import { useProfileEdit } from "hooks/useProfileEdit";
import { NewMessagePopup } from "../NewMessagePopup/NewMessagePopup";
import { Container } from "react-bootstrap";

export const ChatNewMessageButton = () => {
    const {
        clickHandler,
        inboxMessages,
        handleNewMessagePopupClick,
        loadingInboxMessages,
        loadingInboxBlockedMessages,
        getPersonalProfile,
    } = useMessages();
    const { newMessageOpen, setNewMessageOpen, filteredProfiles, loadingUsers, messageDisabled } = useMessagesContext();
    const { handleSearch } = useUser();
    const { isLoadingSave, enableMessaging } = useProfileEdit({ getPersonalProfile });

    const handleEnableMessaging = async () => {
        await enableMessaging({});
    };

    const renderSelectMessageOrDisabled = () => {
        if (messageDisabled) {
            return (
                <>
                    <Text className="message-title">Messaging Disabled</Text>
                    <Text className="message-text">
                        To send messages, allow the messaging feature again by clicking the button.
                    </Text>
                    <LoadingBtn
                        className="message-button"
                        label="ENABLE MESSAGING"
                        loading={isLoadingSave}
                        handleOnClick={handleEnableMessaging}
                    />
                </>
            );
        }

        if (isFilled(inboxMessages)) {
            return (
                <>
                    <Text className="message-title">Select a message</Text>
                    <Text className="message-text">
                        Choose from your existing conversations, start a new one, or just keep chilling.
                    </Text>
                    <Button
                        className="message-button"
                        onClick={clickHandler}
                        disabled={loadingInboxMessages || loadingInboxBlockedMessages}>
                        New message
                    </Button>
                </>
            );
        }

        return <Image className="new-message-dice" alt="dice" src="/dice_drawing.svg" />;
    };

    return (
        <Container fluid className="chat-new-message-button px-0 d-flex flex-column position-relative">
            <View className="message">{renderSelectMessageOrDisabled()}</View>
            <NewMessagePopup
                loading={loadingUsers}
                handleSearch={handleSearch}
                profiles={filteredProfiles}
                open={newMessageOpen}
                setOpen={setNewMessageOpen}
                handleNewMessagePopupClick={handleNewMessagePopupClick}
            />
        </Container>
    );
};
