import { CheckCircleTwoTone, RadioButtonUnchecked } from "@mui/icons-material"
import { Checkbox, FormControlLabel } from "@mui/material"

export const SortingOptions = ({ options, filters, handleFilters }) => {

    const optionsMap = {
        "Join Date" : {
            value: undefined,
            checked: (option) => option === undefined || option === ""
        },
        "Match Score": {
            value: "match",
            checked: (option) => option === "match"
        }
    }
    
    const isChecked = (option, value) => {
        return optionsMap[option].checked(value)
    }

    return (
        <div className="sorting-row">
            <span>Sort by:</span>
            {options.map((option, index) => (
                <FormControlLabel
                    key={index}
                    control={
                        <Checkbox
                            data-testid="filter-sorting"
                            checked={isChecked(option, filters.sort)}
                            onChange={() => handleFilters("sort", optionsMap[option].value)}
                            checkedIcon={<CheckCircleTwoTone />}
                            icon={<RadioButtonUnchecked />}
                        />
                    }
                    label={option}>
                </FormControlLabel>
            ))}
        </div>
    )
}