import { GroupsListItemProps } from "@components/Groups/GroupsList/GroupsListItem";

import { matchColor } from "@utils/matchColor";
import { ParsedSchedule, parseSchedule } from "@utils/schedule";

import { CMSLanguages, GameType, PlatformType } from "@models/cms/fileds";
import { GroupMemberTypeInList, photoObjectType } from "@models/group/GroupModel";

export type GroupsListItemInternalProps = GroupsListItemProps & {
    game?: GroupsGameType;
    host: GroupMemberTypeInList | undefined;
    joined: boolean;
    members: Array<GroupMemberTypeInList>;
    platform?: PlatformType;
    language?: CMSLanguages;
    userIsHost: boolean;
    groupIsFull: boolean;
    endSchedule: ParsedSchedule | null;
    startSchedule: ParsedSchedule | null;
    hostPhotoObject: photoObjectType;
    matchScoreColor: {
        bgColor: string;
        borderColor: string;
    };
};

export type GroupsGameType = Omit<GameType, "groupsImage"> & {
    groupsImage?: string;
};

export default function useGroupListItem({
    group,
    section,
    showPopup,
    filterFields,
    personalProfile,
    handleJoinGroup,
}: GroupsListItemProps): GroupsListItemInternalProps {
    /* Extract CMS information */
    const currentGame = filterFields.games.find((game) => game.id === group.game);
    const game: GroupsGameType | undefined = currentGame ? { ...currentGame, groupsImage: undefined } : undefined;

    if (game && currentGame?.groupsImage) {
        game.groupsImage = `https:${currentGame.groupsImage.fields.file.url}`;
    }

    const platform = filterFields.vtt.find((platform) => platform.id === group.game_platform);
    const language = filterFields.languages.find((language) => language.id === group.languages);

    /* Variables */
    const host: GroupMemberTypeInList | undefined = group.members?.find((member) => member.id === group.creator_id);
    const groupName: string = `${group.Creator}'s Group | ${game?.name}`;

    const joined: boolean = group.members?.some((member) => member.id === personalProfile?.id) || false;
    const userIsHost: boolean = group.creator_id === personalProfile?.id;

    /* Extracting member information to get host image */
    const hostPhotoObject: photoObjectType = {
        name: [host?.name || ""],
        profileURL: [host?.profileURL || ""],
        avatar_id: host?.avatar_id,
        gender: host?.gender,
    };

    /* Updating matchscore if the user is the viewer */
    if (group.creator_id === personalProfile?.id) {
        group.matchScore = 100;
    }

    /* Getting match score color based on match score */
    const matchScoreColor = matchColor(group.matchScore || 100);

    /* Filling in open seats */
    const openSeats =
        group.group_size - (group.members?.length || 0) > 0 ? group.group_size - (group.members?.length || 0) : 0;

    const emptyMember: GroupMemberTypeInList = {
        id: "",
        name: "",
        gender: "",
        avatar_id: 0,
        matchScore: 0,
        vanityUrl: "",
        profileURL: "",
        member_type: "OPEN SEAT",
    };

    /* Merging members and empty seats */
    const emptySeats: Array<GroupMemberTypeInList> = new Array(openSeats).fill(emptyMember);
    const members = [...(group.members || []), ...emptySeats];
    const groupIsFull = (group.members?.length || 0) >= group.group_size;

    /* Getting schedule */

    const startSchedule = parseSchedule(group.schedule_start);
    const endSchedule = parseSchedule(group.schedule_end);

    return {
        game,
        host,
        group,
        joined,
        members,
        section,
        platform,
        language,
        showPopup,
        userIsHost,
        endSchedule,
        groupIsFull,
        filterFields,
        startSchedule,
        matchScoreColor,
        handleJoinGroup,
        hostPhotoObject,
        personalProfile,
    };
}
