import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useNavigate, useParams } from "react-router-dom";
import Text from "@mui/material/Typography"; //rename for portability to React Native
import "./CompleteProfile.css";
import { profileProvider } from "@services/profileProvider";
import { useGlobalContext } from "context/AppContext";
import order from "./order";
import { useSnackbarContext } from "context/SnackbarContext";
import { AsyncFilterFieldsContext } from "context/AsyncFilterFieldsContext";
import { useMutation } from "react-query";
import checkAvailability from "utils/checkAvailability.ts";
import checkName from "utils/checkName.ts";
import checkValue from "utils/checkValue.ts";
import { useAtom } from "jotai";
import { surveyAtom } from "@atoms/survey";

/**
 * Generates a profile image for the personal profile asynchronously.
 *
 * @param {Object} options - The options for generating the profile image.
 * @param {Object} options.personalProfile - The personal profile object.
 * @param {Object} options.profileProvider - The profile provider object.
 * @param {Function} options.setLoadingImage - The function to set the loading state of the image.
 * @param {Function} options.updatePersonalProfile - The function to update the personal profile.
 * @return {Promise<void>} A promise that resolves when the profile image is generated.
 */
const fullfillProfileImageAsync = async ({
    personalProfile,
    profileProvider,
    setLoadingImage,
    updatePersonalProfile,
}) => {
    try {
        if (personalProfile && !personalProfile.avatar_id) {
            await profileProvider.editProfile({ avatar_id: 0 });
            const newProfile = await profileProvider.getPersonalProfile();
            updatePersonalProfile(newProfile);
        }
    } catch (error) {
        console.log("Error Creating Profile Image");
        console.error(error);
    } finally {
        setLoadingImage(false);
    }
};

const CompleteProfilePage = () => {
    const params = useParams();
    const navigation = useNavigate();
    const [surveyState, setSurvey] = useAtom(surveyAtom);

    const { showSnackbar } = useSnackbarContext();
    const { updatePersonalProfile, personalProfile } = useGlobalContext();
    const { loading, filterFields, updateFiltersFieldsAsync } = useContext(AsyncFilterFieldsContext);

    const [counter, setCounter] = useState(0);
    const [state, setState] = useState(personalProfile);
    const [profileState, setProfileState] = useState(personalProfile);
    const [loadingImage, setLoadingImage] = useState(true);
    const [editState, setEditState] = useState({});

    const navigateStack = (counterAdding) => {
        setCounter((counter) => counter + counterAdding);
        navigation(`/createprofile/${counter}`);
    };

    // move to previous question
    const goBack = () => navigateStack(-1);

    // move forward to the next question
    const skipAhead = () => navigateStack(1);
    const pushCounter = () => {
        navigateStack(1);
    };

    // get any existing profile data and store it in the state
    // NOTE: it is contained in an if statement because this page is constantly being refreshed
    const brandNewState = async () => {
        try {
            const personalProfile = await profileProvider.getPersonalProfile();
            setProfileState(true);
            setState(personalProfile);
            updatePersonalProfile(personalProfile);
        } catch (error) {
            showSnackbar({ description: error.message });
        }
    };

    const formatRequestChangeState = (request) => {
        Object.keys(request).forEach((key) => {
            const newObj = { [key]: Array.isArray(request[key]) ? Object.values(request[key]) : [request[key]] };
            setState((oldState) => ({ ...oldState, ...newObj }));
        });
    };

    const handleEditProfile = async (request) => {
        try {
            const objectKeys = Object.keys(request);
            if (objectKeys.includes("name")) {
                formatRequestChangeState(request);
                await onSaveEditProfile(request, pushCounter, formatRequestChangeState);
            } else {
                formatRequestChangeState(request);

                objectKeys.forEach((key) => {
                    if (key !== "vtt" && key !== "identityTags" && !request[key]) {
                        delete request[key];
                    }
                });
                setEditState((lastState) => ({ ...lastState, ...request }));

                pushCounter();
            }
        } catch (error) {
            showSnackbar({ description: error.message });
        }
    };

    const initialLoad = async () => {
        Auth.currentSession().catch((error) => {
            if (error === "No current user") {
                return navigation("/signup");
            }
        });

        if (params.step !== counter) {
            navigation("/createprofile/0");
        }

        if (!profileState) await brandNewState();
    };
    const onSaveEditProfile = async (editState, pushCounter) => {
        const { data } = await profileProvider.getTimezone();
        const state = { ...editState, timezone: data };

        if (state.hasOwnProperty("twitter_name")) {
            const twitter_name = state.twitter_name;
            delete state.twitter_name;
            await profileProvider.putValuesTwitter({ twitter_name: twitter_name });
        }

        await profileProvider.editProfile(state, {
            callback: () => {
                // In case a group was created, then the routes will change, no need to call pushCounter
                if (surveyState.groupCreated) setSurvey({ groupCreated: false });
                // Otherwise, we need to call pushCounter
                else pushCounter?.();
            },
            errorCallback: (e) => {
                const errorMessage = e?.response?.data;
                showSnackbar({ description: errorMessage });
                if (errorMessage === "This username has already been taken") {
                    setState((oldState) => ({ ...oldState, name: null }));
                }
            },
        });
    };

    const { mutate } = useMutation(onSaveEditProfile, {
        onSuccess: () => {
            navigation("/matchscore");
        },
        onError: (...err) => {
            //TODO: If we want to redirect the user to the survey page that we found problem, remove the comments
            // const indexPage = order.findIndex((page) => Object.keys(err[0].response.data).includes(page.name));
            // setCounter(indexPage);
            // navigation(`/createprofile/${indexPage}`);
            showSnackbar({ description: err[0].response.data });
            navigation("/user-list");
        },
        retry: 1,
    });
    const handleSaveSurvey = () => {
        mutate(editState);
    };

    const goNextButton = (stateValue, onClick) => {
        //if we change the survey order, we need to update this code:
        const isNameQuestion = counter === 2;
        const isAvailabilityQuestion = counter === 6;

        const getButtonClass = () => {
            if (isAvailabilityQuestion) {
                return checkAvailability(state) ? "go-next-btn" : "go-next-btn go-next-btn---disabled";
            } else if (isNameQuestion) {
                return checkName(state) ? "go-next-btn" : "go-next-btn go-next-btn---disabled";
            } else {
                return checkValue(stateValue) ? "go-next-btn" : "go-next-btn go-next-btn---disabled";
            }
        };

        const _onClick = () => {
            if (isNameQuestion) {
                checkName(state) && onClick();
            } else if (isAvailabilityQuestion) {
                checkAvailability(state) && onClick();
            } else {
                checkValue(stateValue) && onClick();
            }
        };
        return (
            <Text className={getButtonClass()} onClick={_onClick}>
                Next
            </Text>
        );
    };

    useEffect(initialLoad, []);

    useEffect(
        () => fullfillProfileImageAsync({ personalProfile, profileProvider, setLoadingImage, updatePersonalProfile }),
        [personalProfile?.id],
    );

    const skipButton = () => {
        return (
            <Text className="skip-txt" onClick={skipAhead}>
                Skip
            </Text>
        );
    };
    // progress through the question numerically by loading their stand along components

    const screens = order.filter((item) => {
        // In case no condition is set, then always show the screen
        if (item.condition === undefined) return true;
        // Otherwise execute the condition to check
        return item.condition(editState);
    });

    const RenderComponent = () => {
        const componentFound = screens[counter];

        if (counter < 0 || !componentFound) return null;

        return componentFound.component({
            length: screens.length - 2,
            counter,
            state,
            pushCounter,
            handleEditProfile,
            goNextButton,
            goBack,
            skipButton,
            filterFields,
            loading,
            updateFiltersFieldsAsync,
            handleSaveSurvey,
        });
    };

    if (loadingImage) return null;
    return <RenderComponent />;
};

export default CompleteProfilePage;
