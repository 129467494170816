import React, {useContext, useMemo, useState} from "react";
import { isEqual } from "lodash";
import { useSearchParams } from "react-router-dom";

export const defaultFilters = {
    age: [],
    gamesPlayed: [],
    playerType: [],
    name: "",
    safetyTools: "",
    vtt: [],
    starred: "",
    country: "",
    state: "",
    city: "",
    location: "",
    availability: false,
    badges: [],
    verified: false,
    sort: "",
    languages: []
};

export const typesOfFilter = Object.keys(defaultFilters);
export const FilterContext = React.createContext(defaultFilters);

export const useFilterContext = () => {
    const paramsHook = useSearchParams();
    const { setPreviousFilters, setCurrentFilters, setFilters, setHasFilter, currentFilters, setShowFilter  } = useContext(FilterContext)
    const setSearchParams = (params) => {
        try {
            paramsHook[1](params);
        } catch (error) {
            console.log("paramsHook is not a function", paramsHook);
        }
    };
    const setObjectToFilter = (objectFilter = {}) => {
        setSearchParams(objectFilter);
        setPreviousFilters({ ...currentFilters });
        setCurrentFilters({ ...defaultFilters, ...objectFilter });
        setFilters({ ...defaultFilters, ...objectFilter });
        setHasFilter(true);
        setShowFilter(true);
    };
    return {
        setObjectToFilter
    }
}

export const FilterProvider = ({ children }) => {
    const [filterSignIn, setFilterSignIn] = useState(null);
    const [filters, setFilters] = useState({ ...defaultFilters });
    const [currentFilters, setCurrentFilters] = useState({ ...defaultFilters });
    const [hasFilter, setHasFilter] = useState(false); // true if the current list is filtered
    const [showFilter, setShowFilter] = useState(false); // shows the pop up
    const [isFiltering, setIsFiltering] = useState(false);
    const [isOrdered, setisOrdered] = useState(false);
    const [resultsCount, setResultsCount] = useState(0);
    const hasSelectedFilters = !isEqual(filters, defaultFilters); // if true, enables the "apply" button inside the popup
    const [page, setPage] = useState(1);
    const [previousFilters, setPreviousFilters] = useState({ ...defaultFilters });
    const [isFirstSessionEntry, setIsFirstSessionEntry] = useState(true);

    const value = useMemo(
        () => ({
            filters,
            setFilters,
            hasFilter,
            showFilter,
            setShowFilter,
            setHasFilter,
            isFiltering,
            setIsFiltering,
            isOrdered,
            setisOrdered,
            resultsCount,
            setResultsCount,
            hasSelectedFilters,
            currentFilters,
            setCurrentFilters,
            page,
            setPage,
            filterSignIn,
            setFilterSignIn,
            previousFilters,
            setPreviousFilters,
            isFirstSessionEntry,
            setIsFirstSessionEntry,
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            filters,
            page,
            hasFilter,
            showFilter,
            isFiltering,
            isOrdered,
            resultsCount,
            hasSelectedFilters,
            currentFilters,
        ],
    );
    return <FilterContext.Provider value={value}>{children}</FilterContext.Provider>;
};
