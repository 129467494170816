type BaseQuestion = {
    Title: string;
    APIField: string;
    Description: string;
    Hidden?: boolean;
};

type ArrayQuestion = BaseQuestion & {
    Type: "array";
    Choices: Array<Choice>; // Required when Type is "array"
};

type Choice = {
    Title: string;
    APIField: string;
    Hidden?: boolean;
};

type NonArrayQuestion = BaseQuestion & {
    Type: "boolean" | "string" | "number";
    Choices?: never; // Disallow `Choices` for non-array types
};

export type Question = ArrayQuestion | NonArrayQuestion;

const EmailNotificationSettingsQuestions: Array<Question> = [
    {
        APIField: "ProfileNotifications",
        Title: "Profile",
        Description:
            "Keep these notifications on to receive profile reminders. Your profile is your key to better matches and personalized suggestions, ensuring you make the most of your RPGmatch experience.",
        Type: "array",
        Choices: [
            {
                Title: "Profile completion alerts",
                APIField: "ProfileCompletionAlerts",
                Hidden: true,
            },
            {
                Title: "New profile features",
                APIField: "NewProfileFeatures",
            },
        ],
    },
    {
        APIField: "UnreadMessages",
        Title: "Unread Messages",
        Description:
            "Ensure you never miss a message from potential matches or group members, keeping your game sessions and connections active and engaging.",
        Type: "array",
        Choices: [
            {
                Title: "All unread messages",
                APIField: "AllUnreadMessages",
            },
            {
                Title: "More than 10 unread messages",
                APIField: "MoreThan10UnreadMessages",
                Hidden: true,
            },
            {
                Title: "Unread messages from starred users",
                APIField: "UnreadMessagesFromStarredUsers",
                Hidden: true,
            },
        ],
    },
    {
        APIField: "GroupsMatchesAndPlayers",
        Title: "Groups, Matches & Players",
        Description:
            "Staying connected means more chances to find your perfect gaming crew! Turning off these notifications could mean missing out on the perfect game or player match.",

        Type: "array",
        Choices: [
            {
                Title: "Weekly player matches",
                APIField: "WeeklyPlayerMatches",
                Hidden: true,
            },
            {
                Title: "Send your first message",
                APIField: "SendYourFirstMessage",
                Hidden: true,
            },
            {
                Title: "Join your first group",
                APIField: "JoinYourFirstGroup",
            },
            {
                Title: "Group suggestions",
                APIField: "GroupSuggestions",
                Hidden: true,
            },
        ],
    },
    {
        APIField: "MarketingCommunications",
        Title: "Marketing communications",
        Description:
            "Our marketing communications keep you informed about everything happening at RPGmatch, including exclusive offers and events. Don't miss out on these valuable insights and opportunities.",
        Type: "array",
        Choices: [
            {
                Title: "Newsletters",
                APIField: "Newsletters",
            },
            {
                Title: "New features and updates",
                APIField: "NewFeaturesAndUpdates",
            },
        ],
    },
];
export default EmailNotificationSettingsQuestions;
