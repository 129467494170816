import "./BlockActionModal.scss";

import React from "react";

import { Modal, Box, Typography } from "@mui/material";
import { ApplyButton } from "@components/ApplyButton";

export type BlockActionModalProps = {
    title: string;
    open: boolean;
    cancel: {
        text?: string;
        onClick?: () => void;
    };
    content: React.ReactNode | string;
    confirm: {
        text?: string;
        onClick?: () => void;
    };
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function BlockActionModal({ open, setOpen, title, cancel, confirm, content }: BlockActionModalProps) {
    return (
        <Modal open={open} onClose={() => setOpen(false)}>
            <Box className="block-action-modal">
                <Typography className="block-action-modal-title" variant="h1">
                    {title}
                </Typography>
                {typeof content === "string" ? (
                    <Typography className="block-action-modal-text">{content}</Typography>
                ) : (
                    content
                )}
                <Box component={"section"} className="block-action-modal-buttons">
                    <ApplyButton spanClass="block-action-modal-button-secondary" onClick={cancel.onClick}>
                        {cancel.text}
                    </ApplyButton>
                    <ApplyButton customClass="block-action-modal-button" onClick={confirm.onClick}>
                        {confirm.text}
                    </ApplyButton>
                </Box>
            </Box>
        </Modal>
    );
}
