import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function CompleteProfileRedirect({ to }) {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const params = new URLSearchParams(searchParams);

    useEffect(() => {
        navigate(`${to}?${params.toString()}`);
    }, []);

    return null;
}
