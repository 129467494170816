import React from "react";
import useImageSrc from "hooks/useImageSrc";
import { ProfileModel } from "models/ProfileModels";
import { Avatar, SxProps, Theme } from "@mui/material";

type ProfileAvatarProps = {
    profile: ProfileModel;
    sx?: SxProps<Theme> | undefined;
    alt?: string;
};

export const ProfileAvatar = ({
    profile,
    alt = "Profile Avatar",
    sx = { width: 120, height: 120 },
}: ProfileAvatarProps) => {
    const { onError, imgRef, imgSrc } = useImageSrc(profile);

    return <Avatar ref={imgRef as any} alt={alt} src={imgSrc} sx={sx} onError={onError} />;
};
