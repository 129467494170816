import axios from "axios";
import { environmentApiUrl } from "utils/functions";
import { Auth } from "aws-amplify";

// Create an Axios instance for the RPG API.
// This instance is configured to use the environment-specific
// API URL.  It is exported and used by modules that need to
// perform CRUD operations on the RPG API.
export const api = axios.create({
    baseURL: environmentApiUrl?.(),
});

api.interceptors.request.use(
    async function (config) {
        try {
            const authentication = await Auth.currentSession();

            const token = authentication.idToken.jwtToken;
            config.headers.Authorization = token ? `Bearer ${token}` : "";
            return config;
        } catch (error) {
            return config;
        }
    },
    function (error) {
        return Promise.reject(error);
    },
);


api.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        console.error(error,`ERROR ${error}`);
        return Promise.reject(error);
    },
);
