import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { getPhotoID } from "utils/functions";

import { PlayerProps } from "models/group/PlayerProps";
import { PlayerChipProps } from "models/group/PlayerChipProps";

import { RemovePlayerDialog } from "@components/Groups/GroupDialogs";
import { OpenSeatDialog } from "@components/Groups/GroupDialogs";
import { GoldenBorder } from "@components/StyledBorders";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";
import { useGlobalContext } from "context/AppContext";
import { matchColor } from "utils/matchColor";
import { useBreakpoint } from "hooks/useBreakpoint";

export default function PlayerChip({
    paid = false,
    player,
    groupId,
    hostView,
    memberType,
    matchScore = 0,
    isEditing = false,
    handleRemovePlayer,
    personalProfile,
    handleJoinGroupDialog,
}: PlayerChipProps) {
    /* States and Hooks */
    const matchScoreColor = matchColor(matchScore);

    const { isMobile } = useBreakpoint();
    const { isLoggedIn } = useGlobalContext();

    const [openDialog_RemovePlayer, setOpenDialog_RemovePlayer] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<PlayerProps>({ name: "", id: "" });

    /* Functions */

    const handleOpenDialogRemovePlayer = (event: React.MouseEvent<SVGSVGElement, MouseEvent>, player: PlayerProps) => {
        event.preventDefault();
        setSelectedPlayer(player);
        setOpenDialog_RemovePlayer(true);
    };

    const formatPlayerName = (name: string | undefined, variant: "HOST" | "PARTICIPANT") => {
        const maxLength = isEditing ? 13 : 15;

        if (name && name.length > maxLength) return name.substring(0, maxLength - 3) + "...";
        else return name;
    };

    const conditionalRender = () => {
        switch (memberType) {
            case "host":
                return (
                    <Link to={`/profile/${player.profileUrl}`} target="_blank">
                        <GoldenBorder borderRadius={50} borderSize={0.2} enabled={paid}>
                            <Box className={`player-chip host-player ${paid ? "no-border" : ""}`}>
                                <Box {...(!hostView && { className: "matchScore-chip" })}>
                                    <GoldenBorder borderRadius={50} borderSize={3} enabled={paid && hostView}>
                                        <Box
                                            alt="host avatar"
                                            src={imgSrc}
                                            component={"img"}
                                            {...(paid && hostView && { className: "no-border" })}
                                            {...(!hostView && {
                                                border: `3px solid ${matchScoreColor.bgColor}`,
                                            })}
                                        />
                                    </GoldenBorder>
                                    <ShouldRender returnNull condition={!hostView && Boolean(isLoggedIn)}>
                                        <Box
                                            component="span"
                                            style={{
                                                backgroundColor: matchScoreColor.bgColor,
                                                color: matchScoreColor.textColor,
                                                border: `0.72px solid ${matchScoreColor.borderColor}`,
                                            }}>
                                            {matchScore}%
                                        </Box>
                                    </ShouldRender>
                                </Box>
                                <Box className="player-chip-host-info">
                                    <Typography className="player-chip-host-identifier">host</Typography>
                                    <Typography variant="h5">{formatPlayerName(player.name, "HOST")}</Typography>
                                </Box>
                            </Box>
                        </GoldenBorder>
                    </Link>
                );
            case "player":
                return (
                    <Link to={`/profile/${player.profileUrl}`} target="_blank">
                        <Box className={`player-chip ${iAmThisUser ? "user-chip" : ""}`}>
                            <Box className="matchScore-chip">
                                <Box
                                    alt="participant avatar"
                                    src={imgSrc}
                                    style={{
                                        border: `3px solid ${
                                            isLoggedIn ? matchScoreColor.bgColor : "rgb(166, 31, 56)"
                                        }`,
                                    }}
                                    component="img"
                                />
                                <ShouldRender returnNull condition={!iAmThisUser && Boolean(isLoggedIn)}>
                                    <Box
                                        component="span"
                                        style={{
                                            backgroundColor: isLoggedIn ? matchScoreColor.bgColor : "rgb(166, 31, 56)",
                                            color: isLoggedIn ? matchScoreColor.textColor : "#FFFFFF",
                                            border: `0.72px solid ${matchScoreColor.borderColor}`,
                                        }}>
                                        {matchScore}%
                                    </Box>
                                </ShouldRender>
                            </Box>

                            <Typography variant="h5">{formatPlayerName(player.name, "PARTICIPANT")}</Typography>
                            {isEditing && (
                                <Close
                                    className="remove-player-icon"
                                    onClick={(event) =>
                                        handleOpenDialogRemovePlayer(event, { name: player.name, id: player.id })
                                    }
                                />
                            )}
                        </Box>
                    </Link>
                );
            case "OPEN SEAT":
                return (
                    <Box
                        className={`${isEditing ? "player-chip player-chip-disabled" : "player-chip"}`}
                        onClick={() => !isEditing && handleJoinGroupDialog()}>
                        <img alt="host avatar" src="/avatars/open_seat_avatar.svg" />
                        <Typography variant="h5">Open Seat</Typography>
                        <ShouldRender condition={hostView && isMobile && !isEditing} returnNull>
                            <Typography className="open-seat-invite-player-chip">Invite</Typography>
                        </ShouldRender>
                    </Box>
                );
        }
    };

    /* Variables */
    const imgSrc = getPhotoID({
        name: [player.name],
        gender: player.gender,
        avatar_id: player.avatar_id,
        profileURL: [player.avatar],
    });

    const iAmThisUser: boolean = player.id === personalProfile?.id;

    return (
        <>
            {conditionalRender()}
            <RemovePlayerDialog
                handleRemovePlayer={handleRemovePlayer}
                open={openDialog_RemovePlayer}
                onClose={setOpenDialog_RemovePlayer}
                player={selectedPlayer}
                groupId={groupId}
            />
        </>
    );
}
