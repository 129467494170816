import React from "react";
import { Helmet } from "react-helmet";

type SEOHelmetProps = {
    title: string;
    description: string;
};

export const SEOHelmet = (documentMeta: SEOHelmetProps) => {
    return (
        <Helmet>
            <title>{documentMeta.title}</title>

            <meta name="twitter:title" content={documentMeta.title} />
            <meta name="description" content={documentMeta.description} />
            <meta name="twitter:description" content={documentMeta.description} />
            <meta name="twitter:image" content="https://app.rpgmatch.org/main_logo.png" />

            <meta property="og:title" content={documentMeta.title} />
            <meta property="og:description" content={documentMeta.description} />
            <meta property="og:image" content="https://app.rpgmatch.org/main_logo.png" />
            <meta property="og:url" content="https://app.rpgmatch.org/" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="RPGMatch" />
        </Helmet>
    );
};
