import "./GroupDescription.scss";

import React, { ChangeEvent, SetStateAction, useState } from "react";
import { TextareaAutosize, Box, Typography } from "@mui/material";
import { useBreakpoint } from "hooks/useBreakpoint";
import { ShouldRender } from "components/Profile/components/ShouldRender/ShouldRender";

type GroupDescriptionManagerProps = {
    description: string;
    setDescription: (value: string) => void;
};

/* CONSTANTS */
const MAX_CHARACTERS: number = 5000;
const ENABLE_MAX_CHARACTERS: boolean = true;

export default function GroupDescriptionManager({ description, setDescription }: GroupDescriptionManagerProps) {
    /* Variables */

    /* States and Hooks */
    const { isMobile } = useBreakpoint();

    /* Functions */
    function onChange(event: ChangeEvent<HTMLTextAreaElement>) {
        if (ENABLE_MAX_CHARACTERS && event.target.value.length > MAX_CHARACTERS) return;

        setDescription(event.target.value);
    }

    function DescriptionHint() {
        return (
            <Typography className="description-hint" variant="body1">
                {isMobile
                    ? `Max Characters: ${MAX_CHARACTERS}`
                    : `${MAX_CHARACTERS - description.length} out of ${MAX_CHARACTERS} characters`}
            </Typography>
        );
    }

    return (
        <Box className="profile-component description-container">
            <Typography variant="h3" className="description-header">
                Group description
            </Typography>
            <ShouldRender returnNull condition={ENABLE_MAX_CHARACTERS && isMobile}>
                {DescriptionHint()}
            </ShouldRender>
            <Box className="description-textarea-container">
                <TextareaAutosize
                    minRows={3}
                    value={description}
                    onChange={onChange}
                    className="description-textarea"
                    placeholder="Dive into adventure with our [GAME NAME] group! Every [DAY] from [SCHEDULE], we embark on epic quests and create legendary tales. With a  limit of 8 players, we ensure a personalized and immersive experience. Ready your dice for a journey of imagination, camaraderie, and fun. Join us and forge your legend in the world of [GAME NAME]."
                />
            </Box>
            <ShouldRender returnNull condition={ENABLE_MAX_CHARACTERS && !isMobile}>
                {DescriptionHint()}
            </ShouldRender>
        </Box>
    );
}
