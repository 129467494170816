import "./Inbox.scss";
import React, { useEffect, useMemo } from "react";
import { useGlobalContext } from "context/AppContext";
import { useMessagesContext } from "@context/MessagesContext";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import { InboxHeader } from "@components/InboxComponents/InboxHeader/InboxHeader";
import { InboxList } from "@components/InboxComponents/InboxList/InboxList";
import { ChatHeader } from "@components/InboxComponents/ChatHeader/ChatHeader";
import { Chat } from "@components/InboxComponents/Chat/Chat";

type ChatContainerProps = {
    fullScreenMobileClass?: boolean;
};

export const ChatContainer = ({ fullScreenMobileClass }: ChatContainerProps) => {
    return (
        <Container
            fluid
            className={`inbox-container px-0 d-flex flex-column position-relative ${
                fullScreenMobileClass ? "fullscreen-mobile" : ""
            }`}>
            <ChatHeader />
            <Chat />
        </Container>
    );
};

export const Inbox = () => {
    const { personalProfile, isDesktop } = useGlobalContext();
    const { publicID, returnInbox, setPublicID } = useMessagesContext();
    const { id } = useParams();

    const isChatClosed = useMemo(() => {
        return publicID === "" || returnInbox;
    }, [publicID, returnInbox]);

    const classes = useMemo(() => {
        const classes = "inbox-container px-0 d-flex flex-column position-relative fullscreen-mobile";
        const chatOpenClass = isChatClosed ? "" : "chat-is-open";

        return `${classes} ${chatOpenClass}`;
    }, [isChatClosed]);

    useEffect(() => {
        if (id) {
            setPublicID(id.replace(personalProfile?.id, ""));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, personalProfile]);

    useEffect(() => {
        sessionStorage.removeItem("redirect");

        if (window.visualViewport) {
            const onResize = () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty("--vh", `${vh}px`);
            };
            window.visualViewport.addEventListener("resize", onResize);
            onResize();
            return () => window.visualViewport?.removeEventListener("resize", onResize);
        }
    }, []);

    return isChatClosed || isDesktop ? (
        <Container fluid className={classes}>
            <InboxHeader />
            <InboxList />
        </Container>
    ) : (
        <ChatContainer fullScreenMobileClass />
    );
};
