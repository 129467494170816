import { api } from "./api";
import { endpoints } from "./endpoints";

export type EmailNotificationSettingsSchema = {
    // UserID: string;
    // Email: string;
    GroupsMatchesAndPlayers: {
        SendYourFirstMessage: boolean;
        WeeklyPlayerMatches: boolean;
        GroupSuggestions: boolean;
        JoinYourFirstGroup: boolean;
    };
    UnreadMessages: {
        UnreadMessagesFromStarredUsers: boolean;
        AllUnreadMessages: boolean;
        MoreThan10UnreadMessages: boolean;
    };
    MarketingCommunications: {
        Newsletters: boolean;
        NewFeaturesAndUpdates: boolean;
    };
    ProfileNotifications: {
        ProfileCompletionAlerts: boolean;
        NewProfileFeatures: boolean;
    };
};

async function getNotificationSettings(): Promise<EmailNotificationSettingsSchema> {
    return api.get<EmailNotificationSettingsSchema>(endpoints.emailNotificationSettings).then((res) => res.data);
}

async function updateNotificationSettings(
    data: Partial<EmailNotificationSettingsSchema>,
): Promise<EmailNotificationSettingsSchema> {
    return api.put<EmailNotificationSettingsSchema>(endpoints.emailNotificationSettings, data).then((res) => res.data);
}

async function createNotificationSettings(data: EmailNotificationSettingsSchema) {
    return api.post<EmailNotificationSettingsSchema>(endpoints.emailNotificationSettings, data).then((res) => res.data);
}

async function deleteNotificationSettings(): Promise<string> {
    return api.delete<string>(endpoints.emailNotificationSettings).then((res) => res.data);
}

function generateEmailNotificationSettings(default_value: boolean = true): EmailNotificationSettingsSchema {
    return {
        GroupsMatchesAndPlayers: {
            SendYourFirstMessage: default_value,
            WeeklyPlayerMatches: default_value,
            GroupSuggestions: default_value,
            JoinYourFirstGroup: default_value,
        },
        UnreadMessages: {
            UnreadMessagesFromStarredUsers: default_value,
            AllUnreadMessages: default_value,
            MoreThan10UnreadMessages: default_value,
        },
        MarketingCommunications: {
            Newsletters: default_value,
            NewFeaturesAndUpdates: default_value,
        },
        ProfileNotifications: {
            ProfileCompletionAlerts: default_value,
            NewProfileFeatures: default_value,
        },
    };
}

const emailNotificationSettingsProvider = {
    getNotificationSettings,
    updateNotificationSettings,
    createNotificationSettings,
    deleteNotificationSettings,
    generateEmailNotificationSettings,
};
export default emailNotificationSettingsProvider;
