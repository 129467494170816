import CreateGroupSuggestion from "@components/ProfileInterview/CreateGroupSuggestion";
import { IdentityTags } from "./IdentityTags";
import { Languages } from "./Languages";

import { getDefaultStore } from "jotai";
import { surveyAtom } from "@atoms/survey";
import GroupCreated from "@components/ProfileInterview/GroupCreated";

//const { SocialMediaComponent } = require("./SocialMedia");
const { AgeComponent } = require("./Age");
const { AvailabilityTableComponent } = require("./AvailabilityTable");
const { CharacterDeathComponent } = require("./CharacterDeath");
const { CompleteComponent } = require("./Complete");
const { LocationComponent } = require("./Location");
const { GamesPlayedComponent } = require("./GamesPlayed");
const { GenderComponent } = require("./Gender");
const { LatenessComponent } = require("./Lateness");
const { LGBTQComponent } = require("./LGBTQ");
const { NameComponent } = require("./Name");
const { SafetyToolsComponent } = require("./SafetyTools");
const { ThingsLikedTTRPGComponent } = require("./ThingsLikedTTRPG");
const { Welcome } = require("./Welcome");
const { VTTQuestionComponent } = require("./VTTQuestion");
const { PlayerTypeComponent } = require("./PlayerType");
const { SocialMediasComponent } = require("./SocialMedias");

const order = [
    {
        component: (props) => <Welcome {...props} />,
    },
    {
        component: (props) => <AgeComponent {...props} />,
    },
    {
        component: (props) => <NameComponent {...props} />,
    },
    {
        component: (props) => <LocationComponent {...props} />,
    },
    {
        component: (props) => <GamesPlayedComponent {...props} />,
    },
    {
        component: (props) => <Languages {...props} />,
    },
    {
        component: (props) => <AvailabilityTableComponent {...props} />,
    },
    {
        component: (props) => <ThingsLikedTTRPGComponent {...props} />,
    },
    {
        component: (props) => <SafetyToolsComponent {...props} />,
    },
    {
        component: (props) => <VTTQuestionComponent {...props} />,
    },
    {
        component: (props) => <PlayerTypeComponent {...props} />,
    },
    {
        component: (props) => <LatenessComponent {...props} />,
    },
    {
        component: (props) => <CharacterDeathComponent {...props} />,
    },
    {
        component: (props) => <GenderComponent {...props} />,
    },
    {
        component: (props) => <IdentityTags {...props} />,
    },
    {
        component: (props) => <LGBTQComponent {...props} />,
    },
    {
        component: (props) => <SocialMediasComponent {...props} />,
    },
    {
        component: (props) => <CreateGroupSuggestion {...props} />,
        condition: (editState) => ["GM only.", "Either/Both."].includes(editState?.questionnaireTransitionDM),
    },
    {
        component: (props) => <GroupCreated {...props} />,
        condition: () => {
            // Access the default jotai store (used when there is a default provider)
            const store = getDefaultStore();
            // Check if the group was created or not
            const { groupCreated } = store.get(surveyAtom);
            // Return the component only if a group was created
            return groupCreated;
        },
    },
    {
        component: (props) => <CompleteComponent {...props} />,
    },
];

export default order;
