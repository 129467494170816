import React, { useState } from "react";
import { Autocomplete, Checkbox, CircularProgress, FormControlLabel, FormGroup, TextField } from "@mui/material";
import View from "@mui/material/Box";
import Text from "@mui/material/Typography";
import LoadingBtn from "components/LoadingBtn/LoadingBtn";
import { convertArrayToObject } from "utils/functions";

export const ProfileGamesPlayed = ({
    state,
    pushCounter,
    handleEditProfile,
    isEditing = false,
    changeEditState = () => {},
    filterFields = { games: [], vtt: [] },
    loading,
}) => {
    const hasValues = () => Boolean(state?.gamesPlayed?.length);
    const hasAnything = () => state.gamesPlayed && state.gamesPlayed.includes("I'll play anything!");

    const hasState = () => {
        if (state.gamesPlayed) {
            let filteredList = convertArrayToObject(state.gamesPlayed);

            if (!isEditing && state.gamesPlayed.includes("I'll play anything!")) {
                filteredList = state.gamesPlayed.filter((game) => game?.name !== "I'll play anything!");
            }

            return filteredList;
        }

        return [];
    };

    const [anything, setAnything] = useState(hasAnything);
    const [disabledValue, setDisabledValue] = useState(!hasValues());
    const [gamesState, setGamesState] = useState(hasState());
    const [isLoading, setIsLoading] = useState(false);

    const setAnyBool = (e) => {
        setAnything((anything) => !anything);
        if (gamesState <= 0) {
            setDisabledValue(!e.target.checked);
        }
    };

    //set the text value and enable the submit button
    const addGamesPlayed = (newGames) => {
        setGamesState(newGames);
        if (isEditing && newGames) {
            changeEditState({ gamesPlayed: newGames });
        }

        setDisabledValue(newGames?.length <= 0 && !anything);
    };

    //on press of the submit button send the new value to the api
    const handleSaveValue = async (e) => {
        let games = [];

        gamesState.forEach((element) => {
            games.push(element);
        });
        if (anything) {
            games.push({ id: 715, name: "I'll play anything" });
        }
        setIsLoading(true);
        await handleEditProfile({ gamesPlayed: games });
        setIsLoading(false);
    };

    const ShouldLoad = ({ children }) => {
        if (loading) return <CircularProgress />;
        return children;
    };

    const CreatingProfile = () => {
        return (
            <View className="form-container">
                <Text as="h2">What games are you willing to play?</Text>
                <View className="button-container">
                    <FormGroup className="games-played-container">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={anything}
                                    onClick={setAnyBool}
                                    className="games-played-checkbox"
                                    size="large"
                                />
                            }
                            label={<Text>I'll play anything!</Text>}
                        />
                    </FormGroup>
                </View>
                <View className={`button-container`}>
                    <ShouldLoad>
                        <Autocomplete
                            data-testid="games-played"
                            className="games-played-selector"
                            multiple
                            filterSelectedOptions
                            value={gamesState}
                            onChange={(event, newValue) => addGamesPlayed(newValue)}
                            options={filterFields.games?.filter((x) => x.id !== 715)}
                            getOptionLabel={(game) => game.name || game}
                            renderInput={(params) => (
                                <TextField {...params} value={params.value} label="Games" placeholder="Select Games" />
                            )}
                        />
                    </ShouldLoad>
                </View>
                <View className="button-container games-played-submit">
                    <LoadingBtn
                        label="THAT'S IT"
                        loading={isLoading}
                        disabled={disabledValue}
                        handleOnClick={handleSaveValue}
                    />
                </View>
            </View>
        );
    };

    const EditingProfile = () => {
        const selectedGameIds = gamesState?.map((game) => game.id);
        const availableGames = filterFields.games?.filter((game) => !selectedGameIds.includes(game.id));
        return (
            <View className="form-container">
                <View className="button-container games-played-view">
                    <ShouldLoad>
                        <Autocomplete
                            data-testid="games-played"
                            className="games-played-selector"
                            multiple
                            filterSelectedOptions
                            value={gamesState}
                            onChange={(event, newValue) => addGamesPlayed(newValue)}
                            options={availableGames}
                            getOptionLabel={(game) => game.name || game}
                            renderInput={(params) => (
                                <TextField {...params} value={params.value} label="Games" placeholder="Select Games" />
                            )}
                        />
                    </ShouldLoad>
                </View>
            </View>
        );
    };

    /* Return the component */

    if (isEditing) return <EditingProfile />;
    else return <CreatingProfile />;
};
export default ProfileGamesPlayed;
